import { v4 as uuid } from 'uuid';

export interface IProspect {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
}

export class ProspectFactory {
  public static createProspect(): IProspect {
    return {
      id: uuid(),
      firstName: '',
      lastName: '',
      email: '',
    };
  }
}
