import React, { ChangeEvent } from 'react';

import { connect } from 'react-redux';
import Table from 'antd/lib/table';

import { IGlobalState } from '../../reducers/interfaces/state';
import { SxModal, SxInput, SxIcon } from '../common/sxLibrary';
import { fetchImpersonableUsers } from '../../actions/impersonate';
import { impersonateUser } from '../../actions/user';
import { colors } from '../../styles/variables';
import AutoEllipsisTooltip from '../project/skipLogic/common/autoEllipsisTooltip';
import { getImpersonableState } from '../../selectors/impersonate';
import { IImpersonableUser } from '../../reducers/impersonate/interfaces/impersonate';
import { getUserType } from '../../selectors/common/user';
import { UserTypes } from '../../interfaces/ISecurity';

import styles from './impersonateModal.module.scss';

interface IImpersonateModalBaseProps {
  open: boolean;
  onClose: () => void;
}

interface IStateProps {
  availableUsers: IImpersonableUser[];
  loading: boolean;
  canImpersonate: boolean;
}

interface IDispatchProps {
  impersonateUser: typeof impersonateUser;
  fetchImpersonableUsers: typeof fetchImpersonableUsers;
}

type Props = IImpersonateModalBaseProps & IStateProps & IDispatchProps;

interface IState {
  searchText: string;
}

class ImpersonateModalBase extends React.Component<Props, IState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      searchText: '',
    };
    this.onChangeSearch = this.onChangeSearch.bind(this);
    this.onCloseModal = this.onCloseModal.bind(this);
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    const { open, canImpersonate } = this.props;
    if (!prevProps.open && open && canImpersonate) {
      this.props.fetchImpersonableUsers();
    }
  }

  private onChangeSearch(e: ChangeEvent<HTMLInputElement>) {
    this.setState({ searchText: e.target.value });
  }

  private onRowClick(user: IImpersonableUser) {
    this.props.impersonateUser(user.id);
    this.props.onClose();
  }

  private onCloseModal() {
    const { onClose } = this.props;
    this.setState({ searchText: '' });
    if (onClose) {
      onClose();
    }
  }

  private getColumns() {
    return [
      {
        title: 'Name',
        dataIndex: 'name',
        render: (_, record) => <AutoEllipsisTooltip className={styles.withPointer} text={`${record.name}`} />,
      },
      {
        title: 'Email',
        dataIndex: 'email',
        render: (text) => <AutoEllipsisTooltip className={styles.withPointer} text={text} />,
      },
    ];
  }

  private getFilteredUsers() {
    const { availableUsers } = this.props;
    const { searchText } = this.state;
    const textForSearch = searchText.trim().toLowerCase();
    if (!textForSearch) {
      return availableUsers;
    }
    return availableUsers.filter((user) =>
      `${user.name}`.toLowerCase().includes(textForSearch)
      || user.email.toLowerCase().includes(textForSearch)
      || user.id.toLowerCase().includes(textForSearch));
  }

  render() {
    const { open, loading, canImpersonate } = this.props;
    const { searchText } = this.state;
    if (!canImpersonate) {
      return null;
    }
    return (
      <SxModal
        title="Impersonate User"
        visible={open}
        noFooter
        showCloseIcon
        onCancel={this.onCloseModal}
        className={styles.modal}
        headerClassName={styles.header}
        width={600}
      >
        Choose a user you want to impersonate
        <SxInput
          value={searchText}
          placeholder="Search"
          prefix={<SxIcon name="sx-search-lg" color={colors.gray500} fontSize="15px" />}
          onChange={this.onChangeSearch}
          className={styles.search}
        />
        <Table
          columns={this.getColumns()}
          dataSource={this.getFilteredUsers()}
          pagination={false}
          rowKey={(record) => record.id}
          className={styles.table}
          rowClassName={styles.row}
          sticky
          loading={loading}
          onRow={(record) => ({ onClick: () => this.onRowClick(record) })}
        />
      </SxModal>
    );
  }
}

const mapStateToProps = (state: IGlobalState): IStateProps => {
  const { fetchingImpersonables, impersonableUsers } = getImpersonableState(state);
  const loading = fetchingImpersonables;
  const availableUsers: IImpersonableUser[] = impersonableUsers.filter((user) => !user.email.includes('mailinator'));
  const loggedUserType = getUserType(state);
  const canImpersonate = [UserTypes.SX_ADMIN, UserTypes.MAIN_ADMIN, UserTypes.ADMIN_ASSISTANT].includes(loggedUserType);
  return {
    availableUsers,
    loading,
    canImpersonate,
  };
};

const mapDispatchToProps = {
  impersonateUser,
  fetchImpersonableUsers,
};

export const ImpersonateModal = connect(mapStateToProps, mapDispatchToProps)(ImpersonateModalBase);
