import React, { Component } from 'react';

import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Helmet } from 'react-helmet';

import {
  isFolderPath,
  isProjectPath,
  isAnalysisPath,
  getProjectIdFromURL,
  getSubSectionFromURL,
} from './utils/navigation';
import {
  capitalizeFirstLetter,
  insertSpaceBeforeUpperCaseLetters,
  replaceUnderscoreWithSpace,
} from './utils/string';

class DocumentTitle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      appTitle: 'SightX',
      separator: ' - ',
    };
  }

  getPageTitle() {
    const { appTitle, separator } = this.state;
    const {
      location = { pathname: '' },
      projectTitle,
      subSection,
    } = this.props;
    const titleSuffix = subSection ? `${separator}${subSection}` : '';
    let title = appTitle;

    if (isProjectPath(location.pathname) || isAnalysisPath(location.pathname)) {
      title = projectTitle ? `${projectTitle}${titleSuffix}` : '';
    } else if (!isFolderPath(location.pathname)) {
      title = `${appTitle}${titleSuffix}`;
    }

    return title;
  }

  render() {
    const { pageTitle = this.getPageTitle() } = this.props;

    return (
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { projects, activeProject } = state;
  const { location: { pathname = '' } = {} } = ownProps;
  let { project = {} } = activeProject.present;

  if (isAnalysisPath(pathname)) {
    project = projects.forAnalysis[getProjectIdFromURL(pathname)] || {};
  }

  const subSection = capitalizeFirstLetter(
    insertSpaceBeforeUpperCaseLetters(
      getSubSectionFromURL(
        replaceUnderscoreWithSpace(pathname),
      ),
    ),
  );

  return { projectTitle: project.title, subSection };
};

export const ConnectedDocumentTitle = connect(
  mapStateToProps,
  {},
)(DocumentTitle);

export default withRouter(ConnectedDocumentTitle);
