import React, { memo, useState } from 'react';

import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import { ImpersonateModal } from '../../dashboard/ImpersonateModal';
import { backToMyUser } from '../../../actions/user';
import { isImpersonating, canImpersonate, getFetchingUsers } from '../../../selectors/security/user';
import { SxIcon } from '../sxLibrary';
import { Features } from '../../../featureToggles/features';

import { GetHelp } from './GetHelp';

import styles from './index.module.scss';

export const GetHelpAndImpersonate = memo(() => {
  const [modalOpen, setModalOpen] = useState(false);

  const impersonating = useSelector(isImpersonating);
  const hasPermissionToImpersonate = useSelector(canImpersonate);
  const isFetchingUser = useSelector(getFetchingUsers);

  const dispatch = useDispatch();

  const onClose = () => setModalOpen(false);
  const onOpen = () => {
    if (impersonating) {
      dispatch(backToMyUser());
      return;
    }
    setModalOpen(true);
  };

  return <>
    <div className={styles.getHelpAndImpersonate}>
      <GetHelp />
      {hasPermissionToImpersonate && !isFetchingUser && <SxIcon
        featureToUse={Features.uirefresh}
        name={impersonating ? 'sx-impersonate-fill' : 'sx-impersonate'}
        fontSize={16}
        className={cx(styles.impersonateIcon, {
          [styles.impersonating]: impersonating,
        })}
        onClick={onOpen}
      />}
    </div>
    <ImpersonateModal
      onClose={onClose}
      open={modalOpen}
    />
  </>;
});
