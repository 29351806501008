import React, { memo, useState } from 'react';

import { useSelector } from 'react-redux';
import cx from 'classnames';

import { isLite, isNetquest } from '../../../services/platform';
import { isBrandingPath, isCommunitiesPath, isDistributeSection, isFolderPath, isHomePath, isProjectsPath } from '../utils/navigation';
import { getPathname } from '../../../selectors/common/selectors';
import { AIConsultant } from '../../aiConsultant';
import { getSectionSecurityPermissions, isLapsed } from '../../../reducers/security/selectors';
import { FEATURES, isFeatureAvailable, isFeatureDisabled, isFeatureHidden } from '../../../common/accessControl.helper';
import { EducationModalBase } from '../educationalModalBase';
import { getBrandingStatus, getRecipientsCommunityStatus } from '../../../selectors/accessControl';
import { BrandingRoutePath } from '../../../router.utils';
import { tooltipMessage } from '../../../data/sectionAccessPermissions';
import { LapsedTooltipText, UpgradeTooltipText } from '../accessControl';
import { Features, isFeatureEnabled } from '../../../featureToggles/features';

import { UpgradeToProCTA } from './UpgradeToProButton';
import { ProjectMenu } from './ProjectMenu';
import { UserMenu } from './UserMenu';
import { Option } from './Option';
import { Logo } from './logo/Logo';

import styles from './index.module.scss';

export const LeftNavBar = memo(() => {
  const pathName = useSelector(getPathname);
  const sectionSecurityPermissions = useSelector(getSectionSecurityPermissions);
  const hasBuildAccess = sectionSecurityPermissions.build ?? false;
  const hasBrandingAccess = sectionSecurityPermissions.branding ?? false;
  const isLiteEnv = isLite();
  const [isEducationalModalOpen, setEducationalModalOpenState] = useState<boolean>(false);
  const isLapsedAccount = useSelector(isLapsed);
  const brandingPolicyStatus = useSelector(getBrandingStatus);
  const recipientsCommunityStatus = useSelector(getRecipientsCommunityStatus);
  const isBrandingPolicyHidden = isFeatureHidden(brandingPolicyStatus);
  const isBrandingPolicyAvailable = isFeatureAvailable(brandingPolicyStatus);

  const closeEducationalModal = () => {
    setEducationalModalOpenState(false);
  };

  const renderHomeOption = () => {
    if (!hasBuildAccess) {
      return <Option optionClassName={styles.mainOption} key="MY_PROJECTS" icon="sx-survey" label="My Projects" route="/" active={isHomePath(pathName) || isProjectsPath(pathName) || isFolderPath(pathName)} />;
    }
    return <Option optionClassName={styles.mainOption} key="HOME" icon="sx-home" label="Home" route="/" active={isHomePath(pathName)} />;
  };

  const openEducationModal = (featureName: FEATURES) => {
    if (featureName !== FEATURES.BRANDING) {
      return null;
    }
    setEducationalModalOpenState(true);
  };

  const renderBrandingOption = () => {
    if (isBrandingPolicyHidden || isNetquest()) {
      return null;
    }
    return (
      <Option
        key="BRANDING"
        optionClassName={cx(styles.mainOption, styles.branding, { [styles.disabled]: isBrandingPolicyAvailable && !hasBrandingAccess })}
        icon="sx-paintbrush"
        label="branding"
        isBeta
        route={BrandingRoutePath}
        active={isBrandingPath(pathName)}
        allowTestTrial={!isBrandingPolicyAvailable}
        needUpgrade={!isBrandingPolicyAvailable}
        featureName={FEATURES.BRANDING}
        openEducationModalFor={openEducationModal}
        rightIconSize={14}
        disabled={!hasBrandingAccess}
        disabledText={tooltipMessage}
      />
    );
  };

  const renderCommunities = () => {
    if (isFeatureHidden(recipientsCommunityStatus) || isNetquest() || !isFeatureEnabled(Features.communities)) {
      return null;
    }
    const isRecipientsCommunityPolicyDisabled = isFeatureDisabled(recipientsCommunityStatus);
    let disabledText = isRecipientsCommunityPolicyDisabled ? UpgradeTooltipText : undefined;
    if (isLapsedAccount) {
      disabledText = LapsedTooltipText;
    }
    const isRecipientsCommunityPolicyAvailable = isFeatureAvailable(recipientsCommunityStatus);
    return (
      <Option
        key="COMMUNITIES"
        optionClassName={cx(styles.mainOption, styles.branding, { [styles.disabled]: isRecipientsCommunityPolicyDisabled })}
        icon="sx-users"
        label="Communities"
        route="/communities"
        active={isCommunitiesPath(pathName) && !isDistributeSection(pathName)}
        disabledText={disabledText}
        disabled={isRecipientsCommunityPolicyDisabled}
        allowTestTrial={!isRecipientsCommunityPolicyAvailable}
        needUpgrade={!isRecipientsCommunityPolicyAvailable}
        featureName={FEATURES.RECIPIENTS_COMMUNITY}
        rightIconSize={14}
      />
    );
  };

  const renderMyProjects = () => {
    if (!hasBuildAccess) {
      return null;
    }
    return <Option optionClassName={styles.mainOption} key="MY_PROJECTS" icon="sx-survey" label="My Projects" route="/projects" active={isProjectsPath(pathName) || isFolderPath(pathName)} />;
  };

  const renderEducationalModal = () => {
    return isEducationalModalOpen && (
      <EducationModalBase
        onClose={closeEducationalModal}
        withBackButton={false}
        featureName={FEATURES.BRANDING}
        className={styles.educationalModalIcon}
        showCloseIcon
      />
    );
  };

  return <div className={styles.leftNavigation}>
    <Logo />
    <div className={styles.initialOptions}>
      {isLiteEnv && <UpgradeToProCTA/>}
      {renderHomeOption()}
      {renderMyProjects()}
      {renderCommunities()}
      {renderBrandingOption()}
    </div>
    <ProjectMenu />
    <AIConsultant />
    <UserMenu />
    {renderEducationalModal()}
  </div>;
});
