const css = `.ExpiredTrialModal-module__header___QPWaF {
  display: flex;
  align-items: center;
  padding: 16px;
  gap: 200px;
  height: 100%;
  color: #ffffff;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  justify-content: center;
}
.ExpiredTrialModal-module__header___QPWaF .ExpiredTrialModal-module__contents___yHIO9 {
  display: flex;
  justify-content: center;
  align-items: center;
}
.ExpiredTrialModal-module__header___QPWaF span {
  font-weight: 700;
}

.ExpiredTrialModal-module__leftContainer___j1azU {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  padding: 24px;
  padding-right: 0;
  height: 100%;
  width: 100%;
  justify-content: center;
}
.ExpiredTrialModal-module__leftContainer___j1azU .ExpiredTrialModal-module__imageContainer___zov9I {
  display: flex;
  width: 100%;
  justify-content: center;
}
.ExpiredTrialModal-module__leftContainer___j1azU .ExpiredTrialModal-module__content___EfWH0 {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 40px;
}
.ExpiredTrialModal-module__leftContainer___j1azU .ExpiredTrialModal-module__content___EfWH0 p {
  margin-bottom: 0;
}`;
document.head
    .appendChild(document.createElement("style"))
    .appendChild(document.createTextNode(css));
export {css};
export default {
  "header": "ExpiredTrialModal-module__header___QPWaF",
  "contents": "ExpiredTrialModal-module__contents___yHIO9",
  "leftContainer": "ExpiredTrialModal-module__leftContainer___j1azU",
  "imageContainer": "ExpiredTrialModal-module__imageContainer___zov9I",
  "content": "ExpiredTrialModal-module__content___EfWH0"
};