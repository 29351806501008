import { isSMSEnabled } from '../../../featureToggles/sms';

import * as viewTypes from './viewTypes';

const viewTypesLabels = {
  [viewTypes.OVERVIEW]: 'Overview',
  [viewTypes.SETTINGS]: 'Settings',
  [viewTypes.SHARE]: 'Links',
  [viewTypes.SEND]: isSMSEnabled() ? 'Email / SMS' : 'Email',
  [viewTypes.QUOTAS]: 'Quotas',
  [viewTypes.PANEL]: 'Panel',
  [viewTypes.JWT_TEST]: 'JWT Test',
  [viewTypes.DATA_SYNC]: 'Data Sync',
  [viewTypes.PANEL_AUDIENCE_SAMPLE]: 'Sample',
  [viewTypes.ADMIN]: 'Admin',
  [viewTypes.COMMUNITIES]: isSMSEnabled() ? 'Email / SMS' : 'Email',
};

export default viewTypesLabels;