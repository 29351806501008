const css = `/** do not use this yet */
.index-module__content___qcaHf .index-module__field___RR0eq {
  -webkit-font-smoothing: antialiased;
  font-family: "Source Sans Pro";
}

/* Desktop - Eyebrow/Small - Semi-Bold */
.index-module__content___qcaHf .index-module__field___RR0eq {
  font-size: 14px;
  line-height: normal;
}

.index-module__content___qcaHf .index-module__field___RR0eq {
  font-weight: 400;
}

.index-module__blocked___UzUpn {
  opacity: 0.9;
}

.index-module__disabled-component___93TDb {
  opacity: 0.6;
  cursor: not-allowed;
}

.sightx .index-module__disclaimerModal___q99vh.ant-modal .ant-modal-content {
  border: none;
  border-radius: 12px;
}
.sightx .index-module__disclaimerModal___q99vh.ant-modal .ant-modal-content .ant-modal-close {
  margin: 24px 24px 0 0;
}
.sightx .index-module__disclaimerModal___q99vh.ant-modal .ant-modal-content .ant-modal-close-x {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.sightx .index-module__disclaimerModal___q99vh.ant-modal .ant-modal-content .ant-modal-body {
  padding: 0 24px 20px;
}
.sightx .index-module__disclaimerModal___q99vh.ant-modal .ant-modal-content .ant-modal-header {
  border-radius: 12px 12px 0 0;
}

.index-module__bubble___PPjWv {
  align-items: center;
  background-color: #D1FADF;
  border: 1px solid #ECFDF3;
  border-radius: 6px;
  color: #000000;
  display: inline-flex;
  font-size: 16px;
  padding: 15px 17px;
}

.index-module__content___qcaHf {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
}
.index-module__content___qcaHf .index-module__field___RR0eq {
  color: #000000;
}
.index-module__content___qcaHf .index-module__field___RR0eq .index-module__selection___sYgUL {
  margin-top: 4px;
}
.index-module__content___qcaHf .index-module__field___RR0eq .index-module__selection___sYgUL .ant-select-selection-placeholder {
  color: #98A2B3;
  font-weight: 400;
}
.index-module__content___qcaHf h2 {
  font-family: "Source Sans Pro";
  font-size: 20px;
  font-weight: 600;
  line-height: normal;
  margin: 0;
}
.index-module__content___qcaHf .index-module__textArea___TDpxi {
  font-size: 14px;
  min-height: 84px;
  resize: none;
}
.sightx .index-module__content___qcaHf .index-module__textArea___TDpxi.ant-input {
  padding: 12px 14px !important;
}
.sightx .index-module__content___qcaHf .index-module__textArea___TDpxi.ant-input::placeholder {
  font-weight: 300;
}

.ant-modal-mask {
  z-index: 1002;
}`;
document.head
    .appendChild(document.createElement("style"))
    .appendChild(document.createTextNode(css));
export {css};
export default {
  "content": "index-module__content___qcaHf",
  "field": "index-module__field___RR0eq",
  "blocked": "index-module__blocked___UzUpn",
  "disabled-component": "index-module__disabled-component___93TDb",
  "sightx": "sightx",
  "disclaimerModal": "index-module__disclaimerModal___q99vh",
  "ant-modal": "ant-modal",
  "ant-modal-content": "ant-modal-content",
  "ant-modal-close": "ant-modal-close",
  "ant-modal-close-x": "ant-modal-close-x",
  "ant-modal-body": "ant-modal-body",
  "ant-modal-header": "ant-modal-header",
  "bubble": "index-module__bubble___PPjWv",
  "selection": "index-module__selection___sYgUL",
  "ant-select-selection-placeholder": "ant-select-selection-placeholder",
  "textArea": "index-module__textArea___TDpxi",
  "ant-input": "ant-input",
  "ant-modal-mask": "ant-modal-mask"
};