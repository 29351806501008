import React from 'react';

import { NODE_ENV } from 'env';

if (['development', 'dev'].includes(NODE_ENV)) {
  const whyDidYouRender = require('@welldone-software/why-did-you-render');
  whyDidYouRender(React, {
    trackAllPureComponents: false,
    collapseGroups: true,
    logOnDifferentValues: true,
  });
}