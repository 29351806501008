import * as frontendVersionTypes from './types/frontendVersion';

export const setVersionFromFirebase = commit => {
  return {
    type: frontendVersionTypes.SET_VERSION_FROM_FIREBASE,
    payload: commit,
  };
};


export const queueAppReload = () => {
  return {
    type: frontendVersionTypes.QUEUE_APP_RELOAD
  };
};

export const appReloadSuccess = () => {
  return {
    type: frontendVersionTypes.APP_RELOAD_SUCCESS
  };
};

