import React, { Component } from 'react';

import { connect } from 'react-redux';
import cx from 'classnames';

import { toggleAudiencesFrameVisibility } from '../../actions/audiences';
import FullScreenFrame from '../../components/common/fullScreenFrame';
import AnimatedMount from '../common/AnimatedMount';

import AudiencesList from './audiencesList/audiencesList';
import RecipientsList from './recipientLists/recipientsList';
import Recipients from './recipients/recipients';
import { AudiencesContent } from './content';

import styles from './index.module.scss';

export class Audiences extends Component {
  constructor(props) {
    super(props);
    this.getAnimations = this.getAnimations.bind(this);
    this.closeAudiences = this.closeAudiences.bind(this);
  }

  getAnimations() {
    const {
      newSection,
      previousSection,
    } = this.props;
    const sectionIndexes = {
      audiences: 0,
      lists: 1,
      recipients: 2,
    };
    // when the frame opens there is no previous section set and the animation
    // should not be a slide transition because it doesn't look good
    if (previousSection === null) {
      return {
        mountAnimation: styles.fadeIn,
        unmountAnimation: styles.fadeOut,
      };
    }

    if (sectionIndexes[previousSection] < sectionIndexes[newSection]) {
      return {
        mountAnimation: styles.slideInLeft,
        unmountAnimation: styles.slideOutLeft,
      };
    }

    if (sectionIndexes[previousSection] > sectionIndexes[newSection]) {
      return {
        mountAnimation: styles.slideInRight,
        unmountAnimation: styles.slideOutRight,
      };
    }
  }

  closeAudiences() {
    this.props.toggleAudiencesFrameVisibility(false);
  }

  renderCurrentSection() {
    const { newSection } = this.props;
    // Not using a switch statement due to the animation effects used.
    // More than one section can be present at the same time when transitioning.
    const showAudiences = newSection === 'audiences';
    const showLists = newSection === 'lists';
    const showRecipients = newSection === 'recipients';

    const animations = this.getAnimations();

    return <>
      <AnimatedMount
        className={cx(styles.section)}
        show={showAudiences}
        mountAnimation={animations.mountAnimation}
        unmountAnimation={animations.unmountAnimation}
        duration="300ms"
      >
        <AudiencesList />
      </AnimatedMount>

      <AnimatedMount
        className={cx(styles.section)}
        show={showLists}
        mountAnimation={animations.mountAnimation}
        unmountAnimation={animations.unmountAnimation}
        duration="300ms"
      >
        <RecipientsList />
      </AnimatedMount>

      <AnimatedMount
        className={cx(styles.section)}
        show={showRecipients}
        mountAnimation={animations.mountAnimation}
        unmountAnimation={animations.unmountAnimation}
        duration="300ms"
      >
        <Recipients />
      </AnimatedMount>
    </>;
  }

  renderTitle() {
    const { selectingForProject, title } = this.props;
    if (!selectingForProject) {
      return 'Audiences';
    }
    return `Select Audience for ${title}`;
  }

  render() {
    // TODO:  this is temporal since the close animation is lost, fix later
    if (!this.props.show) {
      return null;
    }
    return <FullScreenFrame show={this.props.show}>
      <AudiencesContent
        title={this.renderTitle()}
        onClick={this.closeAudiences}
        renderCurrentSection={this.renderCurrentSection()}
        isForBuildSection
      />
    </FullScreenFrame>;
  }
}

const mapStateToProps = (state) => {
  const {
    audiences = {},
    activeProject: {
      present: {
        project: {
          title,
        },
      },

    },
  } = state;
  return {
    selectingForProject: audiences.selectingForProject,
    show: audiences.showDialog,
    newSection: audiences.newSection,
    previousSection: audiences.previousSection,
    title,
  };
};

export default connect(
  mapStateToProps,
  { toggleAudiencesFrameVisibility },
)(Audiences);
