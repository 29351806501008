const css = `/** do not use this yet */
/* Desktop - Eyebrow/Small - Semi-Bold */
.TrialBanner-module__blocked___uf2hp {
  opacity: 0.9;
}

.TrialBanner-module__disabled-component___ZeuIN {
  opacity: 0.6;
  cursor: not-allowed;
}

.flip-countdown {
  display: inline-block;
}

.TrialBanner-module__trialBanner___sxcPq {
  min-height: 43px;
  background-color: #DEDDEC;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr min-content 1fr;
  border-bottom: 1px solid #736dba;
}
.TrialBanner-module__trialBanner___sxcPq .TrialBanner-module__information___J5YRr {
  display: flex;
  align-items: center;
  justify-content: center;
}
.TrialBanner-module__trialBanner___sxcPq .TrialBanner-module__information___J5YRr .TrialBanner-module__message___cqhTy {
  color: #5b5b5b;
  white-space: nowrap;
}
.TrialBanner-module__trialBanner___sxcPq .TrialBanner-module__information___J5YRr .TrialBanner-module__message___cqhTy .tick {
  display: inline-block;
}
.TrialBanner-module__trialBanner___sxcPq .TrialBanner-module__information___J5YRr .TrialBanner-module__message___cqhTy .tick .tick-flip-panel {
  background-color: #736dba;
}
.TrialBanner-module__trialBanner___sxcPq .TrialBanner-module__information___J5YRr .TrialBanner-module__message___cqhTy .TrialBanner-module__red___4-FDE {
  color: #D0302F;
  font-weight: bold;
}
.TrialBanner-module__trialBanner___sxcPq .TrialBanner-module__information___J5YRr .TrialBanner-module__message___cqhTy .TrialBanner-module__red___4-FDE .tick .tick-flip-panel {
  background-color: #D0302F;
}
.TrialBanner-module__trialBanner___sxcPq .TrialBanner-module__inviteTeam___r-tcG {
  display: flex;
  justify-content: space-around;
}

.sightx .ant-btn.TrialBanner-module__submitButton___UftSP {
  background-color: #27235C;
  height: 26px;
  margin-left: 10px;
}
.sightx .ant-btn.TrialBanner-module__submitButton___UftSP span {
  font-weight: normal;
}

.TrialBanner-module__inviteButton___gXTkK {
  white-space: nowrap;
}

.sightx.uirefresh .TrialBanner-module__trialBanner___sxcPq {
  background: #D0D5DD;
  border-bottom: 0;
  height: 48px;
}
.sightx.uirefresh .TrialBanner-module__trialBanner___sxcPq .TrialBanner-module__information___J5YRr .TrialBanner-module__message___cqhTy {
  display: flex;
  gap: 11px;
  color: #393939;
  line-height: 30px;
}`;
document.head
    .appendChild(document.createElement("style"))
    .appendChild(document.createTextNode(css));
export {css};
export default {
  "blocked": "TrialBanner-module__blocked___uf2hp",
  "disabled-component": "TrialBanner-module__disabled-component___ZeuIN",
  "flip-countdown": "flip-countdown",
  "trialBanner": "TrialBanner-module__trialBanner___sxcPq",
  "information": "TrialBanner-module__information___J5YRr",
  "message": "TrialBanner-module__message___cqhTy",
  "tick": "tick",
  "tick-flip-panel": "tick-flip-panel",
  "red": "TrialBanner-module__red___4-FDE",
  "inviteTeam": "TrialBanner-module__inviteTeam___r-tcG",
  "sightx": "sightx",
  "ant-btn": "ant-btn",
  "submitButton": "TrialBanner-module__submitButton___UftSP",
  "inviteButton": "TrialBanner-module__inviteButton___gXTkK",
  "uirefresh": "uirefresh"
};