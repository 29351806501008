import React from 'react';

import { connect } from 'react-redux';

import { IGlobalState } from '../../reducers/interfaces/state';
import { getProjectIdFromURL } from '../common/utils/navigation';
import { fetchProjectStats } from '../../actions/activeProject/stats';
import { BlockedByFeature } from '../../featureToggles/features';
import { isFetchingStats } from '../../selectors/activeProject/stats';
import { triggerFloatingNotificationByProjectId } from '../../actions/analysis/conjoint2';
import { getImpersonateUser, getUser, isImpersonating } from '../../selectors/security/user';

interface IProjectStatsState {
  fetchedStats: boolean;
  conjointStatsReady: boolean;
  changedProject: boolean;
}

interface IDispatchToProps {
  fetchProjectStats: typeof fetchProjectStats;
  triggerFloatingNotificationByProjectId: typeof triggerFloatingNotificationByProjectId;
}

interface IMapStateToProps {
  projectId: string;
  isFetching: boolean;
  currentAccount: string | null;
}

type IProps = IMapStateToProps & IDispatchToProps;

class ProjectStatsBase extends React.PureComponent<IProps, IProjectStatsState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      fetchedStats: false,
      conjointStatsReady: false,
      changedProject: false,
    };
  }

  componentDidMount(): void {
    const { projectId, fetchProjectStats, currentAccount } = this.props;
    this.setState({ changedProject: !!projectId });
    if (projectId && currentAccount) {
      this.setState({ fetchedStats: true });
      fetchProjectStats(projectId);
    }
  }

  componentDidUpdate(prevProps: Readonly<IMapStateToProps>) {
    const { projectId: prevProjectId, isFetching: prevIsFetching } = prevProps;
    const { projectId, isFetching, currentAccount } = this.props;
    if (projectId && projectId !== prevProjectId) {
      this.setState({ changedProject: true, fetchedStats: false, conjointStatsReady: false });
    }
    if (this.state.changedProject && currentAccount && !this.state.fetchedStats) {
      this.setState({ fetchedStats: true });
      this.props.fetchProjectStats(projectId);
    }
    if (prevIsFetching && !isFetching) {
      this.setState({ conjointStatsReady: true });
    }

    if (currentAccount && this.state.changedProject && this.state.conjointStatsReady) {
      // this should only be triggered when user switch to another project
      // not every time the project stats changes
      this.setState({ changedProject: false });
      this.props.triggerFloatingNotificationByProjectId(projectId);
    }
  }

  render() {
    return null;
  }
}

const mapStateToProps = (state: IGlobalState): IMapStateToProps => {
  const projectId = getProjectIdFromURL();
  const isFetchingStatsResult = isFetchingStats(state);
  const isFetching = isFetchingStatsResult !== BlockedByFeature ? isFetchingStatsResult : false;
  const impersonating = isImpersonating(state);

  // This account logic is needed due to conjoint being enabled by some exclusive accounts
  // TODO: remove currentAccount when Features.conjoint is removed
  const { account: { id = null } = {} } = getUser(state) ?? {};
  const { account: { id: impersonatingId = null } = {} } = getImpersonateUser(state) ?? {};
  const currentAccount = impersonating ? impersonatingId : id;
  return {
    projectId,
    isFetching,
    currentAccount,
  };
};

const mapDispatchToProps: IDispatchToProps = {
  fetchProjectStats,
  triggerFloatingNotificationByProjectId,
};

export const ProjectStats = connect(mapStateToProps, mapDispatchToProps)(ProjectStatsBase);