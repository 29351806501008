const css = `/** do not use this yet */
/* Desktop - Eyebrow/Small - Semi-Bold */
.msal-module__blocked___jLxH6 {
  opacity: 0.9;
}

.msal-module__disabled-component___gMv3- {
  opacity: 0.6;
  cursor: not-allowed;
}

.msal-module__container___YKbBO {
  position: fixed;
  width: 100px;
  right: 200px;
  top: 0;
  z-index: 10000;
  cursor: default;
  border: 1px solid #27235C;
  border-width: 0 1px 1px 1px;
  padding: 4px 0;
  text-align: center;
  background-color: rgba(39, 35, 92, 0.5019607843);
  color: white;
}`;
document.head
    .appendChild(document.createElement("style"))
    .appendChild(document.createTextNode(css));
export {css};
export default {
  "blocked": "msal-module__blocked___jLxH6",
  "disabled-component": "msal-module__disabled-component___gMv3-",
  "container": "msal-module__container___YKbBO"
};