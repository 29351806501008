import React from 'react';

import { useDispatch } from 'react-redux';

import { SxIcon, SxTrialModal } from '../sxLibrary';
import { colors } from '../../../styles/variables';
import { Features } from '../../../featureToggles/features';
import ProLogo from '../../../assets/sightxProLogoBlack.png';
import EnterpriseLogo from '../../../assets/sightxEnterpriseLogoBlack.png';
import { renderRightBottomSection, renderRightSection } from '../../campaign/common/upgradeToTrialModal';
import { MODAL_ACCOUNT_BENEFITS } from '../educationalModalBase/data';
import { redirectToUpgradeToPro } from '../../../common/upgrade.utils';
import { contactSales } from '../../../actions/contactSales';
import { justSawTheTrialExpiredBanner } from '../../../actions/security';
import { TrialExpiredCode } from '../../../interfaces/ISecurity';

import styles from './ExpiredTrialModal.module.scss';

const renderHeader = (label: string) => {
  return (
    <div className={styles.header}>
      <div className={styles.contents}>
        Your free trial of SightX {label} has&nbsp;<b>expired</b>&nbsp;
        <SxIcon name="sx-star-plus" featureToUse={Features.uirefresh} color={colors.orange500} />
      </div>
    </div>
  );
};

interface ILeftSection {
  label: string;
  image: string;
}

const renderLeftSection = ({ label, image }: ILeftSection) => {
  const message = `Upgrade to ${label} to continue using these great features.`;
  return (
    <div className={styles.leftContainer}>
      <div className={styles.imageContainer}>
        <img src={image} alt="sightx-background"/>
      </div>
      <div className={styles.content}>
        <p><b>We hope you enjoyed your free trial of SightX {label}!</b></p>
        <p>{message}</p>
      </div>
    </div>
  );
};

const SxTrialExpiredModal = ({ code }: { code: TrialExpiredCode }) => {
  const dispatch = useDispatch();
  const onUpgradeNow = () => dispatch(contactSales());

  const closeModal = () => {
    dispatch(justSawTheTrialExpiredBanner());
  };

  const label = code === TrialExpiredCode.Enterprise ? 'Enterprise' : 'Pro';

  return (
    <SxTrialModal
      header={renderHeader(label)}
      leftSection={
        renderLeftSection({
          label,
          image: code === TrialExpiredCode.Enterprise ? EnterpriseLogo : ProLogo,
        })
      }
      rightSection={
        renderRightSection(code === TrialExpiredCode.Enterprise ? MODAL_ACCOUNT_BENEFITS.ENTERPRISE : MODAL_ACCOUNT_BENEFITS.PROFESSIONAL)
      }
      rightBottomSection={
        renderRightBottomSection({
          mainAction: code === TrialExpiredCode.Enterprise ? onUpgradeNow : redirectToUpgradeToPro,
          isForEnterprise: code === TrialExpiredCode.Enterprise,
          maybeLaterAction: closeModal,
        })
      }
      onClose={closeModal}
    />
  );
};

export { SxTrialExpiredModal };
