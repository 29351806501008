import React, { Component } from 'react';

import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import store from '../../store';
import { sendToSentryForAD } from '../../services/sentry';

import styles from './adChecking.module.scss';

const TIME_TO_WAIT = 5000;
const antIcon = <LoadingOutlined className={styles.spinner} spin />;

class AdChecking extends Component {
  constructor(props) {
    super(props);
    this.state = {
      timerId: null,
    };
    this.timeOver = this.timeOver.bind(this);
  }

  componentDidMount() {
    const timerId = setTimeout(this.timeOver, TIME_TO_WAIT);
    this.setState({
      timerId,
    });
  }

  componentWillUnmount() {
    if (this.state.timerId) {
      clearTimeout(this.state.timerId);
    }
  }

  timeOver() {
    const { redirectToUrl } = this.props;
    sendToSentryForAD(store, 'AdChecking::timeOver', { redirectToUrl });
    if (redirectToUrl && !redirectToUrl.includes('adChecking')) {
      window.location = redirectToUrl;
      return;
    }
    this.props.push('/');
  }

  render() {
    return <div className={styles.screen}>
      <Spin indicator={antIcon} />
    </div>;
  }
}
const mapStateToProps = (state) => {
  const { redirectTo } = state;
  return {
    redirectToUrl: redirectTo.url,
  };
};

export default connect(mapStateToProps, { push })(AdChecking);