import React, { Component } from 'react';

import { Modal, Button, Row } from 'antd';
import { connect } from 'react-redux';

import { SxIcon, SxModal } from '../common/sxLibrary';
import { sessionLogout, overrideSession } from '../../actions/security';
import { Features, isFeatureEnabled } from '../../featureToggles/features';
import { isApollo } from '../../services/platform';
import { ADMIN_APP_URL } from '../../common/login.utils';

import styles from './sessionModal.module.scss';

class SessionModal extends Component {
  constructor(props) {
    super(props);
    this.onOverrideSession = this.onOverrideSession.bind(this);
    this.onLogout = this.onLogout.bind(this);
  }

  onLogout() {
    if (isApollo()) {
      this.props.sessionLogout();
      return;
    }
    window.location.href = `${ADMIN_APP_URL}/login?redirectUrl=${window.location.origin}`;
  }

  onOverrideSession() {
    const { userId } = this.props;
    this.props.overrideSession(userId);
  }

  render() {
    if (isFeatureEnabled(Features.uirefresh)) {
      return (
        <SxModal
          featureToUse={Features.uirefresh}
          size="medium"
          title="Open session"
          visible
          okText="Use Here"
          cancelText="Close"
          showCloseIcon
          onCancel={this.onLogout}
          onOk={this.onOverrideSession}
        >
          <p>You have logged into SightX from another device or browser. Click "Use Here" to continue with your session on this device/browser.</p>
        </SxModal>
      );
    }

    return (
      <Modal
        visible
        title="Open session"
        footer={null}
        className={styles.sessionModal}
        closeIcon={<SxIcon name="sx-cross" />}
        onCancel={this.onLogout}
      >
        <p>You have logged into SightX from another device or browser. Click "Use Here" to continue with your session on this device/browser.</p>
        <Row justify="end" className={styles.actionWrapper}>
          <Button
            type="text"
            className={styles.logoutButton}
            onClick={this.onLogout}
          >
            Close
          </Button>
          <Button
            type="primary"
            className={styles.hereButton}
            onClick={this.onOverrideSession}
          >
            Use Here
          </Button>
        </Row>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  userId: state.security.userId,
});

export default connect(mapStateToProps, {
  sessionLogout,
  overrideSession,
})(SessionModal);