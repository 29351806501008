import React, { Component } from 'react';

import { connect } from 'react-redux';

import { Features } from '../../../featureToggles/features';
import { SxButton, SxIcon } from '../sxLibrary';

import styles from './LapsedBanner.module.scss';

class LapsedBannerBase extends Component {
  render() {
    return (
      <div className={styles.lapsedBanner}>
        <div className={styles.information}>
          <p className={styles.message}>
            <span>
              Your SightX subscription has lapsed. Restart your plan to continue using SightX.
            </span>
            <SxButton
              featureToUse={Features.uirefresh}
              onClick={() => null} // TODO: update when the callback action is defined
              type="primary"
            >
              Restart subscription &nbsp; <SxIcon color="#FFCC00" name="sx-rocket" />
            </SxButton>
          </p>
        </div>
      </div>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

export const LapsedBanner = connect(
  mapStateToProps,
  mapDispatchToProps,
)(LapsedBannerBase);
