import React, { Component } from 'react';

import { connect } from 'react-redux';
import Draggable from 'react-draggable';
import { Button, Table } from 'semantic-ui-react';
import { push } from 'connected-react-router';

import { withFirebase } from '../../services/firebase';
import { toggleImportDialog, setCurrentProgress } from '../../actions/import';
import * as parameters from '../../services/configParameters';
import { FeatureToggle, On, Off } from '../../featureToggles';
import { Features } from '../../featureToggles/features';

import { SxIcon } from './sxLibrary';
import StepProgress from './stepProgress';
import BiCircleIcon from './biCircleIcon';
import './importDialogProgress.css';

import styles from './importDialog.module.scss';

class ImportDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeDrags: 0,
      deltaPosition: {
        x: 0, y: 0,
      },
      currentStep: null,
      currentProgress: null,
      errors: [],
      showCloseButton: false,
      showRedirect: false,
    };
    const { firebase } = this.props;
    if (firebase) {
      this.db = firebase.firestore;
    }
    this.closeDialog = this.closeDialog.bind(this);
    this.goToAnalyze = this.goToAnalyze.bind(this);
    this.handleOnStart = this.handleOnStart.bind(this);
    this.handleOnStop = this.handleOnStop.bind(this);
    this.toggleMinimizeDialog = this.toggleMinimizeDialog.bind(this);
  }

  componentDidMount() {
    const { phase } = this.props;
    if (phase !== null && phase !== 'done') {
      this.connectToFirestoreDocument();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.firestoreDocumentId !== this.props.firestoreDocumentId) {
      this.connectToFirestoreDocument();
    }
  }

  handleOnStart() {
    this.setState({ activeDrags: this.state.activeDrags + 1 });
  }

  handleOnStop() {
    this.setState({ activeDrags: this.state.activeDrags - 1 });
  }

  toggleMinimizeDialog() {
    this.setState({
      minimized: !this.state.minimized,
    });
  }

  connectToFirestoreDocument() {
    console.info('FIREBASE_IMPORT_DATABASE', parameters.FIREBASE_IMPORT_DATABASE);
    if (!this.props.firestoreDocumentId) {
      return;
    }
    this.db.collection(parameters.FIREBASE_IMPORT_DATABASE).doc(this.props.firestoreDocumentId)
      .onSnapshot((doc) => {
        const data = doc.data();
        if (!data) {
          return;
        }
        this.props.setCurrentProgress({
          phase: data.phase,
          exception: !!data.exception,
        });
        const state = {
          currentStep: data.phase,
          currentProgress: data.progress,
          errors: data.errors && Object.keys(data.errors).length ? data.errors : {},
        };
        if (data.phase === 'done') {
          state.done = true;
        }
        this.setState(state);
      });
  }

  closeDialog() {
    this.props.toggleImportDialog(false);
    this.setState({
      currentStep: null,
      errors: {},
    });
  }

  goToAnalyze() {
    const { projectId, location } = this.props;
    this.closeDialog();
    if (location.pathname.indexOf('overview') !== -1) {
      window.location.reload();
    } else {
      this.props.push(`/analysis/${projectId}/overview`);
    }
  }

  renderBody() {
    const { currentStep, currentProgress, errors } = this.state;
    const { phase } = this.props;
    const isActive = phase || currentStep;
    let progress = currentProgress;
    if (currentProgress === null) {
      progress = isActive ? -1 : null;
    }
    return <div className={styles.importDialogBody}>
      <StepProgress
        currentStep={phase || currentStep}
        progress={progress}
        hasError={!!Object.keys(errors).length}
        steps={[
          'upload',
          'processing',
        ]}
      />
    </div>;
  }

  renderRowErrors(errors) {
    if (!Array.isArray(errors)) {
      return;
    }
    return errors.map((error, key) => {
      if (typeof error !== 'string') {
        return null;
      }
      return <li key={key}>{error}</li>;
    });
  }

  renderErrors() {
    const { collapsed, errors } = this.state;
    if (collapsed || !errors || !Object.keys(errors).length) {
      return null;
    }
    const errorsFound = Object.keys(errors).map((row, key) => {
      if (errors[row] && typeof errors[row][0] !== 'string') {
        return null;
      }
      return <div className={styles.errors}>
        <Table.Row key={key}>
          <Table.Cell>{row}</Table.Cell>
          <Table.Cell>
            <ul style={{ margin: 0 }}>{this.renderRowErrors(errors[row])}</ul>
          </Table.Cell>
        </Table.Row>
      </div>;
    });
    return <>
      <div className={styles.errorsTitle}>Errors</div>
      <div className={styles.importDialogErrors}>
        {errorsFound}
      </div>
    </>;
  }

  renderRedirect() {
    const { phase } = this.props;
    if (phase !== 'done') {
      return null;
    }
    return <div className={styles.redirectMessage}>
      <span>
        Your responses have been imported, it may take a few minutes for the new data to be reflected on Analyze.
      </span>
      <Button primary onClick={this.goToAnalyze}>Go to Analyze</Button>
    </div>;
  }

  renderContent() {
    const { minimized } = this.state;
    const { exception } = this.props;
    if (exception) {
      return <div className={styles.exceptionMessage}>
        <BiCircleIcon name="sx-cross" classColor="red" />
        <div className={styles.message}>
          There was an error processing the file. Please make sure the file structure is correct and try again.
        </div>
      </div>;
    }
    return <div className={minimized ? styles.minimized : ''}>
      {this.renderBody()}
      {this.renderErrors()}
      {this.renderRedirect()}
    </div>;
  }

  render() {
    const { minimized } = this.state;
    const { showDialog, projectTitle } = this.props;
    if (!showDialog) {
      return null;
    }
    return <Draggable
      onStart={this.handleOnStart}
      onStop={this.handleOnStop}
    >
      <div className={styles.importDialog}>
        <div className={styles.handler}>
          <div className={styles.handlerContent}>Import &nbsp;-&nbsp; {projectTitle}</div>
          <div className={styles.importCollapse} onClick={this.toggleMinimizeDialog}>
            <FeatureToggle feature={Features.uirefresh}>
              <Off>
                <SxIcon noFloat name={minimized ? 'sx-up' : 'sx-down'} color="#eee" />
              </Off>
              <On>
                <SxIcon
                  noFloat
                  name={minimized ? 'sx-chevron-up' : 'sx-chevron-down'}
                  color="#eee"
                  featureToUse={Features.uirefresh}
                />
              </On>
            </FeatureToggle>
          </div>
          <div className={styles.importCollapse} onClick={this.closeDialog}>
            <SxIcon noFloat name="sx-cross" color="#eee" />
          </div>
        </div>
        {this.renderContent()}
      </div>
    </Draggable>;
  }
}

function mapStateToProps(state) {
  const {
    import: {
      firestoreDocumentId,
      phase,
      exception,
      projectTitle,
      projectId,
      showDialog = false,
    } = {},
    router: {
      location,
    },
  } = state;
  return {
    firestoreDocumentId,
    phase,
    exception,
    showDialog,
    projectTitle,
    projectId,
    location,
  };
}

export default connect(
  mapStateToProps,
  {
    toggleImportDialog,
    setCurrentProgress,
    push,
  },
)(withFirebase(ImportDialog));

export { ImportDialog };
