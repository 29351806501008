const css = `/** do not use this yet */
.aiConsultantLight-module__tooltip___xRs8G {
  z-index: 1100;
}

.aiConsultantLight-module__openChatButton___sfsr- {
  grid-row: 4/5;
  margin-top: 14px;
  margin-bottom: 16px;
  cursor: pointer;
  width: 186px;
  display: flex;
  height: 43px;
  padding: 8px 16px 8px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  flex-shrink: 0;
  align-self: stretch;
  border-radius: 25px;
  background: linear-gradient(225deg, rgba(143, 103, 255, 0.5) 0%, rgba(77, 253, 242, 0.5) 100%);
  box-shadow: 0px 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08);
}
.aiConsultantLight-module__openChatButton___sfsr- .aiConsultantLight-module__buttonInnerContainer___aQwaE {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
}
.aiConsultantLight-module__openChatButton___sfsr- .aiConsultantLight-module__buttonInnerContainer___aQwaE .aiConsultantLight-module__icon___Nxj0h {
  width: 30px;
  height: 30px;
}
.aiConsultantLight-module__openChatButton___sfsr- .aiConsultantLight-module__buttonInnerContainer___aQwaE .aiConsultantLight-module__icon___Nxj0h.aiConsultantLight-module__opened___xBIWD {
  filter: invert(1);
  width: 26px;
  height: 26px;
}
.aiConsultantLight-module__openChatButton___sfsr- .aiConsultantLight-module__buttonInnerContainer___aQwaE .aiConsultantLight-module__text___vPrw7 {
  color: #ffffff;
  font-feature-settings: "clig" off, "liga" off;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 18px;
  gap: 6px;
}
.aiConsultantLight-module__openChatButton___sfsr- .aiConsultantLight-module__buttonInnerContainer___aQwaE .aiConsultantLight-module__text___vPrw7 .aiConsultantLight-module__innerText___mRQDq {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: inline-block;
}

.aiConsultantLight-module__betaTag___gQ7JT {
  color: #00936D;
  display: inline-block;
  font-size: 12px;
  font-style: italic;
  font-weight: 700;
  line-height: normal;
  top: unset;
}

@keyframes aiConsultantLight-module__slide-right___YO1BF {
  from {
    margin-left: -100%;
  }
  to {
    margin-left: 0%;
  }
}
.aiConsultantLight-module__container___-d4rW {
  position: fixed;
  bottom: 0;
  left: -100%;
  height: fit-content;
  width: calc(100vw - 218px);
  z-index: 1001;
  transition: left 0.3s;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.aiConsultantLight-module__container___-d4rW.aiConsultantLight-module__opened___xBIWD {
  left: 218px;
}
.aiConsultantLight-module__container___-d4rW.aiConsultantLight-module__opened___xBIWD.aiConsultantLight-module__chatBoxContainer___tbfm7 {
  bottom: 18px;
}
.aiConsultantLight-module__container___-d4rW.aiConsultantLight-module__chatBoxContainer___tbfm7 {
  left: calc(100% - 398px);
  max-width: 380px;
  border-radius: 6px;
  overflow: hidden;
  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03);
  bottom: calc(18px - 100%);
  transition: bottom 0.5s, left 0.3s;
}
.aiConsultantLight-module__container___-d4rW.aiConsultantLight-module__chatBoxContainer___tbfm7.aiConsultantLight-module__chatBoxWithToolBoxOpened___Cxwya {
  left: calc(100% - 948px);
}
.aiConsultantLight-module__container___-d4rW.aiConsultantLight-module__chatBoxContainer___tbfm7.aiConsultantLight-module__chatBoxWithToolBoxMinimized___DFuJz {
  left: calc(100% - 469px);
}
.aiConsultantLight-module__container___-d4rW.aiConsultantLight-module__chatBoxContainer___tbfm7 .react-resizable {
  max-height: calc(100vh - 18px);
  padding-top: 16px;
}
.aiConsultantLight-module__container___-d4rW * {
  user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
.aiConsultantLight-module__container___-d4rW .react-resizable {
  max-height: 100vh;
  bottom: 0;
  left: 0;
  width: 100%;
  background: linear-gradient(173.22deg, rgba(65, 33, 106, 0.8) 0.48%, rgba(14, 74, 78, 0.8) 53.68%, rgba(7, 23, 32, 0.8) 99.11%);
  backdrop-filter: blur(5px);
  padding-top: 8px;
}
.aiConsultantLight-module__container___-d4rW .react-resizable .aiConsultantLight-module__header___CUlbJ {
  height: 51px;
  display: flex;
  padding: 0px 16px 8px 16px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-bottom: 1px solid #98A2B3;
}
.aiConsultantLight-module__container___-d4rW .react-resizable .aiConsultantLight-module__header___CUlbJ .aiConsultantLight-module__innerHeader___KORv4 {
  width: 236px;
  display: flex;
  gap: 8px;
  align-items: center;
}
.aiConsultantLight-module__container___-d4rW .react-resizable .aiConsultantLight-module__header___CUlbJ .aiConsultantLight-module__innerHeader___KORv4 .aiConsultantLight-module__logo___A1ENL {
  width: 43px;
  height: 43px;
  filter: brightness(0%) invert(1);
}
.aiConsultantLight-module__container___-d4rW .react-resizable .aiConsultantLight-module__header___CUlbJ .aiConsultantLight-module__innerHeader___KORv4 .aiConsultantLight-module__title___G8zZe {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
}
.aiConsultantLight-module__container___-d4rW .react-resizable .aiConsultantLight-module__header___CUlbJ .aiConsultantLight-module__actions___1b6lk {
  display: flex;
  align-items: center;
  gap: 24px;
}
.aiConsultantLight-module__container___-d4rW .react-resizable .aiConsultantLight-module__header___CUlbJ .aiConsultantLight-module__actions___1b6lk.aiConsultantLight-module__chatBoxActions___KUrCn {
  gap: 20px;
}
.aiConsultantLight-module__container___-d4rW .react-resizable .aiConsultantLight-module__header___CUlbJ .aiConsultantLight-module__actions___1b6lk .aiConsultantLight-module__action___zv83y {
  align-self: center;
}
.aiConsultantLight-module__container___-d4rW .react-resizable .aiConsultantLight-module__header___CUlbJ .aiConsultantLight-module__actions___1b6lk .aiConsultantLight-module__action___zv83y.ant-btn.ant-btn-default {
  font-size: 14px;
  cursor: pointer;
  color: #ffffff;
}
.aiConsultantLight-module__container___-d4rW .react-resizable .aiConsultantLight-module__header___CUlbJ .aiConsultantLight-module__actions___1b6lk .aiConsultantLight-module__action___zv83y.ant-btn.ant-btn-default:hover, .aiConsultantLight-module__container___-d4rW .react-resizable .aiConsultantLight-module__header___CUlbJ .aiConsultantLight-module__actions___1b6lk .aiConsultantLight-module__action___zv83y.ant-btn.ant-btn-default:focus {
  color: #ffffff;
}
.aiConsultantLight-module__container___-d4rW .react-resizable .aiConsultantLight-module__header___CUlbJ .aiConsultantLight-module__actions___1b6lk .aiConsultantLight-module__action___zv83y.aiConsultantLight-module__chatBoxAction___wUYP3.ant-btn.ant-btn-default {
  padding: 8px;
}
.aiConsultantLight-module__container___-d4rW .react-resizable .aiConsultantLight-module__header___CUlbJ .aiConsultantLight-module__actions___1b6lk .aiConsultantLight-module__action___zv83y.aiConsultantLight-module__chatBoxAction___wUYP3.ant-btn.ant-btn-default .sx-icon.sx-window {
  color: #ffffff;
}
.aiConsultantLight-module__container___-d4rW .react-resizable .aiConsultantLight-module__header___CUlbJ .aiConsultantLight-module__actions___1b6lk .aiConsultantLight-module__action___zv83y.aiConsultantLight-module__chatBoxAction___wUYP3.aiConsultantLight-module__active___DIE1v.ant-btn.ant-btn-default, .aiConsultantLight-module__container___-d4rW .react-resizable .aiConsultantLight-module__header___CUlbJ .aiConsultantLight-module__actions___1b6lk .aiConsultantLight-module__action___zv83y.aiConsultantLight-module__chatBoxAction___wUYP3.aiConsultantLight-module__active___DIE1v:hover.ant-btn.ant-btn-default, .aiConsultantLight-module__container___-d4rW .react-resizable .aiConsultantLight-module__header___CUlbJ .aiConsultantLight-module__actions___1b6lk .aiConsultantLight-module__action___zv83y.aiConsultantLight-module__chatBoxAction___wUYP3.aiConsultantLight-module__active___DIE1v:focus.ant-btn.ant-btn-default, .aiConsultantLight-module__container___-d4rW .react-resizable .aiConsultantLight-module__header___CUlbJ .aiConsultantLight-module__actions___1b6lk .aiConsultantLight-module__action___zv83y.aiConsultantLight-module__chatBoxAction___wUYP3.aiConsultantLight-module__active___DIE1v:focus-visible.ant-btn.ant-btn-default {
  background-color: #000000;
}
.aiConsultantLight-module__container___-d4rW .react-resizable .aiConsultantLight-module__header___CUlbJ .aiConsultantLight-module__actions___1b6lk .aiConsultantLight-module__themeToggle___FDBtd button.ant-switch {
  background-color: #ffffff;
}
.aiConsultantLight-module__container___-d4rW .react-resizable .aiConsultantLight-module__header___CUlbJ .aiConsultantLight-module__actions___1b6lk .aiConsultantLight-module__themeToggle___FDBtd button.ant-switch > .ant-switch-handle:before {
  background-color: #475467;
}
.aiConsultantLight-module__container___-d4rW .react-resizable .aiConsultantLight-module__header___CUlbJ .aiConsultantLight-module__actions___1b6lk .aiConsultantLight-module__themeToggle___FDBtd button.ant-switch > .ant-switch-inner {
  color: #475467;
}
.aiConsultantLight-module__container___-d4rW .react-resizable .aiConsultantLight-module__header___CUlbJ .aiConsultantLight-module__actions___1b6lk .aiConsultantLight-module__themeToggle___FDBtd button.ant-switch-checked {
  background-color: #475467;
}
.aiConsultantLight-module__container___-d4rW .react-resizable .aiConsultantLight-module__header___CUlbJ .aiConsultantLight-module__actions___1b6lk .aiConsultantLight-module__themeToggle___FDBtd button.ant-switch-checked > .ant-switch-inner {
  margin-left: 2px;
  color: #ffffff;
}
.aiConsultantLight-module__container___-d4rW .react-resizable .aiConsultantLight-module__header___CUlbJ .aiConsultantLight-module__actions___1b6lk .aiConsultantLight-module__themeToggle___FDBtd button.ant-switch-checked > .ant-switch-handle:before {
  background-color: #ffffff;
}
.aiConsultantLight-module__container___-d4rW .react-resizable .aiConsultantLight-module__header___CUlbJ .aiConsultantLight-module__actions___1b6lk .aiConsultantLight-module__icon___Nxj0h {
  vertical-align: middle;
}
.aiConsultantLight-module__container___-d4rW .react-resizable .aiConsultantLight-module__innerContainer___6p-oU {
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
  height: calc(100% - 51px);
}
.aiConsultantLight-module__container___-d4rW .react-resizable .aiConsultantLight-module__innerContainer___6p-oU.aiConsultantLight-module__chatBoxInnerContainer___80nT6 {
  flex-direction: column-reverse;
}
.aiConsultantLight-module__container___-d4rW .react-resizable .aiConsultantLight-module__innerContainer___6p-oU .aiConsultantLight-module__column___sWatR {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.aiConsultantLight-module__container___-d4rW .react-resizable .aiConsultantLight-module__innerContainer___6p-oU .aiConsultantLight-module__column___sWatR.aiConsultantLight-module__chatBoxColumn___4izqr {
  height: calc(100% - 48px);
}
.aiConsultantLight-module__container___-d4rW .react-resizable .aiConsultantLight-module__innerContainer___6p-oU .aiConsultantLight-module__column___sWatR.aiConsultantLight-module__chatBoxColumn___4izqr.aiConsultantLight-module__opened___xBIWD {
  height: calc(100% - 220px);
}
.aiConsultantLight-module__container___-d4rW .react-resizable .aiConsultantLight-module__innerContainer___6p-oU .aiConsultantLight-module__column___sWatR .aiConsultantLight-module__conversation___Q79eV {
  border-bottom: 1px solid #98A2B3;
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  justify-content: center;
  scroll-behavior: smooth;
}
.aiConsultantLight-module__container___-d4rW .react-resizable .aiConsultantLight-module__innerContainer___6p-oU .aiConsultantLight-module__column___sWatR .aiConsultantLight-module__conversation___Q79eV.aiConsultantLight-module__chatBox___Im6Ty {
  padding: 0 8px;
}
.aiConsultantLight-module__container___-d4rW .react-resizable .aiConsultantLight-module__innerContainer___6p-oU .aiConsultantLight-module__column___sWatR .aiConsultantLight-module__conversation___Q79eV::-webkit-scrollbar-track {
  background-color: rgba(249, 250, 251, 0.1);
}
.aiConsultantLight-module__container___-d4rW .react-resizable .aiConsultantLight-module__innerContainer___6p-oU .aiConsultantLight-module__column___sWatR .aiConsultantLight-module__conversation___Q79eV.aiConsultantLight-module__ada2___2Cuv9 {
  scrollbar-color: #D0D5DD rgba(249, 250, 251, 0.1);
}
.aiConsultantLight-module__container___-d4rW .react-resizable .aiConsultantLight-module__innerContainer___6p-oU .aiConsultantLight-module__column___sWatR .aiConsultantLight-module__conversation___Q79eV.aiConsultantLight-module__ada2___2Cuv9::-webkit-scrollbar-thumb {
  background: #D0D5DD;
}
.aiConsultantLight-module__container___-d4rW .react-resizable .aiConsultantLight-module__innerContainer___6p-oU .aiConsultantLight-module__column___sWatR .aiConsultantLight-module__conversation___Q79eV .aiConsultantLight-module__innerConversationContainer___xe3gE {
  max-width: 784px;
  padding-top: 8px;
  padding-bottom: 16px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.aiConsultantLight-module__container___-d4rW .react-resizable .aiConsultantLight-module__innerContainer___6p-oU .aiConsultantLight-module__column___sWatR .aiConsultantLight-module__conversation___Q79eV .aiConsultantLight-module__innerConversationContainer___xe3gE.aiConsultantLight-module__chatBoxInnerConversationContainer___XRX92 {
  max-width: 360px;
}
.aiConsultantLight-module__container___-d4rW .react-resizable .aiConsultantLight-module__innerContainer___6p-oU .aiConsultantLight-module__column___sWatR .aiConsultantLight-module__conversation___Q79eV .aiConsultantLight-module__innerConversationContainer___xe3gE .aiConsultantLight-module__loader___wwtrf {
  max-width: 784px;
}
.aiConsultantLight-module__container___-d4rW .react-resizable .aiConsultantLight-module__innerContainer___6p-oU .aiConsultantLight-module__column___sWatR .aiConsultantLight-module__conversation___Q79eV .aiConsultantLight-module__innerConversationContainer___xe3gE .aiConsultantLight-module__loader___wwtrf li {
  max-width: 784px;
  height: 34px;
  border-radius: 6px;
}
.aiConsultantLight-module__container___-d4rW .react-resizable .aiConsultantLight-module__innerContainer___6p-oU .aiConsultantLight-module__column___sWatR .aiConsultantLight-module__conversation___Q79eV .aiConsultantLight-module__innerConversationContainer___xe3gE .aiConsultantLight-module__loader___wwtrf.aiConsultantLight-module__loaderChatBox___sdMiL {
  max-width: 360px;
}
.aiConsultantLight-module__container___-d4rW .react-resizable .aiConsultantLight-module__innerContainer___6p-oU .aiConsultantLight-module__column___sWatR .aiConsultantLight-module__conversation___Q79eV .aiConsultantLight-module__innerConversationContainer___xe3gE .aiConsultantLight-module__loader___wwtrf.aiConsultantLight-module__loaderChatBox___sdMiL li {
  max-width: 360px;
}
.aiConsultantLight-module__container___-d4rW .react-resizable .aiConsultantLight-module__innerContainer___6p-oU .aiConsultantLight-module__column___sWatR .aiConsultantLight-module__conversation___Q79eV .aiConsultantLight-module__innerConversationContainer___xe3gE .aiConsultantLight-module__message___VryV- {
  display: flex;
  padding: 16px;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  border-radius: 6px;
  width: 100%;
}
.aiConsultantLight-module__container___-d4rW .react-resizable .aiConsultantLight-module__innerContainer___6p-oU .aiConsultantLight-module__column___sWatR .aiConsultantLight-module__conversation___Q79eV .aiConsultantLight-module__innerConversationContainer___xe3gE .aiConsultantLight-module__message___VryV-.aiConsultantLight-module__ada___g1qGD {
  justify-content: space-between;
}
.aiConsultantLight-module__container___-d4rW .react-resizable .aiConsultantLight-module__innerContainer___6p-oU .aiConsultantLight-module__column___sWatR .aiConsultantLight-module__conversation___Q79eV .aiConsultantLight-module__innerConversationContainer___xe3gE .aiConsultantLight-module__message___VryV-.aiConsultantLight-module__ada___g1qGD .aiConsultantLight-module__copyButton___dbR4D,
.aiConsultantLight-module__container___-d4rW .react-resizable .aiConsultantLight-module__innerContainer___6p-oU .aiConsultantLight-module__column___sWatR .aiConsultantLight-module__conversation___Q79eV .aiConsultantLight-module__innerConversationContainer___xe3gE .aiConsultantLight-module__message___VryV-.aiConsultantLight-module__ada___g1qGD .aiConsultantLight-module__feedbackButton___zNRzu {
  font-size: 16px;
  width: fit-content;
}
.aiConsultantLight-module__container___-d4rW .react-resizable .aiConsultantLight-module__innerContainer___6p-oU .aiConsultantLight-module__column___sWatR .aiConsultantLight-module__conversation___Q79eV .aiConsultantLight-module__innerConversationContainer___xe3gE .aiConsultantLight-module__message___VryV-.aiConsultantLight-module__ada___g1qGD.aiConsultantLight-module__assistant___B2TaL {
  flex-direction: column;
  gap: 16px;
}
.aiConsultantLight-module__container___-d4rW .react-resizable .aiConsultantLight-module__innerContainer___6p-oU .aiConsultantLight-module__column___sWatR .aiConsultantLight-module__conversation___Q79eV .aiConsultantLight-module__innerConversationContainer___xe3gE .aiConsultantLight-module__message___VryV-.aiConsultantLight-module__ada___g1qGD.aiConsultantLight-module__assistant___B2TaL .aiConsultantLight-module__bottomColumn___-6QpR {
  align-items: center;
  border-top: 1px solid #667085;
  display: flex;
  gap: 16px;
  justify-content: flex-end;
  margin-left: 24px;
  padding-top: 16px;
  width: calc(100% - 24px);
}
.aiConsultantLight-module__container___-d4rW .react-resizable .aiConsultantLight-module__innerContainer___6p-oU .aiConsultantLight-module__column___sWatR .aiConsultantLight-module__conversation___Q79eV .aiConsultantLight-module__innerConversationContainer___xe3gE .aiConsultantLight-module__message___VryV-.aiConsultantLight-module__ada___g1qGD.aiConsultantLight-module__assistant___B2TaL .aiConsultantLight-module__bottomColumn___-6QpR .aiConsultantLight-module__feedbackButton___zNRzu {
  cursor: pointer;
  rotate: 180deg;
}
.aiConsultantLight-module__container___-d4rW .react-resizable .aiConsultantLight-module__innerContainer___6p-oU .aiConsultantLight-module__column___sWatR .aiConsultantLight-module__conversation___Q79eV .aiConsultantLight-module__innerConversationContainer___xe3gE .aiConsultantLight-module__message___VryV-.aiConsultantLight-module__ada___g1qGD.aiConsultantLight-module__assistant___B2TaL .aiConsultantLight-module__bottomColumn___-6QpR .aiConsultantLight-module__feedbackButton___zNRzu.aiConsultantLight-module__positive___OInxV {
  rotate: none;
}
.aiConsultantLight-module__container___-d4rW .react-resizable .aiConsultantLight-module__innerContainer___6p-oU .aiConsultantLight-module__column___sWatR .aiConsultantLight-module__conversation___Q79eV .aiConsultantLight-module__innerConversationContainer___xe3gE .aiConsultantLight-module__message___VryV-.aiConsultantLight-module__ada___g1qGD.aiConsultantLight-module__assistant___B2TaL .aiConsultantLight-module__leftColumn___3axgO {
  width: 100%;
}
.aiConsultantLight-module__container___-d4rW .react-resizable .aiConsultantLight-module__innerContainer___6p-oU .aiConsultantLight-module__column___sWatR .aiConsultantLight-module__conversation___Q79eV .aiConsultantLight-module__innerConversationContainer___xe3gE .aiConsultantLight-module__message___VryV-.aiConsultantLight-module__ada2___2Cuv9.aiConsultantLight-module__assistant___B2TaL {
  gap: 32px;
}
.aiConsultantLight-module__container___-d4rW .react-resizable .aiConsultantLight-module__innerContainer___6p-oU .aiConsultantLight-module__column___sWatR .aiConsultantLight-module__conversation___Q79eV .aiConsultantLight-module__innerConversationContainer___xe3gE .aiConsultantLight-module__message___VryV-.aiConsultantLight-module__ada2___2Cuv9.aiConsultantLight-module__assistant___B2TaL .aiConsultantLight-module__bottomColumn___-6QpR {
  border-top: none;
  line-height: 16px;
  padding-top: 0;
}
.aiConsultantLight-module__container___-d4rW .react-resizable .aiConsultantLight-module__innerContainer___6p-oU .aiConsultantLight-module__column___sWatR .aiConsultantLight-module__conversation___Q79eV .aiConsultantLight-module__innerConversationContainer___xe3gE .aiConsultantLight-module__message___VryV-.aiConsultantLight-module__assistant___B2TaL {
  background: rgba(0, 0, 0, 0.4);
}
.aiConsultantLight-module__container___-d4rW .react-resizable .aiConsultantLight-module__innerContainer___6p-oU .aiConsultantLight-module__column___sWatR .aiConsultantLight-module__conversation___Q79eV .aiConsultantLight-module__innerConversationContainer___xe3gE .aiConsultantLight-module__message___VryV-.aiConsultantLight-module__user___ZvIv- {
  background: rgba(255, 255, 255, 0.1);
}
.aiConsultantLight-module__container___-d4rW .react-resizable .aiConsultantLight-module__innerContainer___6p-oU .aiConsultantLight-module__column___sWatR .aiConsultantLight-module__conversation___Q79eV .aiConsultantLight-module__innerConversationContainer___xe3gE .aiConsultantLight-module__message___VryV- .aiConsultantLight-module__rightColumn___7dLHe {
  width: 40px;
  border-left: 0;
}
.aiConsultantLight-module__container___-d4rW .react-resizable .aiConsultantLight-module__innerContainer___6p-oU .aiConsultantLight-module__column___sWatR .aiConsultantLight-module__conversation___Q79eV .aiConsultantLight-module__innerConversationContainer___xe3gE .aiConsultantLight-module__message___VryV- .aiConsultantLight-module__leftColumn___3axgO {
  width: calc(100% - 40px);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}
.aiConsultantLight-module__container___-d4rW .react-resizable .aiConsultantLight-module__innerContainer___6p-oU .aiConsultantLight-module__column___sWatR .aiConsultantLight-module__conversation___Q79eV .aiConsultantLight-module__innerConversationContainer___xe3gE .aiConsultantLight-module__message___VryV- .aiConsultantLight-module__leftColumn___3axgO .aiConsultantLight-module__hyperlink___bsY7k {
  color: white;
  font-weight: bold;
  text-decoration: underline;
}
.aiConsultantLight-module__container___-d4rW .react-resizable .aiConsultantLight-module__innerContainer___6p-oU .aiConsultantLight-module__column___sWatR .aiConsultantLight-module__conversation___Q79eV .aiConsultantLight-module__innerConversationContainer___xe3gE .aiConsultantLight-module__message___VryV- .aiConsultantLight-module__leftColumn___3axgO .aiConsultantLight-module__hyperlink___bsY7k .aiConsultantLight-module__icon___Nxj0h {
  margin-left: 5px;
  font-size: 12px;
}
.aiConsultantLight-module__container___-d4rW .react-resizable .aiConsultantLight-module__innerContainer___6p-oU .aiConsultantLight-module__column___sWatR .aiConsultantLight-module__conversation___Q79eV .aiConsultantLight-module__innerConversationContainer___xe3gE .aiConsultantLight-module__message___VryV- .aiConsultantLight-module__leftColumn___3axgO .aiConsultantLight-module__cta___OkD3q {
  border-radius: 6px;
  display: inline-block;
  padding: 8px 14px;
  background-color: #00B08C;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: #000000;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  cursor: pointer;
}
.aiConsultantLight-module__container___-d4rW .react-resizable .aiConsultantLight-module__innerContainer___6p-oU .aiConsultantLight-module__column___sWatR .aiConsultantLight-module__conversation___Q79eV .aiConsultantLight-module__innerConversationContainer___xe3gE .aiConsultantLight-module__message___VryV- .aiConsultantLight-module__leftColumn___3axgO .aiConsultantLight-module__cta___OkD3q.aiConsultantLight-module__disabled___Y6mE0 {
  background-color: #979797;
  cursor: default;
}
.aiConsultantLight-module__container___-d4rW .react-resizable .aiConsultantLight-module__innerContainer___6p-oU .aiConsultantLight-module__column___sWatR .aiConsultantLight-module__conversation___Q79eV .aiConsultantLight-module__innerConversationContainer___xe3gE .aiConsultantLight-module__message___VryV- .aiConsultantLight-module__leftColumn___3axgO .aiConsultantLight-module__iconContainer___DtjQO {
  width: 16px;
  height: 16px;
  font-size: 16px;
}
.aiConsultantLight-module__container___-d4rW .react-resizable .aiConsultantLight-module__innerContainer___6p-oU .aiConsultantLight-module__column___sWatR .aiConsultantLight-module__conversation___Q79eV .aiConsultantLight-module__innerConversationContainer___xe3gE .aiConsultantLight-module__message___VryV- .aiConsultantLight-module__leftColumn___3axgO .aiConsultantLight-module__iconContainer___DtjQO .aiConsultantLight-module__aiIcon___71t0i {
  width: 16px;
  height: 16px;
  vertical-align: top;
  margin-top: 2px;
  filter: brightness(0) invert(1);
}
.aiConsultantLight-module__container___-d4rW .react-resizable .aiConsultantLight-module__innerContainer___6p-oU .aiConsultantLight-module__column___sWatR .aiConsultantLight-module__conversation___Q79eV .aiConsultantLight-module__innerConversationContainer___xe3gE .aiConsultantLight-module__message___VryV- .aiConsultantLight-module__leftColumn___3axgO .aiConsultantLight-module__iconContainer___DtjQO.aiConsultantLight-module__ada___g1qGD {
  width: 24px;
  height: 24px;
  font-size: 24px;
}
.aiConsultantLight-module__container___-d4rW .react-resizable .aiConsultantLight-module__innerContainer___6p-oU .aiConsultantLight-module__column___sWatR .aiConsultantLight-module__conversation___Q79eV .aiConsultantLight-module__innerConversationContainer___xe3gE .aiConsultantLight-module__message___VryV- .aiConsultantLight-module__leftColumn___3axgO .aiConsultantLight-module__iconContainer___DtjQO.aiConsultantLight-module__ada___g1qGD .aiConsultantLight-module__aiIcon___71t0i {
  width: 20px;
  height: 20px;
  vertical-align: top;
  margin-top: 2px;
}
.aiConsultantLight-module__container___-d4rW .react-resizable .aiConsultantLight-module__innerContainer___6p-oU .aiConsultantLight-module__column___sWatR .aiConsultantLight-module__conversation___Q79eV .aiConsultantLight-module__innerConversationContainer___xe3gE .aiConsultantLight-module__message___VryV- .aiConsultantLight-module__leftColumn___3axgO .aiConsultantLight-module__iconContainer___DtjQO.aiConsultantLight-module__ada___g1qGD .text-avatar,
.aiConsultantLight-module__container___-d4rW .react-resizable .aiConsultantLight-module__innerContainer___6p-oU .aiConsultantLight-module__column___sWatR .aiConsultantLight-module__conversation___Q79eV .aiConsultantLight-module__innerConversationContainer___xe3gE .aiConsultantLight-module__message___VryV- .aiConsultantLight-module__leftColumn___3axgO .aiConsultantLight-module__iconContainer___DtjQO.aiConsultantLight-module__ada___g1qGD .image-circular-avatar {
  width: 24px;
  height: 24px;
  font-size: 14px;
  margin: 0;
}
.aiConsultantLight-module__container___-d4rW .react-resizable .aiConsultantLight-module__innerContainer___6p-oU .aiConsultantLight-module__column___sWatR .aiConsultantLight-module__conversation___Q79eV .aiConsultantLight-module__innerConversationContainer___xe3gE .aiConsultantLight-module__message___VryV- .aiConsultantLight-module__leftColumn___3axgO .aiConsultantLight-module__text___vPrw7 {
  color: #ffffff;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 100%;
}
.aiConsultantLight-module__container___-d4rW .react-resizable .aiConsultantLight-module__innerContainer___6p-oU .aiConsultantLight-module__column___sWatR .aiConsultantLight-module__conversation___Q79eV .aiConsultantLight-module__innerConversationContainer___xe3gE .aiConsultantLight-module__message___VryV- .aiConsultantLight-module__extraQuestions___hMgxi {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 30px;
  align-self: stretch;
}
.aiConsultantLight-module__container___-d4rW .react-resizable .aiConsultantLight-module__innerContainer___6p-oU .aiConsultantLight-module__column___sWatR .aiConsultantLight-module__conversation___Q79eV .aiConsultantLight-module__innerConversationContainer___xe3gE .aiConsultantLight-module__message___VryV- .aiConsultantLight-module__extraQuestions___hMgxi .aiConsultantLight-module__text___vPrw7 {
  color: #98A2B3;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.aiConsultantLight-module__container___-d4rW .react-resizable .aiConsultantLight-module__innerContainer___6p-oU .aiConsultantLight-module__column___sWatR .aiConsultantLight-module__conversation___Q79eV .aiConsultantLight-module__innerConversationContainer___xe3gE .aiConsultantLight-module__message___VryV- .aiConsultantLight-module__extraQuestions___hMgxi .aiConsultantLight-module__questionsContainer___rL9uZ {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}
.aiConsultantLight-module__container___-d4rW .react-resizable .aiConsultantLight-module__innerContainer___6p-oU .aiConsultantLight-module__column___sWatR .aiConsultantLight-module__conversation___Q79eV .aiConsultantLight-module__innerConversationContainer___xe3gE .aiConsultantLight-module__message___VryV- .aiConsultantLight-module__extraQuestions___hMgxi .aiConsultantLight-module__questionsContainer___rL9uZ .aiConsultantLight-module__question___0g03j {
  display: flex;
  padding: 4px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  border-radius: 20px;
  border: #EAECF0;
  background-color: #ffffff;
  cursor: pointer;
  color: #475467;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.aiConsultantLight-module__container___-d4rW .react-resizable .aiConsultantLight-module__innerContainer___6p-oU .aiConsultantLight-module__column___sWatR .aiConsultantLight-module__conversation___Q79eV .aiConsultantLight-module__innerConversationContainer___xe3gE .aiConsultantLight-module__message___VryV- .aiConsultantLight-module__extraQuestions___hMgxi .aiConsultantLight-module__questionsContainer___rL9uZ .aiConsultantLight-module__question___0g03j:hover {
  background-color: #736DBA;
  color: #ffffff;
}
.aiConsultantLight-module__container___-d4rW .react-resizable .aiConsultantLight-module__innerContainer___6p-oU .aiConsultantLight-module__column___sWatR .aiConsultantLight-module__conversation___Q79eV .aiConsultantLight-module__innerConversationContainer___xe3gE .aiConsultantLight-module__fetchingAiLogoContainer___a4Tf- {
  width: 40px;
  height: 40px;
  margin-left: 8px;
}
.aiConsultantLight-module__container___-d4rW .react-resizable .aiConsultantLight-module__innerContainer___6p-oU .aiConsultantLight-module__column___sWatR .aiConsultantLight-module__conversation___Q79eV .aiConsultantLight-module__innerConversationContainer___xe3gE .aiConsultantLight-module__fetchingAiLogoContainer___a4Tf- .aiConsultantLight-module__fetchingAiLogo___faxAT {
  width: 100%;
}
.aiConsultantLight-module__container___-d4rW .react-resizable .aiConsultantLight-module__innerContainer___6p-oU .aiConsultantLight-module__column___sWatR .aiConsultantLight-module__conversation___Q79eV .aiConsultantLight-module__conversationEnd___KBlXc {
  height: 0;
  width: 100%;
}
.aiConsultantLight-module__container___-d4rW .react-resizable .aiConsultantLight-module__innerContainer___6p-oU .aiConsultantLight-module__column___sWatR .aiConsultantLight-module__inputContainer___Xx3lD {
  height: 85px;
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 16px;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}
.aiConsultantLight-module__container___-d4rW .react-resizable .aiConsultantLight-module__innerContainer___6p-oU .aiConsultantLight-module__column___sWatR .aiConsultantLight-module__inputContainer___Xx3lD.aiConsultantLight-module__ada2___2Cuv9 {
  height: unset;
}
.aiConsultantLight-module__container___-d4rW .react-resizable .aiConsultantLight-module__innerContainer___6p-oU .aiConsultantLight-module__column___sWatR .aiConsultantLight-module__inputContainer___Xx3lD .aiConsultantLight-module__inputInnerContainer___72aaw {
  max-width: 784px;
  width: 100%;
  display: flex;
  gap: 8px;
  flex-direction: column;
}
.aiConsultantLight-module__container___-d4rW .react-resizable .aiConsultantLight-module__innerContainer___6p-oU .aiConsultantLight-module__column___sWatR .aiConsultantLight-module__inputContainer___Xx3lD .aiConsultantLight-module__inputInnerContainer___72aaw .aiConsultantLight-module__input___wlEOf.ant-input-affix-wrapper-focused {
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0 0 0 4px rgba(231, 231, 240, 0.25);
}
.aiConsultantLight-module__container___-d4rW .react-resizable .aiConsultantLight-module__innerContainer___6p-oU .aiConsultantLight-module__column___sWatR .aiConsultantLight-module__inputContainer___Xx3lD .aiConsultantLight-module__inputInnerContainer___72aaw .aiConsultantLight-module__textContainer___srvqe {
  position: relative;
}
.aiConsultantLight-module__container___-d4rW .react-resizable .aiConsultantLight-module__innerContainer___6p-oU .aiConsultantLight-module__column___sWatR .aiConsultantLight-module__inputContainer___Xx3lD .aiConsultantLight-module__inputInnerContainer___72aaw .aiConsultantLight-module__textContainer___srvqe .aiConsultantLight-module__textArea___TbgTp {
  padding-right: 30px !important;
  scroll-behavior: smooth;
  scrollbar-color: #D0D5DD rgba(249, 250, 251, 0.1);
}
.aiConsultantLight-module__container___-d4rW .react-resizable .aiConsultantLight-module__innerContainer___6p-oU .aiConsultantLight-module__column___sWatR .aiConsultantLight-module__inputContainer___Xx3lD .aiConsultantLight-module__inputInnerContainer___72aaw .aiConsultantLight-module__textContainer___srvqe .aiConsultantLight-module__textArea___TbgTp::-webkit-scrollbar-track {
  background-color: rgba(249, 250, 251, 0.1);
}
.aiConsultantLight-module__container___-d4rW .react-resizable .aiConsultantLight-module__innerContainer___6p-oU .aiConsultantLight-module__column___sWatR .aiConsultantLight-module__inputContainer___Xx3lD .aiConsultantLight-module__inputInnerContainer___72aaw .aiConsultantLight-module__textContainer___srvqe .aiConsultantLight-module__textArea___TbgTp::-webkit-scrollbar-thumb {
  background: #D0D5DD;
}
.aiConsultantLight-module__container___-d4rW .react-resizable .aiConsultantLight-module__innerContainer___6p-oU .aiConsultantLight-module__column___sWatR .aiConsultantLight-module__inputContainer___Xx3lD .aiConsultantLight-module__inputInnerContainer___72aaw .aiConsultantLight-module__textContainer___srvqe .aiConsultantLight-module__textArea___TbgTp:focus {
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0 0 0 4px rgba(231, 231, 240, 0.25);
}
.aiConsultantLight-module__container___-d4rW .react-resizable .aiConsultantLight-module__innerContainer___6p-oU .aiConsultantLight-module__column___sWatR .aiConsultantLight-module__inputContainer___Xx3lD .aiConsultantLight-module__inputInnerContainer___72aaw .aiConsultantLight-module__textContainer___srvqe .aiConsultantLight-module__actionButton___rhctf {
  bottom: 2px;
  position: absolute;
  right: 12px;
}
.aiConsultantLight-module__container___-d4rW .react-resizable .aiConsultantLight-module__innerContainer___6p-oU .aiConsultantLight-module__column___sWatR .aiConsultantLight-module__inputContainer___Xx3lD .aiConsultantLight-module__inputInnerContainer___72aaw .aiConsultantLight-module__action___zv83y {
  cursor: pointer;
}
.aiConsultantLight-module__container___-d4rW .react-resizable .aiConsultantLight-module__innerContainer___6p-oU .aiConsultantLight-module__column___sWatR .aiConsultantLight-module__inputContainer___Xx3lD .aiConsultantLight-module__inputInnerContainer___72aaw .aiConsultantLight-module__disclaimer___qyl-w {
  color: #D0D5DD;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
}
.aiConsultantLight-module__container___-d4rW .react-resizable .aiConsultantLight-module__innerContainer___6p-oU .aiConsultantLight-module__column___sWatR .aiConsultantLight-module__popularTopics___D5qlV {
  width: 100%;
  padding: 16px 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  flex: 1 0 0;
  align-self: stretch;
  overflow: hidden;
  position: relative;
}
.aiConsultantLight-module__container___-d4rW .react-resizable .aiConsultantLight-module__innerContainer___6p-oU .aiConsultantLight-module__column___sWatR .aiConsultantLight-module__popularTopics___D5qlV .aiConsultantLight-module__popularTopicsHeader___m5IVJ {
  padding: 0 16px;
  display: flex;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}
.aiConsultantLight-module__container___-d4rW .react-resizable .aiConsultantLight-module__innerContainer___6p-oU .aiConsultantLight-module__column___sWatR .aiConsultantLight-module__popularTopics___D5qlV .aiConsultantLight-module__popularTopicsHeader___m5IVJ .aiConsultantLight-module__title___G8zZe {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
}
.aiConsultantLight-module__container___-d4rW .react-resizable .aiConsultantLight-module__innerContainer___6p-oU .aiConsultantLight-module__column___sWatR .aiConsultantLight-module__popularTopics___D5qlV .aiConsultantLight-module__popularTopicsHeader___m5IVJ .aiConsultantLight-module__title___G8zZe .aiConsultantLight-module__main___wtIlO {
  color: #5AC7FA;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 2.4px;
  text-transform: uppercase;
}
.aiConsultantLight-module__container___-d4rW .react-resizable .aiConsultantLight-module__innerContainer___6p-oU .aiConsultantLight-module__column___sWatR .aiConsultantLight-module__popularTopics___D5qlV .aiConsultantLight-module__popularTopicsHeader___m5IVJ .aiConsultantLight-module__title___G8zZe .aiConsultantLight-module__secondary___l7-gN {
  font-family: Source Sans Pro;
  color: #EAECF0;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.aiConsultantLight-module__container___-d4rW .react-resizable .aiConsultantLight-module__innerContainer___6p-oU .aiConsultantLight-module__column___sWatR .aiConsultantLight-module__popularTopics___D5qlV .aiConsultantLight-module__popularTopicsHeader___m5IVJ .aiConsultantLight-module__title___G8zZe .aiConsultantLight-module__secondary___l7-gN.aiConsultantLight-module__hidden___ve9kA {
  display: none;
}
.aiConsultantLight-module__container___-d4rW .react-resizable .aiConsultantLight-module__innerContainer___6p-oU .aiConsultantLight-module__column___sWatR .aiConsultantLight-module__popularTopics___D5qlV .aiConsultantLight-module__popularTopicsHeader___m5IVJ .aiConsultantLight-module__close___nrjMo {
  font-size: 16px;
  cursor: pointer;
}
.aiConsultantLight-module__container___-d4rW .react-resizable .aiConsultantLight-module__innerContainer___6p-oU .aiConsultantLight-module__column___sWatR .aiConsultantLight-module__popularTopics___D5qlV .aiConsultantLight-module__links___B3ImV {
  padding: 0 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  overflow: auto;
}
.aiConsultantLight-module__container___-d4rW .react-resizable .aiConsultantLight-module__innerContainer___6p-oU .aiConsultantLight-module__column___sWatR .aiConsultantLight-module__popularTopics___D5qlV .aiConsultantLight-module__links___B3ImV.aiConsultantLight-module__hidden___ve9kA {
  height: 0;
  transition: 300ms height;
}
.aiConsultantLight-module__container___-d4rW .react-resizable .aiConsultantLight-module__innerContainer___6p-oU .aiConsultantLight-module__column___sWatR .aiConsultantLight-module__popularTopics___D5qlV .aiConsultantLight-module__links___B3ImV::-webkit-scrollbar-track {
  background-color: rgba(249, 250, 251, 0.1);
}
.aiConsultantLight-module__container___-d4rW .react-resizable .aiConsultantLight-module__innerContainer___6p-oU .aiConsultantLight-module__column___sWatR .aiConsultantLight-module__popularTopics___D5qlV .aiConsultantLight-module__links___B3ImV .aiConsultantLight-module__link___hMvvq {
  display: flex;
  padding: 4px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  border-radius: 20px;
  border: 1px solid #EAECF0;
  background: #ffffff;
  color: #475467;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  cursor: pointer;
}
.aiConsultantLight-module__container___-d4rW .react-resizable .aiConsultantLight-module__innerContainer___6p-oU .aiConsultantLight-module__column___sWatR .aiConsultantLight-module__popularTopics___D5qlV .aiConsultantLight-module__links___B3ImV .aiConsultantLight-module__link___hMvvq:hover {
  background: rgba(0, 0, 0, 0.4);
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
  color: #ffffff;
  border-color: transparent;
}
.aiConsultantLight-module__container___-d4rW .react-resizable .aiConsultantLight-module__innerContainer___6p-oU .aiConsultantLight-module__column___sWatR .aiConsultantLight-module__popularTopics___D5qlV .aiConsultantLight-module__links___B3ImV .aiConsultantLight-module__link___hMvvq.aiConsultantLight-module__disabled___Y6mE0 {
  pointer-events: none;
  opacity: 0.4;
}
.aiConsultantLight-module__container___-d4rW .react-resizable .aiConsultantLight-module__innerContainer___6p-oU .aiConsultantLight-module__rightColumn___7dLHe {
  border-left: 1px solid #98A2B3;
  width: 319px;
  min-width: 319px;
  max-width: 319px;
  margin-right: -319px;
  transition: 300ms margin-right;
}
.aiConsultantLight-module__container___-d4rW .react-resizable .aiConsultantLight-module__innerContainer___6p-oU .aiConsultantLight-module__rightColumn___7dLHe.aiConsultantLight-module__chatBoxMode___AUpne {
  width: 100%;
  max-width: 100%;
  margin-right: 0;
  border: none;
  transition: 300ms height;
}
.aiConsultantLight-module__container___-d4rW .react-resizable .aiConsultantLight-module__innerContainer___6p-oU .aiConsultantLight-module__rightColumn___7dLHe.aiConsultantLight-module__chatBoxMode___AUpne.aiConsultantLight-module__chatBoxRightColumn___rYbIc {
  height: 48px;
}
.aiConsultantLight-module__container___-d4rW .react-resizable .aiConsultantLight-module__innerContainer___6p-oU .aiConsultantLight-module__rightColumn___7dLHe.aiConsultantLight-module__chatBoxRightColumn___rYbIc {
  height: 100%;
  margin-right: 0;
}
.aiConsultantLight-module__container___-d4rW .react-resizable .aiConsultantLight-module__innerContainer___6p-oU .aiConsultantLight-module__rightColumn___7dLHe.aiConsultantLight-module__chatBoxRightColumn___rYbIc .aiConsultantLight-module__popularTopics___D5qlV {
  background-color: #1D2939;
  border-bottom: 1px solid #98A2B3;
  max-height: 48px;
}
.aiConsultantLight-module__container___-d4rW .react-resizable .aiConsultantLight-module__innerContainer___6p-oU .aiConsultantLight-module__rightColumn___7dLHe.aiConsultantLight-module__chatBoxRightColumn___rYbIc .aiConsultantLight-module__popularTopics___D5qlV .aiConsultantLight-module__popularTopicsHeader___m5IVJ {
  line-height: 15px;
}
.aiConsultantLight-module__container___-d4rW .react-resizable .aiConsultantLight-module__innerContainer___6p-oU .aiConsultantLight-module__rightColumn___7dLHe.aiConsultantLight-module__chatBoxRightColumn___rYbIc.aiConsultantLight-module__shown___MCPwt {
  border-bottom: none;
}
.aiConsultantLight-module__container___-d4rW .react-resizable .aiConsultantLight-module__innerContainer___6p-oU .aiConsultantLight-module__rightColumn___7dLHe.aiConsultantLight-module__chatBoxRightColumn___rYbIc.aiConsultantLight-module__shown___MCPwt .aiConsultantLight-module__popularTopics___D5qlV {
  background-color: unset;
  border-bottom: none;
  max-height: unset;
}
.aiConsultantLight-module__container___-d4rW .react-resizable .aiConsultantLight-module__innerContainer___6p-oU .aiConsultantLight-module__rightColumn___7dLHe.aiConsultantLight-module__chatBoxModeOpened___oOaLk {
  background-color: transparent;
  border-bottom: 1px solid #98A2B3;
}
.aiConsultantLight-module__container___-d4rW .react-resizable .aiConsultantLight-module__innerContainer___6p-oU .aiConsultantLight-module__rightColumn___7dLHe.aiConsultantLight-module__chatBoxModeOpened___oOaLk.aiConsultantLight-module__shown___MCPwt {
  border-bottom: 1px solid #98A2B3;
  height: 220px;
}
.aiConsultantLight-module__container___-d4rW .react-resizable .aiConsultantLight-module__innerContainer___6p-oU .aiConsultantLight-module__rightColumn___7dLHe.aiConsultantLight-module__chatBoxModeOpened___oOaLk.aiConsultantLight-module__shown___MCPwt .aiConsultantLight-module__popularTopics___D5qlV {
  max-height: 220px;
}
.aiConsultantLight-module__container___-d4rW .react-resizable .aiConsultantLight-module__innerContainer___6p-oU .aiConsultantLight-module__rightColumn___7dLHe.aiConsultantLight-module__shown___MCPwt {
  margin-right: 0;
}
.aiConsultantLight-module__container___-d4rW .react-resizable .aiConsultantLight-module__innerContainer___6p-oU .aiConsultantLight-module__rightColumn___7dLHe.aiConsultantLight-module__ada___g1qGD {
  margin-right: -304px;
}
.aiConsultantLight-module__container___-d4rW .aiConsultantLight-module__reactResizableHandle___YXIpK {
  position: absolute;
  display: flex;
  width: 40px;
  top: 0;
  right: calc(50% - 20px);
  background-position: bottom right;
  cursor: n-resize;
  height: 24px;
  justify-content: center;
}
.aiConsultantLight-module__container___-d4rW .aiConsultantLight-module__reactResizableHandle___YXIpK::before, .aiConsultantLight-module__container___-d4rW .aiConsultantLight-module__reactResizableHandle___YXIpK::after {
  content: "";
  width: 29px;
  height: 2px;
  background-color: #D0D5DD;
}
.aiConsultantLight-module__container___-d4rW .aiConsultantLight-module__reactResizableHandle___YXIpK::before {
  top: 8px;
  position: absolute;
}
.aiConsultantLight-module__container___-d4rW .aiConsultantLight-module__reactResizableHandle___YXIpK::after {
  top: 14px;
  position: absolute;
}

.aiConsultantLight-module__disclaimerModal___7ioD- .aiConsultantLight-module__text___vPrw7 {
  color: #475467;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.aiConsultantLight-module__openTrigger___5zsx9 {
  position: absolute;
  right: 0;
  bottom: 86px;
  display: flex;
  padding: 8px 16px;
  align-items: center;
  gap: 16px;
  border-radius: 12px 0px 0px 0px;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  justify-content: center;
  line-height: normal;
  opacity: 0;
  transition: 100ms opacity;
  cursor: pointer;
}
.aiConsultantLight-module__openTrigger___5zsx9.aiConsultantLight-module__shown___MCPwt {
  opacity: 1;
  transition: 1000ms opacity;
}
.aiConsultantLight-module__openTrigger___5zsx9 .aiConsultantLight-module__icon___Nxj0h {
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.aiConsultantLight-module__openTrigger___5zsx9 .aiConsultantLight-module__title___G8zZe {
  color: #5AC7FA;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 2.4px;
  text-transform: uppercase;
}

.aiConsultantLight-module__container___-d4rW.aiConsultantLight-module__chatBoxContainer___tbfm7.aiConsultantLight-module__opened___xBIWD .react-resizable .aiConsultantLight-module__header___CUlbJ {
  border: none;
}
.aiConsultantLight-module__container___-d4rW .react-resizable {
  border-top: 1px solid #D0D5DD;
  background: linear-gradient(203deg, rgba(210, 206, 229, 0.8) 28.21%, rgba(208, 227, 238, 0) 46.98%), linear-gradient(173deg, rgba(225, 216, 237, 0.8) 0.48%, rgba(191, 239, 242, 0.8) 52.34%, rgba(208, 227, 238, 0.8) 99.11%);
  backdrop-filter: blur(5px);
}
.aiConsultantLight-module__container___-d4rW .react-resizable .aiConsultantLight-module__header___CUlbJ {
  border-bottom: 1px solid #D0D5DD;
}
.aiConsultantLight-module__container___-d4rW .react-resizable .aiConsultantLight-module__header___CUlbJ .aiConsultantLight-module__innerHeader___KORv4 .aiConsultantLight-module__title___G8zZe {
  color: #000000;
}
.aiConsultantLight-module__container___-d4rW .react-resizable .aiConsultantLight-module__header___CUlbJ .aiConsultantLight-module__innerHeader___KORv4 .aiConsultantLight-module__logo___A1ENL {
  filter: brightness(0%);
}
.aiConsultantLight-module__container___-d4rW .react-resizable .aiConsultantLight-module__header___CUlbJ .aiConsultantLight-module__actions___1b6lk .aiConsultantLight-module__action___zv83y.ant-btn.ant-btn-default {
  color: #000000;
}
.aiConsultantLight-module__container___-d4rW .react-resizable .aiConsultantLight-module__header___CUlbJ .aiConsultantLight-module__actions___1b6lk .aiConsultantLight-module__action___zv83y.aiConsultantLight-module__chatBoxAction___wUYP3.ant-btn.ant-btn-default .sx-icon.sx-window {
  color: #000000;
}
.aiConsultantLight-module__container___-d4rW .react-resizable .aiConsultantLight-module__header___CUlbJ .aiConsultantLight-module__actions___1b6lk .aiConsultantLight-module__action___zv83y.aiConsultantLight-module__chatBoxAction___wUYP3.aiConsultantLight-module__active___DIE1v.ant-btn.ant-btn-default, .aiConsultantLight-module__container___-d4rW .react-resizable .aiConsultantLight-module__header___CUlbJ .aiConsultantLight-module__actions___1b6lk .aiConsultantLight-module__action___zv83y.aiConsultantLight-module__chatBoxAction___wUYP3.aiConsultantLight-module__active___DIE1v:hover.ant-btn.ant-btn-default, .aiConsultantLight-module__container___-d4rW .react-resizable .aiConsultantLight-module__header___CUlbJ .aiConsultantLight-module__actions___1b6lk .aiConsultantLight-module__action___zv83y.aiConsultantLight-module__chatBoxAction___wUYP3.aiConsultantLight-module__active___DIE1v:focus.ant-btn.ant-btn-default, .aiConsultantLight-module__container___-d4rW .react-resizable .aiConsultantLight-module__header___CUlbJ .aiConsultantLight-module__actions___1b6lk .aiConsultantLight-module__action___zv83y.aiConsultantLight-module__chatBoxAction___wUYP3.aiConsultantLight-module__active___DIE1v:focus-visible.ant-btn.ant-btn-default {
  background-color: #736DBA;
}
.aiConsultantLight-module__container___-d4rW .react-resizable .aiConsultantLight-module__header___CUlbJ .aiConsultantLight-module__actions___1b6lk .aiConsultantLight-module__action___zv83y.aiConsultantLight-module__chatBoxAction___wUYP3.aiConsultantLight-module__active___DIE1v.ant-btn.ant-btn-default .sx-icon.sx-window, .aiConsultantLight-module__container___-d4rW .react-resizable .aiConsultantLight-module__header___CUlbJ .aiConsultantLight-module__actions___1b6lk .aiConsultantLight-module__action___zv83y.aiConsultantLight-module__chatBoxAction___wUYP3.aiConsultantLight-module__active___DIE1v:hover.ant-btn.ant-btn-default .sx-icon.sx-window, .aiConsultantLight-module__container___-d4rW .react-resizable .aiConsultantLight-module__header___CUlbJ .aiConsultantLight-module__actions___1b6lk .aiConsultantLight-module__action___zv83y.aiConsultantLight-module__chatBoxAction___wUYP3.aiConsultantLight-module__active___DIE1v:focus.ant-btn.ant-btn-default .sx-icon.sx-window, .aiConsultantLight-module__container___-d4rW .react-resizable .aiConsultantLight-module__header___CUlbJ .aiConsultantLight-module__actions___1b6lk .aiConsultantLight-module__action___zv83y.aiConsultantLight-module__chatBoxAction___wUYP3.aiConsultantLight-module__active___DIE1v:focus-visible.ant-btn.ant-btn-default .sx-icon.sx-window {
  color: #ffffff;
}
.aiConsultantLight-module__container___-d4rW .react-resizable .aiConsultantLight-module__innerContainer___6p-oU .aiConsultantLight-module__column___sWatR .aiConsultantLight-module__popularTopics___D5qlV .aiConsultantLight-module__popularTopicsHeader___m5IVJ .aiConsultantLight-module__title___G8zZe .aiConsultantLight-module__main___wtIlO {
  color: #207CC1;
}
.aiConsultantLight-module__container___-d4rW .react-resizable .aiConsultantLight-module__innerContainer___6p-oU .aiConsultantLight-module__column___sWatR .aiConsultantLight-module__popularTopics___D5qlV .aiConsultantLight-module__popularTopicsHeader___m5IVJ .aiConsultantLight-module__title___G8zZe .aiConsultantLight-module__secondary___l7-gN {
  color: #475467;
}
.aiConsultantLight-module__container___-d4rW .react-resizable .aiConsultantLight-module__innerContainer___6p-oU .aiConsultantLight-module__column___sWatR .aiConsultantLight-module__conversation___Q79eV {
  border-bottom: 1px solid #D0D5DD;
}
.aiConsultantLight-module__container___-d4rW .react-resizable .aiConsultantLight-module__innerContainer___6p-oU .aiConsultantLight-module__column___sWatR .aiConsultantLight-module__conversation___Q79eV .aiConsultantLight-module__innerConversationContainer___xe3gE .aiConsultantLight-module__message___VryV-.aiConsultantLight-module__assistant___B2TaL {
  background: rgba(255, 255, 255, 0.8);
}
.aiConsultantLight-module__container___-d4rW .react-resizable .aiConsultantLight-module__innerContainer___6p-oU .aiConsultantLight-module__column___sWatR .aiConsultantLight-module__conversation___Q79eV .aiConsultantLight-module__innerConversationContainer___xe3gE .aiConsultantLight-module__message___VryV-.aiConsultantLight-module__user___ZvIv- {
  background: rgba(255, 255, 255, 0.4);
}
.aiConsultantLight-module__container___-d4rW .react-resizable .aiConsultantLight-module__innerContainer___6p-oU .aiConsultantLight-module__column___sWatR .aiConsultantLight-module__conversation___Q79eV .aiConsultantLight-module__innerConversationContainer___xe3gE .aiConsultantLight-module__message___VryV- .aiConsultantLight-module__leftColumn___3axgO .aiConsultantLight-module__text___vPrw7 {
  color: #000000;
}
.aiConsultantLight-module__container___-d4rW .react-resizable .aiConsultantLight-module__innerContainer___6p-oU .aiConsultantLight-module__column___sWatR .aiConsultantLight-module__conversation___Q79eV .aiConsultantLight-module__innerConversationContainer___xe3gE .aiConsultantLight-module__message___VryV- .aiConsultantLight-module__leftColumn___3axgO .aiConsultantLight-module__iconContainer___DtjQO .aiConsultantLight-module__aiIcon___71t0i {
  filter: brightness(0);
}
.aiConsultantLight-module__container___-d4rW .react-resizable .aiConsultantLight-module__innerContainer___6p-oU .aiConsultantLight-module__column___sWatR .aiConsultantLight-module__conversation___Q79eV .aiConsultantLight-module__innerConversationContainer___xe3gE .aiConsultantLight-module__message___VryV- .aiConsultantLight-module__extraQuestions___hMgxi .aiConsultantLight-module__text___vPrw7 {
  color: #000000;
}
.aiConsultantLight-module__container___-d4rW .react-resizable .aiConsultantLight-module__innerContainer___6p-oU .aiConsultantLight-module__column___sWatR .aiConsultantLight-module__conversation___Q79eV .aiConsultantLight-module__innerConversationContainer___xe3gE .aiConsultantLight-module__message___VryV- .aiConsultantLight-module__extraQuestions___hMgxi .aiConsultantLight-module__questionsContainer___rL9uZ .aiConsultantLight-module__question___0g03j {
  background-color: #CCCBE7;
}
.aiConsultantLight-module__container___-d4rW .react-resizable .aiConsultantLight-module__innerContainer___6p-oU .aiConsultantLight-module__column___sWatR .aiConsultantLight-module__conversation___Q79eV .aiConsultantLight-module__innerConversationContainer___xe3gE .aiConsultantLight-module__message___VryV- .aiConsultantLight-module__extraQuestions___hMgxi .aiConsultantLight-module__questionsContainer___rL9uZ .aiConsultantLight-module__question___0g03j:hover {
  background-color: #736DBA;
  color: #ffffff;
}
.aiConsultantLight-module__container___-d4rW .react-resizable .aiConsultantLight-module__innerContainer___6p-oU .aiConsultantLight-module__column___sWatR .aiConsultantLight-module__conversation___Q79eV .aiConsultantLight-module__innerConversationContainer___xe3gE .aiConsultantLight-module__fetchingAiLogoContainer___a4Tf- .aiConsultantLight-module__fetchingAiLogo___faxAT {
  filter: brightness(0%);
}
.aiConsultantLight-module__container___-d4rW .react-resizable .aiConsultantLight-module__innerContainer___6p-oU .aiConsultantLight-module__column___sWatR .aiConsultantLight-module__inputContainer___Xx3lD .aiConsultantLight-module__inputInnerContainer___72aaw .aiConsultantLight-module__disclaimer___qyl-w {
  color: #475467;
}
.aiConsultantLight-module__container___-d4rW .react-resizable .aiConsultantLight-module__innerContainer___6p-oU .aiConsultantLight-module__rightColumn___7dLHe.aiConsultantLight-module__chatBoxModeOpened___oOaLk {
  background-color: transparent;
  border-bottom: 1px solid #D0D5DD;
}
.aiConsultantLight-module__container___-d4rW .react-resizable .aiConsultantLight-module__innerContainer___6p-oU .aiConsultantLight-module__rightColumn___7dLHe.aiConsultantLight-module__chatBoxModeOpened___oOaLk.aiConsultantLight-module__shown___MCPwt {
  border-bottom: 1px solid #D0D5DD;
}
.aiConsultantLight-module__container___-d4rW .react-resizable .aiConsultantLight-module__innerContainer___6p-oU .aiConsultantLight-module__rightColumn___7dLHe.aiConsultantLight-module__chatBoxRightColumn___rYbIc {
  background-color: transparent;
}
.aiConsultantLight-module__container___-d4rW .react-resizable .aiConsultantLight-module__innerContainer___6p-oU .aiConsultantLight-module__rightColumn___7dLHe.aiConsultantLight-module__chatBoxRightColumn___rYbIc.aiConsultantLight-module__shown___MCPwt .aiConsultantLight-module__popularTopics___D5qlV {
  background: linear-gradient(173deg, rgba(225, 216, 237, 0.8) 0.48%, rgba(191, 239, 242, 0.8) 52.34%, rgba(208, 227, 238, 0.8) 99.11%);
}
.aiConsultantLight-module__container___-d4rW .react-resizable .aiConsultantLight-module__innerContainer___6p-oU .aiConsultantLight-module__rightColumn___7dLHe.aiConsultantLight-module__chatBoxRightColumn___rYbIc .aiConsultantLight-module__popularTopics___D5qlV {
  background-color: #F2F4F7;
  border-bottom: 1px solid #D0D5DD;
}
.aiConsultantLight-module__container___-d4rW .react-resizable .aiConsultantLight-module__innerContainer___6p-oU .aiConsultantLight-module__rightColumn___7dLHe.aiConsultantLight-module__chatBoxRightColumn___rYbIc .aiConsultantLight-module__popularTopics___D5qlV .aiConsultantLight-module__links___B3ImV .aiConsultantLight-module__link___hMvvq:hover {
  background-color: #736DBA;
}
.aiConsultantLight-module__container___-d4rW .react-resizable .aiConsultantLight-module__reactResizableHandle___YXIpK::before, .aiConsultantLight-module__container___-d4rW .react-resizable .aiConsultantLight-module__reactResizableHandle___YXIpK::after {
  background-color: #98A2B3;
}`;
document.head
    .appendChild(document.createElement("style"))
    .appendChild(document.createTextNode(css));
export {css};
export default {
  "tooltip": "aiConsultantLight-module__tooltip___xRs8G",
  "openChatButton": "aiConsultantLight-module__openChatButton___sfsr-",
  "buttonInnerContainer": "aiConsultantLight-module__buttonInnerContainer___aQwaE",
  "icon": "aiConsultantLight-module__icon___Nxj0h",
  "opened": "aiConsultantLight-module__opened___xBIWD",
  "text": "aiConsultantLight-module__text___vPrw7",
  "innerText": "aiConsultantLight-module__innerText___mRQDq",
  "betaTag": "aiConsultantLight-module__betaTag___gQ7JT",
  "container": "aiConsultantLight-module__container___-d4rW",
  "chatBoxContainer": "aiConsultantLight-module__chatBoxContainer___tbfm7",
  "chatBoxWithToolBoxOpened": "aiConsultantLight-module__chatBoxWithToolBoxOpened___Cxwya",
  "chatBoxWithToolBoxMinimized": "aiConsultantLight-module__chatBoxWithToolBoxMinimized___DFuJz",
  "react-resizable": "react-resizable",
  "header": "aiConsultantLight-module__header___CUlbJ",
  "innerHeader": "aiConsultantLight-module__innerHeader___KORv4",
  "logo": "aiConsultantLight-module__logo___A1ENL",
  "title": "aiConsultantLight-module__title___G8zZe",
  "actions": "aiConsultantLight-module__actions___1b6lk",
  "chatBoxActions": "aiConsultantLight-module__chatBoxActions___KUrCn",
  "action": "aiConsultantLight-module__action___zv83y",
  "ant-btn": "ant-btn",
  "ant-btn-default": "ant-btn-default",
  "chatBoxAction": "aiConsultantLight-module__chatBoxAction___wUYP3",
  "sx-icon": "sx-icon",
  "sx-window": "sx-window",
  "active": "aiConsultantLight-module__active___DIE1v",
  "themeToggle": "aiConsultantLight-module__themeToggle___FDBtd",
  "ant-switch": "ant-switch",
  "ant-switch-handle": "ant-switch-handle",
  "ant-switch-inner": "ant-switch-inner",
  "ant-switch-checked": "ant-switch-checked",
  "innerContainer": "aiConsultantLight-module__innerContainer___6p-oU",
  "chatBoxInnerContainer": "aiConsultantLight-module__chatBoxInnerContainer___80nT6",
  "column": "aiConsultantLight-module__column___sWatR",
  "chatBoxColumn": "aiConsultantLight-module__chatBoxColumn___4izqr",
  "conversation": "aiConsultantLight-module__conversation___Q79eV",
  "chatBox": "aiConsultantLight-module__chatBox___Im6Ty",
  "ada2": "aiConsultantLight-module__ada2___2Cuv9",
  "innerConversationContainer": "aiConsultantLight-module__innerConversationContainer___xe3gE",
  "chatBoxInnerConversationContainer": "aiConsultantLight-module__chatBoxInnerConversationContainer___XRX92",
  "loader": "aiConsultantLight-module__loader___wwtrf",
  "loaderChatBox": "aiConsultantLight-module__loaderChatBox___sdMiL",
  "message": "aiConsultantLight-module__message___VryV-",
  "ada": "aiConsultantLight-module__ada___g1qGD",
  "copyButton": "aiConsultantLight-module__copyButton___dbR4D",
  "feedbackButton": "aiConsultantLight-module__feedbackButton___zNRzu",
  "assistant": "aiConsultantLight-module__assistant___B2TaL",
  "bottomColumn": "aiConsultantLight-module__bottomColumn___-6QpR",
  "positive": "aiConsultantLight-module__positive___OInxV",
  "leftColumn": "aiConsultantLight-module__leftColumn___3axgO",
  "user": "aiConsultantLight-module__user___ZvIv-",
  "rightColumn": "aiConsultantLight-module__rightColumn___7dLHe",
  "hyperlink": "aiConsultantLight-module__hyperlink___bsY7k",
  "cta": "aiConsultantLight-module__cta___OkD3q",
  "disabled": "aiConsultantLight-module__disabled___Y6mE0",
  "iconContainer": "aiConsultantLight-module__iconContainer___DtjQO",
  "aiIcon": "aiConsultantLight-module__aiIcon___71t0i",
  "text-avatar": "text-avatar",
  "image-circular-avatar": "image-circular-avatar",
  "extraQuestions": "aiConsultantLight-module__extraQuestions___hMgxi",
  "questionsContainer": "aiConsultantLight-module__questionsContainer___rL9uZ",
  "question": "aiConsultantLight-module__question___0g03j",
  "fetchingAiLogoContainer": "aiConsultantLight-module__fetchingAiLogoContainer___a4Tf-",
  "fetchingAiLogo": "aiConsultantLight-module__fetchingAiLogo___faxAT",
  "conversationEnd": "aiConsultantLight-module__conversationEnd___KBlXc",
  "inputContainer": "aiConsultantLight-module__inputContainer___Xx3lD",
  "inputInnerContainer": "aiConsultantLight-module__inputInnerContainer___72aaw",
  "input": "aiConsultantLight-module__input___wlEOf",
  "ant-input-affix-wrapper-focused": "ant-input-affix-wrapper-focused",
  "textContainer": "aiConsultantLight-module__textContainer___srvqe",
  "textArea": "aiConsultantLight-module__textArea___TbgTp",
  "actionButton": "aiConsultantLight-module__actionButton___rhctf",
  "disclaimer": "aiConsultantLight-module__disclaimer___qyl-w",
  "popularTopics": "aiConsultantLight-module__popularTopics___D5qlV",
  "popularTopicsHeader": "aiConsultantLight-module__popularTopicsHeader___m5IVJ",
  "main": "aiConsultantLight-module__main___wtIlO",
  "secondary": "aiConsultantLight-module__secondary___l7-gN",
  "hidden": "aiConsultantLight-module__hidden___ve9kA",
  "close": "aiConsultantLight-module__close___nrjMo",
  "links": "aiConsultantLight-module__links___B3ImV",
  "link": "aiConsultantLight-module__link___hMvvq",
  "chatBoxMode": "aiConsultantLight-module__chatBoxMode___AUpne",
  "chatBoxRightColumn": "aiConsultantLight-module__chatBoxRightColumn___rYbIc",
  "shown": "aiConsultantLight-module__shown___MCPwt",
  "chatBoxModeOpened": "aiConsultantLight-module__chatBoxModeOpened___oOaLk",
  "reactResizableHandle": "aiConsultantLight-module__reactResizableHandle___YXIpK",
  "disclaimerModal": "aiConsultantLight-module__disclaimerModal___7ioD-",
  "openTrigger": "aiConsultantLight-module__openTrigger___5zsx9",
  "slide-right": "aiConsultantLight-module__slide-right___YO1BF"
};