import { PublicClientApplication } from '@azure/msal-browser';
import * as config from '../services/configParameters';
export const AD_SELECTORS = {
  INCROWD: 'INCROWD',
  SIGHTX: 'SIGHTX',
};

let clientId;
let authority;

export const switchADSelector = () => {
  const configSelector = getADSelector();
  if(configSelector === AD_SELECTORS.INCROWD){
    localStorage.setItem('ad_selector', AD_SELECTORS.SIGHTX);
  }else{
    localStorage.setItem('ad_selector', AD_SELECTORS.INCROWD);
  }
  window.location = '/';
};

export const getADSelector = () => {
  return localStorage.getItem('ad_selector') || AD_SELECTORS.INCROWD;
};

const configSelector = getADSelector();
if(configSelector === AD_SELECTORS.INCROWD){
  clientId = config.AD_CLIENT_ID;
  authority = config.AD_AUTHORITY_URL;
}else{ //sightx AD
  clientId = 'dfa566f7-b5d4-4718-a001-119381ce187b';
  authority = 'https://login.microsoftonline.com/488439f0-2bfb-45f8-a321-c2d2e8c58f1d';
}
const msalConfig = {
  auth: {
    clientId,
    authority,
  }
};
let msalInstance;

export const getMsalInstance = () =>{
  if(!msalInstance){
    msalInstance = new PublicClientApplication(msalConfig);
  }
  return msalInstance;
};
