import React, { Component } from 'react';

import { connect } from 'react-redux';

import { ADMIN_APP_URL } from '../../../common/login.utils';
import { Features } from '../../../featureToggles/features';
import { SxButton, SxIcon } from '../sxLibrary';
import { BillingCadence } from '../../../router';
import { IGlobalState } from '../../../reducers/interfaces/state';
import { getIsLiteAccount } from '../../../selectors/common/user';
import { colors } from '../../../styles/variables';

import styles from './index.module.scss';

interface IProps {
  responsesAllowed: number | null;
  paymentCadence: string;
  isLiteAccount: boolean;
}

class ResponsesLimitBase extends Component<IProps> {
  constructor(props: IProps) {
    super(props);
    this.renderMessage = this.renderMessage.bind(this);
    this.renderCallToActionText = this.renderCallToActionText.bind(this);
  }

  private renderMessage() {
    const { paymentCadence, responsesAllowed, isLiteAccount } = this.props;
    if (paymentCadence === BillingCadence.MONTHLY) {
      const monthlyLabel = isLiteAccount ? 'available.' : 'available this month';
      return `You have collected all ${responsesAllowed} responses ${monthlyLabel}.`;
    }
    const annualLabel = isLiteAccount ? 'responses.' : 'annual responses.';
    return `You have collected all of your ${responsesAllowed} ${annualLabel}`;
  }

  private renderCallToActionText() {
    const { isLiteAccount } = this.props;
    const accountType = isLiteAccount ? 'Pro' : 'Enterprise';
    return <SxButton
      type="primary"
      className={styles.button}
      featureToUse={Features.plgexternal}
    >
      <a href={`${ADMIN_APP_URL}/settings/subscription/compare`}><div>Upgrade to {accountType} &nbsp; <SxIcon color={colors.yellow400} name="sx-rocket" /></div></a>
    </SxButton>;
  }

  render() {
    return (
      <div className={styles.banner}>
        <div className={styles.information}>
          <p className={styles.message}>
            <span>
              {this.renderMessage()}
            </span>
            <SxButton
              featureToUse={Features.uirefresh}
              onClick={() => null} // TODO: update when the callback action is defined
              type="primary"
            >
              {this.renderCallToActionText()}
            </SxButton>
          </p>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: IGlobalState) => {
  const {
    security: {
      account: { account: { responsesAllowed = null } = {} } = {},
      subscription: { paymentCadence = '' } = {},
    } = {},
  } = state;
  const isLiteAccount = getIsLiteAccount(state);
  return {
    responsesAllowed,
    paymentCadence,
    isLiteAccount,
  };
};

const mapDispatchToProps = {};

export const ResponsesLimitBanner = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ResponsesLimitBase);
