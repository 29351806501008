import React, { memo } from 'react';

import { useSelector } from 'react-redux';

import { isFeatureDisabled, isFeatureHidden } from '../../../common/accessControl.helper';
import { getRecipientsCommunityStatus } from '../../../selectors/accessControl';
import { isAudiencesPath } from '../utils/navigation';
import { LapsedTooltipText, UpgradeTooltipText } from '../accessControl';
import { tooltipMessage } from '../../../data/sectionAccessPermissions';
import { getSectionSecurityPermissions } from '../../../reducers/security/selectors';
import { Features, isFeatureEnabled } from '../../../featureToggles/features';

import { Option } from './Option';

interface IProps {
  isLapsedAccount: boolean;
}

export const AudienceOption = memo((props: IProps) => {
  const recipientsCommunityStatus = useSelector(getRecipientsCommunityStatus);
  const sectionSecurityPermissions = useSelector(getSectionSecurityPermissions);

  if (isFeatureHidden(recipientsCommunityStatus) || isFeatureEnabled(Features.communities)) {
    return null;
  }
  const isRecipientsCommunityDisabled = isFeatureDisabled(recipientsCommunityStatus);
  let disabledText = isRecipientsCommunityDisabled ? UpgradeTooltipText : undefined;
  const { isLapsedAccount } = props;
  if (isLapsedAccount) {
    disabledText = LapsedTooltipText;
  }

  if (!sectionSecurityPermissions.distribute) {
    disabledText = () => <>{tooltipMessage}</>;
  }

  return <Option
    key="AUDIENCES"
    icon="sx-users"
    label="AUDIENCES"
    route="/audiences"
    needUpgrade={isLapsedAccount || isRecipientsCommunityDisabled}
    disabledText={disabledText}
    active={isAudiencesPath()}
    disabled={!sectionSecurityPermissions?.distribute}
  />;
});
