import { BlockByFeatureType, BlockedByFeature, Features, isFeatureEnabled } from '../../featureToggles/features';
import { TrialDescriptor } from '../../interfaces/ISecurity';
import { AccountsSupported } from '../common/educationalModalBase/helper';

export const getTrialType = (descriptor?: string): AccountsSupported | undefined | BlockByFeatureType => {
  if (!isFeatureEnabled(Features.TrialsOfTiers)) {
    return BlockedByFeature;
  }
  switch (descriptor) {
    case TrialDescriptor.Pro:
      return AccountsSupported.Pro;
    case TrialDescriptor.Enterprise:
      return AccountsSupported.Enterprise;
    default:
      return;
  }
};