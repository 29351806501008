import React, { memo } from 'react';

import cx from 'classnames';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { AnyFunction } from 'sx-project-library/dist/interfaces/common';

import { SxIcon } from '../sxLibrary';
import { IIcons } from '../../../assets/sightx-icons/icons.interface';
import {
  defaultSubSectionByMainRoute,
  getProjectIdFromURL,
  MainRoutesForProject,
} from '../utils/navigation';
import { getPathname } from '../../../selectors/common/selectors';
import { BlockedByFeature, Features } from '../../../featureToggles/features';
import { Tooltip } from '../utils/wrapComponents';
import { colors } from '../../../styles/variables';
import { UpgradeTooltipText } from '../accessControl';
import { FEATURES } from '../../../common/accessControl.helper';
import { wasProjectCreatedBeforeTrial } from '../../../selectors/accessControl';
import { getAccountAccessType } from '../../../selectors/common/user';
import { getSectionSecurityPermissionForRoute } from '../../../reducers/security/selectors';
import { tooltipMessage } from '../../../data/sectionAccessPermissions';

import { Option } from './Option';
import { getOptionsByDashboard } from './options';
import { getDisabledInformationPerProjectView } from './helper';

import styles from './index.module.scss';

interface IMenuCollapsibleProps {
  icon?: IIcons;
  active?: boolean;
  label: string;
  mainRoute: MainRoutesForProject;
  open: boolean;
  onCollapseIconClick: AnyFunction;
  disabled?: boolean;
  disabledTooltip?: React.ReactNode;
  ['data-e2e-selector']?: string;
  // for product analytics
  logNavigationEvent?: AnyFunction;
  // end of product analytics
  // use in Features.TrialOfTiers
  openEducationModalFor?: (featureName: FEATURES) => void;
  // end of use in Features.TrialOfTiers
}

export const MenuCollapsible = memo(({
  icon,
  label,
  mainRoute,
  open,
  onCollapseIconClick,
  active,
  disabled,
  disabledTooltip = UpgradeTooltipText,
  'data-e2e-selector': e2eSelector,
  logNavigationEvent,
  openEducationModalFor,
}: IMenuCollapsibleProps) => {
  const sectionAccessPermission = useSelector(getSectionSecurityPermissionForRoute(mainRoute));
  const isDisabledByAccessPermission = !sectionAccessPermission;

  const internalDisabled = disabled || isDisabledByAccessPermission;
  const internalDisabledTooltip = isDisabledByAccessPermission ? <>{tooltipMessage}</> : disabledTooltip;
  const pathName = useSelector(getPathname);
  const subSections = useSelector(getOptionsByDashboard(mainRoute)) || [];
  const isPastProjectResult = useSelector(wasProjectCreatedBeforeTrial);
  const accessType = useSelector(getAccountAccessType);

  const projectId = getProjectIdFromURL(pathName);
  const startRoute = `/${mainRoute}/${projectId}/`;

  const handleNavigationClick = () => {
    if (!active && logNavigationEvent !== undefined) {
      logNavigationEvent();
    }
    active && onCollapseIconClick();
  };

  const chevronIcon = subSections.length
    ? <SxIcon
      className={styles.dropdownIcon}
      name="sx-chevron-down"
      onClick={onCollapseIconClick}
    />
    : null;

  const disabledIcon = (
    <SxIcon
      fontSize={15}
      name="sx-lock"
      color={isDisabledByAccessPermission ? colors.grayWarm600 : colors.baseWhite} />
  );

  const rightIcon = internalDisabled ? disabledIcon : chevronIcon;

  const Menu = <div className={cx(styles.menuCollapsible, {
    [styles.open]: open && subSections.length,
  })} data-e2e-selector={ e2eSelector }>
    <div className={cx(styles.menuCollapsibleHeader, {
      [styles.active]: active,
      [styles.disabled]: internalDisabled,
      [styles.accessDisabled]: !sectionAccessPermission,
    })}>
      <Link
        to={!internalDisabled ? startRoute + defaultSubSectionByMainRoute[mainRoute] : window.location.pathname}
        onClick={handleNavigationClick}
      >
        {icon ? <SxIcon name={icon} /> : null} {label}
      </Link>
      {rightIcon}
    </div>
    <div className={styles.menuCollapsibleContent} >
      {subSections.map((subSection) => {
        const disabledInformationResult = getDisabledInformationPerProjectView({
          key: subSection.key,
          accessType,
          isPastProject: isPastProjectResult,
          isDisabled: !!subSection.needUpgrade,
          disabledTooltip: subSection.disabledText,
        });
        const disabledInformation = disabledInformationResult !== BlockedByFeature ?
          disabledInformationResult :
          {
            disabled: !!subSection.needUpgrade,
            disabledText: subSection.disabledText,
          };
        return <Option
          {...subSection}
          {...disabledInformation}
          showIcon={false}
          key={subSection.key}
          route={'route' in subSection ? subSection.route : ''}
          openEducationModalFor={openEducationModalFor}
        />;
      })}
    </div>
  </div>;

  return (
    <Tooltip
      placement="right"
      featureToUse={Features.uirefresh}
      className={styles.disabledTooltip}
      Component={Menu}
      tooltipText={internalDisabledTooltip}
      hideTooltip={!internalDisabled}
    />
  );
});
