import { isApollo, isNetquest } from '../services/platform';

import { COMPANIES } from './constants';

const userpilotIntegrationBlockedFor = [
  COMPANIES.INCROWD,
];

export const isUserPilotIntegrationBlockedFor = (companyId) => {
  return isApollo() || isNetquest() || userpilotIntegrationBlockedFor.includes(companyId);
};