import React, { Component } from 'react';

import { Provider, ReactReduxContext, connect } from 'react-redux';
import 'es7-object-polyfill';
import { DragDropContext } from 'react-dnd';
import MultiBackend from 'react-dnd-multi-backend';
import HTML5toTouch from 'react-dnd-multi-backend/lib/HTML5toTouch'; // or any other pipeline
import { Helmet } from 'react-helmet';
import { Chart as Chartjs } from 'react-chartjs-2';
import ctx from 'classnames';
import 'intersection-observer';
import { MsalProvider } from '@azure/msal-react';
import replaceAllInserter from 'string.prototype.replaceall';
import { PersistGate } from 'redux-persist/integration/react';

import { getFeaturesAvailability } from './actions/featuresAvailability/index';
import { roundedCornersBar } from './components/analysis/charts/common/chartJSExtensions';
import Router from './router';
import store, { persistor } from './store';
import Firebase, { FirebaseContext } from './services/firebase';
import { BASE_URL } from './services/api/middleware';
import { PROJECTS_CORE } from './services/api/microservicesTypes';
import { isDev, isTablet, isMobile, isOnlyRelease, isOnlyProduction, isNetquest } from './services/platform';
import GlobalBoundary from './components/common/errorReporter';
import { getMsalInstance } from './services/azureADConfig';
import MSAL from './MSAL';
import EnsureHydratedContainer from './components/common/ensureHydratedContainer';
import { RolesFetcher } from './common/RolesFetcher';
import { Features, isFeatureEnabled } from './featureToggles/features';
import { FavIcon } from './components/common/favIcon';

import 'react-datepicker/dist/react-datepicker.css';
import 'react-intl-tel-input/dist/main.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './App.scss';

replaceAllInserter.shim();
// 'ResizeObserver loop limit exceeded': Based on https://github.com/WICG/resize-observer/issues/38 is not an error but
// chrome treats it like one, also based on https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded/50387233
// is safe to ignore
const ignoreErrors = ['ResizeObserver loop limit exceeded', 'ResizeObserver loop completed with undelivered notifications.'];

window.onerror = async (message, url, lineNo, columnNo, error) => {
  // Due a bug in the browser's same-origin policy behavior in webkit based browsers:
  // https://stackoverflow.com/questions/45844565/script-error-errors-in-window-onerror-in-safari-only
  // https://bugs.webkit.org/show_bug.cgi?id=132945
  // we are ignoring 'script errors' in Safari and iOS devices.
  const UA = window.navigator.userAgent;
  const V = window.navigator.vendor;
  const isSafari = /Safari/.test(UA) && /WebKit/.test(UA) && /Apple/.test(V);
  const isIOSDevice = /\b(iPad|iPhone|iPod)\b/.test(UA);
  const isWebkitError = message === 'Script error.' && (isSafari || isIOSDevice);

  if (isDev() || !localStorage || ignoreErrors.includes(message) || isWebkitError) {
    return;
  }
  const reloadedTimes = localStorage.getItem('reloadedTimes');
  if (reloadedTimes === '1') {
    return;
  }
  const data = {
    date: new Date(),
    message,
    url,
    lineNo,
    columnNo,
    stacktrace: error ? error.stack : '',
  };
  const logUrl = `${BASE_URL[PROJECTS_CORE]}/logs`;
  localStorage.setItem('reloadedTimes', 1);
  await fetch(logUrl, {
    method: 'POST',
    body: JSON.stringify({ data }),
    headers: {
      'Content-Type': 'application/json',
    },
  });
  window.location.reload(true);
};
if (localStorage) {
  setTimeout(() => {
    localStorage.setItem('reloadedTimes', 0);
  }, 10000);
}

class App extends Component {
  async componentDidMount() {
    if (!('scrollBehavior' in document.documentElement.style)) {
      await import('scroll-behavior-polyfill');
    }
    if (isFeatureEnabled(Features.TrialsOfTiers)) {
      this.props.getFeaturesAvailability();
    }
    this.renderUserPilot();
  }

  renderUserPilot() {
    if ((isOnlyRelease() || isOnlyProduction()) && !isNetquest()) {
      const script = document.createElement('script');
      script.src = '/userpilot.prod.js';
      document.head.appendChild(script);
      const scriptSDK = document.createElement('script');
      scriptSDK.src = 'https://userpilot.sightx.io/sdk/latest.js';
      document.head.appendChild(scriptSDK);
    }
  }

  render() {
    const { isPreview } = this.props;
    const classNames = ctx(
      'app',
      {
        preview: isPreview,
        tablet: isTablet(),
        mobile: isMobile(),
      },
    );
    return <div className={classNames}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>SightX</title>
      </Helmet>
      <MsalProvider instance={getMsalInstance()}>
        {/* <AuthenticatedTemplate interactionType={InteractionType.Redirect}> */}
        <Router context={ReactReduxContext} />
        {/* </AuthenticatedTemplate> */}
        <EnsureHydratedContainer>
          <RolesFetcher />
          <MSAL />
        </EnsureHydratedContainer>
      </MsalProvider>
    </div>;
  }
}
const mapStateToProp = (state) => {
  const { activeProject: { present: { project } } } = state;
  return { isPreview: !!project.preview };
};

const dispatchToProps = {
  // use in Features.TrialOfTiers
  getFeaturesAvailability,
  // end of use in Features.TrialOfTiers
};

const ConnectedApp = connect(mapStateToProp, dispatchToProps)(App);

class FullApp extends Component {
  constructor(props) {
    super(props);
    Chartjs.elements.Rectangle.prototype.draw = roundedCornersBar;
  }

  renderApp() {
    return (
      <Provider store={store} context={ReactReduxContext}>
        <PersistGate persistor={persistor}>
          <FirebaseContext.Provider value={new Firebase()}>
            <ConnectedApp />
          </FirebaseContext.Provider>
        </PersistGate>
      </Provider>
    );
  }

  render() {
    if (isDev()) {
      return this.renderApp();
    }
    return (
      <GlobalBoundary>
        <FavIcon />
        {this.renderApp()}
      </GlobalBoundary>
    );
  }
}
export default DragDropContext(MultiBackend(HTML5toTouch))(FullApp);
