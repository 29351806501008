import React from 'react';

import { Button } from 'antd';
import cx from 'classnames';

import { Features, isFeatureEnabled } from '../../../../featureToggles/features';
import { redirectToUpgradeToPro } from '../../../../common/upgrade.utils';
import { colors } from '../../../../styles/variables';
import { SxIcon } from '../../sxLibrary';

import styles from './index.module.scss';

const isTrialsEnvEnabled = isFeatureEnabled(Features.TrialsOfTiers);

interface IProps {
  className?: string;
}

export const UpgradeToProCTA: React.FunctionComponent<IProps> = ({ className }) => {
  if (!isTrialsEnvEnabled) {
    return null;
  }

  return (
    <Button className={cx(styles.upgradeButton, className)} onClick={redirectToUpgradeToPro}>
      <span className={styles.title}>Upgrade to Pro <SxIcon featureToUse={Features.uirefresh} name="sx-star-plus" color={colors.orange500}/></span>
    </Button>
  );
};