import { FunctionComponent, useEffect } from 'react';

import { NetQuestFavIcons } from '../../assets/netquest/favicons';
import { SightXFavIcons } from '../../assets/favicons';
import { isNetquest } from '../../services/platform';

interface IFavIconInfo {
  rel: string;
  href: string;
  sizes?: string;
}

const addFavIcon = (favIconInfo: IFavIconInfo, prefix: string) => {
  const link = document.createElement('link');
  link.href = `/${prefix}/${favIconInfo.href}`;
  link.rel = favIconInfo.rel;
  if (favIconInfo.sizes) {
    link.setAttribute('sizes', favIconInfo.sizes);
  }
  document.getElementsByTagName('head')[0].appendChild(link);
};
export const FavIcon: FunctionComponent = () => {
  useEffect(() => {
    if (isNetquest()) {
      NetQuestFavIcons.forEach((info) => addFavIcon(info, 'netquestFavicons'));
      return;
    }
    SightXFavIcons.forEach((info) => addFavIcon(info, 'sightXFavicons'));
  }, []);
  return null;
};