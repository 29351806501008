import React, { Component } from 'react';

import cx from 'classnames';

import { SxIcon } from './sxLibrary';

import styles from './stepProgress.module.scss';

class StepProgress extends Component {
  constructor (props) {
    super(props);
    this.state = {
      steps: [],
    };
  }
  componentDidMount () {
    this.setState({
      steps: [...this.props.steps, 'done'],
    });
  }

  shouldComponentUpdate (nextProps) {
    return !!nextProps.currentStep;
  }

  renderSteps () {
    const { currentStep, progress, hasError } = this.props;
    const { steps } = this.state;
    const currentStepIndex = steps.indexOf(currentStep);

    return steps.map((step, index) => {
      const isLastItem = index === steps.length - 1;
      const isCurrentItem = index === currentStepIndex;
      let nodeClassName = '';
      let currentStepProgress = 0;
      let isIndeterminate = false;
      if (index < currentStepIndex) {
        nodeClassName = styles.stepNodeComplete;
        currentStepProgress = '100%';
      } else if (isCurrentItem) {
        nodeClassName = styles.stepNodeActive;
        if (hasError) {
          nodeClassName = styles.stepNodeError;
        }
        if (progress === -1) {
          currentStepProgress = '100%';
          isIndeterminate = true;
        } else {
          currentStepProgress = `${progress}%`;
        }
      }
      return <div key={`step-${index}`} style={{ width: isLastItem ? 'auto' : `calc(${100 / (steps.length - 1)}%)` }} className={cx(styles.stepContainer, isLastItem ? styles.lastStep : '')}>
        <div className={cx(styles.stepNode, nodeClassName)}>
          {
            index < currentStepIndex || (isLastItem && index === currentStepIndex) ?
              <SxIcon className={cx(isLastItem && index === currentStepIndex ? styles.lastStepCheck : '')} name="sx-check" noFloat /> :
              isCurrentItem && hasError ?
                <SxIcon name="sx-cross" noFloat /> :
                <span className={styles.stepNumber}>{index + 1}</span>
          }
          <span className={styles.stepLabel}>{step}</span>
        </div>
        {!isLastItem && <div className={cx(styles.baseBar, styles.fullBar)} />}
        {!isLastItem && <div style={{ width: currentStepProgress }} className={cx(
          styles.baseBar,
          styles.stepBar,
          isCurrentItem ? styles.rounderStepBar : '',
          isIndeterminate ? 'shimmerBar' : '',
          isCurrentItem && hasError ? styles.stepBarError : '',
        )} />}
      </div>;
    });
  }
  render() {
    return <div className={styles.stepProgressBar}>
      {this.renderSteps()}
    </div>;
  }
}

export default StepProgress;
