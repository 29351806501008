import { useEffect } from 'react';

import { HUBSPOT_PORTAL_ID } from '../services/configParameters';

export const useHubspotChat = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = `https://js.hs-scripts.com/${HUBSPOT_PORTAL_ID}.js`;
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return;
};
