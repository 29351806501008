import React, { Component } from 'react';

import { connect } from 'react-redux';
import { ResizableBox } from 'react-resizable';
import cx from 'classnames';
import { Skeleton, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import CopyToClipboard from 'react-copy-to-clipboard';

import { IGlobalState } from '../../reducers/interfaces/state';
import { resetConversation, sendConversationMessage } from '../../actions/aiConsultant';
import { SxButton, SxIcon, SxInput, SxModal, SxSwitch, SxTextArea } from '../common/sxLibrary';
import { colors } from '../../styles/variables';
import { Content, ContentPart, ICTA, IHyperlink, IMessage, RoleType } from '../../reducers/gpt/aiConsultant/interfaces';
import { striptagsAndUnescapeText } from '../common/utils/helpers';
import { BlockedByFeature, Features, isFeatureEnabled } from '../../featureToggles/features';
import sightxAILogoAnimatedWhite from '../../assets/gifs/sightx-ai-logo-white-150.gif';
import { getAdasFeedbackTypes, isFetchingAdasFeedbackTypes } from '../../reducers/gpt/aiConsultantFeedback/selectors';
import { fetchFeedbackTypes } from '../../actions/aiConsultant/feedback';
import userAILogo from '../../assets/aiUserLogoForLight.svg';
import { Tooltip } from '../common/utils/wrapComponents';
import { closeChatWindow } from '../../reducers/gpt/aiConsultant';
import { showSuccessMessage } from '../../actions/notifications';
import { getGPTStatus } from '../../selectors/accessControl';
import { FeatureStatus, isFeatureAvailable } from '../../common/accessControl.helper';
import { FeatureToggle, Off, On } from '../../featureToggles';
import { getSecurity } from '../../selectors/security/user';
import Avatar from '../common/avatar';
import { getToolboxViewState } from '../../reducers/analysis/toolbox/selectors';
import { ToolboxState } from '../analysis/toolbox/common/utils';
import { PopularTopicsPromptsTypes } from '../../reducers/gpt/aiConsultantPopularTopics/interfaces';

import { PopularTopics } from './popularTopics';
import { FeedbackModal } from './feedbackModal';
import { FeedbackType } from './constants';

import stylesDark from './aiConsultant.module.scss';
import stylesLight from './aiConsultantLight.module.scss';

const DEFAULT_INITIAL_HEIGHT = 314;
const DEFAULT_MARGIN_TOP = 18;
const MAX_HEIGHT_ALLOWED = 1000;
const MAX_WIDTH_ALLOWED = 1000;
const INITIAL_HEIGHT_KEY = 'aiHeight';
const ADA_VIEW_KEY = 'ada-chat-box';
const ADA_VIEW = {
  CHAT: 'chat',
  WINDOW: 'window',
};
const ADA_THEME_KEY = 'ada-theme';
const ADA_THEME = {
  LIGHT: 'light',
  DARK: 'dark',
};

interface IChatWindowMapsProps {
  conversation: IMessage[];
  fetching: boolean;
  sending: boolean;
  opened: boolean;
  conversationId: string;
  gptStatus: FeatureStatus;
  // Use for Features.adasFeedback
  shouldFetchFeedbackTypes: boolean;
  // End of use for Features.adasFeedback
  // Use for Features.ada2
  toolBoxViewState: ToolboxState;
  viewType: string | undefined;
  // End of use for Features.ada2
}

interface IDispatchProps {
  sendConversationMessage: typeof sendConversationMessage;
  resetConversation: typeof resetConversation;
  closeChatWindow: typeof closeChatWindow;
  showSuccessMessage: typeof showSuccessMessage;
  // Use for Features.adasFeedback
  fetchFeedbackTypes: typeof fetchFeedbackTypes;
  // End of use for Features.adasFeedback
}

type Props = IChatWindowMapsProps & IDispatchProps;

interface IChatWindowState {
  popularTopicsOpened: boolean;
  message: string;
  initialHeight: number;
  isHidden: boolean;
  copying: number;
  openDisclaimer: boolean;
  isLightMode: boolean;
  // Use for Features.adasFeedback
  feedbackType: FeedbackType | null;
  index: number | null;
  // End of use for Features.adasFeedback
  // Use for Features.ada2
  isChatBox: boolean;
  // End of use for Features.ada2
}

let styles = stylesDark;

class ChatWindowBase extends Component<Props, IChatWindowState> {
  messagesRefs: React.RefObject<HTMLDivElement>[];
  loadingMessageRef: React.RefObject<HTMLDivElement>;

  constructor(props) {
    super(props);
    const value: string | null = localStorage.getItem(INITIAL_HEIGHT_KEY);
    const initialHeight = Number(value ?? DEFAULT_INITIAL_HEIGHT);
    const theme = localStorage.getItem(ADA_THEME_KEY);
    const view = localStorage.getItem(ADA_VIEW_KEY);
    const isLightMode = theme === ADA_THEME.LIGHT;
    styles = isLightMode ? stylesLight : stylesDark;
    const isChatBox = view ? view === ADA_VIEW.CHAT : false;
    this.state = {
      popularTopicsOpened: isFeatureEnabled(Features.ada2) ? !isChatBox : true,
      message: '',
      initialHeight,
      isHidden: false,
      copying: -1,
      openDisclaimer: false,
      isLightMode,
      feedbackType: null,
      index: null,
      isChatBox,
    };
    this.onTogglePopularTopics = this.onTogglePopularTopics.bind(this);
    this.onMessageChange = this.onMessageChange.bind(this);
    this.onMessageKeyDown = this.onMessageKeyDown.bind(this);
    this.onMessageKeyUp = this.onMessageKeyUp.bind(this);
    this.sendMessage = this.sendMessage.bind(this);
    this.onResetConversation = this.onResetConversation.bind(this);
    this.onCloseChat = this.onCloseChat.bind(this);
    this.onResize = this.onResize.bind(this);
    this.onCopyConversationId = this.onCopyConversationId.bind(this);
    this.onOpenDisclaimer = this.onOpenDisclaimer.bind(this);
    this.onHideDisclaimerModal = this.onHideDisclaimerModal.bind(this);
    this.onOpenDisclaimerPage = this.onOpenDisclaimerPage.bind(this);
    this.onToggleLightMode = this.onToggleLightMode.bind(this);
    this.onToggleChatWindow = this.onToggleChatWindow.bind(this);
    this.messagesRefs = [];
    this.loadingMessageRef = React.createRef();
  }

  componentDidMount(): void {
    const { shouldFetchFeedbackTypes, fetchFeedbackTypes } = this.props;
    if (shouldFetchFeedbackTypes) {
      fetchFeedbackTypes();
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<IChatWindowState>): void {
    const { conversation: prevConversation, sending: prevSending } = prevProps;
    const { isChatBox: prevIsChatBox } = prevState;
    const { conversation, sending } = this.props;
    if (!prevSending && sending) {
      this.loadingMessageRef?.current?.scrollIntoView({ behavior: 'smooth' });
      return;
    }
    if (prevConversation.length === 0) {
      const index = conversation.length - 1;
      this.messagesRefs[index]?.current?.scrollIntoView({ behavior: 'smooth' });
      return;
    }
    if (prevConversation.length < conversation.length) { // go to new message
      const index = this.findNextAssistantMessage(prevConversation.length);
      this.messagesRefs[index]?.current?.scrollIntoView({ behavior: 'smooth' });
    }

    if (isFeatureEnabled(Features.ada2) && this.state.isChatBox && !prevIsChatBox) {
      this.setState({ popularTopicsOpened: !this.state.isChatBox });
    }

    if (isFeatureEnabled(Features.ada2) && this.state.isChatBox !== prevIsChatBox) {
      const { initialHeight } = this.state;
      const newHeight = this.state.isChatBox ? initialHeight - DEFAULT_MARGIN_TOP : initialHeight + DEFAULT_MARGIN_TOP;
      this.setState({ initialHeight: newHeight });
      localStorage.setItem('aiHeight', `${newHeight}`);
    }

    const theme = this.state.isLightMode ? ADA_THEME.LIGHT : ADA_THEME.DARK;
    localStorage.setItem(ADA_THEME_KEY, theme);
    const view = this.state.isChatBox ? ADA_VIEW.CHAT : ADA_VIEW.WINDOW;
    localStorage.setItem(ADA_VIEW_KEY, view);
  }

  private onToggleFeedback(feedbackType: FeedbackType | null, index: number | null) {
    this.setState({ feedbackType, index });
  }

  private onToggleLightMode() {
    this.setState((prevState) => {
      styles = prevState.isLightMode ? stylesDark : stylesLight; //change to other mode
      return { ...prevState, isLightMode: !prevState.isLightMode };
    });
  }

  private onToggleChatWindow() {
    this.setState((prevState) =>
      ({ ...prevState, isChatBox: !prevState.isChatBox, popularTopicsOpened: false }),
    );
  }

  private onOpenDisclaimer() {
    this.setState({
      openDisclaimer: true,
    });
  }

  private onHideDisclaimerModal() {
    this.setState({
      openDisclaimer: false,
    });
  }

  private onCopy(index: number) {
    this.setState({
      copying: index,
    }, () => {
      setTimeout(() => {
        this.setState({
          copying: -1,
        });
      }, 400);
    });
  }

  private onCTAClick(type: string) {
    this.setState({
      message: 'CREATE IT',
      isHidden: true,
    }, () => {
      this.sendMessage();
    });
  }

  private onCloseChat() {
    this.props.closeChatWindow();
  }

  private onResize(_, { size }: any) {
    localStorage.setItem('aiHeight', size.height);
    if (isFeatureEnabled(Features.ada2)) {
      this.setState({ initialHeight: Number(size.height) });
    }
  }

  private onResetConversation() {
    this.props.resetConversation();
    this.messagesRefs = [];
  }

  private onMessageKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key !== 'Enter') {
      return;
    }
    this.sendMessage();
  }

  private onMessageKeyUp(event: React.KeyboardEvent<HTMLTextAreaElement>) {
    if (isFeatureEnabled(Features.ada2) && event.key === 'Enter' && !event.shiftKey) {
      this.sendMessage();
    }
  }

  private onMessageChange(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    if (isFeatureEnabled(Features.ada2) && !this.getMessage() && event.target.value === '\n') {
      return;
    }
    this.setState({
      message: event.target.value,
    });
  }

  private onTogglePopularTopics(popularTopicsOpened: boolean) {
    this.setState({ popularTopicsOpened: popularTopicsOpened });
  }

  private onCopyConversationId() {
    const { conversationId } = this.props;
    navigator.clipboard.writeText(conversationId);
    this.props.showSuccessMessage(`Conversation id copied ${conversationId}`);
  }

  private onOpenDisclaimerPage() {
    window.open('https://sightx.io/data-governance-policy', '_blank');
  }

  private onExtraQuestionClick(text: string) {
    this.props.sendConversationMessage(text, false);
  }

  private findNextAssistantMessage(index: number): number {
    const { conversation } = this.props;
    const count = conversation.length;
    for (let i = index; i < count; ++i) {
      if (conversation[i].role === RoleType.ASSISTANT) {
        return i;
      }
    }
    return count - 1;
  }

  private getIsChatBoxModeActive() {
    const { isChatBox } = this.state;
    return isChatBox;
  }

  private getCloseButtonColor() {
    const { isLightMode } = this.state;
    return isLightMode ? colors.baseBlack : colors.gray400;
  }

  private getMessage() {
    return striptagsAndUnescapeText(this.state.message).trim();
  }

  private sendMessage() {
    const { sending, fetching } = this.props;
    if (sending || fetching) {
      return;
    }
    const message = this.getMessage();
    if (!message) {
      return;
    }
    const { isHidden } = this.state;
    this.props.sendConversationMessage(message, isHidden);
    this.setState({
      message: '',
      isHidden: false,
    });
  }

  private renderPopularTopics() { // TODO: content fixed, has to change
    const { popularTopicsOpened, isLightMode } = this.state;

    if (!popularTopicsOpened && !isFeatureEnabled(Features.ada2)) {
      return null;
    }
    return <PopularTopics
      isLightMode={isLightMode}
      isChatBoxModeActive={this.getIsChatBoxModeActive() && isFeatureEnabled(Features.ada2)}
      onToggle={this.onTogglePopularTopics}
    />;
  }

  private renderMessageIcon(role: RoleType) {
    if (role === RoleType.ASSISTANT) {
      return <img className={styles.aiIcon} src={userAILogo} alt="sightx ai" />;
    }

    if (isFeatureEnabled(Features.adasFeedback)) {
      return <Avatar />;
    }
    return <SxIcon name="sx-icon-personas" />;
  }

  private renderFeedbackButton(index: number, positive?: boolean) {
    if (!isFeatureEnabled(Features.adasFeedback)) {
      return null;
    }
    const { feedbackType, isLightMode, index: currentIndex } = this.state;
    const type = positive ? FeedbackType.Positive : FeedbackType.Negative;
    const isButtonActive = feedbackType === type && index === currentIndex;
    const iconName = isButtonActive ? 'sx-thumbs-up-filled' : 'sx-thumbs-up';
    const activeColor = isLightMode ? colors.gray600 : colors.baseWhite;
    const color = isButtonActive ? activeColor : colors.gray400;
    return (
      <SxIcon
        name={iconName}
        color={color}
        onClick={this.onToggleFeedback.bind(this, type, index)}
        className={cx(styles.feedbackButton, { [styles.positive]: positive })}
      />
    );
  }

  private renderCopy(role: RoleType, message: string, index: number) {
    if (role !== RoleType.ASSISTANT && !isFeatureEnabled(Features.adasFeedback)) {
      return null;
    }

    const defaultIconName = isFeatureEnabled(Features.adasFeedback) ? 'sx-duplicate' : 'sx-clipboard';
    const iconName = index === this.state.copying ? 'sx-check' : defaultIconName;

    return <div className={styles.copyButton}>
      <CopyToClipboard text={message} onCopy={this.onCopy.bind(this, index)}>
        <SxIcon color={colors.gray400} name={iconName} />
      </CopyToClipboard>
    </div>;
  }

  private renderContent(content: Content[], isLastMessage: boolean) {
    return content.map((item) => {
      if (typeof item === 'string') {
        return item;
      }
      const { key, type } = item;
      switch (type) {
        case ContentPart.CTA:
          const { ctaType } = item as ICTA;
          return <span
            key={key}
            className={cx(styles.cta, { [styles.disabled]: !isLastMessage })}
            onClick={!isLastMessage ? undefined : this.onCTAClick.bind(this, ctaType)}
          >Generate</span>;
        case ContentPart.BR:
          return <br key={item.key} />;
        case ContentPart.HYPERLINK:
          const { text, url } = item as IHyperlink;
          return <a className={styles.hyperlink} key={key} target="_blank" href={url} rel="noreferrer">{text} <SxIcon className={styles.icon} name="sx-external-link" /></a>;
      }
      return null;
    });
  }

  private renderColumnByRole(index: number, role: RoleType, contentToCopy: string) {
    const rightColumn = (
      <div className={cx(styles.rightColumn, { [styles[Features.adasFeedback]]: isFeatureEnabled(Features.adasFeedback) })}>
        {this.renderCopy(role, contentToCopy, index)}
      </div>
    );
    if (isFeatureEnabled(Features.adasFeedback) && role === RoleType.USER) {
      return rightColumn;
    }
    return (
      <FeatureToggle feature={Features.adasFeedback}>
        <On>
          <div className={styles.bottomColumn}>
            {this.renderFeedbackButton(index, true)}
            {this.renderFeedbackButton(index)}
            {this.renderCopy(role, contentToCopy, index)}
          </div>
        </On>
        <Off>{rightColumn}</Off>
      </FeatureToggle>
    );
  }

  private renderExtraQuestions(extraQuestions: string[] = []) {
    if (!isFeatureEnabled(Features.ada3) || extraQuestions.length === 0) {
      return null;
    }
    return <div className={styles.extraQuestions}>
      <div className={styles.text}>Here are a few suggestions for next steps</div>
      <div className={styles.questionsContainer}>
        {extraQuestions.map((question, index) => {
          return <div key={index} className={styles.question} onClick={this.onExtraQuestionClick.bind(this, question)}>
            “{question}”
          </div>;
        })}
      </div>
    </div>;
  }

  private renderMessages() {
    const { fetching } = this.props;
    if (fetching) {
      return <>
        <Skeleton title={false} paragraph={{
          rows: 3,
          style: { height: 34, width: '100%' },
          className: cx(styles.loader, { [styles.loaderChatBox]: this.getIsChatBoxModeActive() && isFeatureEnabled(Features.ada2) }),
          width: MAX_WIDTH_ALLOWED,
        }} style={{ height: 140, width: MAX_WIDTH_ALLOWED }} active />
      </>;
    }
    const { conversation } = this.props;
    const conversationLastIndex = conversation.length - 1;
    return conversation.map((message, index) => {
      const isLastMessage = conversationLastIndex === index;
      const isFirstMessage = index === 0;
      const { role, function_call, transformedContent, contentToCopy, extraQuestions } = message;
      if (function_call || role === RoleType.FUNCTION) {
        return null;
      }
      if (this.messagesRefs[index] === undefined) {
        this.messagesRefs[index] = React.createRef();
      }
      return <div ref={this.messagesRefs[index]} className={cx(styles.message, styles[role], {
        [styles[Features.adasFeedback]]: isFeatureEnabled(Features.adasFeedback),
        [styles[Features.ada2]]: isFeatureEnabled(Features.ada2),
      })} key={index}>
        <div className={styles.leftColumn}>
          <div className={cx(styles.iconContainer, { [styles[Features.adasFeedback]]: isFeatureEnabled(Features.adasFeedback) })}>
            {this.renderMessageIcon(role)}
          </div>
          <div className={styles.text}>
            {this.renderContent(transformedContent, isLastMessage)}
            {isLastMessage && !isFirstMessage ? this.renderExtraQuestions(extraQuestions) : null}
          </div>
        </div>
        {this.renderColumnByRole(index, role, contentToCopy)}
      </div>;
    });
  }

  private renderLoadingMessage() {
    if (!this.props.sending) {
      return null;
    }

    return <>
      <FeatureToggle feature={Features.ada2}>
        <On>
          {this.renderAdaIsThinking()}
        </On>
        <Off>
          <Skeleton title={false} paragraph={{
            rows: 3,
            style: { height: 34, width: '100%' },
            className: cx(styles.loader, { [styles.loaderChatBox]: this.getIsChatBoxModeActive() && isFeatureEnabled(Features.ada2) }),
            width: MAX_WIDTH_ALLOWED,
          }} style={{ height: 140, width: MAX_WIDTH_ALLOWED }} active />
        </Off>
      </FeatureToggle>
      <div ref={this.loadingMessageRef} className={styles.conversationEnd} />
    </>;
  }

  private renderConversation() {
    const { opened } = this.props;
    if (!opened) {
      return null;
    }
    return <div className={cx(styles.conversation, {
      [styles.chatBox]: this.getIsChatBoxModeActive() && isFeatureEnabled(Features.ada2),
      [styles[Features.ada2]]: isFeatureEnabled(Features.ada2),
    })}>
      <div className={cx(styles.innerConversationContainer, { [styles.chatBoxInnerConversationContainer]: this.getIsChatBoxModeActive() && isFeatureEnabled(Features.ada2) })}>
        {this.renderMessages()}
        {this.renderLoadingMessage()}
      </div>
    </div>;
  }

  private renderActionButton() {
    const { sending } = this.props;
    if (sending) {
      return <Spin indicator={<LoadingOutlined style={{ color: colors.gray400, fontSize: 14 }} spin />} />;
    }
    return (
      <SxIcon
        name="sx-send"
        fontSize={isFeatureEnabled(Features.ada2) ? 16 : undefined}
        className={styles.action}
        color={colors.gray400}
        onClick={this.sendMessage}
      />
    );
  }

  private renderAdaIsThinking() {
    return (
      <div className={styles.fetchingAiLogoContainer} >
        <img className={cx(styles.fetchingAiLogo)} src={sightxAILogoAnimatedWhite} alt="sightx ai" />
      </div>
    );
  }

  private renderInput() {
    const { message } = this.state;
    const { sending } = this.props;
    return <div className={cx(styles.inputContainer, { [styles[Features.ada2]]: isFeatureEnabled(Features.ada2) })}>
      <div className={styles.inputInnerContainer}>
        <FeatureToggle feature={Features.ada2}>
          <On>
            <div className={styles.textContainer}>
              <SxTextArea
                placeholder=""
                autoSize={{ minRows: 1, maxRows: 4 }}
                disabled={sending}
                value={message}
                className={styles.textArea}
                onChange={this.onMessageChange}
                onKeyUp={this.onMessageKeyUp}
              />
              <span className={styles.actionButton}>{this.renderActionButton()}</span>
            </div>
          </On>
          <Off>
            <SxInput
              disabled={sending}
              className={styles.input}
              placeholder=""
              suffix={this.renderActionButton()}
              value={message}
              onKeyUp={this.onMessageKeyDown}
              onChange={this.onMessageChange}
            />
          </Off>
        </FeatureToggle>
        <div onClick={this.onOpenDisclaimer} className={styles.disclaimer}>Ada by SightX Feature Disclaimer</div>
      </div>
    </div>;
  }

  private renderDisclaimerModal() {
    const { openDisclaimer } = this.state;
    if (!openDisclaimer) {
      return null;
    }
    return <SxModal
      featureToUse={Features.uirefresh}
      showCloseIcon
      visible
      title="Ada by SightX Feature Disclaimer"
      className={styles.disclaimerModal}
      onlyCancelButton
      cancelText="Close"
      onCancel={this.onHideDisclaimerModal}
    >
      <p className={styles.text}>
        This content has been algorithmically generated by an A.I. model that has been trained on a diverse range of data. While efforts have been made, and continue to be made, to ensure the quality and reliability of the content, there may be limitations, inaccuracies, or biases present. The content should not be considered as a substitute for professional advice, or your own thoughtful research. We recommend consulting with appropriate experts or sources for specific information or guidance. SightX assumes no responsibility for any consequences arising from the use of this content.
      </p>
      <SxButton
        featureToUse={Features.uirefresh}
        type="link"
        onClick={this.onOpenDisclaimerPage}
      >
        Data Governance Policy for SightX
      </SxButton>
    </SxModal>;
  }

  private renderFeedbackModal() {
    const { feedbackType, index } = this.state;
    const { conversationId } = this.props;
    if (!isFeatureEnabled(Features.adasFeedback) || feedbackType === null || index === null) {
      return null;
    }
    return (
      <FeedbackModal
        index={index}
        conversationId={conversationId}
        type={feedbackType}
        onClose={this.onToggleFeedback.bind(this, null, null)}
      />
    );
  }

  render() {
    const { opened, gptStatus, toolBoxViewState, viewType } = this.props;
    if (!isFeatureAvailable(gptStatus)) {
      return null;
    }

    const { initialHeight, isLightMode, isChatBox, popularTopicsOpened } = this.state;
    const isChatBoxModeActive = this.getIsChatBoxModeActive() && isFeatureEnabled(Features.ada2);
    const heightConstraint = isChatBoxModeActive ? MAX_HEIGHT_ALLOWED - DEFAULT_MARGIN_TOP : MAX_HEIGHT_ALLOWED;

    return <>
      {this.renderDisclaimerModal()}
      {this.renderFeedbackModal()}
      <div className={cx(styles.container, {
        [styles.opened]: opened,
        [styles.chatBoxContainer]: isChatBoxModeActive,
        [styles.chatBoxWithToolBoxOpened]: isChatBoxModeActive && toolBoxViewState === ToolboxState.open && viewType !== PopularTopicsPromptsTypes.PIVOT_TABLES_DASHBOARD,
        [styles.chatBoxWithToolBoxMinimized]: isChatBoxModeActive && toolBoxViewState === ToolboxState.minimized,
      })}>
        <ResizableBox
          style={{ width: '100%' }}
          height={initialHeight}
          minConstraints={[100, DEFAULT_INITIAL_HEIGHT]}
          maxConstraints={[MAX_WIDTH_ALLOWED, heightConstraint]}
          axis="y"
          resizeHandles={['n']}
          handle={
            <div className={styles.reactResizableHandle} />
          }
          onResize={this.onResize}
        >
          <>
            <div className={styles.header}>
              <div className={styles.innerHeader}>
                <img onClick={this.onCopyConversationId} className={styles.logo} src={userAILogo} alt="sightx ai" />
                <div className={styles.title}>
                  <span className={styles.innerText}>Ada</span>
                </div>
              </div>
              <div className={cx(styles.actions, { [styles.chatBoxActions]: isFeatureEnabled(Features.ada2) })}>
                <SxSwitch
                  className={styles.themeToggle}
                  checked={isLightMode}
                  checkedChildren={<SxIcon name="sx-moon" fontSize={10} />}
                  unCheckedChildren={<SxIcon name="sx-sun" fontSize={10} />}
                  onChange={this.onToggleLightMode}
                />
                <Tooltip
                  Component={<span>
                    <SxButton
                      type="secondary"
                      className={styles.action}
                      onClick={this.onResetConversation}
                      color={colors.gray400}
                      featureToUse={Features.uirefresh}
                    >
                      Reset
                    </SxButton>
                  </span>}
                  tooltipText="Reset conversation"
                />
                <FeatureToggle feature={Features.ada2}>
                  <On>
                    <SxButton
                      type="secondary"
                      className={cx(styles.action, styles.chatBoxAction, { [styles.active]: isChatBox })}
                      onClick={this.onToggleChatWindow}
                      color={colors.gray400}
                      featureToUse={Features.uirefresh}
                    >
                      <SxIcon name="sx-window" fontSize={16} />
                    </SxButton>
                  </On>
                  <Off />
                </FeatureToggle>
                <SxIcon
                  className={styles.action}
                  onClick={this.onCloseChat}
                  color={this.getCloseButtonColor()}
                  name={isFeatureEnabled(Features.ada2) ? 'sx-cross' : 'sx-chevron-left'}
                  fontSize={14}
                />
              </div>
            </div>
            <div className={cx(styles.innerContainer, { [styles.chatBoxInnerContainer]: isChatBoxModeActive })}>
              <div className={cx(styles.column,
                {
                  [styles.chatBoxColumn]: isChatBoxModeActive,
                  [styles.opened]: isChatBoxModeActive && popularTopicsOpened,
                })}>
                {this.renderConversation()}
                {this.renderInput()}
              </div>
              {this.renderPopularTopics()}
            </div>
          </>
        </ResizableBox>
      </div>
    </>;
  }
}

const mapStateToProps = (state: IGlobalState): IChatWindowMapsProps => {
  const {
    gpt,
    projects: {
      viewType,
    } = {},
  } = state;
  const { aiConsultant } = gpt;
  const { conversation, fetching, sending, chatWindowOpened, id } = aiConsultant;
  const gptStatus = getGPTStatus(state);
  const feedbackTypes = getAdasFeedbackTypes(state);
  const isFetchingFeedbackTypes = isFetchingAdasFeedbackTypes(state);
  const { token } = getSecurity(state);
  const shouldFetchFeedbackTypes = isFeatureEnabled(Features.adasFeedback) &&
    !!token &&
    isFetchingFeedbackTypes !== BlockedByFeature &&
    !isFetchingFeedbackTypes &&
    feedbackTypes !== BlockedByFeature &&
    !feedbackTypes.length;
  const toolboxViewStateResult = getToolboxViewState(state);
  const toolBoxViewState = toolboxViewStateResult !== BlockedByFeature ? toolboxViewStateResult : ToolboxState.closed;

  return {
    conversation,
    fetching,
    sending,
    opened: chatWindowOpened,
    conversationId: id,
    gptStatus,
    shouldFetchFeedbackTypes,
    toolBoxViewState,
    viewType,
  };
};

const mapDispatchToProps: IDispatchProps = {
  fetchFeedbackTypes,
  sendConversationMessage,
  resetConversation,
  closeChatWindow,
  showSuccessMessage,
};

export const ChatWindow = connect(mapStateToProps, mapDispatchToProps)(ChatWindowBase);
