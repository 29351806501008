const css = `/** do not use this yet */
/* Desktop - Eyebrow/Small - Semi-Bold */
.adChecking-module__blocked___KqHMZ {
  opacity: 0.9;
}

.adChecking-module__disabled-component___HBexp {
  opacity: 0.6;
  cursor: not-allowed;
}

.adChecking-module__screen___3G-Oz {
  position: fixed;
  z-index: 10000;
  height: 100vh;
  width: 100vw;
  background-color: #f9f9f9;
  display: flex;
  align-items: center;
  justify-content: center;
}

.adChecking-module__spinner___rd6Iy.ant-spin-dot {
  font-size: 80px;
  color: #959595;
}`;
document.head
    .appendChild(document.createElement("style"))
    .appendChild(document.createTextNode(css));
export {css};
export default {
  "blocked": "adChecking-module__blocked___KqHMZ",
  "disabled-component": "adChecking-module__disabled-component___HBexp",
  "screen": "adChecking-module__screen___3G-Oz",
  "spinner": "adChecking-module__spinner___rd6Iy",
  "ant-spin-dot": "ant-spin-dot"
};