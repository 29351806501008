import React, { useState } from 'react';

import { Col, Divider, Row } from 'antd';
import cx from 'classnames';

import { AntdModal } from '../../antdModal';
import FlatButton from '../../flatButton';
import { AntdButton } from '../../antdButton';
import groupIcon from '../../../../assets/group-12.png';
import groupIconForUirefresh from '../../../../assets/group-12.svg';
import { FeatureToggle, Off, On } from '../../../../featureToggles';
import { Features, isFeatureEnabled } from '../../../../featureToggles/features';
import { SxButton, SxModal } from '../../sxLibrary';

import { IProspect, ProspectFactory } from './prospect.factory';
import { Prospect } from './prospect';

import styles from './inviteTeamModal.module.scss';

interface IInviteTeamModalProps {
  isOpen: boolean;
  onCancel: () => void;
  onInviteProspects: (prospects: IProspect[]) => void;
}

export const InviteTeamModal = (props: IInviteTeamModalProps) => {
  const {
    isOpen,
    onCancel,
    onInviteProspects,
  } = props;

  const prospect = ProspectFactory.createProspect();
  const [prospects, updateProspects] = useState([prospect]);

  const titleText = (
    <span>Invite your team</span>
  );
  const titleIcon = (
    <img style={{ width: 45, height: 45 }} src={groupIcon} alt="team modal icon" />
  );

  const onChangeProspect = (id: string, value: string, fieldName: string) => {
    const prospectUpdated = prospects.find((prospect) => prospect.id === id);
    if (!prospectUpdated) {
      return;
    }
    prospectUpdated[fieldName] = value;
  };

  const onRemoveProspect = (id: string) => {
    updateProspects(prospects.filter((prospect) => prospect.id !== id));
  };

  const renderDivider = (index: number) => {
    if (index > 0) {
      return <Divider className={cx({ [styles.divider]: isFeatureEnabled(Features.uirefresh) })} />;
    }
    return null;
  };

  const renderProspectList = () => {
    return (
      <>
        {
          prospects.map((prospect, index) =>
            (
              <div className={styles.prospectContainer} key={prospect.id}>
                {renderDivider(index)}
                <Prospect
                  onChange={onChangeProspect}
                  onRemove={onRemoveProspect}
                  prospect={prospect}
                  index={index}
                />
              </div>
            ),
          )
        }
      </>
    );
  };

  const onAddTeammate = () => {
    updateProspects((oldProspects) => [...oldProspects, ProspectFactory.createProspect()]);
  };

  const renderAddTeammateButton = () => {
    const label = 'Add a teammate';
    const icon = 'sx-plus';
    if (prospects.length >= 3) {
      return null;
    }
    return (
      <FeatureToggle feature={Features.uirefresh}>
        <On>
          <SxButton featureToUse={Features.uirefresh} onClick={onAddTeammate} iconLeft="sx-plus" type="link">
            Add a teammate
          </SxButton>
        </On>
        <Off>
          <div style={{ marginTop: '10px' }}>
            <FlatButton onClick={onAddTeammate} label={label} icon={icon} />
          </div>
        </Off>
      </FeatureToggle>
    );
  };

  const onInviteClick = () => {
    onInviteProspects([...prospects]);
    updateProspects([ProspectFactory.createProspect()]);
  };

  const onCancelHandler = () => {
    updateProspects([ProspectFactory.createProspect()]);
    onCancel();
  };

  const content = (
    <div>
      <header className={styles.header}>
        <p>SightX is built for collaboration! Invite your team so that you can share and collaborate on projects during your trial period.</p>
      </header>
      <main className={styles.main}>
        {renderProspectList()}
        {renderAddTeammateButton()}
      </main>
      <Row justify="center" className={styles.footer}>
        <Col span={24} className={styles.buttonCol}>
          <AntdButton label="Send invitations" buttonClassName="ant-btn-primary" onClick={onInviteClick} />
        </Col>
        <Col span={24} className={styles.buttonCol}>
          <FlatButton label="Cancel" buttonClassName="ant-btn-primary" onClick={onCancelHandler} />
        </Col>
      </Row>
    </div>
  );

  return (
    <FeatureToggle feature={Features.uirefresh}>
      <On>
        <SxModal
          featureToUse={Features.uirefresh}
          size="extraSmall"
          visible={isOpen}
          onCancel={onCancelHandler}
          titleSubComponent={
            <div className={styles.header}>
              <div className={styles.iconContainer}>
                <img className={styles.icon} src={groupIconForUirefresh} alt="team modal icon" />
              </div>
                Invite your team
            </div>
          }
          showCloseIcon
          noFooter
        >
          <p className={styles.description}>SightX is built for collaboration! Invite your team so that you can share and collaborate on projects during your trial period.</p>
          <main className={styles.content}>
            {renderProspectList()}
            {renderAddTeammateButton()}
          </main>
          <footer className={styles.footer}>
            <SxButton
              featureToUse={Features.uirefresh}
              onClick={onInviteClick}
              type="primary"
              className={styles.button}
            >
              Send invitations
            </SxButton>
            <SxButton
              featureToUse={Features.uirefresh}
              onClick={onCancelHandler}
              type="secondary"
              className={styles.button}
            >
              Cancel
            </SxButton>
          </footer>
        </SxModal>
      </On>
      <Off>
        <AntdModal
          width={420}
          onCancel={onCancelHandler}
          isOpen={isOpen}
          isClosable={false}
          titleText={titleText}
          titleIcon={titleIcon}
          content={content}
        />
      </Off>
    </FeatureToggle>
  );
};
