const css = `/** do not use this yet */
/* Desktop - Eyebrow/Small - Semi-Bold */
.LapsedBanner-module__blocked___jThiw {
  opacity: 0.9;
}

.LapsedBanner-module__disabled-component___CO78g {
  opacity: 0.6;
  cursor: not-allowed;
}

.LapsedBanner-module__lapsedBanner___fZi88 {
  min-height: 43px;
  background: #D0D5DD;
  width: 100%;
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #736dba;
  border-bottom: 0;
  height: 48px;
}
.LapsedBanner-module__lapsedBanner___fZi88 .LapsedBanner-module__information___IxOno {
  display: flex;
  align-items: center;
  justify-content: center;
}
.LapsedBanner-module__lapsedBanner___fZi88 .LapsedBanner-module__information___IxOno .LapsedBanner-module__message___6-O26 {
  white-space: nowrap;
  display: flex;
  gap: 11px;
  color: #393939;
  line-height: 30px;
}

.sightx .ant-btn.LapsedBanner-module__submitButton___BVOqZ {
  background-color: #27235C;
  height: 26px;
  margin-left: 10px;
}
.sightx .ant-btn.LapsedBanner-module__submitButton___BVOqZ span {
  font-weight: normal;
}`;
document.head
    .appendChild(document.createElement("style"))
    .appendChild(document.createTextNode(css));
export {css};
export default {
  "blocked": "LapsedBanner-module__blocked___jThiw",
  "disabled-component": "LapsedBanner-module__disabled-component___CO78g",
  "lapsedBanner": "LapsedBanner-module__lapsedBanner___fZi88",
  "information": "LapsedBanner-module__information___IxOno",
  "message": "LapsedBanner-module__message___6-O26",
  "sightx": "sightx",
  "ant-btn": "ant-btn",
  "submitButton": "LapsedBanner-module__submitButton___BVOqZ"
};