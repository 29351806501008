import React, { Component } from 'react';

import { connect } from 'react-redux';
import moment from 'moment';
import cx from 'classnames';

import { AntdButton } from '../antdButton';
import FlatButton from '../flatButton';
import {
  requestUpgrade,
  inviteProspects,
  getRemainingAnswers,
} from '../../../actions/security';
import { LOCAL_STORAGE_UPGRADE_KEY } from '../../../reducers/security/security.helper';
import { Flip } from '../Flip';
import { IGlobalState } from '../../../reducers/interfaces/state';
import { getRemainingResponses, getUserAccount, getUserId } from '../../../selectors/security/user';
import { Features, isFeatureEnabled } from '../../../featureToggles/features';
import { SxButton } from '../sxLibrary';
import { FeatureToggle, Off, On } from '../../../featureToggles';

import { InviteTeamModal } from './inviteTeamModal';

import styles from './TrialBanner.module.scss';

const MINUTE_IN_MILLISECONDS = 60 * 1000;

const isInUiRefresh = isFeatureEnabled(Features.uirefresh);

interface IMapStateProps {
  userId: string;
  trialExpiresAt: string;
  hideUpgradeButton: boolean;
  remainingResponses;
}

interface IMapDispatchProps {
  requestUpgrade: typeof requestUpgrade;
  inviteProspects: typeof inviteProspects;
  getRemainingAnswers: typeof getRemainingAnswers;
}

type ITrialBannerProps = IMapStateProps & IMapDispatchProps;

interface ITrialBannerState {
  isTeamModalOpen: boolean;
  showResponses: boolean;
}

class TrialBannerBase extends Component<ITrialBannerProps, ITrialBannerState> {
  private changeBannerTextInterval!: NodeJS.Timer;

  constructor(props: ITrialBannerProps) {
    super(props);
    this.state = {
      isTeamModalOpen: false,
      showResponses: false,
    };
    this.onUpgradeHandler = this.onUpgradeHandler.bind(this);
    this.onInviteTeamClick = this.onInviteTeamClick.bind(this);
    this.onInviteTeamModalCancel = this.onInviteTeamModalCancel.bind(this);
    this.onInviteProspectsClick = this.onInviteProspectsClick.bind(this);
  }

  componentDidMount() {
    this.changeBannerTextInterval = setInterval(() => {
      this.setState((state) => ({
        showResponses: !state.showResponses,
      }));
    }, MINUTE_IN_MILLISECONDS);
    this.props.getRemainingAnswers();
  }

  componentWillUnmount() {
    clearInterval(this.changeBannerTextInterval);
  }

  private onInviteTeamClick() {
    this.setState({
      isTeamModalOpen: true,
    });
  }

  private onInviteTeamModalCancel() {
    this.setState({
      isTeamModalOpen: false,
    });
  }

  private onInviteProspectsClick(prospects) {
    const { userId } = this.props;
    const data = prospects.map((prospect) => {
      return {
        firstName: prospect.firstName,
        lastName: prospect.lastName,
        email: prospect.email,
        accountId: userId,
      };
    });
    this.props.inviteProspects(data);
    this.setState({
      isTeamModalOpen: false,
    });
  }

  private onUpgradeHandler() {
    const { userId } = this.props;
    this.props.requestUpgrade(userId);
  }

  private renderCountdown() {
    const { trialExpiresAt, remainingResponses } = this.props;

    if (this.state.showResponses) {
      return <Flip value={remainingResponses} />;
    }

    const date = moment(trialExpiresAt);
    return <Flip value={date.diff(moment(), 'days')} />;
  }

  private renderUpgradeButton() {
    const { hideUpgradeButton } = this.props;
    if (hideUpgradeButton) {
      return null;
    }
    const buttonLabel = 'Let\'s do it!';
    if (isInUiRefresh) {
      return (
        <SxButton
          featureToUse={Features.uirefresh}
          onClick={this.onUpgradeHandler}
          type="primary"
        >
          {buttonLabel}
        </SxButton>
      );
    }
    const btnClassname = cx(styles.submitButton, 'ant-btn-primary');
    return <AntdButton onClick={this.onUpgradeHandler} label={buttonLabel} buttonClassName={btnClassname} />;
  }

  private renderModal() {
    const {
      isTeamModalOpen,
    } = this.state;

    return <InviteTeamModal isOpen={isTeamModalOpen} onCancel={this.onInviteTeamModalCancel} onInviteProspects={this.onInviteProspectsClick}/>;
  }

  private renderTextCountdown() {
    const { showResponses } = this.state;
    const { remainingResponses } = this.props;
    const type = showResponses ? 'responses' : 'days';
    return <span className={cx({ [styles.red]: showResponses && remainingResponses < 30 })}>
      You have {this.renderCountdown()} {type} left in your trial {isInUiRefresh ? '.' : ''}
    </span>;
  }

  render() {
    const inviteLabel = 'Invite your team';
    const upgradeText = <>🚀<b>Upgrade your plan</b> to get unlimited survey responses and access to our full suite of features</>;
    return (
      <React.Fragment>
        <div className={styles.trialBanner}>
          <div>
            {this.renderModal()}
          </div>
          <div className={styles.information}>
            <p className={styles.message}>
              <FeatureToggle feature={Features.uirefresh}>
                <On>
                  {this.renderTextCountdown()}
                  <span>
                    🚀<b>Upgrade your plan</b> to get unlimited survey responses and access to our full suite of features
                  </span>
                </On>
                <Off>
                  {this.renderTextCountdown()}. {upgradeText}
                </Off>
              </FeatureToggle>
              {this.renderUpgradeButton()}
            </p>
          </div>
          <div className={styles.inviteTeam}>
            <FlatButton onClick={this.onInviteTeamClick} icon="sx-email" label={inviteLabel} buttonClassName={styles.inviteButton}/>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: IGlobalState) => {
  const { allowAccessUntil } = getUserAccount(state);
  const userId = getUserId(state);

  return {
    userId,
    trialExpiresAt: allowAccessUntil,
    hideUpgradeButton: !!localStorage.getItem(`${LOCAL_STORAGE_UPGRADE_KEY}-${userId}`),
    remainingResponses: getRemainingResponses(state),
  };
};

const mapDispatchToProps = {
  requestUpgrade,
  inviteProspects,
  getRemainingAnswers,
};

export const TrialBanner = connect(
  mapStateToProps,
  mapDispatchToProps,
)(TrialBannerBase);
