const css = `.fullScreenFrame-module__fullScreenDialog___P5cKY {
  position: fixed;
  display: block;
  width: 100vw;
  right: 0;
  z-index: 1101;
  background: white;
}

@keyframes fullScreenFrame-module__slideIn___KEEgx {
  0% {
    top: 100%;
  }
  100% {
    top: 0;
  }
}
@keyframes fullScreenFrame-module__slideOut___pm8kO {
  0% {
    top: 0;
  }
  100% {
    top: 100%;
  }
}`;
document.head
    .appendChild(document.createElement("style"))
    .appendChild(document.createTextNode(css));
export {css};
export default {
  "fullScreenDialog": "fullScreenFrame-module__fullScreenDialog___P5cKY",
  "slideIn": "fullScreenFrame-module__slideIn___KEEgx",
  "slideOut": "fullScreenFrame-module__slideOut___pm8kO"
};