const css = `/** do not use this yet */
.prospect-module__prospectRow___taGO7 .prospect-module__lastNameCol___P-NUS {
  display: flex;
  align-items: center;
}
.prospect-module__prospectRow___taGO7 .prospect-module__lastNameCol___P-NUS .prospect-module__removeIcon___EdPVs {
  padding-left: 10px;
  padding-right: 0;
  cursor: pointer;
}

.uirefresh .prospect-module__removeIcon___EdPVs {
  color: #000000;
}
.uirefresh .prospect-module__removeIcon___EdPVs:hover {
  color: #736DBA;
  transition: color 0.5s ease-out;
}
.uirefresh .prospect-module__prospectContainer___sILNB {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.uirefresh .prospect-module__prospectContainer___sILNB .prospect-module__inlineContainer___wAtFm {
  display: flex;
  gap: 1rem;
  align-items: center;
}`;
document.head
    .appendChild(document.createElement("style"))
    .appendChild(document.createTextNode(css));
export {css};
export default {
  "prospectRow": "prospect-module__prospectRow___taGO7",
  "lastNameCol": "prospect-module__lastNameCol___P-NUS",
  "removeIcon": "prospect-module__removeIcon___EdPVs",
  "uirefresh": "uirefresh",
  "prospectContainer": "prospect-module__prospectContainer___sILNB",
  "inlineContainer": "prospect-module__inlineContainer___wAtFm"
};