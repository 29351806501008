import React from 'react';

import { connect } from 'react-redux';
import { isEqual } from 'lodash';
import { areFrontendIdsEqual, isNullOrUndefined } from 'sx-project-library/dist/helpers';

import { getUser } from '../../selectors/security/user';
import { getUserCompleteName } from '../../common/user.utils';
import { isUserPilotIntegrationBlockedFor } from '../../featureToggles/userpilotIntegration';
import { companyIdFromUser } from '../common/utils/helpers';
import { IGlobalState } from '../../reducers/interfaces/state';
import { ITrial, IUser } from '../../interfaces/ISecurity';
import { EmptyObject, IFrontendId } from '../../interfaces/common';
import { getCurrentEnterpriseTrial, getCurrentProTrial, getPastProTrial } from '../../selectors/common/user';
import { AccountsSupported } from '../common/educationalModalBase/helper';
import { BlockedByFeature } from '../../featureToggles/features';

import { getTrialType } from './helper';

interface IUserPilotState {
  userId: IFrontendId | null;
}

interface IUserPilotProps {
  account: IUser | EmptyObject;
  isEnabled: boolean;
  pathname: string;
  // Use for Features.TrialsOfTiers
  proTrial: ITrial | undefined;
  enterpriseTrial: ITrial | undefined;
  pastProTrial: ITrial | undefined;
  trialType: AccountsSupported | undefined;
  // End of use for Features.TrialsOfTiers
  userName?: string;
}

class UserPilotBase extends React.PureComponent<IUserPilotProps, IUserPilotState> {
  constructor(props: IUserPilotProps) {
    super(props);
    this.state = {
      userId: null,
    };
  }

  componentDidUpdate(prevProps: Readonly<IUserPilotProps>, prevState: Readonly<IUserPilotState>) {
    const { userId } = this.state;
    const { userId: prevUserId } = prevState;
    const { account, pathname, userName, isEnabled, trialType, proTrial, enterpriseTrial, pastProTrial } = this.props;
    const {
      account: prevAccount,
      pathname: prevPathname,
      userName: prevUserName,
    } = prevProps;
    const userPilotId: string | undefined = window['userpilotSettings']?.userid;
    const shouldReload = prevUserId !== userId ||
      prevUserName !== userName ||
      !isEqual(prevAccount, account) ||
      isNullOrUndefined(userPilotId) ||
      (userId && !areFrontendIdsEqual(userPilotId, userId));

    if (account && prevAccount.id !== account.id && account.id) {
      this.setState({ userId: account.id });
    }

    if (
      userId &&
      shouldReload &&
      !!userpilot &&
      isEnabled &&
      userName
    ) {
      userpilot.identify(`${userId}`, {
        name: userName,
        email: account.email,
        created_at: account.createdAt,
        first_login: account.firstLogin,
        company: {
          id: account.accountId,
          name: account.account?.name || '',
          account_type: account.account?.accessType,
          trial: trialType,
          past_pro_trial: pastProTrial ? 'Y' : undefined,
          pro_trial_start_date: proTrial?.startDateUtc,
          pro_trial_end_date: proTrial?.endDateUtc,
          enterprise_trial_start_date: enterpriseTrial?.startDateUtc,
          enterprise_trial_end_date: enterpriseTrial?.endDateUtc,
        },
      });
    }

    if (
      userpilot &&
      userId &&
      isEnabled &&
      (
        pathname !== prevPathname ||
        shouldReload
      )
    ) {
      userpilot.reload();
    }
  }

  render() {
    return null;
  }
}

const mapStateToProps = (state: IGlobalState): IUserPilotProps => {
  const {
    router: { location: { pathname = '' } },
  } = state;
  const account = getUser(state);
  const accountCompanyId = companyIdFromUser(state);
  const userName = getUserCompleteName(account);
  const isEnabled = !isUserPilotIntegrationBlockedFor(accountCompanyId);
  const enterpriseTrial = getCurrentEnterpriseTrial(state);
  const proTrial = getCurrentProTrial(state);
  const pastProTrial = getPastProTrial(state);
  const trialTypeResult = getTrialType(proTrial?.descriptor || enterpriseTrial?.descriptor);
  const trialType = trialTypeResult !== BlockedByFeature ? trialTypeResult : undefined;

  return {
    account,
    isEnabled,
    userName,
    pathname: pathname,
    proTrial,
    enterpriseTrial,
    pastProTrial,
    trialType,
  };
};

export const UserPilot = connect(mapStateToProps, {})(UserPilotBase);