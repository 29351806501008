import { IGlobalState } from '../../interfaces/state';

export const selectDynamicTopics = (state: IGlobalState) => {
  return state.gpt?.aiConsultantPopularTopics?.topics ?? [];
};

export const isDynamicTopicsFetching = (state: IGlobalState) => {
  return state.gpt?.aiConsultantPopularTopics?.fetching;
};

export const selectSectionFetched = (state: IGlobalState) => {
  return state.gpt?.aiConsultantPopularTopics?.sectionFetched;
};

export const isProjectToLarge = (state: IGlobalState) => {
  return state.gpt?.aiConsultantPopularTopics?.projectToLarge;
};