import React, { memo, useState } from 'react';

import cx from 'classnames';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { SxIcon, SxPopup } from '../sxLibrary';
import { BlockedByFeature, Features, isFeatureEnabled } from '../../../featureToggles/features';
import { colors } from '../../../styles/variables';
import { Tooltip } from '../utils/wrapComponents';
import { UpgradeTooltipText } from '../accessControl';
import { EventLabels, logEvent } from '../../../actions/globals';
import {
  isCreateSection,
  isAnalysisPath,
  isAnalyzeOverviewSection,
  isAnalyzeAutomatedPersonasSection,
  isAnalyzeCorrelationSection,
  isAnalyzeLinearRegressionSection,
  isAnalyzeManualPersonasSection,
  isAnalyzeQuestionSection,
  isAnalyzeResponsesSection,
  isAnalyzeSimulatorSection,
  isAnalyzeTrendSection,
  isAnalyzePivotTablesSection,
  isDistributeLinkSection,
  isDistributePanelSection,
  isDistributeEmailSmsSection,
  isDistributeSection,
} from '../utils/navigation';
import { getActiveProjectProjectId, getActiveProjectProjectStatus } from '../../../selectors/activeProject';
import BetaTag from '../betaTag';

import { ILeftMenuItem } from './interface';

import styles from './index.module.scss';

const isTrialOfTiersEnv = isFeatureEnabled(Features.TrialsOfTiers);

export const Option = memo((props: ILeftMenuItem) => {
  const dispatch = useDispatch();
  const [subMenuOpen, setSubMenuOpen] = useState(false);
  const projectId = useSelector(getActiveProjectProjectId);
  const projectStatus = useSelector(getActiveProjectProjectStatus);
  const {
    icon,
    label: labelText,
    active,
    isBeta,
    showIcon = true,
    disabled,
    needUpgrade,
    disabledText = UpgradeTooltipText,
    openEducationModalFor,
    featureName,
    allowTestTrial,
    optionClassName: externalOptionClassName,
    rightIconSize = 12,
  } = props;

  const label = isBeta ? <span className={styles.labelWithBetaTag}>{labelText} <BetaTag className={styles.betaTag} /></span> : labelText;

  const onVisibleChange = (open: boolean) => setSubMenuOpen(open);

  const onSubOptionClick = () => setSubMenuOpen(false);

  const onOptionClick = () => {
    const extraFields = { projectStatus, projectId };
    const route = 'route' in props ? props.route : null;
    if (!route) {
      return;
    }
    if (isCreateSection(route)) {
      dispatch(logEvent(EventLabels.CREATE_VIEW, extraFields));
    }

    if (isAnalysisPath(route)) {
      const sectionChecks = [
        { sectionChecker: isAnalyzeOverviewSection, eventLabel: EventLabels.ANALYZE_OVERVIEW },
        { sectionChecker: isAnalyzeResponsesSection, eventLabel: EventLabels.ANALYZE_RESPONSES },
        { sectionChecker: isAnalyzeQuestionSection, eventLabel: EventLabels.ANALYZE_QUESTION },
        { sectionChecker: isAnalyzePivotTablesSection, eventLabel: EventLabels.ANALYZE_PIVOT_TABLES },
        { sectionChecker: isAnalyzeCorrelationSection, eventLabel: EventLabels.ANALYZE_CORRELATION },
        { sectionChecker: isAnalyzeLinearRegressionSection, eventLabel: EventLabels.ANALYZE_LINEAR_REGRESSION },
        { sectionChecker: isAnalyzeTrendSection, eventLabel: EventLabels.ANALYZE_TREND },
        { sectionChecker: isAnalyzeSimulatorSection, eventLabel: EventLabels.ANALYZE_SIMULATOR },
        { sectionChecker: isAnalyzeManualPersonasSection, eventLabel: EventLabels.ANALYZE_MANUAL_PERSONA },
        { sectionChecker: isAnalyzeAutomatedPersonasSection, eventLabel: EventLabels.ANALYZE_AUTOMATED_PERSONA },
      ];

      for (const sectionCheck of sectionChecks) {
        const { sectionChecker, eventLabel } = sectionCheck;
        if (sectionChecker(route) !== BlockedByFeature && sectionChecker(route)) {
          dispatch(logEvent(eventLabel, extraFields));
          break;
        }
      }
    }

    if (isDistributeSection(route)) {
      const sectionChecks = [
        { sectionChecker: isDistributeLinkSection, eventLabel: EventLabels.DISTRIBUTE_LINKS },
        { sectionChecker: isDistributePanelSection, eventLabel: EventLabels.DISTRIBUTE_PANEL },
        { sectionChecker: isDistributeEmailSmsSection, eventLabel: EventLabels.DISTRIBUTE_EMAIL_SMS },
      ];

      for (const sectionCheck of sectionChecks) {
        const { sectionChecker, eventLabel } = sectionCheck;
        if (sectionChecker(route) !== BlockedByFeature && sectionChecker(route)) {
          dispatch(logEvent(eventLabel, extraFields));
          break;
        }
      }
    }
  };

  // called in Features.TrialOfTiers
  const onClickUpgradeTo = () => {
    if (!isTrialOfTiersEnv) {
      return null;
    }
    return (openEducationModalFor && featureName && openEducationModalFor(featureName));
  };

  const leftIcon = (icon && showIcon) ? <SxIcon name={icon} className={styles.icon} /> : null;

  const optionClassName = cx(styles.option, {
    [styles.active]: active,
    [styles.needUpgrade]: needUpgrade,
    [styles.disabled]: disabled,
  }, externalOptionClassName);

  let rightIcon: null | JSX.Element = null;

  if (needUpgrade) {
    rightIcon = <SxIcon
      name={isTrialOfTiersEnv ? 'sx-star-plus' : 'sx-lock'}
      color={isTrialOfTiersEnv ? colors.orange500 : undefined}
      fontSize={rightIconSize}
    />;
  }

  if (disabled) {
    rightIcon = <SxIcon
      name="sx-lock"
      color={colors.grayWarm600}
      fontSize={rightIconSize}
    />;
  }

  if ('subOptions' in props) {
    if (!props.subOptions.length) {
      return null;
    }

    const Trigger = <span className={optionClassName}>
      <div className={styles.label}>{leftIcon}{label}{rightIcon}</div>
      {!needUpgrade && <SxIcon
        className={cx(styles.popupIcon, { [styles.open]: subMenuOpen })}
        name="sx-chevron-right"
      />}
    </span>;

    if (needUpgrade) {
      return <Tooltip
        placement="right"
        featureToUse={Features.uirefresh}
        className={styles.disabledTooltip}
        Component={Trigger}
        tooltipText={disabledText}
      />;
    }

    return <SxPopup
      featureToUse={Features.uirefresh}
      forceCloseOnClick
      overlayClassName={styles.subOptionsMenu}
      placement="right"
      showOn="click"
      open={subMenuOpen}
      onVisibleChange={onVisibleChange}
      trigger={Trigger}
    >
      <div onClick={onSubOptionClick}>
        {props.subOptions.map((subOption) => <Option {...subOption} showIcon={false} openEducationModalFor={openEducationModalFor} />)}
      </div>
    </SxPopup>;
  }

  const onChangeRoute = () => needUpgrade ? window.location.pathname : props.route;
  let option;
  if (allowTestTrial && isTrialOfTiersEnv && !(disabled && isFeatureEnabled(Features.sectionsPermissions))) {
    const upgradeIcon = <SxIcon name="sx-star-plus" fontSize={rightIconSize} color={colors.orange500} />;
    option = <div className={optionClassName} onClick={onClickUpgradeTo}>
      <div className={cx(styles.label, styles.whiteText)}>{leftIcon}{label}<div className={styles.iconRightContainer}>{upgradeIcon}</div></div>
    </div>;
  } else {
    option = needUpgrade || (disabled && isFeatureEnabled(Features.sectionsPermissions)) ? <div className={optionClassName}>
      <div className={styles.label}><div className={styles.labelAndIcon}>{leftIcon}{label}</div>{rightIcon}</div>
    </div> : <Link to={onChangeRoute} className={optionClassName} onClick={onOptionClick}>
      <div className={styles.label}><div className={styles.labelAndIcon}>{leftIcon}{label}</div></div>
    </Link>;
  }

  return <Tooltip
    placement="right"
    featureToUse={Features.uirefresh}
    className={styles.disabledTooltip}
    Component={option}
    tooltipText={disabledText}
    hideTooltip={!needUpgrade && !disabled}
  />;
});
