const css = `/* Desktop - Eyebrow/Small - Semi-Bold */
.GetHelp-module__getHelp___jxuxQ {
  color: #FFFFFF;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  margin: 2px 0;
  font-size: 14px;
  font-weight: 600;
}
.GetHelp-module__getHelp___jxuxQ:hover .GetHelp-module__label___lCgAQ,
.GetHelp-module__getHelp___jxuxQ:hover .sx-icon {
  color: #06BFA0;
}

.GetHelp-module__userConfigMenuPopup___Vwz5i {
  z-index: 1100;
}`;
document.head
    .appendChild(document.createElement("style"))
    .appendChild(document.createTextNode(css));
export {css};
export default {
  "getHelp": "GetHelp-module__getHelp___jxuxQ",
  "label": "GetHelp-module__label___lCgAQ",
  "sx-icon": "sx-icon",
  "userConfigMenuPopup": "GetHelp-module__userConfigMenuPopup___Vwz5i"
};