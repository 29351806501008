/* eslint-disable no-extend-native */

if (!Math.trunc) {
  Math.trunc = function (value) {
    value = +value;

    if (!isFinite(value)) {
      return value;
    }

    //second case for zeros values -0.123, 0.123
    return (value - value % 1) || (value < 0 ? -0 : 0);
  };
}

if (![].at) {
  Array.prototype.at = function (n) {
    n = Math.trunc(n) || 0;
    // Allow negative indexing from the end
    if (n < 0) {
      n += this.length;
    }
    // OOB access is guaranteed to return undefined
    if (n < 0 || n >= this.length) {
      return undefined;
    }

    return this[n];
  };
}

export {};