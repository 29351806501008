const css = `.sightx .index-module__upgradeButton___tVlRX.ant-btn.ant-btn-default {
  background-color: transparent;
  border-radius: 6px;
  border: 1px solid #475467;
  color: #ffffff;
  font-weight: 600;
  height: 30px;
  margin-bottom: 16px;
  padding: 3px 15px;
  width: 100%;
}
.sightx .index-module__upgradeButton___tVlRX.ant-btn.ant-btn-default:hover, .sightx .index-module__upgradeButton___tVlRX.ant-btn.ant-btn-default:active, .sightx .index-module__upgradeButton___tVlRX.ant-btn.ant-btn-default:focus {
  background-color: transparent;
  border-color: #475467;
}
.sightx .index-module__upgradeButton___tVlRX .index-module__title___hW6m9 {
  align-items: center;
  display: flex;
  justify-content: center;
  gap: 8px;
}`;
document.head
    .appendChild(document.createElement("style"))
    .appendChild(document.createTextNode(css));
export {css};
export default {
  "sightx": "sightx",
  "upgradeButton": "index-module__upgradeButton___tVlRX",
  "ant-btn": "ant-btn",
  "ant-btn-default": "ant-btn-default",
  "title": "index-module__title___hW6m9"
};