const css = `/** do not use this yet */
/* Desktop - Eyebrow/Small - Semi-Bold */
.importDialog-module__blocked___7I-eb {
  opacity: 0.9;
}

.importDialog-module__disabled-component___RdCpV {
  opacity: 0.6;
  cursor: not-allowed;
}

.importDialog-module__minimized___-l7w4 {
  display: none;
}

.importDialog-module__errorsTitle___FYpNq {
  font-size: 16px;
  color: white;
  font-weight: 600;
  padding: 5px 10px;
  background: #d73332;
}

.importDialog-module__importDialog___Q8jdb {
  width: 300px;
  bottom: 40px;
  right: 60px;
  position: fixed;
  background: white;
  border: 1px solid #27235C;
  border-radius: 4px;
  z-index: 999999999999;
  max-height: 400px;
}
.importDialog-module__importDialog___Q8jdb .importDialog-module__exceptionMessage___Bs9Vy {
  display: flex;
  padding: 10px;
}
.importDialog-module__importDialog___Q8jdb .importDialog-module__exceptionMessage___Bs9Vy .importDialog-module__message___RnN-b {
  margin-left: 10px;
}
.importDialog-module__importDialog___Q8jdb .importDialog-module__redirectMessage___WmlhJ {
  padding: 5px 20px 15px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.importDialog-module__importDialog___Q8jdb .importDialog-module__redirectMessage___WmlhJ span {
  margin-bottom: 15px;
}
.importDialog-module__importDialog___Q8jdb .importDialog-module__importDialogErrors___CLdmD {
  padding: 10px;
  max-height: 240px;
  overflow-y: auto;
}
.importDialog-module__importDialog___Q8jdb .importDialog-module__importDialogErrors___CLdmD .importDialog-module__errors___2AbSK {
  border-bottom: 1px solid #ddd;
  padding: 5px 0;
  margin-bottom: 10px;
}
.importDialog-module__importDialog___Q8jdb .importDialog-module__importDialogErrors___CLdmD .importDialog-module__errors___2AbSK:last-of-type {
  border-bottom: none;
}
.importDialog-module__importDialog___Q8jdb .importDialog-module__importDialogErrors___CLdmD tr td:first-of-type {
  width: 45px;
}
.importDialog-module__importDialog___Q8jdb .importDialog-module__importDialogBody___iyq-B {
  padding: 20px 20px 32px 20px;
}
.importDialog-module__importDialog___Q8jdb .importDialog-module__importDialogBody___iyq-B .importDialog-module__progressStepContainer___W-zXp .importDialog-module__progressStep___G0S65 {
  width: 35px;
  height: 35px;
  border-radius: 20px;
  border: 1px solid purple;
}
.importDialog-module__importDialog___Q8jdb .importDialog-module__importDialogBody___iyq-B .importDialog-module__progressStepContainer___W-zXp span {
  position: absolute;
  top: 40px;
  font-size: 10px;
}

.importDialog-module__handler___OQY-m {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  background: #27235c;
  color: white;
  cursor: move;
  display: flex;
  align-items: center;
}
.importDialog-module__handler___OQY-m .importDialog-module__handlerContent___cwalr {
  user-select: none;
  width: 90%;
  padding: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.importDialog-module__handler___OQY-m .importDialog-module__importCollapse___ZQFgG {
  padding: 10px;
  width: 10%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.importDialog-module__handler___OQY-m .importDialog-module__importCollapse___ZQFgG:hover {
  cursor: pointer;
}
.importDialog-module__handler___OQY-m .importDialog-module__importCollapse___ZQFgG:hover * {
  color: white !important;
}`;
document.head
    .appendChild(document.createElement("style"))
    .appendChild(document.createTextNode(css));
export {css};
export default {
  "blocked": "importDialog-module__blocked___7I-eb",
  "disabled-component": "importDialog-module__disabled-component___RdCpV",
  "minimized": "importDialog-module__minimized___-l7w4",
  "errorsTitle": "importDialog-module__errorsTitle___FYpNq",
  "importDialog": "importDialog-module__importDialog___Q8jdb",
  "exceptionMessage": "importDialog-module__exceptionMessage___Bs9Vy",
  "message": "importDialog-module__message___RnN-b",
  "redirectMessage": "importDialog-module__redirectMessage___WmlhJ",
  "importDialogErrors": "importDialog-module__importDialogErrors___CLdmD",
  "errors": "importDialog-module__errors___2AbSK",
  "importDialogBody": "importDialog-module__importDialogBody___iyq-B",
  "progressStepContainer": "importDialog-module__progressStepContainer___W-zXp",
  "progressStep": "importDialog-module__progressStep___G0S65",
  "handler": "importDialog-module__handler___OQY-m",
  "handlerContent": "importDialog-module__handlerContent___cwalr",
  "importCollapse": "importDialog-module__importCollapse___ZQFgG"
};