const css = `/** do not use this yet */
.inviteTeamModal-module__prospectContainer___DmBNZ {
  margin-top: 5px;
  margin-bottom: 5px;
}

.inviteTeamModal-module__footer___N-KNR {
  margin-top: 30px;
}
.inviteTeamModal-module__footer___N-KNR .inviteTeamModal-module__buttonCol___ONJgE {
  margin-top: 10px;
  display: flex;
  justify-content: center;
}

.inviteTeamModal-module__header___TfxEE {
  margin: 15px 0;
}
.inviteTeamModal-module__header___TfxEE p {
  text-align: center;
}

.inviteTeamModal-module__main___oOOv2 {
  margin-top: 25px;
}

.sightx.uirefresh .inviteTeamModal-module__header___TfxEE {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  gap: 1.5rem;
  padding-left: 2rem;
  margin: 0;
}
.sightx.uirefresh .inviteTeamModal-module__header___TfxEE .inviteTeamModal-module__iconContainer___XdMVH {
  display: grid;
  place-content: center;
  border-radius: 100%;
  border: 7px solid #CCCBE7;
  width: 4rem;
  height: 4rem;
  background-color: #EAEAF5;
}
.sightx.uirefresh .inviteTeamModal-module__header___TfxEE .inviteTeamModal-module__iconContainer___XdMVH .inviteTeamModal-module__icon___1ibNY {
  width: 2.5rem;
}
.sightx.uirefresh .inviteTeamModal-module__description___tP0uW {
  text-align: center;
  width: 370px;
  position: relative;
  right: 9px;
  line-height: normal;
  color: #475467;
}
.sightx.uirefresh .inviteTeamModal-module__divider___LFeWn {
  background-color: #736DBA;
  margin: 1rem 0;
}
.sightx.uirefresh .inviteTeamModal-module__footer___N-KNR {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 19px;
}`;
document.head
    .appendChild(document.createElement("style"))
    .appendChild(document.createTextNode(css));
export {css};
export default {
  "prospectContainer": "inviteTeamModal-module__prospectContainer___DmBNZ",
  "footer": "inviteTeamModal-module__footer___N-KNR",
  "buttonCol": "inviteTeamModal-module__buttonCol___ONJgE",
  "header": "inviteTeamModal-module__header___TfxEE",
  "main": "inviteTeamModal-module__main___oOOv2",
  "sightx": "sightx",
  "uirefresh": "uirefresh",
  "iconContainer": "inviteTeamModal-module__iconContainer___XdMVH",
  "icon": "inviteTeamModal-module__icon___1ibNY",
  "description": "inviteTeamModal-module__description___tP0uW",
  "divider": "inviteTeamModal-module__divider___LFeWn"
};