const css = `.index-module__leftNavigation___HhbIp .index-module__projectMenu___OURz5 .index-module__menuCollapsible___vHETN .index-module__menuCollapsibleContent___VVF7w .index-module__option___zr4zk .index-module__label___QukRT {
  -webkit-font-smoothing: antialiased;
  font-family: "Source Sans Pro";
}

/* Desktop - Eyebrow/Small - Semi-Bold */
.index-module__leftNavigation___HhbIp .index-module__projectMenu___OURz5 .index-module__menuCollapsible___vHETN .index-module__menuCollapsibleContent___VVF7w .index-module__option___zr4zk .index-module__label___QukRT {
  font-size: 14px;
  line-height: normal;
}

.index-module__leftNavigation___HhbIp .index-module__projectMenu___OURz5 .index-module__menuCollapsible___vHETN .index-module__menuCollapsibleContent___VVF7w .index-module__option___zr4zk .index-module__label___QukRT {
  font-weight: 400;
}

.index-module__leftNavigation___HhbIp .index-module__userMenu___kcSXN .index-module__userInfo___eP-5R .index-module__userAndEmail___-jBy- .index-module__email___BqaUN {
  font-weight: 400;
  font-size: 12px;
  line-height: normal;
}

.index-module__leftNavigation___HhbIp .index-module__userMenu___kcSXN .index-module__getHelpAndImpersonate___d3xoA {
  font-weight: 600;
  font-size: 12px;
  line-height: normal;
}

.index-module__leftNavigation___HhbIp .index-module__userMenu___kcSXN .index-module__userInfo___eP-5R .index-module__userAndEmail___-jBy- .index-module__username___gUYgW, .index-module__leftNavigation___HhbIp .index-module__projectMenu___OURz5 .index-module__menuCollapsible___vHETN .index-module__menuCollapsibleHeader___DxRVT > a, .index-module__leftNavigation___HhbIp .index-module__option___zr4zk .index-module__label___QukRT {
  font-weight: 700;
  font-size: 12px;
  line-height: normal;
}

.index-module__subOptionsMenu___Cbp0f {
  left: 218px !important;
  padding: 0;
}
.index-module__subOptionsMenu___Cbp0f .ant-popover-arrow {
  display: none;
}
.index-module__subOptionsMenu___Cbp0f .ant-popover-content .ant-popover-inner {
  background-color: #1C1C1C;
  border-radius: 6px;
  border: 1px solid #3C3C3C;
  padding: 5px;
}
.index-module__subOptionsMenu___Cbp0f .ant-popover-content .ant-popover-inner .ant-popover-inner-content {
  padding: 0;
}
.index-module__subOptionsMenu___Cbp0f .index-module__option___zr4zk.index-module__disabled___Lo6Ie .index-module__label___QukRT {
  color: #ffffff;
}

.index-module__leftNavigation___HhbIp {
  position: relative;
  z-index: 1100;
  width: 218px;
  min-width: 218px;
  height: 100%;
  background-color: #000000;
  color: #ffffff;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: min-content min-content 1fr min-content;
  padding: 15px 12px;
}
.index-module__leftNavigation___HhbIp .index-module__initialOptions___qg1-p {
  padding-top: 4px;
}
.index-module__leftNavigation___HhbIp a,
.index-module__leftNavigation___HhbIp .sx-icon {
  color: #ffffff;
  transition: color ease 300ms;
}
.index-module__leftNavigation___HhbIp .index-module__logo___CI4qH {
  display: flex;
  justify-content: center;
  margin-top: 9px;
}
.index-module__leftNavigation___HhbIp .index-module__logo___CI4qH > a {
  width: unset;
}
.index-module__leftNavigation___HhbIp .index-module__logo___CI4qH > a > img {
  width: auto;
  height: 31px;
}
.index-module__leftNavigation___HhbIp .index-module__option___zr4zk {
  margin-bottom: 8px;
  padding: 0 16px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  opacity: 1;
  transition: background-color 300ms, opacity 200ms, margin 400ms, height 400ms;
  white-space: nowrap;
  cursor: pointer;
}
.index-module__leftNavigation___HhbIp .index-module__option___zr4zk .index-module__label___QukRT {
  letter-spacing: 2.4px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
}
.index-module__leftNavigation___HhbIp .index-module__option___zr4zk .index-module__label___QukRT > i {
  font-size: 15px;
}
.index-module__leftNavigation___HhbIp .index-module__option___zr4zk .index-module__label___QukRT .index-module__labelAndIcon___tSAZt {
  display: flex;
  align-items: center;
  gap: 8px;
}
.index-module__leftNavigation___HhbIp .index-module__option___zr4zk .index-module__label___QukRT .index-module__labelAndIcon___tSAZt > i {
  font-size: 15px;
}
.index-module__leftNavigation___HhbIp .index-module__option___zr4zk .index-module__label___QukRT .index-module__iconRightContainer___S0sVZ {
  display: flex;
  flex: 1;
  justify-content: flex-end;
}
.index-module__leftNavigation___HhbIp .index-module__option___zr4zk .index-module__popupIcon___rOXZQ {
  display: inline-block;
  transition: transform ease 300ms;
}
.index-module__leftNavigation___HhbIp .index-module__option___zr4zk .index-module__popupIcon___rOXZQ.index-module__open___s-Bf7 {
  transform: rotate(-180deg);
}
.index-module__leftNavigation___HhbIp .index-module__option___zr4zk .index-module__popupIcon___rOXZQ.index-module__accessDisabled___ULctd.index-module__disabled___Lo6Ie > a {
  color: #6F6F6F;
}
.index-module__leftNavigation___HhbIp .index-module__option___zr4zk.index-module__active___cskj3 {
  background-color: #736DBA;
}
.index-module__leftNavigation___HhbIp .index-module__option___zr4zk.index-module__disabled___Lo6Ie, .index-module__leftNavigation___HhbIp .index-module__option___zr4zk.index-module__needUpgrade___uBR8F {
  cursor: pointer;
  color: #ffffff;
}
.index-module__leftNavigation___HhbIp .index-module__option___zr4zk.index-module__disabled___Lo6Ie .index-module__label___QukRT, .index-module__leftNavigation___HhbIp .index-module__option___zr4zk.index-module__needUpgrade___uBR8F .index-module__label___QukRT {
  opacity: 1;
}
.index-module__leftNavigation___HhbIp .index-module__option___zr4zk.index-module__disabled___Lo6Ie:hover, .index-module__leftNavigation___HhbIp .index-module__option___zr4zk.index-module__needUpgrade___uBR8F:hover {
  background-color: inherit;
}
.index-module__leftNavigation___HhbIp .index-module__option___zr4zk.index-module__disabled___Lo6Ie.index-module__accessDisabled___ULctd, .index-module__leftNavigation___HhbIp .index-module__option___zr4zk.index-module__needUpgrade___uBR8F.index-module__accessDisabled___ULctd {
  color: #6F6F6F;
}
.index-module__leftNavigation___HhbIp .index-module__option___zr4zk.index-module__disabled___Lo6Ie.index-module__accessDisabled___ULctd:hover, .index-module__leftNavigation___HhbIp .index-module__option___zr4zk.index-module__needUpgrade___uBR8F.index-module__accessDisabled___ULctd:hover {
  color: #6F6F6F;
}
.index-module__leftNavigation___HhbIp .index-module__option___zr4zk:hover {
  background-color: #504097;
}
.index-module__leftNavigation___HhbIp .index-module__option___zr4zk.index-module__mainOption___dQyj4 {
  border-radius: 6px;
  border: 1px solid #475467;
}
.index-module__leftNavigation___HhbIp .index-module__option___zr4zk.index-module__mainOption___dQyj4.index-module__disabled___Lo6Ie {
  opacity: 0.4;
}
.index-module__leftNavigation___HhbIp .index-module__option___zr4zk.index-module__mainOption___dQyj4.index-module__disabled___Lo6Ie > div {
  justify-content: space-between;
}
.index-module__leftNavigation___HhbIp .index-module__option___zr4zk.index-module__mainOption___dQyj4:hover {
  border: 1px solid #ffffff;
  background-color: #1C1C1C;
}
.index-module__leftNavigation___HhbIp .index-module__option___zr4zk.index-module__mainOption___dQyj4.index-module__active___cskj3 {
  background-color: #736DBA;
}
.index-module__leftNavigation___HhbIp .index-module__option___zr4zk.index-module__branding___fdwfS.index-module__disabled___Lo6Ie .sx-lock {
  color: #ffffff !important;
}
.index-module__leftNavigation___HhbIp .index-module__projectMenu___OURz5 {
  height: 100%;
  overflow: hidden;
  padding-top: 0;
  padding-bottom: 0;
}
.index-module__leftNavigation___HhbIp .index-module__projectMenu___OURz5 .index-module__menuCollapsible___vHETN {
  display: flex;
  background-color: #1C1C1C;
  padding-left: 12px;
  padding-right: 12px;
  flex-direction: column;
  gap: 8px;
  align-self: stretch;
  max-height: calc(100% - 80px - 25px);
}
.index-module__leftNavigation___HhbIp .index-module__projectMenu___OURz5 .index-module__menuCollapsible___vHETN:first-child {
  border-radius: 6px 6px 0 0;
  padding-top: 12px;
}
.index-module__leftNavigation___HhbIp .index-module__projectMenu___OURz5 .index-module__menuCollapsible___vHETN:last-child {
  border-radius: 0 0 6px 6px;
  padding-bottom: 6px;
}
.index-module__leftNavigation___HhbIp .index-module__projectMenu___OURz5 .index-module__menuCollapsible___vHETN.index-module__open___s-Bf7 .index-module__dropdownIcon___5Q94Q {
  transform: rotate(-180deg);
}
.index-module__leftNavigation___HhbIp .index-module__projectMenu___OURz5 .index-module__menuCollapsible___vHETN.index-module__open___s-Bf7 .index-module__menuCollapsibleContent___VVF7w {
  padding: 0;
}
.index-module__leftNavigation___HhbIp .index-module__projectMenu___OURz5 .index-module__menuCollapsible___vHETN.index-module__open___s-Bf7 .index-module__menuCollapsibleContent___VVF7w .index-module__option___zr4zk {
  height: 39px;
  margin-bottom: 0px;
}
.index-module__leftNavigation___HhbIp .index-module__projectMenu___OURz5 .index-module__menuCollapsible___vHETN:not(.index-module__open___s-Bf7) .index-module__option___zr4zk {
  height: 0;
  opacity: 0;
  margin: 0;
}
.index-module__leftNavigation___HhbIp .index-module__projectMenu___OURz5 .index-module__menuCollapsible___vHETN .index-module__menuCollapsibleHeader___DxRVT {
  padding: 0px 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.index-module__leftNavigation___HhbIp .index-module__projectMenu___OURz5 .index-module__menuCollapsible___vHETN .index-module__menuCollapsibleHeader___DxRVT > a {
  display: flex;
  align-items: center;
  gap: 8px;
  letter-spacing: 2.4px;
  text-transform: uppercase;
  width: 100%;
  height: 40px;
}
.index-module__leftNavigation___HhbIp .index-module__projectMenu___OURz5 .index-module__menuCollapsible___vHETN .index-module__menuCollapsibleHeader___DxRVT > a > i {
  font-size: 16px;
}
.index-module__leftNavigation___HhbIp .index-module__projectMenu___OURz5 .index-module__menuCollapsible___vHETN .index-module__menuCollapsibleHeader___DxRVT.index-module__accessDisabled___ULctd.index-module__disabled___Lo6Ie > a {
  color: #6F6F6F;
}
.index-module__leftNavigation___HhbIp .index-module__projectMenu___OURz5 .index-module__menuCollapsible___vHETN .index-module__menuCollapsibleHeader___DxRVT.index-module__accessDisabled___ULctd.index-module__disabled___Lo6Ie > a .sx-icon {
  color: #6F6F6F;
}
.index-module__leftNavigation___HhbIp .index-module__projectMenu___OURz5 .index-module__menuCollapsible___vHETN .index-module__menuCollapsibleHeader___DxRVT .index-module__dropdownIcon___5Q94Q {
  display: inline-block;
  transition: transform ease 300ms;
}
.index-module__leftNavigation___HhbIp .index-module__projectMenu___OURz5 .index-module__menuCollapsible___vHETN .index-module__menuCollapsibleHeader___DxRVT.index-module__disabled___Lo6Ie > a, .index-module__leftNavigation___HhbIp .index-module__projectMenu___OURz5 .index-module__menuCollapsible___vHETN .index-module__menuCollapsibleHeader___DxRVT.index-module__needUpgrade___uBR8F > a {
  opacity: 1;
  pointer-events: none;
}
.index-module__leftNavigation___HhbIp .index-module__projectMenu___OURz5 .index-module__menuCollapsible___vHETN .index-module__menuCollapsibleHeader___DxRVT.index-module__disabled___Lo6Ie:hover > a, .index-module__leftNavigation___HhbIp .index-module__projectMenu___OURz5 .index-module__menuCollapsible___vHETN .index-module__menuCollapsibleHeader___DxRVT.index-module__needUpgrade___uBR8F:hover > a {
  color: inherit;
}
.index-module__leftNavigation___HhbIp .index-module__projectMenu___OURz5 .index-module__menuCollapsible___vHETN .index-module__menuCollapsibleHeader___DxRVT.index-module__disabled___Lo6Ie:hover > a .sx-icon, .index-module__leftNavigation___HhbIp .index-module__projectMenu___OURz5 .index-module__menuCollapsible___vHETN .index-module__menuCollapsibleHeader___DxRVT.index-module__needUpgrade___uBR8F:hover > a .sx-icon {
  color: inherit;
}
.index-module__leftNavigation___HhbIp .index-module__projectMenu___OURz5 .index-module__menuCollapsible___vHETN .index-module__menuCollapsibleHeader___DxRVT.index-module__disabled___Lo6Ie.index-module__accessDisabled___ULctd, .index-module__leftNavigation___HhbIp .index-module__projectMenu___OURz5 .index-module__menuCollapsible___vHETN .index-module__menuCollapsibleHeader___DxRVT.index-module__needUpgrade___uBR8F.index-module__accessDisabled___ULctd {
  color: #6F6F6F;
}
.index-module__leftNavigation___HhbIp .index-module__projectMenu___OURz5 .index-module__menuCollapsible___vHETN .index-module__menuCollapsibleHeader___DxRVT.index-module__disabled___Lo6Ie.index-module__accessDisabled___ULctd .sx-icon, .index-module__leftNavigation___HhbIp .index-module__projectMenu___OURz5 .index-module__menuCollapsible___vHETN .index-module__menuCollapsibleHeader___DxRVT.index-module__needUpgrade___uBR8F.index-module__accessDisabled___ULctd .sx-icon {
  color: #6F6F6F;
}
.index-module__leftNavigation___HhbIp .index-module__projectMenu___OURz5 .index-module__menuCollapsible___vHETN .index-module__menuCollapsibleHeader___DxRVT.index-module__disabled___Lo6Ie.index-module__accessDisabled___ULctd:hover, .index-module__leftNavigation___HhbIp .index-module__projectMenu___OURz5 .index-module__menuCollapsible___vHETN .index-module__menuCollapsibleHeader___DxRVT.index-module__needUpgrade___uBR8F.index-module__accessDisabled___ULctd:hover {
  color: #6F6F6F;
}
.index-module__leftNavigation___HhbIp .index-module__projectMenu___OURz5 .index-module__menuCollapsible___vHETN .index-module__menuCollapsibleHeader___DxRVT.index-module__disabled___Lo6Ie.index-module__accessDisabled___ULctd:hover > a, .index-module__leftNavigation___HhbIp .index-module__projectMenu___OURz5 .index-module__menuCollapsible___vHETN .index-module__menuCollapsibleHeader___DxRVT.index-module__needUpgrade___uBR8F.index-module__accessDisabled___ULctd:hover > a {
  color: #6F6F6F;
}
.index-module__leftNavigation___HhbIp .index-module__projectMenu___OURz5 .index-module__menuCollapsible___vHETN .index-module__menuCollapsibleHeader___DxRVT.index-module__active___cskj3 > a {
  color: #00B08C;
}
.index-module__leftNavigation___HhbIp .index-module__projectMenu___OURz5 .index-module__menuCollapsible___vHETN .index-module__menuCollapsibleHeader___DxRVT.index-module__active___cskj3 > a .sx-icon {
  color: #00B08C;
}
.index-module__leftNavigation___HhbIp .index-module__projectMenu___OURz5 .index-module__menuCollapsible___vHETN .index-module__menuCollapsibleHeader___DxRVT.index-module__active___cskj3.index-module__accessDisabled___ULctd.index-module__disabled___Lo6Ie > a {
  color: #6F6F6F;
}
.index-module__leftNavigation___HhbIp .index-module__projectMenu___OURz5 .index-module__menuCollapsible___vHETN .index-module__menuCollapsibleHeader___DxRVT.index-module__active___cskj3.index-module__accessDisabled___ULctd.index-module__disabled___Lo6Ie > a .sx-icon {
  color: #6F6F6F;
}
.index-module__leftNavigation___HhbIp .index-module__projectMenu___OURz5 .index-module__menuCollapsible___vHETN .index-module__menuCollapsibleHeader___DxRVT:hover > a {
  color: #00825D;
}
.index-module__leftNavigation___HhbIp .index-module__projectMenu___OURz5 .index-module__menuCollapsible___vHETN .index-module__menuCollapsibleHeader___DxRVT:hover > a .sx-icon {
  color: #00B08C;
}
.index-module__leftNavigation___HhbIp .index-module__projectMenu___OURz5 .index-module__menuCollapsible___vHETN .index-module__menuCollapsibleContent___VVF7w {
  overflow-y: auto;
}
.index-module__leftNavigation___HhbIp .index-module__projectMenu___OURz5 .index-module__menuCollapsible___vHETN .index-module__menuCollapsibleContent___VVF7w .index-module__option___zr4zk .index-module__label___QukRT {
  letter-spacing: normal;
  text-transform: none;
}
.index-module__leftNavigation___HhbIp .index-module__projectMenu___OURz5 .index-module__menuCollapsible___vHETN .index-module__menuCollapsibleContent___VVF7w .index-module__option___zr4zk .index-module__whiteText___FsGwh {
  color: #ffffff;
}
.index-module__leftNavigation___HhbIp .index-module__projectMenu___OURz5 .index-module__menuCollapsible___vHETN .index-module__menuCollapsibleContent___VVF7w::-webkit-scrollbar-track {
  background: transparent;
}
.index-module__leftNavigation___HhbIp .index-module__projectMenu___OURz5 .index-module__menuCollapsible___vHETN .index-module__menuCollapsibleContent___VVF7w::-webkit-scrollbar-thumb {
  background: #3C3C3C;
}
.index-module__leftNavigation___HhbIp .index-module__userMenu___kcSXN {
  margin-top: 0;
  grid-row: 5/5;
  border-top: 1px solid #475467;
}
.index-module__leftNavigation___HhbIp .index-module__userMenu___kcSXN .index-module__userInfo___eP-5R {
  display: flex;
  gap: 8px;
  align-items: center;
  padding: 14px;
  border-bottom: 1px solid #475467;
  cursor: pointer;
}
.index-module__leftNavigation___HhbIp .index-module__userMenu___kcSXN .index-module__userInfo___eP-5R .image-circular-avatar {
  max-width: 34px;
  max-height: 34px;
  margin: 0;
}
.index-module__leftNavigation___HhbIp .index-module__userMenu___kcSXN .index-module__userInfo___eP-5R .index-module__avatar___bzToA {
  align-items: center;
  display: flex;
  justify-content: center;
  min-width: 34px;
  position: relative;
}
.index-module__leftNavigation___HhbIp .index-module__userMenu___kcSXN .index-module__userInfo___eP-5R .index-module__userAndEmail___-jBy- {
  width: 75%;
}
.index-module__leftNavigation___HhbIp .index-module__userMenu___kcSXN .index-module__userInfo___eP-5R .index-module__userAndEmail___-jBy- .index-module__username___gUYgW {
  cursor: pointer;
  color: #EAECF0;
}
.index-module__leftNavigation___HhbIp .index-module__userMenu___kcSXN .index-module__userInfo___eP-5R .index-module__userAndEmail___-jBy- .index-module__email___BqaUN {
  cursor: pointer;
  color: #EAECF0;
}
.index-module__leftNavigation___HhbIp .index-module__userMenu___kcSXN .index-module__getHelpAndImpersonate___d3xoA {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px;
}
.index-module__leftNavigation___HhbIp .index-module__userMenu___kcSXN .index-module__getHelpAndImpersonate___d3xoA .index-module__impersonateIcon___U8x0w.index-module__impersonating___iYL3K {
  color: #00B08C;
}
.index-module__leftNavigation___HhbIp .index-module__userMenu___kcSXN .index-module__userConfigMenuPopup___j8-B7 .ant-popover-inner-content {
  padding: 8px 0px;
  width: 186px;
}
.index-module__leftNavigation___HhbIp .index-module__userMenu___kcSXN .index-module__userConfigMenuPopup___j8-B7 .ant-popover-inner-content .index-module__userConfigMenu___vF0GT hr {
  border: unset;
  border-top: 1px solid #EAECF0;
}
.index-module__leftNavigation___HhbIp .index-module__userMenu___kcSXN .index-module__userConfigMenuPopup___j8-B7 .ant-popover-inner-content .index-module__userConfigMenu___vF0GT a,
.index-module__leftNavigation___HhbIp .index-module__userMenu___kcSXN .index-module__userConfigMenuPopup___j8-B7 .ant-popover-inner-content .index-module__userConfigMenu___vF0GT .sx-icon {
  color: #000000;
}
.index-module__leftNavigation___HhbIp .index-module__userMenu___kcSXN .index-module__userConfigMenuPopup___j8-B7 .ant-popover-inner-content .index-module__userConfigMenu___vF0GT .index-module__option___zr4zk {
  background-color: transparent;
}
.index-module__leftNavigation___HhbIp .index-module__userMenu___kcSXN .index-module__userConfigMenuPopup___j8-B7 .ant-popover-inner-content .index-module__userConfigMenu___vF0GT .index-module__option___zr4zk.index-module__needUpgrade___uBR8F .index-module__label___QukRT {
  justify-content: flex-start;
}
.index-module__leftNavigation___HhbIp .index-module__userMenu___kcSXN .index-module__userConfigMenuPopup___j8-B7 .ant-popover-inner-content .index-module__userConfigMenu___vF0GT .index-module__option___zr4zk .index-module__label___QukRT {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.index-module__leftNavigation___HhbIp .index-module__userMenu___kcSXN .index-module__userConfigMenuPopup___j8-B7 .ant-popover-inner-content .index-module__userConfigMenu___vF0GT .index-module__option___zr4zk .index-module__label___QukRT .index-module__labelAndIcon___tSAZt {
  display: flex;
  align-items: center;
  gap: 8px;
}
.index-module__leftNavigation___HhbIp .index-module__userMenu___kcSXN .index-module__userConfigMenuPopup___j8-B7 .ant-popover-inner-content .index-module__userConfigMenu___vF0GT .index-module__option___zr4zk .index-module__label___QukRT .index-module__labelAndIcon___tSAZt > i {
  font-size: 15px;
}
.index-module__leftNavigation___HhbIp .index-module__userMenu___kcSXN .index-module__userConfigMenuPopup___j8-B7 .ant-popover-inner-content .index-module__userConfigMenu___vF0GT .index-module__option___zr4zk:hover .index-module__label___QukRT,
.index-module__leftNavigation___HhbIp .index-module__userMenu___kcSXN .index-module__userConfigMenuPopup___j8-B7 .ant-popover-inner-content .index-module__userConfigMenu___vF0GT .index-module__option___zr4zk:hover .sx-icon {
  color: #736DBA;
}
.index-module__leftNavigation___HhbIp .index-module__userMenu___kcSXN .index-module__userConfigMenuPopup___j8-B7 .ant-popover-inner-content .index-module__userConfigMenu___vF0GT .index-module__option___zr4zk.index-module__disabled___Lo6Ie {
  color: #6F6F6F;
}
.index-module__leftNavigation___HhbIp .index-module__userMenu___kcSXN .index-module__userConfigMenuPopup___j8-B7 .ant-popover-inner-content .index-module__userConfigMenu___vF0GT .index-module__option___zr4zk.index-module__disabled___Lo6Ie .sx-icon {
  color: #6F6F6F;
}
.index-module__leftNavigation___HhbIp .index-module__userMenu___kcSXN .index-module__userConfigMenuPopup___j8-B7 .ant-popover-inner-content .index-module__userConfigMenu___vF0GT .index-module__option___zr4zk.index-module__disabled___Lo6Ie:hover .index-module__label___QukRT,
.index-module__leftNavigation___HhbIp .index-module__userMenu___kcSXN .index-module__userConfigMenuPopup___j8-B7 .ant-popover-inner-content .index-module__userConfigMenu___vF0GT .index-module__option___zr4zk.index-module__disabled___Lo6Ie:hover .sx-icon {
  color: #6F6F6F;
}
.index-module__leftNavigation___HhbIp .index-module__userMenu___kcSXN .index-module__userConfigMenuPopup___j8-B7 .ant-popover-inner-content .index-module__userConfigMenu___vF0GT .index-module__option___zr4zk.index-module__disabled___Lo6Ie .sx-lock {
  right: 0;
}
.index-module__leftNavigation___HhbIp .index-module__userMenu___kcSXN.index-module__aiConsultantOptions___qHySr .ant-dropdown {
  top: calc(100vh - 318px) !important;
}
.index-module__leftNavigation___HhbIp .index-module__userMenu___kcSXN .ant-dropdown {
  left: 218px !important;
  top: calc(100vh - 270px) !important;
}
.index-module__leftNavigation___HhbIp .index-module__userMenu___kcSXN .ant-dropdown .index-module__adminSubMenuPopup___qEvKf {
  background-color: #1C1C1C;
  border: 1px solid #3C3C3C;
}
.index-module__leftNavigation___HhbIp .index-module__userMenu___kcSXN .ant-dropdown .index-module__adminSubMenuPopup___qEvKf .index-module__adminSubMenu___XVLk9 {
  padding: 5px;
}
.index-module__leftNavigation___HhbIp .index-module__userMenu___kcSXN .ant-dropdown .index-module__adminSubMenuPopup___qEvKf .index-module__adminSubMenu___XVLk9 .index-module__option___zr4zk {
  padding: 10px;
}
.index-module__leftNavigation___HhbIp .index-module__userMenu___kcSXN .ant-dropdown .index-module__adminSubMenuPopup___qEvKf .index-module__adminSubMenu___XVLk9 .index-module__option___zr4zk .index-module__label___QukRT {
  letter-spacing: normal;
  text-transform: none;
}

.index-module__disabledTooltip___5zIlt {
  left: 210px !important;
}

.index-module__educationalModalIcon___Rg1Yj {
  color: #FF9500;
}

.index-module__betaTag___f9SaE {
  letter-spacing: normal;
}

.index-module__labelWithBetaTag___wKsBF {
  display: flex;
  align-items: center;
  gap: 3px;
}
.index-module__labelWithBetaTag___wKsBF .index-module__betaTag___f9SaE {
  top: -3.8px;
}

.index-module__icon___ai-3Z {
  letter-spacing: normal;
}`;
document.head
    .appendChild(document.createElement("style"))
    .appendChild(document.createTextNode(css));
export {css};
export default {
  "leftNavigation": "index-module__leftNavigation___HhbIp",
  "projectMenu": "index-module__projectMenu___OURz5",
  "menuCollapsible": "index-module__menuCollapsible___vHETN",
  "menuCollapsibleContent": "index-module__menuCollapsibleContent___VVF7w",
  "option": "index-module__option___zr4zk",
  "label": "index-module__label___QukRT",
  "userMenu": "index-module__userMenu___kcSXN",
  "userInfo": "index-module__userInfo___eP-5R",
  "userAndEmail": "index-module__userAndEmail___-jBy-",
  "email": "index-module__email___BqaUN",
  "getHelpAndImpersonate": "index-module__getHelpAndImpersonate___d3xoA",
  "username": "index-module__username___gUYgW",
  "menuCollapsibleHeader": "index-module__menuCollapsibleHeader___DxRVT",
  "subOptionsMenu": "index-module__subOptionsMenu___Cbp0f",
  "ant-popover-arrow": "ant-popover-arrow",
  "ant-popover-content": "ant-popover-content",
  "ant-popover-inner": "ant-popover-inner",
  "ant-popover-inner-content": "ant-popover-inner-content",
  "disabled": "index-module__disabled___Lo6Ie",
  "initialOptions": "index-module__initialOptions___qg1-p",
  "sx-icon": "sx-icon",
  "logo": "index-module__logo___CI4qH",
  "labelAndIcon": "index-module__labelAndIcon___tSAZt",
  "iconRightContainer": "index-module__iconRightContainer___S0sVZ",
  "popupIcon": "index-module__popupIcon___rOXZQ",
  "open": "index-module__open___s-Bf7",
  "accessDisabled": "index-module__accessDisabled___ULctd",
  "active": "index-module__active___cskj3",
  "needUpgrade": "index-module__needUpgrade___uBR8F",
  "mainOption": "index-module__mainOption___dQyj4",
  "branding": "index-module__branding___fdwfS",
  "sx-lock": "sx-lock",
  "dropdownIcon": "index-module__dropdownIcon___5Q94Q",
  "whiteText": "index-module__whiteText___FsGwh",
  "image-circular-avatar": "image-circular-avatar",
  "avatar": "index-module__avatar___bzToA",
  "impersonateIcon": "index-module__impersonateIcon___U8x0w",
  "impersonating": "index-module__impersonating___iYL3K",
  "userConfigMenuPopup": "index-module__userConfigMenuPopup___j8-B7",
  "userConfigMenu": "index-module__userConfigMenu___vF0GT",
  "aiConsultantOptions": "index-module__aiConsultantOptions___qHySr",
  "ant-dropdown": "ant-dropdown",
  "adminSubMenuPopup": "index-module__adminSubMenuPopup___qEvKf",
  "adminSubMenu": "index-module__adminSubMenu___XVLk9",
  "disabledTooltip": "index-module__disabledTooltip___5zIlt",
  "educationalModalIcon": "index-module__educationalModalIcon___Rg1Yj",
  "betaTag": "index-module__betaTag___f9SaE",
  "labelWithBetaTag": "index-module__labelWithBetaTag___wKsBF",
  "icon": "index-module__icon___ai-3Z"
};