import React, { Component } from 'react';

import * as Sentry from '@sentry/browser';
import { Button } from 'semantic-ui-react';

import { isDev, isTest } from '../../services/platform';
import errorOwl from '../../assets/owl.svg';
import sightXLogo from '../../assets/sightxLogoBlack.svg';
import { Features } from '../../featureToggles/features';
import { FeatureToggle, On, Off } from '../../featureToggles';

import { SxButton } from './sxLibrary';
import './errorReporter.scss';

class GlobalBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { eventId: null, hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope((scope) => {
      scope.setExtras(errorInfo);
      const eventId = Sentry.captureException(error);
      this.setState({ eventId });
    });
  }

  render() {
    if (this.state.hasError && !isDev() && !isTest()) {
      //render fallback UI
      return (
        <div className="errorScreen">
          <div>
            <img className="sightxLogo" alt="SightX logo" src={sightXLogo} />
          </div>
          <div className="centerMessage">
            <img className="errorScreenImage" alt="Error owl" src={errorOwl} />
            <p className="errorMessage">Oops! You have encountered an error</p>
            <p className="infoText">
              We are working right now on getting this fixed. Would you like to
              report exactly what happened so we can help you further?
            </p>
            <FeatureToggle feature={Features.uirefresh}>
              <Off>
                <Button
                  primary
                  onClick={() =>
                    Sentry.showReportDialog({ eventId: this.state.eventId })
                  }
                >
                  Report feedback
                </Button>
              </Off>
              <On>
                <SxButton
                  type="primary"
                  className="btnReportFeedback"
                  featureToUse={Features.uirefresh}
                  children="Report feedback"
                  onClick={() =>
                    Sentry.showReportDialog({ eventId: this.state.eventId })
                  }
                />
              </On>
            </FeatureToggle>
            <p className="reloadText">
              or{' '}
              <span
                onClick={() => {
                  window.location.reload(true);
                }}
                className="reloadButton"
              >
                Reload
              </span>{' '}
              and try again
            </p>
          </div>
        </div>
      );
    }

    //when there's not an error, render children untouched
    return this.props.children;
  }
}

export default GlobalBoundary;
