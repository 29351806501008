import './polyfills';
import './wdyr';
import './rhlConfig';
import React from 'react';

import ReactDOM from 'react-dom';
import { Quill } from 'react-quill';

import App from './App';
import { loadSentry } from './services/sentry';
import { loadAmplify } from './services/amplify';
import { overwriteConsoleLog } from './services/consoleLogOverwrite';

import './semantic/dist/semantic.min.css';
import '../node_modules/antd/dist/antd.less';
import 'rc-tooltip/assets/bootstrap.css';
import './index.scss';

overwriteConsoleLog();
loadSentry();
loadAmplify();

window.Quill = Quill;
window.userpilot = undefined;

ReactDOM.render(<App />, document.getElementById('root'));