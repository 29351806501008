const css = `.logo-module__container___U-LKO {
  margin-top: 9px;
  padding-bottom: 16px;
}
.logo-module__container___U-LKO.logo-module__netquest___jJBOG {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
}
.logo-module__container___U-LKO.logo-module__netquest___jJBOG .logo-module__logo___8-mcn {
  width: 106px;
  height: 34px;
}
.logo-module__container___U-LKO.logo-module__netquest___jJBOG .logo-module__powerBySightX___-I-7Y {
  display: flex;
  gap: 3px;
  flex-direction: row;
}
.logo-module__container___U-LKO.logo-module__netquest___jJBOG .logo-module__powerBySightX___-I-7Y .logo-module__label___SZpQJ {
  color: #98A2B3;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Source Sans Pro";
  font-size: 10px;
  font-style: italic;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}
.logo-module__container___U-LKO.logo-module__netquest___jJBOG .logo-module__powerBySightX___-I-7Y .logo-module__miniLogo___AqT8G {
  display: flex;
  width: 30px;
  height: 13px;
  justify-content: center;
  align-items: center;
}
.logo-module__container___U-LKO.logo-module__sightx___hXv8V {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
}
.logo-module__container___U-LKO.logo-module__sightx___hXv8V .logo-module__logo___8-mcn {
  display: flex;
  width: 67x;
  height: 31px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}`;
document.head
    .appendChild(document.createElement("style"))
    .appendChild(document.createTextNode(css));
export {css};
export default {
  "container": "logo-module__container___U-LKO",
  "netquest": "logo-module__netquest___jJBOG",
  "logo": "logo-module__logo___8-mcn",
  "powerBySightX": "logo-module__powerBySightX___-I-7Y",
  "label": "logo-module__label___SZpQJ",
  "miniLogo": "logo-module__miniLogo___AqT8G",
  "sightx": "logo-module__sightx___hXv8V"
};