import { Features, isFeatureEnabled } from '../../../featureToggles/features';

const minuteInSeconds = 60;
const hourInSeconds = 60 * minuteInSeconds;
const dayInSeconds = 24 * hourInSeconds;

export const getRemainingMessage = (seconds: number): string => {
  if (!isFeatureEnabled(Features.TrialsOfTiers)) {
    return '';
  }
  const days = Math.round(seconds / dayInSeconds);
  if (days >= 1) {
    return `${days} day${days > 1 ? 's' : ''}`;
  }
  const hours = Math.round(seconds / hourInSeconds);
  if (hours >= 1) {
    return `${hours} hour${hours > 1 ? 's' : ''}`;
  }
  const minutes = Math.round(seconds / minuteInSeconds);
  if (minutes >= 1) {
    return `${minutes} minute${minutes > 1 ? 's' : ''}`;
  }
  return 'a few seconds';
};