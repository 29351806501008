import React, { PureComponent } from 'react';

import { connect } from 'react-redux';

import {
  getProjectIdFromURL,
  isAnalysisPath,
  isDistributeSection,
  isProjectPath,
  MainRoutesForProject,
} from '../utils/navigation';
import { getPathname } from '../../../selectors/common/selectors';
import { clearAllCampaigns } from '../../../reducers/campaigns';
import { IGlobalState } from '../../../reducers/interfaces/state';
import { isDemoProject } from '../../../selectors/analysis';
import { Features, isFeatureEnabled } from '../../../featureToggles/features';
import { isOnTemplatesFolder } from '../../../selectors/currentProjectFolder/currentProjectFolder';
import { EventLabels, logEvent } from '../../../actions/globals';
import { FEATURES } from '../../../common/accessControl.helper';
import { EducationModalForAnalysis } from '../../analysis/common/EducationalModal';

import { MenuCollapsible } from './ProjectMenuCollapsible';

import styles from './index.module.scss';

interface IMapStateProps {
  pathName: string;
  isDemoProject: boolean;
  // use in Feature.GPT
  isInsideTemplateFolder: boolean;
  // end of use in Feature.GPT
}

interface IMapDispatchProps {
  clearAllCampaigns: typeof clearAllCampaigns;
  // for product analytics
  logEvent: typeof logEvent;
  // end of product analytics
}

type IProjectMenuProps = IMapStateProps & IMapDispatchProps;

interface IProjectMenuState {
  openCollapsible: MainRoutesForProject | null;
  // use in Features.TrialOfTiers
  educationalModal?: {
    featureName?: FEATURES;
    openModal: boolean;
  };
  // end of use in Features.TrialOfTiers
}
const isProductAnalyticsFeatureEnabled = isFeatureEnabled(Features.PRODUCT_ANALYTICS);

class ProjectMenuBase extends PureComponent<IProjectMenuProps, IProjectMenuState> {
  constructor(props) {
    super(props);
    this.state = {
      openCollapsible: null,
      educationalModal: {
        openModal: false,
      },
    };
    this.logNavigation = this.logNavigation.bind(this);
    this.openEducationalModalByFeatureName = this.openEducationalModalByFeatureName.bind(this);
    this.renderEducationalModal = this.renderEducationalModal.bind(this);
    this.closeEducationalModal = this.closeEducationalModal.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.pathName === this.props.pathName) {
      return;
    }

    if (isDistributeSection(prevProps.pathName) && !isDistributeSection(this.props.pathName)) {
      this.props.clearAllCampaigns();
    }

    const mainRoute = this.props.pathName.split('/')[1];
    this.setState({
      openCollapsible: mainRoute as MainRoutesForProject,
    });
  }

  private toggleOpen(mainRoute: MainRoutesForProject) {
    this.setState(({ openCollapsible: prevOpen }) => ({
      openCollapsible: prevOpen === mainRoute ? null : mainRoute,
    }));
  }

  private closeEducationalModal() {
    if (!isFeatureEnabled(Features.TrialsOfTiers)) {
      return;
    }
    this.setState((prevState) => ({
      educationalModal: {
        openModal: !prevState.educationalModal?.openModal,
        featureName: undefined,
      },
    }));
  }

  private openEducationalModalByFeatureName(featureName: FEATURES) {
    if (!isFeatureEnabled(Features.TrialsOfTiers)) {
      return;
    }
    this.setState({
      educationalModal: {
        openModal: true,
        featureName: featureName,
      },
    });
  }

  private logNavigation(route: MainRoutesForProject) {
    if (!isProductAnalyticsFeatureEnabled) {
      return;
    }
    const { logEvent } = this.props;
    switch (route) {
      case MainRoutesForProject.analysis:
        logEvent(EventLabels.ANALYZE_OVERVIEW);
        break;
      case MainRoutesForProject.distribute:
        logEvent(EventLabels.DISTRIBUTE_CAMPAIGNS);
        break;
      default:
        logEvent(EventLabels.CREATE_VIEW);
        break;
    }
  }

  private renderEducationalModal() {
    if (!isFeatureEnabled(Features.TrialsOfTiers) ||
    !this.state.educationalModal?.openModal ||
    !this.state.educationalModal.featureName) {
      return null;
    }

    return <EducationModalForAnalysis
      onClose={this.closeEducationalModal}
      featureName={this.state.educationalModal.featureName}
    />;
  }

  render() {
    const { openCollapsible } = this.state;
    const { isDemoProject, isInsideTemplateFolder } = this.props;
    const isDisabled = isDemoProject || isInsideTemplateFolder;

    if (!getProjectIdFromURL(this.props.pathName)) {
      return null;
    }

    return <div className={styles.projectMenu} data-e2e-selector="project-menu">
      <MenuCollapsible
        icon="sx-data"
        label="Build"
        mainRoute={MainRoutesForProject.project}
        open={openCollapsible === MainRoutesForProject.project}
        onCollapseIconClick={this.toggleOpen.bind(this, MainRoutesForProject.project)}
        active={isProjectPath()}
        data-e2e-selector="build-section"
        logNavigationEvent={this.logNavigation.bind(this, MainRoutesForProject.project)}
      />
      <MenuCollapsible
        icon="sx-fork"
        label="Distribute"
        mainRoute={MainRoutesForProject.distribute}
        open={openCollapsible === MainRoutesForProject.distribute}
        onCollapseIconClick={this.toggleOpen.bind(this, MainRoutesForProject.distribute)}
        active={isDistributeSection()}
        disabled={isDisabled}
        disabledTooltip={isDisabled ? `The Distribute page is unavailable in pre-populated ${isDemoProject ? 'demo' : 'template'} projects.` : ''}
        data-e2e-selector="distribute-section"
        logNavigationEvent={this.logNavigation.bind(this, MainRoutesForProject.distribute)}
      />
      <MenuCollapsible
        icon="sx-bar-chart"
        label="Analyze"
        mainRoute={MainRoutesForProject.analysis}
        open={openCollapsible === MainRoutesForProject.analysis}
        onCollapseIconClick={this.toggleOpen.bind(this, MainRoutesForProject.analysis)}
        active={isAnalysisPath()}
        data-e2e-selector="analyze-section"
        disabled={isFeatureEnabled(Features.GPT) ? isInsideTemplateFolder : false}
        disabledTooltip={isFeatureEnabled(Features.GPT) && isInsideTemplateFolder ? 'The Analysis page is unavailable in pre-populated template projects.' : ''}
        logNavigationEvent={this.logNavigation.bind(this, MainRoutesForProject.analysis)}
        openEducationModalFor={this.openEducationalModalByFeatureName}
      />
      {this.renderEducationalModal()}
    </div>;
  }
}

const mapStateToProps = (state: IGlobalState): IMapStateProps => {
  return {
    pathName: getPathname(state),
    isDemoProject: isDemoProject(state),
    isInsideTemplateFolder: isOnTemplatesFolder(state),
  };
};

const mapDispatchToProps: IMapDispatchProps = {
  clearAllCampaigns,
  logEvent,
};

export const ProjectMenu = connect(mapStateToProps, mapDispatchToProps)(ProjectMenuBase);