import React, { Component } from 'react';

import { connect } from 'react-redux';
import cls from 'classnames';
import { Skeleton } from 'antd';

import { Features, isFeatureEnabled } from '../../featureToggles/features';
import { IGlobalState } from '../../reducers/interfaces/state';
import { fetchPopularTopics, sendConversationMessage } from '../../actions/aiConsultant';
import { SxIcon } from '../common/sxLibrary';
import { colors } from '../../styles/variables';
import { selectProjectIdFromPath } from '../../reducers/router/selectors';
import { IPopularTopic, PopularTopicsPromptsTypes, PreTextTypes } from '../../reducers/gpt/aiConsultantPopularTopics/interfaces';
import { getActiveProjectProjectId } from '../../selectors/activeProject';
import { isDynamicTopicsFetching, isProjectToLarge, selectDynamicTopics, selectSectionFetched } from '../../reducers/gpt/aiConsultantPopularTopics/selectors';
import { selectProjectHasTextQuestions } from '../../reducers/activeProject/onePage/selectors';
import { resetPopularTopics } from '../../reducers/gpt/aiConsultantPopularTopics';
import { isSendingMessage } from '../../reducers/gpt/aiConsultant/selectors';
import { FeatureToggle, Off, On } from '../../featureToggles';

import { PostText, PreText, getFixedPopularTopics, getSection, isDataAvailableForSection, sectionHasDynamicPopularTopics, sectionHasFixedPopularTopics } from './util';

import stylesLight from './aiConsultantLight.module.scss';
import stylesDark from './aiConsultant.module.scss';

const DEFAULT_INITIAL_HEIGHT = 314;
const INITIAL_HEIGHT_KEY = 'aiHeight';

interface IPopularTopicsMapsProps {
  section: PopularTopicsPromptsTypes;
  projectId?: string;
  projectFetched: boolean;
  dataAvailableForSection: boolean;
  topics: IPopularTopic[];
  isFetching: boolean;
  hasDynamicTopicsFetched: boolean;
  hasFixedPopularTopics: boolean;
  hasDynamicPopularTopics: boolean;
  isProjectToLarge: boolean;
  sectionFetched: PopularTopicsPromptsTypes;
  sendingMessage: boolean;
}

interface IDispatchProps {
  fetchPopularTopics: typeof fetchPopularTopics;
  sendConversationMessage: typeof sendConversationMessage;
  resetPopularTopics: typeof resetPopularTopics;
}

interface IPopularTopics {
  isLightMode: boolean;
  isChatBoxModeActive: boolean;
  onToggle: (popularTopicsOpened: boolean) => void;
}

type Props = IPopularTopicsMapsProps & IDispatchProps & IPopularTopics;

interface IChatWindowState {
  popularTopicsOpened: boolean;
  message: string;
  initialHeight: number;
  isHidden: boolean;
}

class PopularTopicsBase extends Component<Props, IChatWindowState> {
  constructor(props) {
    super(props);
    const value: string | null = localStorage.getItem(INITIAL_HEIGHT_KEY);
    const initialHeight = Number(value ?? DEFAULT_INITIAL_HEIGHT);
    this.state = {
      popularTopicsOpened: isFeatureEnabled(Features.ada2) ? !props.isChatBoxModeActive : true,
      message: '',
      initialHeight,
      isHidden: false,
    };
    this.onTogglePopularTopics = this.onTogglePopularTopics.bind(this);
    this.onFetchT = this.onFetchT.bind(this);
  }

  componentDidMount(): void {
    const { section, projectId, dataAvailableForSection, hasDynamicPopularTopics, isFetching, sectionFetched, hasDynamicTopicsFetched } = this.props;
    if (dataAvailableForSection && !isFetching && section !== sectionFetched) {
      this.props.fetchPopularTopics(section, projectId);
    } else if ((!hasDynamicPopularTopics && hasDynamicTopicsFetched) || (!dataAvailableForSection && section === PopularTopicsPromptsTypes.OTHER && !isFetching)) {
      this.props.resetPopularTopics();
      return;
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    const { section: prevSection, dataAvailableForSection: prevDataAvailableForSection, hasDynamicTopicsFetched, isChatBoxModeActive: prevIsChatBoxModeActive } = prevProps;
    const { section, projectId, dataAvailableForSection, isFetching, sectionFetched, hasDynamicPopularTopics, isChatBoxModeActive } = this.props;
    if (prevSection === section && !prevDataAvailableForSection && dataAvailableForSection && !isFetching && section !== sectionFetched) {
      this.props.fetchPopularTopics(section, projectId);
    } else if ((!hasDynamicPopularTopics && hasDynamicTopicsFetched) || (!dataAvailableForSection && prevSection !== PopularTopicsPromptsTypes.OTHER && section === PopularTopicsPromptsTypes.OTHER && !isFetching)) {
      this.props.resetPopularTopics();
      return;
    }
    if (isFeatureEnabled(Features.ada2) && isChatBoxModeActive !== prevIsChatBoxModeActive) {
      this.setState({ popularTopicsOpened: !isChatBoxModeActive });
    }
  }

  private onCTA(topic: IPopularTopic) {
    if (this.props.sendingMessage) {
      return;
    }
    this.props.sendConversationMessage(`${topic.extraPreText ? PreText[topic.extraPreText] ?? '' : ''}${topic.text}${topic.extraPostText ? PostText[topic.extraPostText] ?? '' : ''}`, false);
  }

  private onFetchT() {
    const { section, projectId } = this.props;
    if (section) {
      this.props.fetchPopularTopics(section, projectId);
    }
  }

  private onTogglePopularTopics() {
    this.setState((state) => {
      const { popularTopicsOpened } = state;
      this.props.onToggle(!popularTopicsOpened);

      return {
        popularTopicsOpened: !popularTopicsOpened,
      };
    });
  }

  private getCollapseIcon() {
    const { popularTopicsOpened } = this.state;
    if (isFeatureEnabled(Features.ada2)) {
      return popularTopicsOpened ? 'sx-chevron-up' : 'sx-chevron-down';
    }
    return 'sx-cross';
  }

  private getCollapseIconColor() {
    const { isLightMode } = this.props;
    let color: string = colors.gray400;
    if (isFeatureEnabled(Features.ada2)) {
      color = isLightMode ? colors.baseBlack : colors.baseWhite;
    }
    return color;
  }

  private getStyles() {
    const { isLightMode } = this.props;
    return isLightMode ? stylesLight : stylesDark;
  }

  private renderTopicsLinks() {
    const {
      topics,
      hasFixedPopularTopics,
      hasDynamicPopularTopics,
      isProjectToLarge,
      isFetching,
      sendingMessage,
    } = this.props;
    const styles = this.getStyles();

    if (isFetching) {
      return <Skeleton title={false} paragraph={{
        rows: 5,
        style: { width: '100%' },
        className: styles.loader,
        width: '100%',
      }} style={{ width: '100%' }} active />;
    }
    if (isProjectToLarge) {
      return <span className={styles.link}>“Can't support big projects yet”</span>;
    }
    if (!hasFixedPopularTopics && !hasDynamicPopularTopics) {
      return <span className={styles.link}>“Nothing here yet”</span>;
    }
    return topics.map((topic, index) => {
      return <span
        onClick={this.onCTA.bind(this, topic)}
        key={`dynamic_${index}`}
        className={cls(styles.link, { [styles.disabled]: sendingMessage })}
      >
        “{topic.text}”
      </span>;
    });
  }

  render() { // TODO: content fixed, has to change
    const { popularTopicsOpened } = this.state;
    const { isChatBoxModeActive } = this.props;
    const styles = this.getStyles();

    return <>
      <div className={cls(styles.column, styles.rightColumn, {
        [styles.shown]: popularTopicsOpened,
        [styles.chatBoxMode]: isChatBoxModeActive,
        [styles.chatBoxModeOpened]: isChatBoxModeActive && popularTopicsOpened,
        [styles.chatBoxRightColumn]: isFeatureEnabled(Features.ada2),
      })}>
        <div className={styles.popularTopics}> {this.props.projectFetched}
          <div className={styles.popularTopicsHeader}>
            <div className={styles.title} onClick={this.onFetchT}>
              <div className={styles.main}>popular topics</div>
              <div className={cls(styles.secondary,
                { [styles.hidden]: isFeatureEnabled(Features.ada2) && !popularTopicsOpened })}
              >Here are are few topics to get you started</div>
            </div>
            <div className={styles.close} onClick={this.onTogglePopularTopics}>
              <SxIcon color={this.getCollapseIconColor()} name={this.getCollapseIcon()}
              />
            </div>
          </div>
          <div className={cls(styles.links, { [styles.hidden]: isFeatureEnabled(Features.ada2) && !popularTopicsOpened })}>
            {this.renderTopicsLinks()}
          </div>
        </div>
      </div>
      <FeatureToggle feature={Features.ada2} >
        <On/>
        <Off>
          <div className={cls(styles.openTrigger, {
            [styles.shown]: !popularTopicsOpened,
          })} onClick={this.onTogglePopularTopics}>
            <div className={styles.icon}><SxIcon fontSize={14} color={colors.gray400} name="sx-chevron-up" /></div>
            <div className={styles.title}>Popular Topics</div>
          </div>
        </Off>
      </FeatureToggle>
    </>;
  }
}

const mapStateToProps = (state: IGlobalState): IPopularTopicsMapsProps => {
  const projectId = selectProjectIdFromPath(state);
  const projectIdFromStorage = getActiveProjectProjectId(state);
  const projectFetched = !!projectId && projectId === projectIdFromStorage;
  const section = getSection(state);
  let dynamicTopics = selectDynamicTopics(state);
  const fixedTopics = getFixedPopularTopics(section);
  const isFetching = isDynamicTopicsFetching(state);
  const sectionFetched = selectSectionFetched(state);
  const sendingMessage = isSendingMessage(state);
  if (section === PopularTopicsPromptsTypes.ANALYSIS && selectProjectHasTextQuestions(state)) {
    dynamicTopics = [...dynamicTopics, {
      text: 'Run sentiment analysis on data of a question',
      extraPreText: PreTextTypes.FROM_MY_DATA,
    }];
  }
  return {
    section,
    projectId: projectId && projectFetched ? projectId : undefined,
    dataAvailableForSection: isDataAvailableForSection(section, state),
    projectFetched,
    topics: [...fixedTopics, ...dynamicTopics],
    isFetching,
    sectionFetched,
    hasDynamicTopicsFetched: dynamicTopics.length > 0,
    hasFixedPopularTopics: sectionHasFixedPopularTopics(section),
    hasDynamicPopularTopics: sectionHasDynamicPopularTopics(section),
    isProjectToLarge: isProjectToLarge(state),
    sendingMessage,
  };
};

const mapDispatchToProps: IDispatchProps = {
  fetchPopularTopics,
  sendConversationMessage,
  resetPopularTopics,
};

export const PopularTopics = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PopularTopicsBase);
