import React, { Component } from 'react';

import { Route, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { connect } from 'react-redux';
import { hot } from 'react-hot-loader/root';
import cx from 'classnames';

import { ADMIN_APP_URL } from './common/login.utils';
import { history } from './store';
import { isApollo, isDev, isOnlyProduction, isTrial } from './services/platform';
import { appReloadSuccess } from './actions/frontendVersion';
import EnsureLoggedInContainerForApollo from './components/common/ensureLoggedInContainerForApollo';
import NewEnsureLoggedInContainer from './components/common/ensureLoggedInContainer';
import DocumentTitle from './components/common/documentTitle';
import Notifications from './components/common/notifications';
import Loading from './components/common/loading';
import * as config from './services/configParameters';
import FrontendChangeDetector from './components/common/frontendChangeDetector';
import ImportDialog from './components/common/importDialog';
import Audiences from './components/audiences';
import {
  CaptureSubmitted,
  ForgotPassword,
  ResetPassword,
  Logout,
  RedirectLogin,
  PrintPreview,
  AnalysisRouter,
  Dashboard,
  RecreateData,
  ProjectMembers,
  ProjectRevisions,
  ProjectRouter,
  DistributeRouter,
  Monitor,
  Settings,
  AccessError,
  CompleteRegistration,
  OverQuotaCampaign,
  QuestionLibrary,
  Dacast,
  ThrowError,
  Unsubscribe,
  PanelIntegrations,
  ProjectOverview,
  AudiencesLanding,
  CheckoutsRouter,
  PromptAdmin,
  TemplateRoute,
  HomeRoute,
  AdasFeedback,
  ColorTest,
  Branding,
  Communities,
} from './routes';
import { BrandingRoutePath, CommunitiesRouterPath, Suspense } from './router.utils';
import { SURVEY_URL } from './services/api/middleware';
import AdChecking from './components/common/adChecking';
import { ImpersonateCodeListener } from './components/common/impersonateCodeListener';
import { getIsTrialAccount, getUserType, getIsProfessionalAccount, getIsLiteAccount, showTrialBanner } from './selectors/common/user';
import { SxLibraryDemo } from './components/common/sxLibrary/demo';
import { BlockedByFeature, Features, isFeatureEnabled } from './featureToggles/features';
import { LeftNavBar } from './components/common/leftNavBar';
import { ChatBot } from './components/Chatbot/Chatbot';
import { UserPilot } from './components/userpilot';
import { checkoutsPaths } from './routers/checkouts/routesData';
import { LapsedBanner } from './components/common/LapsedBanner';
import { TrialBanner } from './components/common/TrialBanner';
import { AccountStatus } from './common/accessControl.helper';
import { fetchAccountResponseLimit } from './actions/responses';
import { SxModal, SxIcon, SxButton } from './components/common/sxLibrary';
import { getSubscription } from './actions/security';
import { ResponsesLimitBanner } from './components/common/ResponsesLimitBanner';
import { ChatWindow } from './components/aiConsultant/chatWindow';
import { TrialOfTiersBanner } from './components/common/TrialOfTiersBanner';
import { colors } from './styles/variables';
import { selectExpiredTrialCode } from './selectors/security/user';
import { SxTrialExpiredModal } from './components/common/ExpiredTrialModal';
import { getSectionSecurityPermissions } from './reducers/security/selectors';
import { renderRouteForFeature } from './featureToggles';
import { FloatingNotification } from './components/common/FloatingProgressNotification';
import { ProjectStats } from './components/projectStats';

import styles from './index.module.scss';

const EnsureLoggedInContainer = isApollo() ? EnsureLoggedInContainerForApollo : NewEnsureLoggedInContainer;

export const BillingCadence = {
  MONTHLY: 'MONTHLY',
  ANNUALLY: 'ANNUALLY',
};

const PercentLimits = {
  P90: 'responsesLimit90Shown',
  P100: 'responsesLimit100Shown',
};

class Router extends Component {
  constructor() {
    super();
    this.state = {
      showModal: true,
      showResponsesLimitBanner: false,
    };
    this.closeResponsesLimitModal = this.closeResponsesLimitModal.bind(this);
  }

  componentDidMount() {
    this.unlisten = history.listen(() => {
      if (this.props.shouldRefreshApp) {
        this.props.appReloadSuccess();
      }
    });
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.shouldRefreshApp &&
      !this.props.shouldRefreshApp &&
      config.FRONTEND_COMMIT !== 'DEVELOPMENT' &&
      config.FRONTEND_COMMIT !== 'COMMIT' &&
      config.ENV !== 'RELEASE' &&
      this.props.frontendVersion !== config.FRONTEND_COMMIT
    ) {
      console.info('Reloading... new version');
      window.location.reload(true);
    }
    const { authToken, accountId, accountAccessType } = this.props;
    if (authToken && accountId && accountAccessType === 'PROFESSIONAL') {
      this.props.fetchAccountResponseLimit();
      this.props.getSubscription(accountId);
    }
  }

  componentWillUnmount() {
    this.unlisten();
  }

  closeResponsesLimitModal(percentLimitReached) {
    this.setState({
      showModal: false,
    });
    localStorage.setItem(percentLimitReached, 'true');
  }

  renderResponsesLimitModal() {
    const { responsesAllowed, responsesUsageForAccount, paymentCadence, isLiteAccount } = this.props;
    const responses90LimitShown = localStorage.getItem(PercentLimits.P90);
    const responses100LimitShown = localStorage.getItem(PercentLimits.P100);
    if (!responsesAllowed || !responsesUsageForAccount) {
      return null;
    }
    const usagePercent = (responsesUsageForAccount * 100) / responsesAllowed;
    let title;
    let description;
    let showModal = false;
    let percentLimitReached;
    const annualLabel = isLiteAccount ? 'responses' : 'annual responses';
    const accountType = this.props.isLiteAccount ? 'Pro' : 'Enterprise';
    const callToActionText = <a href={`${ADMIN_APP_URL}/settings/subscription/compare`}><div>Upgrade to {accountType} &nbsp; <SxIcon color={colors.yellow400} name="sx-rocket" /></div></a>;

    if (usagePercent >= 90 && usagePercent < 100) {
      if (responses90LimitShown === 'true') {
        return null;
      }
      percentLimitReached = PercentLimits.P90;
      showModal = true;
      title = 'You are nearing your response limit';
      if (paymentCadence === BillingCadence.MONTHLY) {
        const monthlyLabel = isLiteAccount ? 'responses' : 'monthly responses';
        description = `You have collected 90% of your ${responsesAllowed} ${monthlyLabel}. Switch to Enterprise to access more responses and features this month.`;
      } else {
        description = `You have collected 90% of your ${responsesAllowed} ${annualLabel}. Upgrade your plan to collect more responses and features.`;
      }
    } else if (usagePercent >= 100) {
      if (responses100LimitShown === 'true') {
        return null;
      }
      percentLimitReached = PercentLimits.P100;
      showModal = true;
      title = 'You\'ve reached your response limit';
      if (paymentCadence === BillingCadence.MONTHLY) {
        const monthlyLabel = isLiteAccount ? 'available' : 'available this month';
        description = `You have collected all ${responsesAllowed} responses ${monthlyLabel}. Switch to Enterprise to access more responses and features.`;
      } else {
        description = `You have collected all of your ${responsesAllowed} ${annualLabel}. Upgrade your plan to collect more responses and features.`;
      }
    }
    if (showModal) {
      return <SxModal
        featureToUse={Features.uirefresh}
        visible={this.state.showModal}
        title={<div className={styles.titleContent}>
          {this.renderModalIcon()}
          <span>{title}</span>
        </div>}
        footer={<div className={styles.modalActions}>
          <SxButton
            type="primary"
            className={styles.button}
            featureToUse={Features.plgexternal}
          >
            {callToActionText}
          </SxButton>
          <SxButton
            type="secondary"
            className={styles.button}
            featureToUse={Features.plgexternal}
            onClick={this.closeResponsesLimitModal.bind(this, percentLimitReached)}
          >
            Not now
          </SxButton>
        </div>}
        onCancel={this.closeResponsesLimitModal}
        size="extraSmall"
        noFooter
      >
        <div className={styles.description}>{description}</div>
      </SxModal>;
    }
    return null;
  }

  renderModalIcon() {
    return <div className={styles.circle}>
      <div className={styles.innerCircle}>
        <SxIcon name="sx-exclamation-triangle" />
      </div>
    </div>;
  }

  renderTrialBanner() {
    if (!this.props.isTrialAccount && !isTrial()) {
      return null;
    }
    return <TrialBanner />;
  }

  renderTrialOfTiersBanner() {
    if (!this.props.showBanner) {
      return null;
    }
    return <TrialOfTiersBanner />;
  }

  renderLapsedBanner() {
    const { accountStatus } = this.props;
    if ( accountStatus !== AccountStatus.LAPSED) {
      return null;
    }
    return <LapsedBanner />;
  }

  renderResponsesLimitBanner() {
    const { responsesUsageForAccount, responsesAllowed, isProfessionalAccount, isLiteAccount } = this.props;
    if ((!isProfessionalAccount && !isLiteAccount) || (responsesUsageForAccount !== responsesAllowed)) {
      return null;
    }
    return <ResponsesLimitBanner />;
  }

  renderSxLibraryRoute() {
    if (isOnlyProduction()) {
      return null;
    }
    return <Route path="/sxlibrary" component={SxLibraryDemo} />;
  }

  renderTrialJustExpiredModal() {
    const { expiredTrialCode } = this.props;

    if (expiredTrialCode === null) {
      return null;
    }

    return (
      <SxTrialExpiredModal code={expiredTrialCode}/>
    );
  }

  renderProjectRoutes() {
    return <Route path="/(project|distribute|analysis)/:projectId">
      <Switch>
        <Route
          path="/project/:projectId"
          component={ProjectRouter}
        />
        <Route
          path="/distribute/:projectId"
          component={DistributeRouter}
        />
        <Route
          path="/analysis/:projectId"
          component={AnalysisRouter}
        />
      </Switch>
    </Route>;
  }

  renderHomeRoute() {
    const { hasBuildAccess } = this.props;
    if ((isFeatureEnabled(Features.sectionsPermissions) && !hasBuildAccess)) {
      return <Route exact path="/" component={Dashboard} />;
    }
    return <Route exact path="/" component={HomeRoute} />;
  }

  renderColorTestRoute() {
    const { isSightXAdmin } = this.props;
    if (isSightXAdmin && isFeatureEnabled(Features.branding)) {
      return <Route path="/colorTests" component={ColorTest} />;
    }
    return null;
  }

  renderProjectsDashboardRoute() {
    return <Route exact path="/projects" component={Dashboard} />;
  }

  renderAppLayout() {
    const { isTrialAccount, showBanner, isSightXAdmin } = this.props;

    return <Route>
      <div className={cx(styles.appContainer, { [styles.withTrialBanner]: isTrialAccount || showBanner })}>
        <LeftNavBar />
        <div className={styles.appContent}>
          <div>
            <DocumentTitle />
            {this.renderTrialBanner()}
            {this.renderTrialOfTiersBanner()}
            {this.renderLapsedBanner()}
            {this.renderResponsesLimitBanner()}
            {this.renderResponsesLimitModal()}
            {this.renderTrialJustExpiredModal()}
          </div>
          <FloatingNotification />
          <ImportDialog />
          <Switch>
            {this.renderSxLibraryRoute()}
            {this.renderProjectRoutes()}
            <Route path="/folder/:id" component={Dashboard} />
            <Route path="/projectOverview" component={ProjectOverview} />
            <Route
              path="/recreateData"
              component={RecreateData}
            />
            <Route path="/revisions" component={ProjectRevisions} />
            <Route path="/members" component={ProjectMembers} />
            <Route path="/prompts" component={PromptAdmin} />
            <Route path="/settings" component={Settings} />
            {renderRouteForFeature(<Route path="/adasFeedback" component={AdasFeedback} />, Features.adasFeedback)}
            {this.renderProjectsDashboardRoute()}
            {this.renderHomeRoute()}
            {this.renderColorTestRoute()}
            <Route
              path="/questionLibrary"
              component={QuestionLibrary}
            />
            {renderRouteForFeature(<Route path="/audiences" component={AudiencesLanding} />, Features.communities, true)}
            {isSightXAdmin && <Route
              path="/panelIntegrations"
              component={PanelIntegrations}
            />}
            <Route path="/monitor" component={Monitor} />
            <Route path="/dacast" component={Dacast} />
            <Route path="/templates" component={TemplateRoute} />
            {renderRouteForFeature(<Route path={BrandingRoutePath} component={Branding} />, Features.branding)}
            {renderRouteForFeature(<Route path={CommunitiesRouterPath} component={Communities} />, Features.communities)}
            <AccessError />
          </Switch>
        </div>
        <FrontendChangeDetector />
        <ChatWindow />
      </div>
    </Route>;
  }

  render() {
    const { context } = this.props;
    return (
      <ConnectedRouter history={history} context={context}>
        <Notifications />
        <UserPilot />
        <ChatBot />
        <Suspense>
          <Switch>
            {/*Don't remove this path*/}
            <Route path="/throwError" exact component={ThrowError} />
            <Route path="/loading" exact component={Loading} />
            <Route path="/capture/:hash" exact
              render={({ location: { search }, match: { params } }) => {
                window.location = `${SURVEY_URL}/${params.hash}${search}`;
                return null;
              }} />
            <Route
              path="/capture/:hash/complete"
              exact
              component={CaptureSubmitted}
            />
            <Route
              path="/capture/survey/not-available"
              exact
              component={OverQuotaCampaign}
            />
            <Route path="/unsubscribe/:hash" exact component={Unsubscribe} />
            <Route path="/forgotPassword" exact component={ForgotPassword} />
            <Route path="/resetPassword" component={ResetPassword} />
            <Route
              path="/complete-registration"
              component={CompleteRegistration}
            />
            <Route
              path="/project/:id/preview"
              exact
              render={({ location: { search }, match: { params } }) => {
                window.location = `${SURVEY_URL}/preview/${params.id}${search}`;
                return null;
              }}
            />
            <Route path="/logout" component={Logout} />
            <Route path="/login" component={RedirectLogin} />
            <Route>
              <Route path="/adChecking" component={AdChecking} />
              <Audiences />
              <EnsureLoggedInContainer>
                <ProjectStats />
                <ImpersonateCodeListener>
                  <Suspense>
                    <Switch>
                      <Route path={checkoutsPaths} component={CheckoutsRouter} />
                      <Route
                        path="/project/:id/printPreview"
                        component={PrintPreview}
                      />
                      {this.renderAppLayout()}
                    </Switch>
                  </Suspense>
                </ImpersonateCodeListener>
              </EnsureLoggedInContainer >
            </Route >
          </Switch >
        </Suspense >
      </ConnectedRouter >
    );
  }
}

const mapStateToProps = (state) => {
  const {
    frontendVersion = {},
    responses: { responsesUsageForAccount } = {},
    security: {
      account: { account: { id, status, accessType, responsesAllowed } = {} } = {},
      token,
      subscription: { paymentCadence } = {},
    } = {},
  } = state;
  const isTrialAccount = getIsTrialAccount(state);
  const showBanner = showTrialBanner(state);
  const userType = getUserType(state);
  const isProfessionalAccount = getIsProfessionalAccount(state);
  const isLiteAccount = getIsLiteAccount(state);
  const expiredTrialCode = selectExpiredTrialCode(state);
  const sectionSecurityPermissions = getSectionSecurityPermissions(state);
  const hasBuildAccess = sectionSecurityPermissions !== BlockedByFeature && (sectionSecurityPermissions?.build ?? false);
  return {
    shouldRefreshApp: frontendVersion.shouldRefreshApp,
    frontendVersion: frontendVersion.version,
    isTrialAccount,
    isSightXAdmin: userType === 'SX_ADMIN',
    accountStatus: status,
    accountAccessType: accessType,
    authToken: token,
    responsesAllowed,
    responsesUsageForAccount,
    accountId: id,
    paymentCadence,
    isProfessionalAccount,
    isLiteAccount,
    // Use for Features.TrialsOfTiers
    showBanner,
    expiredTrialCode,
    // End of use for Features.TrialsOfTiers
    hasBuildAccess,
  };
};

let RouterModule = connect(mapStateToProps, {
  appReloadSuccess,
  getSubscription,
  fetchAccountResponseLimit,
})(Router);

if (isDev) {
  RouterModule = hot(RouterModule);
}

export default RouterModule;
