const css = `/** do not use this yet */
/* Desktop - Eyebrow/Small - Semi-Bold */
.sessionModal-module__blocked___rmJMc {
  opacity: 0.9;
}

.sessionModal-module__disabled-component___k1QAc {
  opacity: 0.6;
  cursor: not-allowed;
}

.sessionModal-module__sessionModal___eYDOY {
  width: 749px !important;
}
@media (max-width: 480px) {
  .sessionModal-module__sessionModal___eYDOY {
    width: 360px;
  }
}
@media (max-width: 768px) and (min-width: 480px) {
  .sessionModal-module__sessionModal___eYDOY {
    width: 650px;
  }
}
.sessionModal-module__sessionModal___eYDOY .ant-modal-content {
  border-radius: 6px;
  padding-bottom: 25px;
}
.sessionModal-module__sessionModal___eYDOY .ant-modal-header {
  border-radius: 6px 6px 0 0;
  border-bottom: none;
  padding: 42px 40px;
}
.sessionModal-module__sessionModal___eYDOY .ant-modal-close-x {
  width: 42px;
  height: 42px;
  line-height: 42px;
}
.sessionModal-module__sessionModal___eYDOY .sessionModal-module__actionWrapper___DNVBY {
  border-top: 1px solid #e5e5e5;
  position: relative;
  top: 14px;
}
.sessionModal-module__sessionModal___eYDOY p {
  font-size: 16px;
}
.sessionModal-module__sessionModal___eYDOY .ant-btn.sessionModal-module__logoutButton___6qxU9 {
  margin-top: 19px;
  margin-right: 10px;
  background-color: white;
  color: #736dba;
  font-weight: bold;
}
.sessionModal-module__sessionModal___eYDOY .ant-btn.sessionModal-module__logoutButton___6qxU9:hover {
  background-color: transparent;
  color: #030027;
}
.sessionModal-module__sessionModal___eYDOY .sessionModal-module__hereButton___6Hwy1 {
  background-color: #27235C;
  width: 95px;
  margin-top: 19px;
}`;
document.head
    .appendChild(document.createElement("style"))
    .appendChild(document.createTextNode(css));
export {css};
export default {
  "blocked": "sessionModal-module__blocked___rmJMc",
  "disabled-component": "sessionModal-module__disabled-component___k1QAc",
  "sessionModal": "sessionModal-module__sessionModal___eYDOY",
  "ant-modal-content": "ant-modal-content",
  "ant-modal-header": "ant-modal-header",
  "ant-modal-close-x": "ant-modal-close-x",
  "actionWrapper": "sessionModal-module__actionWrapper___DNVBY",
  "ant-btn": "ant-btn",
  "logoutButton": "sessionModal-module__logoutButton___6qxU9",
  "hereButton": "sessionModal-module__hereButton___6Hwy1"
};