import { FunctionComponent, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { addRolesForADUser } from '../actions/security';
import { USE_LEGACY_AUTH } from '../services/configParameters';
import { selectRolesFetching, selectAccessToken } from '../reducers/security/oldReducer';

export const RolesFetcher: FunctionComponent = () => {
  const rolesFetching = useSelector(selectRolesFetching);
  const token = useSelector(selectAccessToken);
  const dispatch = useDispatch();
  useEffect(() => {
    if (rolesFetching || !token || !USE_LEGACY_AUTH) {
      return;
    }
    dispatch(addRolesForADUser(token));
  }, [dispatch, rolesFetching, token]);

  return null;
};