/*
* Source:
* https://github.com/jedtrow/Chart.js-Rounded-Bar-Charts/blob/master/Chart.roundedBarCharts.js
* */
export const roundedCornersBar = function () {
  const { _chart = {}, _view: vm = {} } = this;
  const { ctx, config = {}, height: chartHeight } = _chart;
  const { data = {}, options = {} } = config;
  const { datasets = [] } = data;
  const {
    cornerRadius = 0,
    radiusByStep = false,
    grouped = false,
    maxDiffExperiment = false,
    scales: { xAxes = [], yAxes = [] },
  } = options;

  const maxDiffExperimentDatasetIndexMapper = {
    0: 1,
    1: 2,
    2: 0,
  };

  let isBarBase = false;
  let isBarTip = false;

  if (cornerRadius > 0) {
    const isStacked = (!!xAxes.length && xAxes.some((item) => item.stacked)) || (!!yAxes.length && yAxes.some((item) => item.stacked));

    // If stacked, we need to check if there are values in previous and next stacked blocks
    if (isStacked && !radiusByStep) {
      const datasetIndex = maxDiffExperiment ? maxDiffExperimentDatasetIndexMapper[this._datasetIndex] : this._datasetIndex;
      const currentDataset = datasets[datasetIndex];

      let start;

      // Check previous stacked values
      let previousBlocksWithValues = false;
      start = grouped ? currentDataset.groupIndex * currentDataset.groupCount : 0;
      for (let i = start; i < datasetIndex; i++) {
        const datasetMeta = _chart.getDatasetMeta(i);
        const hidden = grouped ? datasets[i].hidden : datasetMeta.hidden;
        if (parseInt(datasets[i].data[this._index], 10) !== 0 && !hidden) {
          previousBlocksWithValues = true;
          break;
        }
      }
      if (!previousBlocksWithValues) {
        isBarBase = true;
      }

      // Check next stacked values
      let nextBlocksWithValues = false;
      start = grouped ? currentDataset.groupIndex * currentDataset.groupCount + currentDataset.groupCount - 1 : datasets.length - 1;
      for (let i = start; i > datasetIndex; i--) {
        const datasetMeta = _chart.getDatasetMeta(i);
        const hidden = grouped ? datasets[i].hidden : datasetMeta.hidden;
        if (parseInt(datasets[i].data[this._index], 10) !== 0 && !hidden && datasetMeta.type !== 'line') {
          nextBlocksWithValues = true;
          break;
        }
      }
      if (!nextBlocksWithValues) {
        isBarTip = true;
      }
    } else {
      isBarBase = true;
      isBarTip = true;
    }
  }

  let left,
    right,
    top,
    bottom,
    signX,
    signY,
    borderSkipped;

  if (!vm.horizontal) {
    // Vertical bar
    left = vm.x - vm.width / 2;
    right = vm.x + vm.width / 2;
    top = vm.y;
    bottom = vm.base;
    signX = 1;
    signY = bottom > top ? 1 : -1;
    borderSkipped = vm.borderSkipped || 'bottom';
  } else {
    // Horizontal bar
    left = vm.base;
    right = vm.x;
    top = vm.y - vm.height / 2;
    bottom = vm.y + vm.height / 2;
    signX = right > left ? 1 : -1;
    signY = 1;
    borderSkipped = vm.borderSkipped || 'left';
  }

  // Canvas doesn't allow us to stroke inside the width so we can
  // adjust the sizes to fit if we're setting a stroke on the line
  let borderWidth = vm.borderWidth;
  if (borderWidth) {
    // borderWidth should be less than bar width and bar height
    const barSize = Math.min(Math.abs(left - right), Math.abs(top - bottom));
    borderWidth = borderWidth > barSize ? barSize : borderWidth;
    const halfStroke = borderWidth / 2;
    // Adjust borderWidth when bar top position is near vm.base(zero)
    const borderLeft = left + (borderSkipped !== 'left' ? halfStroke * signX : 0);
    const borderRight = right + (borderSkipped !== 'right' ? -halfStroke * signX : 0);
    const borderTop = top + (borderSkipped !== 'top' ? halfStroke * signY : 0);
    const borderBottom = bottom + (borderSkipped !== 'bottom' ? -halfStroke * signY : 0);
    // Not become a vertical line?
    if (borderLeft !== borderRight) {
      top = borderTop;
      bottom = borderBottom;
    }
    // Not become a horizontal line?
    if (borderTop !== borderBottom) {
      left = borderLeft;
      right = borderRight;
    }
  }

  ctx.beginPath();
  ctx.fillStyle = vm.backgroundColor;
  ctx.strokeStyle = vm.borderColor;
  ctx.lineWidth = borderWidth;

  // Corner points, from bottom-left to bottom-right clockwise
  // | 1 2 |
  // | 0 3 |
  const corners = [
    [left, bottom],
    [left, top],
    [right, top],
    [right, bottom],
  ];

  // Find first (starting) corner with fallback to 'bottom'
  const borders = ['bottom', 'left', 'top', 'right'];
  let startCorner = borders.indexOf(borderSkipped, 0);
  if (startCorner === -1) {
    startCorner = 0;
  }

  function cornerAt(index) {
    return corners[(startCorner + index) % 4];
  }

  // Draw rectangle from 'startCorner'
  let corner = cornerAt(0);
  ctx.moveTo(corner[0], corner[1]);

  let width,
    height,
    x,
    y;
  for (let i = 1; i < 4; i++) {
    corner = cornerAt(i);

    width = corners[2][0] - corners[1][0];
    height = corners[0][1] - corners[1][1];
    x = corners[1][0];
    y = corners[1][1];

    // Negative radius defaults to 0
    let radius = cornerRadius >= 0 ? cornerRadius : 0;
    // Fix radius being too large
    if (radius > Math.abs(height) / 2) {
      radius = Math.floor(Math.abs(height) / 2);
    }
    if (radius > Math.abs(width) / 2) {
      radius = Math.floor(Math.abs(width) / 2);
    }

    let x_tl,
      x_tr,
      y_tl,
      y_tr,
      x_bl,
      x_br,
      y_bl,
      y_br;
    if (height < 0) {
      // Negative values in a vertical bar
      x_tl = x;
      x_tr = x + width;
      y_tl = y + height;
      y_tr = y + height;

      x_bl = x;
      x_br = x + width;
      y_bl = y;
      y_br = y;

      // Begin draw at bottom left
      if (isBarBase) {
        ctx.moveTo(x_bl + radius, y_bl);
      } else {
        ctx.moveTo(x_bl, y_bl);
      }

      // Go to bottom right
      if (isBarBase) {
        ctx.lineTo(x_br - radius, y_br);
        ctx.quadraticCurveTo(x_br, y_br, x_br, y_br - radius);
      } else {
        ctx.lineTo(x_br, y_br);
      }

      // Go to top right
      if (isBarTip) {
        ctx.lineTo(x_tr, y_tr + radius);
        ctx.quadraticCurveTo(x_tr, y_tr, x_tr - radius, y_tr);
      } else {
        ctx.lineTo(x_tr, y_tr);
      }

      // Go to top left
      if (isBarTip) {
        ctx.lineTo(x_tl + radius, y_tl);
        ctx.quadraticCurveTo(x_tl, y_tl, x_tl, y_tl + radius);
      } else {
        ctx.lineTo(x_tl, y_tl);
      }

      // Go to bottom left
      if (isBarBase) {
        ctx.lineTo(x_bl, y_bl - radius);
        ctx.quadraticCurveTo(x_bl, y_bl, x_bl + radius, y_bl);
      } else {
        ctx.lineTo(x_bl, y_bl);
      }
    } else if (width < 0) {
      // Negative values in a horizontal bar
      x_tl = x + width;
      x_tr = x;
      y_tl = y;
      y_tr = y;

      x_bl = x + width;
      x_br = x;
      y_bl = y + height;
      y_br = y + height;

      // Begin draw at bottom left
      if (isBarBase) {
        ctx.moveTo(x_bl + radius, y_bl);
      } else {
        ctx.moveTo(x_bl, y_bl);
      }

      // Go to bottom right
      if (isBarTip) {
        ctx.lineTo(x_br - radius, y_br);
        ctx.quadraticCurveTo(x_br, y_br, x_br, y_br - radius);
      } else {
        ctx.lineTo(x_br, y_br);
      }

      // Go to top right
      if (isBarTip) {
        ctx.lineTo(x_tr, y_tr + radius);
        ctx.quadraticCurveTo(x_tr, y_tr, x_tr - radius, y_tr);
      } else {
        ctx.lineTo(x_tr, y_tr);
      }

      // Go to top left
      if (isBarBase) {
        ctx.lineTo(x_tl + radius, y_tl);
        ctx.quadraticCurveTo(x_tl, y_tl, x_tl, y_tl + radius);
      } else {
        ctx.lineTo(x_tl, y_tl);
      }

      // Go to bottom left
      if (isBarBase) {
        ctx.lineTo(x_bl, y_bl - radius);
        ctx.quadraticCurveTo(x_bl, y_bl, x_bl + radius, y_bl);
      } else {
        ctx.lineTo(x_bl, y_bl);
      }
    } else {
      if (!vm.horizontal) {
        // Positive values in a vertical bar
        const data = datasets[this._datasetIndex].data[this._index];
        const extraRadiusForSmallBars = data !== 0 && data < 3 ? 8 - data : 0;

        // Begin draw at top left
        if (isBarTip) {
          ctx.moveTo(x, y + radius + extraRadiusForSmallBars);
        } else {
          ctx.moveTo(x, y);
        }

        // Go to top left
        if (isBarTip) {
          ctx.lineTo(x, y + radius + extraRadiusForSmallBars);
          ctx.quadraticCurveTo(x, y, x + radius + extraRadiusForSmallBars, y);
        } else {
          ctx.lineTo(x, y);
        }

        // Go to top right
        if (isBarTip) {
          ctx.lineTo(x + width - radius - extraRadiusForSmallBars, y);
          ctx.quadraticCurveTo(x + width, y, x + width, y + radius + extraRadiusForSmallBars);
        } else {
          ctx.lineTo(x + width, y);
        }

        const extraHeight = data !== 0 && radiusByStep ? chartHeight : 0;
        const bottomRadius = radiusByStep ? 0 : radius;

        // Go to bottom right
        if (isBarBase) {
          ctx.lineTo(x + width, y + height + extraHeight - bottomRadius);
          ctx.quadraticCurveTo(x + width, y + height + extraHeight, x + width - bottomRadius, y + height + extraHeight);
        } else {
          ctx.lineTo(x + width, y + height);
        }

        // Go to bottom left
        if (isBarBase) {
          ctx.lineTo(x + bottomRadius, y + height + extraHeight);
          ctx.quadraticCurveTo(x, y + height, x, y + height - bottomRadius);
        } else {
          ctx.lineTo(x, y + height);
        }
      } else {
        // Positive values in a horizontal bar

        // Begin draw at top left
        if (isBarBase) {
          ctx.moveTo(x + radius, y);
        } else {
          ctx.moveTo(x, y);
        }

        // Go to top right
        if (isBarTip) {
          ctx.lineTo(x + width - radius, y);
          ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
        } else {
          ctx.lineTo(x + width, y);
        }

        // Go to bottom right
        if (isBarTip) {
          ctx.lineTo(x + width, y + height - radius);
          ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
        } else {
          ctx.lineTo(x + width, y + height);
        }

        // Go to bottom left
        if (isBarBase) {
          ctx.lineTo(x + radius, y + height);
          ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
        } else {
          ctx.lineTo(x, y + height);
        }

        // Go to top left
        if (isBarBase) {
          ctx.lineTo(x, y + radius);
          ctx.quadraticCurveTo(x, y, x + radius, y);
        } else {
          ctx.lineTo(x, y);
        }
      }
    }
  }

  ctx.fill();
  if (borderWidth) {
    ctx.stroke();
  }
};
