import * as importTypes from '../types/import';

export const toggleImportDialog = showDialog => {
  return {
    type: importTypes.TOGGLE_IMPORT_DIALOG,
    payload: showDialog,
  };
};

export const setCurrentProgress = (payload) => {
  return {
    type: importTypes.SET_CURRENT_IMPORT_PROGRESS,
    payload,
  };
};
