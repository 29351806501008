const css = `/** do not use this yet */
/* Desktop - Eyebrow/Small - Semi-Bold */
.impersonateModal-module__blocked___dNlDy {
  opacity: 0.9;
}

.impersonateModal-module__disabled-component___N7mBX {
  opacity: 0.6;
  cursor: not-allowed;
}

.sightx .impersonateModal-module__modal___d13Vo.ant-modal .ant-modal-content .ant-modal-body {
  padding-top: 0;
  font-size: 16px;
}

.impersonateModal-module__header___-FQQ9 {
  color: #000000;
  font-family: "Source Sans Pro";
  font-weight: 600;
  font-size: 20px;
}

.impersonateModal-module__table___AaSYj .ant-table-body {
  height: 340px;
  overflow: auto;
  border-radius: 0 0 6px 6px;
  border: 1px solid #EAECF0;
  border-top: none;
}
.impersonateModal-module__table___AaSYj tr.ant-table-placeholder td.ant-table-cell {
  border: none;
  height: 320px;
}
.impersonateModal-module__table___AaSYj .ant-table-header.ant-table-sticky-holder {
  border-radius: 6px 6px 0 0;
}
.impersonateModal-module__table___AaSYj th.ant-table-cell {
  background: #CACFD9;
  color: #414144;
  font-weight: 700;
  border-bottom: none;
}
.impersonateModal-module__table___AaSYj th.ant-table-cell::before {
  display: none;
}
.impersonateModal-module__table___AaSYj .impersonateModal-module__row___brg0W {
  cursor: pointer;
  color: #475467;
}
.impersonateModal-module__table___AaSYj .impersonateModal-module__row___brg0W:nth-of-type(even) {
  background: #EAECF0;
}
.impersonateModal-module__table___AaSYj .impersonateModal-module__row___brg0W .impersonateModal-module__withPointer___oyrtJ {
  cursor: pointer;
}
.impersonateModal-module__table___AaSYj .impersonateModal-module__row___brg0W:hover {
  background: #504097;
}
.impersonateModal-module__table___AaSYj .impersonateModal-module__row___brg0W:hover > td.ant-table-cell {
  background: #504097;
  color: #ffffff;
}
.impersonateModal-module__table___AaSYj .impersonateModal-module__row___brg0W > td {
  border: none;
}

.impersonateModal-module__search___4dXHg {
  margin: 24px 0;
}`;
document.head
    .appendChild(document.createElement("style"))
    .appendChild(document.createTextNode(css));
export {css};
export default {
  "blocked": "impersonateModal-module__blocked___dNlDy",
  "disabled-component": "impersonateModal-module__disabled-component___N7mBX",
  "sightx": "sightx",
  "modal": "impersonateModal-module__modal___d13Vo",
  "ant-modal": "ant-modal",
  "ant-modal-content": "ant-modal-content",
  "ant-modal-body": "ant-modal-body",
  "header": "impersonateModal-module__header___-FQQ9",
  "table": "impersonateModal-module__table___AaSYj",
  "ant-table-body": "ant-table-body",
  "ant-table-placeholder": "ant-table-placeholder",
  "ant-table-cell": "ant-table-cell",
  "ant-table-header": "ant-table-header",
  "ant-table-sticky-holder": "ant-table-sticky-holder",
  "row": "impersonateModal-module__row___brg0W",
  "withPointer": "impersonateModal-module__withPointer___oyrtJ",
  "search": "impersonateModal-module__search___4dXHg"
};