import React, { Component } from 'react';

import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { IGlobalState } from '../../reducers/interfaces/state';
import { selectSecurity } from '../../selectors/common/securitySelectors';
import { AccountStatus } from '../../common/accessControl.helper';
import SessionModal from '../security/sessionModal';
import { refreshTokenOnly, updatePermissions } from '../../actions/security';
import { selectIsRefreshing, selectPoliciesIsFetching, selectRefreshFailed } from '../../reducers/security/selectors';
import { fetchCurrentUser, fetchImpersonatedUser } from '../../actions/management';
import { isAllowedInEnvironment } from '../../selectors/common/user';

import AccessError from './accessError/accessError';
import Loading from './loading';

interface IMapStateProps {
  token: string;
  loggedUserId: string;
  isFetchingCurrentUser: boolean;
  hydrated: boolean;
  accountStatus: string;
  uniqueSession: boolean;
  refreshing: boolean;
  refreshFailed: boolean;
  impersonating: boolean;
  shouldForceLoginRedirect: boolean;
  isPoliciesFetching: boolean;
}

interface IDispatchProps {
  refresh: typeof refreshTokenOnly;
  fetchCurrentUser: typeof fetchCurrentUser;
  updatePermissions: typeof updatePermissions;
  fetchImpersonatedUser: typeof fetchImpersonatedUser;
}

type Props = IMapStateProps & IDispatchProps & RouteComponentProps;

class EnsureLoggedInContainerBase extends Component<Props> {
  async componentDidMount() {
    // TODO, remove url manipulation once none core stops sending the token and userId as params
    const currentUrl = new URLSearchParams(window.location.search);
    const USER_ID_Q_PARAM = 'userId';
    const TOKEN_Q_PARAM = 'token';
    if (currentUrl.has(TOKEN_Q_PARAM) && currentUrl.has(USER_ID_Q_PARAM)) {
      currentUrl.delete(TOKEN_Q_PARAM);
      currentUrl.delete(USER_ID_Q_PARAM);
      this.props.history.replace({
        search: currentUrl.toString(),
      });
    }

    this.refreshLogic();
  }

  async componentDidUpdate(prevProps: Readonly<Props>) {
    this.refreshLogic(prevProps);
  }

  private refreshLogic(prevProps?: Readonly<Props>) {
    const { hydrated,
      token,
      refresh,
      refreshing,
      refreshFailed,
      fetchCurrentUser,
      isFetchingCurrentUser,
      loggedUserId,
      updatePermissions,
      impersonating,
      fetchImpersonatedUser,
      shouldForceLoginRedirect,
      uniqueSession,
      location: {
        pathname,
      },
      isPoliciesFetching,
    } = this.props;
    const wasImpersonating = prevProps?.impersonating;
    if (!hydrated || refreshing || !uniqueSession) {
      return;
    }
    if ((refreshFailed || shouldForceLoginRedirect) && pathname !== '/logout') {
      this.props.history.replace('/logout');
    }
    if (!token) {
      refresh();
      return;
    }
    if (!isFetchingCurrentUser && !loggedUserId) {
      fetchCurrentUser();
    }
    if (!isPoliciesFetching && !loggedUserId) {
      updatePermissions();
    }
    if (!wasImpersonating && impersonating) {
      fetchImpersonatedUser();
    }
  }

  render(): React.ReactNode {
    const { hydrated, location: { pathname }, accountStatus, children, uniqueSession, refreshing } = this.props;
    const allowedPathsForLapsedAccounts = [
      '/folder',
      '/projectOverview',
      '/settings',
      '/projects',
    ];
    if (!hydrated || refreshing) {
      return <Loading />;
    }
    if (!uniqueSession) {
      return <>
        {<SessionModal />}
        {children}
      </>;
    }
    const isAllowedPathForLapsedAccount = pathname === '/' || allowedPathsForLapsedAccounts.some((path) => pathname.startsWith(path));
    if (accountStatus === AccountStatus.LAPSED && !isAllowedPathForLapsedAccount) {
      return <AccessError type="ForbiddenLapsed" />;
    }
    return children;
  }
}

const mapStateToProps = (state: IGlobalState): IMapStateProps => {
  const {
    token,
    userId: loggedUserId,
    account,
    uniqueSession,
    impersonating,
  } = selectSecurity(state) ?? {};
  const {
    fetching: isFetchingCurrentUser,
    account: {
      status: accountStatus = '',
    } = {},
  } = account || {};
  const { hydrated } = state;
  const isPoliciesFetching = selectPoliciesIsFetching(state);
  return {
    token: token ?? '',
    loggedUserId: loggedUserId ?? '',
    isFetchingCurrentUser,
    hydrated,
    accountStatus,
    uniqueSession,
    impersonating,
    refreshing: selectIsRefreshing()(state),
    refreshFailed: selectRefreshFailed()(state),
    shouldForceLoginRedirect: !isAllowedInEnvironment(state),
    isPoliciesFetching: !!isPoliciesFetching,
  };
};

const dispatchProps: IDispatchProps = {
  refresh: refreshTokenOnly,
  fetchCurrentUser,
  updatePermissions,
  fetchImpersonatedUser,
};

export const ConnectedEnsureLoggedInContainer = connect(mapStateToProps, dispatchProps)(EnsureLoggedInContainerBase);

export default withRouter(ConnectedEnsureLoggedInContainer);
