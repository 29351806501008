import React, { PureComponent } from 'react';

import { Option } from './Option';

export class LogoutOption extends PureComponent {
  render() {
    return (
      <Option
        key="LOGOUT"
        icon="sx-disqualify"
        label="Log out"
        route="/logout"
        active
      />
    );
  }
}