const css = `/** do not use this yet */
/* Desktop - Eyebrow/Small - Semi-Bold */
.stepProgress-module__blocked___z3i0w {
  opacity: 0.9;
}

.stepProgress-module__disabled-component___G1OtB {
  opacity: 0.6;
  cursor: not-allowed;
}

.stepProgress-module__stepProgressBar___Qs4m9 {
  display: flex;
}
.stepProgress-module__stepProgressBar___Qs4m9 .stepProgress-module__stepContainer___Hqu9i {
  position: relative;
  display: block;
}
.stepProgress-module__stepProgressBar___Qs4m9 .stepProgress-module__stepContainer___Hqu9i:not(:first-of-type) {
  margin-left: 35px;
}
.stepProgress-module__stepProgressBar___Qs4m9 .stepProgress-module__stepContainer___Hqu9i .stepProgress-module__stepNode___5kwo- {
  width: 35px;
  height: 35px;
  border-radius: 20px;
  background-color: white;
  border: 3px solid #ddd;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.stepProgress-module__stepProgressBar___Qs4m9 .stepProgress-module__stepContainer___Hqu9i .stepProgress-module__stepNode___5kwo- .stepProgress-module__lastStepCheck___8RGqM {
  color: white;
}
.stepProgress-module__stepProgressBar___Qs4m9 .stepProgress-module__stepContainer___Hqu9i .stepProgress-module__stepNode___5kwo- .stepProgress-module__stepNumber___wolem {
  font-size: 1.2em;
  font-family: "Roboto Slab", serif;
}
.stepProgress-module__stepProgressBar___Qs4m9 .stepProgress-module__stepContainer___Hqu9i .stepProgress-module__stepNode___5kwo- .stepProgress-module__stepLabel___L539A {
  text-transform: uppercase;
  font-size: 10px;
  top: 35px;
  position: absolute;
}
.stepProgress-module__stepProgressBar___Qs4m9 .stepProgress-module__stepContainer___Hqu9i .stepProgress-module__stepNodeActive___4TvXw {
  border: 3px solid #46277e;
  background-color: #46277e;
}
.stepProgress-module__stepProgressBar___Qs4m9 .stepProgress-module__stepContainer___Hqu9i .stepProgress-module__stepNodeActive___4TvXw .stepProgress-module__stepNumber___wolem {
  color: white;
}
.stepProgress-module__stepProgressBar___Qs4m9 .stepProgress-module__stepContainer___Hqu9i .stepProgress-module__stepNodeError___BcfyX {
  border: 3px solid #d73332;
  background-color: white;
}
.stepProgress-module__stepProgressBar___Qs4m9 .stepProgress-module__stepContainer___Hqu9i .stepProgress-module__stepNodeError___BcfyX * {
  color: #d73332 !important;
  font-size: 18px;
}
.stepProgress-module__stepProgressBar___Qs4m9 .stepProgress-module__stepContainer___Hqu9i .stepProgress-module__stepNodeComplete___opMqZ {
  background-color: white;
  border: 3px solid #46277e;
}
.stepProgress-module__stepProgressBar___Qs4m9 .stepProgress-module__stepContainer___Hqu9i .stepProgress-module__stepNodeComplete___opMqZ * {
  color: #46277e;
}
.stepProgress-module__stepProgressBar___Qs4m9 .stepProgress-module__stepContainer___Hqu9i .stepProgress-module__baseBar___RgCgf {
  height: 4px;
  position: absolute;
  top: calc(50% - 2px);
  left: 35px;
}
.stepProgress-module__stepProgressBar___Qs4m9 .stepProgress-module__stepContainer___Hqu9i .stepProgress-module__stepBarError___2-OLO {
  background: #d73332 !important;
}
.stepProgress-module__stepProgressBar___Qs4m9 .stepProgress-module__stepContainer___Hqu9i .stepProgress-module__stepBar___tcunr {
  background: #46277e;
}
.stepProgress-module__stepProgressBar___Qs4m9 .stepProgress-module__stepContainer___Hqu9i .stepProgress-module__rounderStepBar___4O5aQ {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.stepProgress-module__stepProgressBar___Qs4m9 .stepProgress-module__stepContainer___Hqu9i .stepProgress-module__fullBar___AoXl6 {
  background: #ddd;
  width: 100%;
}`;
document.head
    .appendChild(document.createElement("style"))
    .appendChild(document.createTextNode(css));
export {css};
export default {
  "blocked": "stepProgress-module__blocked___z3i0w",
  "disabled-component": "stepProgress-module__disabled-component___G1OtB",
  "stepProgressBar": "stepProgress-module__stepProgressBar___Qs4m9",
  "stepContainer": "stepProgress-module__stepContainer___Hqu9i",
  "stepNode": "stepProgress-module__stepNode___5kwo-",
  "lastStepCheck": "stepProgress-module__lastStepCheck___8RGqM",
  "stepNumber": "stepProgress-module__stepNumber___wolem",
  "stepLabel": "stepProgress-module__stepLabel___L539A",
  "stepNodeActive": "stepProgress-module__stepNodeActive___4TvXw",
  "stepNodeError": "stepProgress-module__stepNodeError___BcfyX",
  "stepNodeComplete": "stepProgress-module__stepNodeComplete___opMqZ",
  "baseBar": "stepProgress-module__baseBar___RgCgf",
  "stepBarError": "stepProgress-module__stepBarError___2-OLO",
  "stepBar": "stepProgress-module__stepBar___tcunr",
  "rounderStepBar": "stepProgress-module__rounderStepBar___4O5aQ",
  "fullBar": "stepProgress-module__fullBar___AoXl6"
};