const css = `/** do not use this yet */
/* Desktop - Eyebrow/Small - Semi-Bold */
.TrialBanner-module__blocked___pAosi {
  opacity: 0.9;
}

.TrialBanner-module__disabled-component___zWmVY {
  opacity: 0.6;
  cursor: not-allowed;
}

.TrialBanner-module__trialBanner___YZ4JY {
  min-height: 43px;
  background-color: #ffffff;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: #475467;
}
.TrialBanner-module__trialBanner___YZ4JY .TrialBanner-module__button___s3QsL {
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  margin-left: 16px;
}`;
document.head
    .appendChild(document.createElement("style"))
    .appendChild(document.createTextNode(css));
export {css};
export default {
  "blocked": "TrialBanner-module__blocked___pAosi",
  "disabled-component": "TrialBanner-module__disabled-component___zWmVY",
  "trialBanner": "TrialBanner-module__trialBanner___YZ4JY",
  "button": "TrialBanner-module__button___s3QsL"
};