import React from 'react';

import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { withMsal } from '@azure/msal-react';

import { setLoginInfo, addRolesForADUser } from './actions/security';
import { clearRedirectTo, setRedirectTo } from './actions/redirectTo';
import { switchADSelector, getADSelector, AD_SELECTORS } from './services/azureADConfig';
import * as config from './services/configParameters';
import store from './store';
import { sendToSentryForAD } from './services/sentry';

import styles from './msal.module.scss';

class MSAL extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      settingToken: false,
      loginRedirect: false,
      adSelector: null,
    };
  }

  componentDidMount() {
    if (!config.USE_AD_AUTH) {
      return;
    }
    const { pathname = '' } = window.location;
    if (pathname !== '/switchAD') {
      this.setState({
        adSelector: getADSelector(),
      });
      return;
    }
    switchADSelector();
  }

  async componentDidUpdate(prevProps) {
    if (!config.USE_AD_AUTH) {
      return;
    }
    const { isLoginInfoSet: wereLoginInfoSet } = prevProps;
    const { msalContext, hydrated, isLoginInfoSet, token } = this.props;
    if (hydrated && !wereLoginInfoSet && isLoginInfoSet) {
      this.props.addRolesForADUser(token);
    }
    if (!hydrated || isLoginInfoSet) {
      return;
    }
    const isAuthenticatedUsingAD = msalContext.accounts.length > 0;
    if (isAuthenticatedUsingAD && !this.state.settingToken) {
      this.setState({
        settingToken: true,
      }, async () => {
        const account = msalContext.accounts[0];
        const response = await msalContext.instance.acquireTokenSilent({
          account,
          scopes: ['User.Read'],
        });
        const token = `Bearer ${response.accessToken}`;
        const { idTokenClaims } = account;
        if (account.username.includes('@')) {
          idTokenClaims.email = account.username;
        }
        await this.props.setLoginInfo({
          account: account.idTokenClaims,
          token,
        });
        this.props.addRolesForADUser(token);
        const { redirectToUrl } = this.props;
        const redirectURLIsLogout = redirectToUrl && redirectToUrl.includes('logout');
        const redirectURLIsLogin = redirectToUrl && redirectToUrl.includes('login');
        const redirectURLIsAdChecking = redirectToUrl && redirectToUrl.includes('adChecking');
        const currentURLIsAdChecking = window.location.href.includes('adChecking');

        if (redirectToUrl) {
          if (
            !redirectURLIsAdChecking &&
            !redirectURLIsLogout &&
            !redirectURLIsLogin &&
            window.location.href !== redirectToUrl
          ) {
            await this.props.clearRedirectTo();
            window.location = redirectToUrl;
          } else {
            if (redirectURLIsAdChecking) {
              this.props.push('/');
            }
            await this.props.clearRedirectTo();
          }
        } else {
          if (currentURLIsAdChecking) {
            this.props.push('/');
          }
        }
      });
      return;
    }
    const msalInstance = msalContext.instance;
    if (!isAuthenticatedUsingAD && !this.state.loginRedirect && !window.location.href.includes('logout') && !window.location.href.includes('login')) {
      this.setState({
        loginRedirect: true,
      }, async () => {
        const { redirectToUrl } = this.props;
        if (!window.location.href.includes('/adChecking') && !redirectToUrl) {
          await this.props.setRedirectTo(window.location.href);
        }
        await this.props.push('/adChecking');
        try {
          await msalInstance.handleRedirectPromise();
        } catch (e) {
          sendToSentryForAD(store, 'MSAL::componentDidUpdate::msalInstance.handleRedirectPromise', e);
        }
        msalInstance.loginRedirect().catch((e) => {
          console.error('error', e);
          sendToSentryForAD(store, 'MSAL::componentDidUpdate::msalInstance.loginRedirect', e);
        });
      });
    }
  }

  render() {
    if (!config.USE_AD_AUTH) {
      return null;
    }
    const { adSelector } = this.state;
    if (adSelector === AD_SELECTORS.INCROWD) {
      return null;
    }
    // eslint-disable-next-line
    return <marquee className={styles.container}>
      you are using the SIGHTX Azure Active Directory
    </marquee>;
  }
}

const mapStateToProps = (state) => {
  const { security = {}, redirectTo } = state;
  return {
    token: security.token,
    isLoginInfoSet: security.account !== undefined,
    hydrated: state.hydrated,
    redirectToUrl: redirectTo.url,
  };
};

export default withMsal(connect(mapStateToProps, { setLoginInfo, addRolesForADUser, clearRedirectTo, setRedirectTo, push })(MSAL));