import React, { memo } from 'react';

import { useSelector } from 'react-redux';

import { SxIcon } from '../sxLibrary';
import { ADMIN_APP_URL } from '../../../common/login.utils';
import { isImpersonating, getUserType } from '../../../selectors/security/user';
import { UserTypes } from '../../../interfaces/ISecurity';
import { Features, isFeatureEnabled } from '../../../featureToggles/features';

import styles from './index.module.scss';

const plgExternalEnabled = isFeatureEnabled(Features.plgexternal);

export const AdminOption = memo(() => {
  const userType = useSelector(getUserType);
  const impersonating = useSelector(isImpersonating);

  const isSxAdmin = userType === UserTypes.SX_ADMIN;
  const isMainAdmin = userType === UserTypes.MAIN_ADMIN;
  const isAdmin = [UserTypes.SX_ADMIN, UserTypes.ACCOUNT_ADMIN, UserTypes.TEAM_ADMIN].includes(userType);

  if (isFeatureEnabled(Features.sectionsPermissions)) {
    const accountOption = <a href={`${ADMIN_APP_URL}/settings/profile`} className={styles.option}>
      <div className={styles.label}>
        <div className={styles.labelAndIcon}>
          <SxIcon name="sx-icon-personas" /> Account
        </div>
      </div>
    </a>;

    if (impersonating && !isAdmin) {
      return accountOption;
    }

    return <>
      {accountOption}
      {(isSxAdmin || isMainAdmin) ? <a href={`${ADMIN_APP_URL}${plgExternalEnabled ? '' : '/users'}`} className={styles.option}>
        <div className={styles.label}>
          <div className={styles.labelAndIcon}>
            <SxIcon name={plgExternalEnabled ? 'sx-lock' : 'sx-icon-personas'} /> Admin
          </div>
        </div>
      </a> : null}
    </>;
  }

  const accountOption = plgExternalEnabled ? <a href={`${ADMIN_APP_URL}/settings/profile`} className={styles.option}>
    <div className={styles.label} >
      <SxIcon name="sx-icon-personas" /> Account
    </div>
  </a> : null;

  if (impersonating && !isAdmin) {
    return accountOption;
  }

  return <>
    {accountOption}
    {(isSxAdmin || isMainAdmin) && <a href={`${ADMIN_APP_URL}${plgExternalEnabled ? '' : '/users'}`} className={styles.option}>
      <div className={styles.label} >
        <SxIcon name={plgExternalEnabled ? 'sx-lock' : 'sx-icon-personas'} /> Admin
      </div>
    </a>}
  </>;
});