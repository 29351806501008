import React, { FunctionComponent } from 'react';

import cls from 'classnames';
import { Link } from 'react-router-dom';

import sightXLogo from '../../../../assets/sightxLogoWhite.svg';
import sightXLogoLite from '../../../../assets/sightxLogoLite.svg';
import netquestLogo from '../../../../assets/netquest/Flash-Survey-Logo-White.svg';
import { isLite, isNetquest } from '../../../../services/platform';

import styles from './logo.module.scss';

export const Logo: FunctionComponent = () => {
  const isLiteEnv = isLite();
  const logo = isLiteEnv ? sightXLogoLite : sightXLogo;

  const isNetquestClient = isNetquest();
  if (isNetquestClient) {
    return <div className={cls(styles.container, styles.netquest)}>
      <Link to="/">
        <img className={styles.logo} src={netquestLogo} alt="Netquest Logo" />
        <div className={styles.powerBySightX}>
          <span className={styles.label}>Powered by</span>
          <img className={styles.miniLogo} src={sightXLogo} alt="SightX Logo" />
        </div>
      </Link>
    </div>;
  }
  return <div className={cls(styles.container, styles.sightx)}>
    <Link to="/">
      <img className={styles.logo} src={logo} alt="SightX Logo" />
    </Link>
  </div>;
};
