import React, { Component } from 'react';
import styles from './fullScreenFrame.module.scss';
import cx from 'classnames';
import AnimatedMount from './AnimatedMount';


class FullScreenFrame extends Component {

  render() {
    return <AnimatedMount
      className={cx(styles.fullScreenDialog)}
      show={this.props.show}
      mountAnimation={styles.slideIn}
      unmountAnimation={styles.slideOut}
      duration="300ms"
    >
      {this.props.children}
    </AnimatedMount>;
  }
}

export default FullScreenFrame;
