import { isDebugModeEnabled } from './queryParameters';

export const overwriteConsoleLog = (force = false) => {
  if (force || !isDebugModeEnabled) {
    // eslint-disable-next-line no-global-assign
    console = {
      log: () => {},
      warn: () => {},
      warning: () => {},
      info: () => {},
      debug: () => {},
      error: () => {},
    };
  }
};
