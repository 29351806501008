import React, { PureComponent } from 'react';

import { Popover } from 'antd';
import { Link } from 'react-router-dom';

import { SxIcon } from '../sxLibrary';
import { Features, isFeatureEnabled } from '../../../featureToggles/features';
import { isNetquest } from '../../../services/platform';

import styles from './GetHelp.module.scss';

interface IGetHelpState {
  popupOpened: boolean;
}

export class GetHelp extends PureComponent<Record<string, never>, IGetHelpState> {
  constructor(props) {
    super(props);

    this.handleUserPilotTrigger = this.handleUserPilotTrigger.bind(this);
    this.onVisibilityChange = this.onVisibilityChange.bind(this);
    this.state = {
      popupOpened: false,
    };
  }

  private handleUserPilotTrigger() {
    if (!userpilot) {
      return;
    }
    userpilot.trigger('resource_center:kR3Dp8qsAG');
  }

  private onVisibilityChange(value: boolean) {
    this.setState({
      popupOpened: value,
    });
  }

  private renderPopupContent() {
    const europeEmail = 'flashsurvey.europa@netquest.com';
    const americaEmail = 'flashsurvey.americas@netquest.com';
    return <div className={styles.emails}>
      <div className={styles.email}>
        <span className={styles.label}>Europe: </span>
        <Link
          className={styles.link}
          to="#"
          onClick={(e) => {
            window.location.href = `mailto:${europeEmail}`;
            e.preventDefault();
          }}
        >
          {europeEmail}
        </Link>
      </div>
      <div className={styles.email}>
        <span className={styles.label}>America: </span>
        <Link
          className={styles.link}
          to="#"
          onClick={(e) => {
            window.location.href = `mailto:${americaEmail}`;
            e.preventDefault();
          }}
        >
          {americaEmail}
        </Link>
      </div>
    </div>;
  }

  render() {
    if (isFeatureEnabled(Features.netquest) && isNetquest()) {
      const { popupOpened } = this.state;
      return <Popover
        overlayClassName={styles.userConfigMenuPopup}
        trigger="click"
        visible={popupOpened}
        onVisibleChange={this.onVisibilityChange}
        content={this.renderPopupContent()}
        placement="right"
      >
        <span className={styles.getHelp}>
          <SxIcon name="sx-help-circle" fontSize="16px"/>
          <span className={styles.label}>Get help</span>
        </span>
      </Popover>;
    }

    return (
      <span className={styles.getHelp} onClick={this.handleUserPilotTrigger}>
        <SxIcon name="sx-help-circle" fontSize="16px"/>
        <span className={styles.label}>Get help</span>
      </span>
    );
  }
}