import React from 'react';

import { useSelector } from 'react-redux';
import cx from 'classnames';

import { SxPopup } from '../sxLibrary';
import { getImpersonateUser, getUser, isImpersonating } from '../../../selectors/security/user';
import { getUserCompleteName } from '../../../common/user.utils';
import AutoEllipsisTooltip from '../../project/skipLogic/common/autoEllipsisTooltip';
import { isProjectOverviewSection, isQuestionLibraryPath } from '../utils/navigation';
import Avatar from '../avatar';
import { Features } from '../../../featureToggles/features';
import { LapsedTooltipText } from '../accessControl';
import { AccountStatus } from '../../../common/accessControl.helper';
import { tooltipMessage } from '../../../data/sectionAccessPermissions';
import { getSectionSecurityPermissions } from '../../../reducers/security/selectors';
import { isAIConsultantAdminAllowedFor } from '../../../featureToggles/aiConsultantAdmin';

import { AdminSubMenu } from './AdminSubMenu';
import { Option } from './Option';
import { AdminOption } from './AdminOption';
import { GetHelpAndImpersonate } from './GetHelpAndImpersonate';
import { AudienceOption } from './AudienceOption';
import { LogoutOption } from './LogoutOption';

import styles from './index.module.scss';

export const UserMenu = () => {
  const sectionSecurityPermissions = useSelector(getSectionSecurityPermissions);
  const impersonating = useSelector(isImpersonating);
  const account = useSelector(getUser<never>);
  const impersonateAccount = useSelector(getImpersonateUser);

  const username = getUserCompleteName(impersonating ? impersonateAccount : account);
  const accountStatus = account.account?.status;
  const email = impersonating ? impersonateAccount.email : account.email;
  const isLapsedAccount = accountStatus === AccountStatus.LAPSED;
  const showAIConsultant = isAIConsultantAdminAllowedFor(account?.id);

  let libraryTooltipText: () => JSX.Element | null = () => null;
  if (isLapsedAccount) {
    libraryTooltipText = LapsedTooltipText;
  }

  if (!sectionSecurityPermissions?.build) {
    libraryTooltipText = () => <>{tooltipMessage}</>;
  }

  return <div className={cx(styles.userMenu, {
    [styles.aiConsultantOptions]: showAIConsultant,
  })}>
    <AdminSubMenu>
      <SxPopup
        featureToUse={Features.uirefresh}
        forceCloseOnClick
        arrowContent
        overlayClassName={styles.userConfigMenuPopup}
        trigger={
          <div className={styles.userInfo}>
            <Avatar className={styles.avatar} />
            <div className={styles.userAndEmail}>
              <AutoEllipsisTooltip className={styles.username} text={username} />
              <AutoEllipsisTooltip className={styles.email} text={email} />
            </div>
          </div>
        }
      >
        <div className={styles.userConfigMenu}>
          <AudienceOption isLapsedAccount={isLapsedAccount} />
          <Option
            key="LIBRARY"
            icon="sx-group"
            label="library"
            route="/questionLibrary"
            needUpgrade={isLapsedAccount}
            disabledText={libraryTooltipText}
            active={isQuestionLibraryPath()}
            disabled={!sectionSecurityPermissions?.build}
          />
          <Option
            key="USAGE"
            icon="sx-file"
            label="usage"
            route="/projectOverview"
            active={isProjectOverviewSection()}
          />
          <hr />
          <AdminOption />
          <LogoutOption />
        </div>
      </SxPopup>
    </AdminSubMenu>
    <GetHelpAndImpersonate />
  </div >;
};
