import React from 'react';

import { useSelector } from 'react-redux';

import { SxDropdown } from '../sxLibrary';
import {
  isAdasFeedbackPath,
  isMembersPath,
  isPanelIntegrationPath,
  isPromptAdminPath,
  isRecreateDataPath,
  isRevisionsPath,
} from '../utils/navigation';
import { isSupportUser, getUserType, isProjectExtras, getUser } from '../../../selectors/security/user';
import { Features, isFeatureEnabled } from '../../../featureToggles/features';
import { UserTypes } from '../../../interfaces/ISecurity';
import { isAIConsultantAdminAllowedFor } from '../../../featureToggles/aiConsultantAdmin';

import { Option } from './Option';

import styles from './index.module.scss';

export const AdminSubMenu = ({ children }) => {
  const isSupport = useSelector(isSupportUser);
  const userType = useSelector(getUserType);
  const userId = useSelector(getUser<Features.prod>)?.id;
  const userIsProjectExtras = useSelector(isProjectExtras);

  const isSxAdmin = userType === UserTypes.SX_ADMIN;
  const isAdmin = isSxAdmin || [UserTypes.ACCOUNT_ADMIN, UserTypes.TEAM_ADMIN].includes(userType);
  const showProjectMember = (!userType || isAdmin) && (userIsProjectExtras || isSupport);
  const showAIConsultant = isAIConsultantAdminAllowedFor(userId);

  if (!showProjectMember && !isSupport && !showAIConsultant) {
    return children;
  }

  return <SxDropdown
    featureToUse={Features.uirefresh}
    forceCloseOnClick
    trigger="contextMenu"
    contentClassName={styles.adminSubMenuPopup}
    content={
      <div className={styles.adminSubMenu}>
        {isSxAdmin && <Option key="PANEL_INTEGRATIONS" showIcon={false} label="Panel Integrations" route="/panelIntegrations" active={isPanelIntegrationPath()} />}
        {showProjectMember && <Option key="PROJECT_MEMBERS" showIcon={false} label="Project members" route="/members" active={isMembersPath()} />}
        {isSupport && <Option key="RECREATE_DATA" showIcon={false} label="Recreate data" route="/recreateData" active={isRecreateDataPath()} />}
        {isSupport && <Option key="PROJECT_REVISION" showIcon={false} label="Project revisions" route="/revisions" active={isRevisionsPath()} />}
        {showAIConsultant && <Option key="PROMPT_ADMIN" showIcon={false} label="Prompts admin" route="/prompts" active={isPromptAdminPath()} />}
        {isFeatureEnabled(Features.adasFeedback) && showAIConsultant && <Option key="ADAS_FEEDBACK" showIcon={false} label="Ada’s Feedback" route="/adasFeedback" active={isAdasFeedbackPath()} />}
      </div>
    }>
    {children}
  </SxDropdown>;
};
