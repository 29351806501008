import React, { BaseSyntheticEvent, useState } from 'react';

import { Col, Input, Row } from 'antd';

import { SxIcon, SxInput } from '../../sxLibrary';
import { FeatureToggle, Off, On } from '../../../../featureToggles';
import { Features } from '../../../../featureToggles/features';

import { IProspect } from './prospect.factory';

import styles from './prospect.module.scss';

interface IProspectProps {
  onChange: (id: string, value: string, fieldName: string) => void;
  prospect: IProspect;
  index: number;
  onRemove: (id: string) => void;
}

export const Prospect = (props: IProspectProps) => {
  const {
    onChange,
    prospect,
    index,
    onRemove,
  } = props;

  const [firstName, setFirstName] = useState(prospect.firstName);
  const [lastName, setLastName] = useState(prospect.lastName);
  const [email, setEmail] = useState(prospect.email);

  const setLocalData = (value: string, name: string) => {
    switch (name) {
      case 'firstName':
        setFirstName(value);
        break;
      case 'lastName':
        setLastName(value);
        break;
      case 'email':
        setEmail(value);
        break;
      default:
        break;
    }
  };

  const onInputChange = (e: BaseSyntheticEvent) => {
    const {
      target: {
        value,
        name,
      },
    } = e;
    setLocalData(value, name);
    onChange(prospect.id, value, name);
  };

  const onRemoveClick = () => {
    onRemove(prospect.id);
  };

  const renderRemoveProspect = () => {
    if (index === 0) {
      return null;
    }
    return (
      <FeatureToggle feature={Features.uirefresh}>
        <On>
          <SxIcon
            featureToUse={Features.uirefresh}
            onClick={onRemoveClick}
            className={styles.removeIcon}
            name="sx-trash"
            fontSize={16}
          />
        </On>
        <Off>
          <SxIcon className={styles.removeIcon} onClick={onRemoveClick} name="sx-cross" />
        </Off>
      </FeatureToggle>
    );
  };

  return (
    <FeatureToggle feature={Features.uirefresh}>
      <On>
        <div className={styles.prospectContainer}>
          <div className={styles.inlineContainer}>
            <SxInput
              placeholder="First name"
              name="firstName"
              value={firstName}
              onChange={onInputChange}
            />
            <SxInput
              placeholder="Last name"
              name="lastName"
              value={lastName}
              onChange={onInputChange}
            />
            {renderRemoveProspect()}
          </div>
          <SxInput
            placeholder="Email"
            name="email"
            value={email}
            onChange={onInputChange}
            type="email"
          />
        </div>
      </On>
      <Off>
        <Row gutter={12} className={styles.prospectRow}>
          <Col span={12}>
            <Input placeholder="First name" name="firstName" value={firstName} onChange={onInputChange} />
          </Col>
          <Col span={12} className={styles.lastNameCol}>
            <Input placeholder="Last name" name="lastName" value={lastName} onChange={onInputChange} />
            {renderRemoveProspect()}
          </Col>
          <Col span={24} style={{ marginTop: '10px' }}>
            <Input placeholder="Email" name="email" value={email} onChange={onInputChange} />
          </Col>
        </Row>
      </Off>
    </FeatureToggle>
  );
};
