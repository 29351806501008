import React, { Component } from 'react';

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { replace } from 'connected-react-router';

import { saveAuthData, updatePermissions } from '../../actions/security';
import { fetchCurrentUser, fetchImpersonatedUser } from '../../actions/management';
import SessionModal from '../security/sessionModal';
import {
  isLite,
  isRegionAllowed,
  isStage,
  isTrial,
  LITE_ACCESS_TYPE,
  TRIAL_ACCESS_TYPE,
} from '../../services/platform';
import * as config from '../../services/configParameters';
import { getQueryParam, getTokenFromQuery, getUserIdFromQuery } from '../../services/queryParameters';
import { AUTH_LOGIN_URL_W_REDIRECT_TO_CURRENT_HREF } from '../../common/login.utils';
import {
  selectSecurity,
  selectSecurityUserAccount,
  selectSecurityUser,
} from '../../selectors/common/securitySelectors';
import { isSuperAdmin as checkIfSuperAdmin } from '../../reducers/security/security.helper';
import { getAccountRegion, getUserRoles } from '../../selectors/common/user';
import { setAppClientId, clearImpersonate } from '../../reducers/security';
import { updateToken } from '../../services/api/tokenManagement';
import { Features, isFeatureEnabled } from '../../featureToggles/features';
import { AccountStatus } from '../../common/accessControl.helper';
import { isProspect } from '../../selectors/security/user';
import { showSuccessMessage } from '../../actions/notifications';

import Loading from './loading';
import AccessError from './accessError/accessError';

const USER_ID_Q_PARAM = 'userId';
const TOKEN_Q_PARAM = 'token';
const CLIENT_ID = 'sx_client_id';
const SX_ICODE = 'sx_icode';
const REGISTERED_Q_PARAM = 'registered';

const allowedPathsForLapsedAccounts = [
  /^\/$/,
  /^\/projects$/,
  /^\/folder/,
  /^\/projectOverview/,
  /^\/settings/,
];

class EnsureLoggedInContainer extends Component {
  constructor(props) {
    super(props);
    this.token = getTokenFromQuery();
    this.userId = getUserIdFromQuery();
  }

  async componentDidMount() {
    const { hydrated, isLoggedIn, history } = this.props;
    if (!hydrated) {
      return;
    }
    const currentUrl = new URLSearchParams(window.location.search);
    if (!isLoggedIn && currentUrl.has(TOKEN_Q_PARAM) && currentUrl.has(USER_ID_Q_PARAM)) {
      this.props.updateToken();
      currentUrl.delete(TOKEN_Q_PARAM);
      currentUrl.delete(USER_ID_Q_PARAM);
      history.push({
        search: currentUrl.toString(),
      });
    } else if (!isLoggedIn && !currentUrl.has(TOKEN_Q_PARAM) && !currentUrl.has(USER_ID_Q_PARAM)) {
      if (isFeatureEnabled(Features.STAGE)) {
        const result = await this.props.updateToken();
        if (result === false || result.status === 403) {
          /*
          false is because refresh token failed
          403   is because there is an existing session in another browser
          */
          window.location.href = AUTH_LOGIN_URL_W_REDIRECT_TO_CURRENT_HREF;
        }
        return;
      }
      window.location.href = AUTH_LOGIN_URL_W_REDIRECT_TO_CURRENT_HREF;
    }
  }

  componentDidUpdate(prevProps) {
    const {
      hydrated,
      isLoggedIn,
      shouldForceLoginRedirect,
      fetchingPolicies,
      hasPermissions,
      isFetchingCurrentUser,
      wasUserFetched,
      uniqueSession,
      history,
      setAppClientId,
      clearImpersonate,
    } = this.props;

    if (!hydrated) {
      return;
    }
    const currentUrl = new URLSearchParams(window.location.search);
    if (!isLoggedIn && currentUrl.has(CLIENT_ID)) {
      setAppClientId(currentUrl.get(CLIENT_ID));
      currentUrl.delete(CLIENT_ID);
      if (currentUrl.has(SX_ICODE)) {
        clearImpersonate();
        currentUrl.delete(SX_ICODE);
      }
      history.push({
        search: currentUrl.toString(),
      });
    }
    if (isFeatureEnabled(Features.plgexternal2) && currentUrl.has(REGISTERED_Q_PARAM)) {
      const isAlreadyRegistered = getQueryParam(REGISTERED_Q_PARAM);
      currentUrl.delete(REGISTERED_Q_PARAM);
      if (isAlreadyRegistered) {
        this.props.showSuccessMessage('You’re in! Looks like you already had an active SightX account.');
      }
    }
    if (!isLoggedIn && currentUrl.has(TOKEN_Q_PARAM) && currentUrl.has(USER_ID_Q_PARAM)) {
      this.props.updateToken();
      currentUrl.delete(TOKEN_Q_PARAM);
      currentUrl.delete(USER_ID_Q_PARAM);
      history.push({
        search: currentUrl.toString(),
      });
      return;
    }

    if (!isLoggedIn && !currentUrl.has(TOKEN_Q_PARAM) && !currentUrl.has(USER_ID_Q_PARAM) && uniqueSession) {
      if (this.props.history.location.pathname !== '/login') {
        if (config.USE_LEGACY_AUTH || isFeatureEnabled(Features.STAGE)) {
          return;
        }
        localStorage.removeItem('refresh');
        window.location.href = AUTH_LOGIN_URL_W_REDIRECT_TO_CURRENT_HREF;
        return;
      }
      return;
    }
    if (isLoggedIn) {
      if (currentUrl.has(CLIENT_ID)) {
        setAppClientId(currentUrl.get(CLIENT_ID));
        currentUrl.delete(CLIENT_ID);
        if (currentUrl.has(SX_ICODE)) {
          clearImpersonate();
          currentUrl.delete(SX_ICODE);
        }
        history.push({
          search: currentUrl.toString(),
        });
        return;
      }

      if (this.props.history.location.pathname === '/' && (currentUrl.has(TOKEN_Q_PARAM) && currentUrl.has(USER_ID_Q_PARAM))) {
        return this.props.replace('/');
      }

      if (shouldForceLoginRedirect && !isFetchingCurrentUser) {
        return this.props.replace('/login');
      }
      if (!fetchingPolicies && !hasPermissions && uniqueSession) {
        this.props.updatePermissions();
      }
      if (!!this.props.loggedUserId && ((prevProps.loggedUserId !== this.props.loggedUserId || !wasUserFetched) && !isFetchingCurrentUser && uniqueSession && !this.props.impersonating && !config.USE_LEGACY_AUTH)) {
        this.props.fetchCurrentUser();
      }
      if (prevProps.impersonating !== this.props.impersonating) {
        if (this.props.impersonatedId && this.props.impersonating) {
          this.props.fetchImpersonatedUser(this.props.impersonatedId);
        }
        this.props.updatePermissions();
      }
    }
  }

  saveTokenAndUpdateUserData() {
    const { token, userId } = this;
    if (config.USE_LEGACY_AUTH) {
      return;
    }
    this.props.saveAuthData({ token, userId });
    // TODO check if we can already remove userID from queryparams
    this.props.fetchCurrentUser();
    this.props.updatePermissions();
  }

  render() {
    const { hydrated, isLoggedIn, accountStatus, uniqueSession, children, location: { pathname } = {} } = this.props;
    if (!hydrated) {
      return <Loading />;
    }
    if (hydrated && isLoggedIn) {
      const isAllowedPathForLapsedAccount = allowedPathsForLapsedAccounts.some((path) => path.test(pathname));
      if (accountStatus === AccountStatus.LAPSED && !isAllowedPathForLapsedAccount) {
        return <AccessError type="ForbiddenLapsed" />;
      }
      const sessionConflictModal = uniqueSession ? null : <SessionModal />;
      return (
        <>
          {sessionConflictModal}
          {children}
        </>
      );
    }
    return <div />;
  }
}

const mapStateToProps = (state) => {
  const security = selectSecurity(state) || {};
  const { id: userId } = selectSecurityUser(state) || {};

  const securityUserAccount = selectSecurityUserAccount(state) || {};
  const isSuperAdmin = checkIfSuperAdmin(getUserRoles(state));
  const accountRegion = getAccountRegion(state);

  const isLiteUser = securityUserAccount.accessType === LITE_ACCESS_TYPE;
  const isTrialUser = securityUserAccount.accessType === TRIAL_ACCESS_TYPE;

  const { permissions, fetchingPolicies, account: { fetching, account: { status } = {} } = {}, impersonate, impersonating, uniqueSession, token, userId: loggedUserId } = security;
  // TODO, add interfaces
  const invalidTrialAccess = (!isTrial() && !isStage() && isTrialUser) || (isTrial() && !isTrialUser && !isSuperAdmin);
  const invalidLiteAccess = (!isLite() && !isStage() && isLiteUser) || (isLite() && !isLiteUser && !isSuperAdmin);
  const isInvalidRegion = !isSuperAdmin && !isRegionAllowed(accountRegion);
  const isProspectUser = isProspect(state);

  // TODO, extract logic for shouldForceLoginRedirect and test it
  const shouldForceLoginRedirect = (!!invalidTrialAccess || !!isInvalidRegion || isProspectUser || !!invalidLiteAccess) && !!userId;
  return {
    isLoggedIn: !!token,
    loggedUserId: loggedUserId,
    isFetchingCurrentUser: !!fetching,
    hydrated: state.hydrated,
    uniqueSession: uniqueSession,
    impersonating: impersonating,
    impersonatedId: impersonate?.userId,
    impersonatedUser: impersonate?.user,
    shouldForceLoginRedirect,
    hasPermissions: !!permissions,
    fetchingPolicies,
    wasUserFetched: !!userId,
    accountStatus: status,
  };
};

export const ConnectedEnsureLoggedInContainer = connect(
  mapStateToProps,
  {
    saveAuthData,
    fetchCurrentUser,
    replace,
    updatePermissions,
    fetchImpersonatedUser,
    setAppClientId,
    clearImpersonate,
    updateToken,
    showSuccessMessage,
  },
)(EnsureLoggedInContainer);

export default withRouter(ConnectedEnsureLoggedInContainer);
