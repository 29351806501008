import React, { ChangeEvent } from 'react';

import { connect } from 'react-redux';

import { BlockedByFeature, Features } from '../../../featureToggles/features';
import { SxIcon, SxModal, SxSelection, SxTextArea } from '../../common/sxLibrary';
import { IGlobalState } from '../../../reducers/interfaces/state';
import { saveAdasFeedbackByType } from '../../../actions/aiConsultant/feedback';
import { getAdasFeedbackError, getAdasFeedbackTypes, isSendingAdasFeedback } from '../../../reducers/gpt/aiConsultantFeedback/selectors';
import { IFeedbackType } from '../../../reducers/gpt/aiConsultantFeedback/interfaces';
import { FeedbackType } from '../constants';
import { clearError } from '../../../reducers/gpt/aiConsultantFeedback';

import styles from './index.module.scss';

interface IMapStateToProps {
  feedbackTypes: IFeedbackType[];
  isSendingFeedback: boolean;
  errorMessage: string | undefined;
}

interface IMapDispatchToProps {
  saveAdasFeedbackByType: typeof saveAdasFeedbackByType;
  clearError: typeof clearError;
}
interface IFeedbackModalProps {
  type: FeedbackType;
  conversationId: string;
  index: number;
  onClose: () => void;
}

interface IFeedbackModalState {
  issueType: string | undefined;
  message: string | undefined;
}

type IProps = IFeedbackModalProps & IMapStateToProps & IMapDispatchToProps;

class FeedbackModalBase extends React.Component<IProps, IFeedbackModalState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      issueType: undefined,
      message: undefined,
    };
    this.onMessageChange = this.onMessageChange.bind(this);
    this.onSubmitFeedback = this.onSubmitFeedback.bind(this);
    this.onFeedbackSelection = this.onFeedbackSelection.bind(this);
  }

  componentDidUpdate(prevProps: Readonly<IProps>): void {
    const { isSendingFeedback, errorMessage, onClose } = this.props;
    const { isSendingFeedback: isSendingFeedbackPrev } = prevProps;
    if (!errorMessage && !isSendingFeedback && isSendingFeedbackPrev) {
      onClose();
    }
  }

  componentWillUnmount(): void {
    this.props.clearError();
  }

  private onSubmitFeedback() {
    const { message, issueType } = this.state;
    const { saveAdasFeedbackByType, type, conversationId, index } = this.props;
    if (!type || !message) {
      return;
    }
    saveAdasFeedbackByType({
      type,
      conversationId,
      index,
      message,
      issueType,
    });
  }

  private onMessageChange(event: ChangeEvent<HTMLTextAreaElement>) {
    this.setState({ message: event.target.value });
  }

  private onFeedbackSelection(issueType: string) {
    this.setState({ issueType });
  }

  private renderIcon() {
    return (
      <div className={styles.bubble}><SxIcon name="sx-icon-comment"/></div>
    );
  }

  private renderIssueSelector() {
    const { type, feedbackTypes, isSendingFeedback } = this.props;
    if (type === FeedbackType.Positive) {
      return null;
    }
    const options = feedbackTypes.map((feedbackType) => ({
      key: feedbackType.type,
      text: feedbackType.label,
      value: feedbackType.type,
    }));
    return (
      <div className={styles.field}>
        <label>What type of issue would you like to report:</label>
        <SxSelection
          placeholder="Pick an option"
          options={options}
          disabled={isSendingFeedback}
          className={styles.selection}
          onSelect={this.onFeedbackSelection}
        />
      </div>
    );
  }

  private renderContent() {
    const { isSendingFeedback, type } = this.props;
    const { message } = this.state;
    const placeholder = type === FeedbackType.Positive ? 'What do you like about the response?' : 'What didn’t you like about the response?';
    return (
      <div className={styles.content}>
        <h2>Provide us your feedback</h2>
        {this.renderIssueSelector()}
        <SxTextArea
          value={message}
          placeholder={placeholder}
          className={styles.textArea}
          disabled={isSendingFeedback}
          onChange={this.onMessageChange}
        />
      </div>
    );
  }

  render() {
    const { onClose, type, isSendingFeedback } = this.props;
    const { message, issueType } = this.state;
    const shouldDisableSubmit = !message || isSendingFeedback || (type === FeedbackType.Negative && !issueType);
    return (
      <SxModal
        featureToUse={Features.uirefresh}
        showCloseIcon={!isSendingFeedback}
        visible
        className={styles.disclaimerModal}
        okButtonProps={{ disabled: shouldDisableSubmit }}
        cancelButtonProps={{ disabled: isSendingFeedback }}
        size="extraSmall"
        cancelText="Cancel"
        okText="Submit feedback"
        onOk={this.onSubmitFeedback}
        onCancel={onClose}
      >
        {this.renderIcon()}
        {this.renderContent()}
      </SxModal>
    );
  }
}

const mapStateToProps = (state: IGlobalState): IMapStateToProps => {
  const result = getAdasFeedbackTypes(state);
  const isSendingResult = isSendingAdasFeedback(state);
  const errorResult = getAdasFeedbackError(state);

  return {
    feedbackTypes: result !== BlockedByFeature ? result : [],
    isSendingFeedback: isSendingResult !== BlockedByFeature ? isSendingResult : false,
    errorMessage: errorResult !== BlockedByFeature ? errorResult : undefined,
  };
};

const mapDispatchToProps: IMapDispatchToProps = {
  saveAdasFeedbackByType,
  clearError,
};

export const FeedbackModal = connect(mapStateToProps, mapDispatchToProps)(FeedbackModalBase);