import { Component } from 'react';

import moment from 'moment';
import { connect } from 'react-redux';

import { isLoadingSecurityState } from '../../../selectors/accessControl';
import { callUpgradeToEnterpriseForm } from '../../../actions/callUpgradeToEnterpriseForm';
import { redirectToUpgradeToPro } from '../../../common/upgrade.utils';
import { Features, isFeatureEnabled } from '../../../featureToggles/features';
import { IGlobalState } from '../../../reducers/interfaces/state';
import { getCurrentEnterpriseTrial, getCurrentProTrial, getRecentPastTrial, getUser } from '../../../selectors/common/user';
import { SxButton } from '../sxLibrary';
import { colors } from '../../../styles/variables';
import { AccountsSupported } from '../educationalModalBase/helper';
import { ITrial, TrialDescriptor } from '../../../interfaces/ISecurity';

import { getRemainingMessage } from './helper';

import styles from './TrialBanner.module.scss';

const TIME_LIMIT_IN_SECONDS = 300;
const MINUTE_IN_SECONDS = 60;
const DELAY = (MINUTE_IN_SECONDS / 2) * 1000;
const DELAY_IN_MILLISECONDS = DELAY * 1000;

interface IMapStateProps {
  accessType?: string;
  trialExpiredMoreThanAWeekAgo: boolean;
  pastTrial: ITrial | undefined;
  currentTrial: ITrial | undefined;
  isLoading: boolean;
  remainingSeconds: number;
}

interface ITrialBannerState {
  time: number;
  remainingMessage: string;
}

interface IDispatchToProps {
  callUpgradeToEnterpriseForm: typeof callUpgradeToEnterpriseForm;
}

type ITrialBannerProps = IMapStateProps & IDispatchToProps;

class TrialBannerBase extends Component<ITrialBannerProps, ITrialBannerState> {
  private timer: NodeJS.Timer | undefined;

  constructor(props: ITrialBannerProps) {
    super(props);
    const { remainingSeconds } = this.props;
    this.timer = undefined;
    this.state = {
      time: remainingSeconds,
      remainingMessage: getRemainingMessage(remainingSeconds),
    };
  }

  componentDidMount(): void {
    if (!this.timer && this.state.time > MINUTE_IN_SECONDS) {
      this.timer = setInterval(() => {
        const seconds = this.state.time - DELAY;
        this.setState({ time: seconds, remainingMessage: getRemainingMessage(seconds) });
        if (seconds <= TIME_LIMIT_IN_SECONDS) {
          this.setState({ time: 0, remainingMessage: 'a few minutes' });
          clearInterval(this.timer);
        }
      }, DELAY_IN_MILLISECONDS);
    }
  }

  componentWillUnmount(): void {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }

  render() {
    const {
      currentTrial,
      trialExpiredMoreThanAWeekAgo,
      callUpgradeToEnterpriseForm,
      isLoading = false,
      pastTrial,
      accessType,
    } = this.props;

    const isEnterpriseAccount = accessType === AccountsSupported.Enterprise;
    const isProAccount = accessType === AccountsSupported.Pro;
    const userDidUpgrade = (pastTrial?.descriptor === TrialDescriptor.Pro && (isProAccount || isEnterpriseAccount)) ||
      (pastTrial?.descriptor === TrialDescriptor.Enterprise && isEnterpriseAccount);

    if (
      !isFeatureEnabled(Features.TrialsOfTiers)
      || !accessType
      || (!pastTrial && !currentTrial)
      || (pastTrial && trialExpiredMoreThanAWeekAgo && !currentTrial)
      || userDidUpgrade
    ) {
      return null;
    }

    const hasActiveTrial = !!currentTrial;
    const isCurrentProTrial = hasActiveTrial && currentTrial.descriptor === TrialDescriptor.Pro;
    let message = '';
    if (hasActiveTrial) {
      const trialType = isCurrentProTrial ? 'Professional' : 'Enterprise';
      message = `You'll lose access to SightX ${trialType} plan features in ${this.state.remainingMessage}`;
    }
    if (!hasActiveTrial && pastTrial) {
      const isProTrial = pastTrial.descriptor === TrialDescriptor.Pro;
      const trialType = isProTrial ? 'Professional' : 'Enterprise';
      const proOrEnterprise = isProTrial ? 'Pro' : 'Enterprise';
      message = `Your trial of SightX ${trialType} has ended. Upgrade to continue using ${proOrEnterprise} features!`;
    }
    const isLite = accessType === AccountsSupported.Lite;
    const actionButton = (isCurrentProTrial || (!hasActiveTrial && isLite)) ? redirectToUpgradeToPro : callUpgradeToEnterpriseForm;

    return (
      <div className={styles.trialBanner}>
        {message}
        <SxButton
          featureToUse={Features.uirefresh}
          className={styles.button}
          onClick={actionButton}
          type="primary"
          iconRight="sx-star-plus"
          iconColor={colors.orange500}
          disabled={isCurrentProTrial ? false : isLoading}
        >
          Upgrade now
        </SxButton>
      </div>
    );
  }
}

const mapStateToProps = (state: IGlobalState): IMapStateProps => {
  const user = getUser(state);
  const proTrial = getCurrentProTrial(state);
  const enterpriseTrial = getCurrentEnterpriseTrial(state);
  const currentTrial = proTrial ?? enterpriseTrial;
  const pastTrial = getRecentPastTrial(state);
  const now = moment.utc();
  const currentEndDate = moment.utc(currentTrial?.endDateUtc);
  const pastEndDate = moment.utc(pastTrial?.endDateUtc).startOf('day');
  const remainingSeconds = currentTrial ? Math.floor(currentEndDate.diff(now) / 1000) : 0;
  const trialExpiredMoreThanAWeekAgo = pastTrial ? Math.abs(pastEndDate.diff(now, 'days')) > 7 : false;

  return {
    accessType: user?.account?.accessType,
    remainingSeconds,
    trialExpiredMoreThanAWeekAgo,
    currentTrial,
    pastTrial,
    isLoading: isLoadingSecurityState(state),
  };
};

const dispatchToProps: IDispatchToProps = {
  callUpgradeToEnterpriseForm,
};

export const TrialOfTiersBanner = connect(mapStateToProps, dispatchToProps)(TrialBannerBase);
