import apiActionCreator from '../../services/api/actionCreator';
import * as microservicesTypes from '../../services/api/microservicesTypes';
import {
  fetchingImpersonableUsers,
  fetchImpersonableUsersFailed,
  fetchImpersonableUsersSuccess,
} from '../../reducers/impersonate/impersonate';
import { IImpersonableUser } from '../../reducers/impersonate/interfaces/impersonate';

export const fetchImpersonableUsers = () => (dispatch) => {
  return dispatch(
    apiActionCreator({
      contentType: 'application/json',
      accept: 'application/json',
      data: null,
      endpoint: '/users/impersonables',
      authenticated: true,
      microservice: microservicesTypes.USERS,
      method: 'GET',
      types: [
        fetchingImpersonableUsers(),
        [
          (response: IImpersonableUser[]) => fetchImpersonableUsersSuccess(response),
        ],
        [
          fetchImpersonableUsersFailed(),
        ],
      ],
    }),
  );
};