import { Features, isFeatureEnabled } from '../../../featureToggles/features';

import * as viewTypes from './viewTypes';

const panelFeatureEnabled = isFeatureEnabled(Features.panel);

const viewTypesLabels = {
  [viewTypes.BUILD]: 'Create',
  [viewTypes.CATALOG]: 'Catalog',
  [viewTypes.CAMPAIGNS]: 'Distribute',
  [viewTypes.CUSTOMIZE]: 'Design',
  [viewTypes.OPTIONS]: 'Options',
  [viewTypes.LANGUAGES]: panelFeatureEnabled ? 'Translations' : 'Languages',
  [viewTypes.SKIP_LOGIC]: 'Logic',
  [viewTypes.USERS]: 'Users',
};

if (isFeatureEnabled(Features.GPT)) {
  viewTypesLabels[[viewTypes.TEMPLATE_TAGS]] = 'Template Tags';
}

export default viewTypesLabels;