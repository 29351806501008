import * as microservicesTypes from '../../services/api/microservicesTypes';
import apiActionCreator from '../../services/api/actionCreator';
import { getFeatureAvailabilityInstance } from '../../services/featuresAvailability';
import { doNothing } from '../../featureToggles';

let retries = 3;
export const getFeaturesAvailability = () => (dispatch) => {
  const instance = getFeatureAvailabilityInstance();
  --retries;
  if (!instance.isEmpty() || retries <= 0) {
    return dispatch(doNothing('featureAvailabilitySkipped'));
  }
  return dispatch(apiActionCreator({
    endpoint: '/features/availability',
    authenticated: false,
    microservice: microservicesTypes.USERS,
    method: 'GET',
    types: [
      doNothing('featureAvailabilityFetching'),
      [
        (response) => {
          instance.setFeaturesAvailabilityResult(response);
          return doNothing('featureAvailabilitySuccess');
        }
      ],
      [
        () => {
          setTimeout(() => dispatch(getFeaturesAvailability(), 100));
          return doNothing('featureAvailabilityError');
        }
      ],
    ],
    contentType: 'application/json',
    accept: 'application/json',
    data: null,
  }));
};