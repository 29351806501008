const css = `/** do not use this yet */
/* Desktop - Eyebrow/Small - Semi-Bold */
.index-module__blocked___Slfrq {
  opacity: 0.9;
}

.index-module__disabled-component___WkvHY {
  opacity: 0.6;
  cursor: not-allowed;
}

.index-module__banner___CMcCl {
  min-height: 43px;
  background: #D0D5DD;
  width: 100%;
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #736dba;
  border-bottom: 0;
  height: 48px;
}
.index-module__banner___CMcCl .index-module__information___XBvXv {
  display: flex;
  align-items: center;
  justify-content: center;
}
.index-module__banner___CMcCl .index-module__information___XBvXv .index-module__message___U8ReK {
  white-space: nowrap;
  display: flex;
  gap: 11px;
  color: #393939;
  line-height: 30px;
}

.sightx .ant-btn.index-module__submitButton___7d27e {
  background-color: #27235C;
  height: 26px;
  margin-left: 10px;
}
.sightx .ant-btn.index-module__submitButton___7d27e span {
  font-weight: normal;
}`;
document.head
    .appendChild(document.createElement("style"))
    .appendChild(document.createTextNode(css));
export {css};
export default {
  "blocked": "index-module__blocked___Slfrq",
  "disabled-component": "index-module__disabled-component___WkvHY",
  "banner": "index-module__banner___CMcCl",
  "information": "index-module__information___XBvXv",
  "message": "index-module__message___U8ReK",
  "sightx": "sightx",
  "ant-btn": "ant-btn",
  "submitButton": "index-module__submitButton___7d27e"
};