import { Component } from 'react';
import { connect } from 'react-redux';

import { withFirebase } from '../../services/firebase';
import {
  setVersionFromFirebase,
  queueAppReload,
} from '../../actions/frontendVersion';
import * as config from '../../services/configParameters';

import './frontendNotification.scss';

class FrontendChangeDetector extends Component {
  constructor(props) {
    super(props);
    const { firebase } = this.props;
    if (firebase) {
      this.ref = this.props.firebase.database.ref(
        `${config.FIREBASE_FRONTEND_STATUS_DATABASE}`,
      );
    }
    this.onCollectionUpdate = this.onCollectionUpdate.bind(this);
    this.newVersionAvailable = this.newVersionAvailable.bind(this);
  }

  componentDidMount() {
    if (this.ref) {
      this.ref.limitToLast(1).on('value', this.onCollectionUpdate);
    }
  }

  newVersionAvailable() {
    const { frontendVersion } = this.props;
    return (
      config.FRONTEND_COMMIT !== 'DEVELOPMENT' &&
      config.ENV !== 'RELEASE' &&
      typeof frontendVersion === 'string' &&
      frontendVersion &&
      frontendVersion !== config.FRONTEND_COMMIT
    );
  }

  componentDidUpdate() {
    if (this.newVersionAvailable()) {
      this.props.queueAppReload();
    }
  }

  onCollectionUpdate(querySnapshot) {
    let lastCommit;
    querySnapshot.forEach(res => {
      lastCommit = res.val().commit;
    });
    if (lastCommit) {
      this.props.setVersionFromFirebase(lastCommit);
    }
  }
  componentWillUnmount() {
    this.ref.off('value', this.onCollectionUpdate);
  }
  render() {
    return null;
  }
}

const mapStateToProps = state => {
  const { frontendVersion = {} } = state;
  return {
    frontendVersion: frontendVersion.version,
  };
};

export default connect(
  mapStateToProps,
  { setVersionFromFirebase, queueAppReload },
)(withFirebase(FrontendChangeDetector));

export { FrontendChangeDetector };
