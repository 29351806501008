const css = `/** do not use this yet */
.aiConsultant-module__tooltip___Tdj1m {
  z-index: 1100;
}

.aiConsultant-module__openChatButton___Ons5b {
  grid-row: 4/5;
  margin-top: 14px;
  margin-bottom: 16px;
  cursor: pointer;
  width: 186px;
  display: flex;
  height: 43px;
  padding: 8px 16px 8px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  flex-shrink: 0;
  align-self: stretch;
  border-radius: 25px;
  background: linear-gradient(225deg, rgba(143, 103, 255, 0.5) 0%, rgba(77, 253, 242, 0.5) 100%);
  box-shadow: 0px 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08);
}
.aiConsultant-module__openChatButton___Ons5b .aiConsultant-module__buttonInnerContainer___8OWP3 {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
}
.aiConsultant-module__openChatButton___Ons5b .aiConsultant-module__buttonInnerContainer___8OWP3 .aiConsultant-module__icon___KZfOf {
  width: 30px;
  height: 30px;
}
.aiConsultant-module__openChatButton___Ons5b .aiConsultant-module__buttonInnerContainer___8OWP3 .aiConsultant-module__icon___KZfOf.aiConsultant-module__opened___C7OPF {
  filter: invert(1);
  width: 26px;
  height: 26px;
}
.aiConsultant-module__openChatButton___Ons5b .aiConsultant-module__buttonInnerContainer___8OWP3 .aiConsultant-module__text___qWX9- {
  color: #ffffff;
  font-feature-settings: "clig" off, "liga" off;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 18px;
  gap: 6px;
}
.aiConsultant-module__openChatButton___Ons5b .aiConsultant-module__buttonInnerContainer___8OWP3 .aiConsultant-module__text___qWX9- .aiConsultant-module__innerText___6SgV7 {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: inline-block;
}

.aiConsultant-module__betaTag___rHzoW {
  color: #00936D;
  display: inline-block;
  font-size: 12px;
  font-style: italic;
  font-weight: 700;
  line-height: normal;
  top: unset;
}

@keyframes aiConsultant-module__slide-right___Ida8A {
  from {
    margin-left: -100%;
  }
  to {
    margin-left: 0%;
  }
}
.aiConsultant-module__container___Wbcma {
  position: fixed;
  bottom: 0;
  left: -100%;
  height: fit-content;
  width: calc(100vw - 218px);
  z-index: 1001;
  transition: left 0.3s;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.aiConsultant-module__container___Wbcma.aiConsultant-module__opened___C7OPF {
  left: 218px;
}
.aiConsultant-module__container___Wbcma.aiConsultant-module__opened___C7OPF.aiConsultant-module__chatBoxContainer___1tUiy {
  bottom: 18px;
}
.aiConsultant-module__container___Wbcma.aiConsultant-module__chatBoxContainer___1tUiy {
  left: calc(100% - 398px);
  max-width: 380px;
  border-radius: 6px;
  overflow: hidden;
  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03);
  bottom: calc(18px - 100%);
  transition: bottom 0.5s, left 0.3s;
}
.aiConsultant-module__container___Wbcma.aiConsultant-module__chatBoxContainer___1tUiy.aiConsultant-module__chatBoxWithToolBoxOpened___KMG2w {
  left: calc(100% - 948px);
}
.aiConsultant-module__container___Wbcma.aiConsultant-module__chatBoxContainer___1tUiy.aiConsultant-module__chatBoxWithToolBoxMinimized___KF2zG {
  left: calc(100% - 469px);
}
.aiConsultant-module__container___Wbcma.aiConsultant-module__chatBoxContainer___1tUiy .react-resizable {
  max-height: calc(100vh - 18px);
  padding-top: 16px;
}
.aiConsultant-module__container___Wbcma * {
  user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
.aiConsultant-module__container___Wbcma .react-resizable {
  max-height: 100vh;
  bottom: 0;
  left: 0;
  width: 100%;
  background: linear-gradient(173.22deg, rgba(65, 33, 106, 0.8) 0.48%, rgba(14, 74, 78, 0.8) 53.68%, rgba(7, 23, 32, 0.8) 99.11%);
  backdrop-filter: blur(5px);
  padding-top: 8px;
}
.aiConsultant-module__container___Wbcma .react-resizable .aiConsultant-module__header___Zomk6 {
  height: 51px;
  display: flex;
  padding: 0px 16px 8px 16px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-bottom: 1px solid #98A2B3;
}
.aiConsultant-module__container___Wbcma .react-resizable .aiConsultant-module__header___Zomk6 .aiConsultant-module__innerHeader___4wS0z {
  width: 236px;
  display: flex;
  gap: 8px;
  align-items: center;
}
.aiConsultant-module__container___Wbcma .react-resizable .aiConsultant-module__header___Zomk6 .aiConsultant-module__innerHeader___4wS0z .aiConsultant-module__logo___YqpY7 {
  width: 43px;
  height: 43px;
  filter: brightness(0%) invert(1);
}
.aiConsultant-module__container___Wbcma .react-resizable .aiConsultant-module__header___Zomk6 .aiConsultant-module__innerHeader___4wS0z .aiConsultant-module__title___DsUVP {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
}
.aiConsultant-module__container___Wbcma .react-resizable .aiConsultant-module__header___Zomk6 .aiConsultant-module__actions___QkliU {
  display: flex;
  align-items: center;
  gap: 24px;
}
.aiConsultant-module__container___Wbcma .react-resizable .aiConsultant-module__header___Zomk6 .aiConsultant-module__actions___QkliU.aiConsultant-module__chatBoxActions___r-Gft {
  gap: 20px;
}
.aiConsultant-module__container___Wbcma .react-resizable .aiConsultant-module__header___Zomk6 .aiConsultant-module__actions___QkliU .aiConsultant-module__action___DvJDc {
  align-self: center;
}
.aiConsultant-module__container___Wbcma .react-resizable .aiConsultant-module__header___Zomk6 .aiConsultant-module__actions___QkliU .aiConsultant-module__action___DvJDc.ant-btn.ant-btn-default {
  font-size: 14px;
  cursor: pointer;
  color: #ffffff;
}
.aiConsultant-module__container___Wbcma .react-resizable .aiConsultant-module__header___Zomk6 .aiConsultant-module__actions___QkliU .aiConsultant-module__action___DvJDc.ant-btn.ant-btn-default:hover, .aiConsultant-module__container___Wbcma .react-resizable .aiConsultant-module__header___Zomk6 .aiConsultant-module__actions___QkliU .aiConsultant-module__action___DvJDc.ant-btn.ant-btn-default:focus {
  color: #ffffff;
}
.aiConsultant-module__container___Wbcma .react-resizable .aiConsultant-module__header___Zomk6 .aiConsultant-module__actions___QkliU .aiConsultant-module__action___DvJDc.aiConsultant-module__chatBoxAction___BwYDn.ant-btn.ant-btn-default {
  padding: 8px;
}
.aiConsultant-module__container___Wbcma .react-resizable .aiConsultant-module__header___Zomk6 .aiConsultant-module__actions___QkliU .aiConsultant-module__action___DvJDc.aiConsultant-module__chatBoxAction___BwYDn.ant-btn.ant-btn-default .sx-icon.sx-window {
  color: #ffffff;
}
.aiConsultant-module__container___Wbcma .react-resizable .aiConsultant-module__header___Zomk6 .aiConsultant-module__actions___QkliU .aiConsultant-module__action___DvJDc.aiConsultant-module__chatBoxAction___BwYDn.aiConsultant-module__active___W7y0j.ant-btn.ant-btn-default, .aiConsultant-module__container___Wbcma .react-resizable .aiConsultant-module__header___Zomk6 .aiConsultant-module__actions___QkliU .aiConsultant-module__action___DvJDc.aiConsultant-module__chatBoxAction___BwYDn.aiConsultant-module__active___W7y0j:hover.ant-btn.ant-btn-default, .aiConsultant-module__container___Wbcma .react-resizable .aiConsultant-module__header___Zomk6 .aiConsultant-module__actions___QkliU .aiConsultant-module__action___DvJDc.aiConsultant-module__chatBoxAction___BwYDn.aiConsultant-module__active___W7y0j:focus.ant-btn.ant-btn-default, .aiConsultant-module__container___Wbcma .react-resizable .aiConsultant-module__header___Zomk6 .aiConsultant-module__actions___QkliU .aiConsultant-module__action___DvJDc.aiConsultant-module__chatBoxAction___BwYDn.aiConsultant-module__active___W7y0j:focus-visible.ant-btn.ant-btn-default {
  background-color: #000000;
}
.aiConsultant-module__container___Wbcma .react-resizable .aiConsultant-module__header___Zomk6 .aiConsultant-module__actions___QkliU .aiConsultant-module__themeToggle___UvrN- button.ant-switch {
  background-color: #ffffff;
}
.aiConsultant-module__container___Wbcma .react-resizable .aiConsultant-module__header___Zomk6 .aiConsultant-module__actions___QkliU .aiConsultant-module__themeToggle___UvrN- button.ant-switch > .ant-switch-handle:before {
  background-color: #475467;
}
.aiConsultant-module__container___Wbcma .react-resizable .aiConsultant-module__header___Zomk6 .aiConsultant-module__actions___QkliU .aiConsultant-module__themeToggle___UvrN- button.ant-switch > .ant-switch-inner {
  color: #475467;
}
.aiConsultant-module__container___Wbcma .react-resizable .aiConsultant-module__header___Zomk6 .aiConsultant-module__actions___QkliU .aiConsultant-module__themeToggle___UvrN- button.ant-switch-checked {
  background-color: #475467;
}
.aiConsultant-module__container___Wbcma .react-resizable .aiConsultant-module__header___Zomk6 .aiConsultant-module__actions___QkliU .aiConsultant-module__themeToggle___UvrN- button.ant-switch-checked > .ant-switch-inner {
  margin-left: 2px;
  color: #ffffff;
}
.aiConsultant-module__container___Wbcma .react-resizable .aiConsultant-module__header___Zomk6 .aiConsultant-module__actions___QkliU .aiConsultant-module__themeToggle___UvrN- button.ant-switch-checked > .ant-switch-handle:before {
  background-color: #ffffff;
}
.aiConsultant-module__container___Wbcma .react-resizable .aiConsultant-module__header___Zomk6 .aiConsultant-module__actions___QkliU .aiConsultant-module__icon___KZfOf {
  vertical-align: middle;
}
.aiConsultant-module__container___Wbcma .react-resizable .aiConsultant-module__innerContainer___-9WZO {
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
  height: calc(100% - 51px);
}
.aiConsultant-module__container___Wbcma .react-resizable .aiConsultant-module__innerContainer___-9WZO.aiConsultant-module__chatBoxInnerContainer___NfEV8 {
  flex-direction: column-reverse;
}
.aiConsultant-module__container___Wbcma .react-resizable .aiConsultant-module__innerContainer___-9WZO .aiConsultant-module__column___T3vGH {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.aiConsultant-module__container___Wbcma .react-resizable .aiConsultant-module__innerContainer___-9WZO .aiConsultant-module__column___T3vGH.aiConsultant-module__chatBoxColumn___UwXqB {
  height: calc(100% - 48px);
}
.aiConsultant-module__container___Wbcma .react-resizable .aiConsultant-module__innerContainer___-9WZO .aiConsultant-module__column___T3vGH.aiConsultant-module__chatBoxColumn___UwXqB.aiConsultant-module__opened___C7OPF {
  height: calc(100% - 220px);
}
.aiConsultant-module__container___Wbcma .react-resizable .aiConsultant-module__innerContainer___-9WZO .aiConsultant-module__column___T3vGH .aiConsultant-module__conversation___ZaY81 {
  border-bottom: 1px solid #98A2B3;
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  justify-content: center;
  scroll-behavior: smooth;
}
.aiConsultant-module__container___Wbcma .react-resizable .aiConsultant-module__innerContainer___-9WZO .aiConsultant-module__column___T3vGH .aiConsultant-module__conversation___ZaY81.aiConsultant-module__chatBox___EcUwE {
  padding: 0 8px;
}
.aiConsultant-module__container___Wbcma .react-resizable .aiConsultant-module__innerContainer___-9WZO .aiConsultant-module__column___T3vGH .aiConsultant-module__conversation___ZaY81::-webkit-scrollbar-track {
  background-color: rgba(249, 250, 251, 0.1);
}
.aiConsultant-module__container___Wbcma .react-resizable .aiConsultant-module__innerContainer___-9WZO .aiConsultant-module__column___T3vGH .aiConsultant-module__conversation___ZaY81.aiConsultant-module__ada2___d0HZt {
  scrollbar-color: #D0D5DD rgba(249, 250, 251, 0.1);
}
.aiConsultant-module__container___Wbcma .react-resizable .aiConsultant-module__innerContainer___-9WZO .aiConsultant-module__column___T3vGH .aiConsultant-module__conversation___ZaY81.aiConsultant-module__ada2___d0HZt::-webkit-scrollbar-thumb {
  background: #D0D5DD;
}
.aiConsultant-module__container___Wbcma .react-resizable .aiConsultant-module__innerContainer___-9WZO .aiConsultant-module__column___T3vGH .aiConsultant-module__conversation___ZaY81 .aiConsultant-module__innerConversationContainer___gV3aV {
  max-width: 784px;
  padding-top: 8px;
  padding-bottom: 16px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.aiConsultant-module__container___Wbcma .react-resizable .aiConsultant-module__innerContainer___-9WZO .aiConsultant-module__column___T3vGH .aiConsultant-module__conversation___ZaY81 .aiConsultant-module__innerConversationContainer___gV3aV.aiConsultant-module__chatBoxInnerConversationContainer___BTNq8 {
  max-width: 360px;
}
.aiConsultant-module__container___Wbcma .react-resizable .aiConsultant-module__innerContainer___-9WZO .aiConsultant-module__column___T3vGH .aiConsultant-module__conversation___ZaY81 .aiConsultant-module__innerConversationContainer___gV3aV .aiConsultant-module__loader___t8NGe {
  max-width: 784px;
}
.aiConsultant-module__container___Wbcma .react-resizable .aiConsultant-module__innerContainer___-9WZO .aiConsultant-module__column___T3vGH .aiConsultant-module__conversation___ZaY81 .aiConsultant-module__innerConversationContainer___gV3aV .aiConsultant-module__loader___t8NGe li {
  max-width: 784px;
  height: 34px;
  border-radius: 6px;
}
.aiConsultant-module__container___Wbcma .react-resizable .aiConsultant-module__innerContainer___-9WZO .aiConsultant-module__column___T3vGH .aiConsultant-module__conversation___ZaY81 .aiConsultant-module__innerConversationContainer___gV3aV .aiConsultant-module__loader___t8NGe.aiConsultant-module__loaderChatBox___aSk3I {
  max-width: 360px;
}
.aiConsultant-module__container___Wbcma .react-resizable .aiConsultant-module__innerContainer___-9WZO .aiConsultant-module__column___T3vGH .aiConsultant-module__conversation___ZaY81 .aiConsultant-module__innerConversationContainer___gV3aV .aiConsultant-module__loader___t8NGe.aiConsultant-module__loaderChatBox___aSk3I li {
  max-width: 360px;
}
.aiConsultant-module__container___Wbcma .react-resizable .aiConsultant-module__innerContainer___-9WZO .aiConsultant-module__column___T3vGH .aiConsultant-module__conversation___ZaY81 .aiConsultant-module__innerConversationContainer___gV3aV .aiConsultant-module__message___VYn9V {
  display: flex;
  padding: 16px;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  border-radius: 6px;
  width: 100%;
}
.aiConsultant-module__container___Wbcma .react-resizable .aiConsultant-module__innerContainer___-9WZO .aiConsultant-module__column___T3vGH .aiConsultant-module__conversation___ZaY81 .aiConsultant-module__innerConversationContainer___gV3aV .aiConsultant-module__message___VYn9V.aiConsultant-module__ada___G5Ryy {
  justify-content: space-between;
}
.aiConsultant-module__container___Wbcma .react-resizable .aiConsultant-module__innerContainer___-9WZO .aiConsultant-module__column___T3vGH .aiConsultant-module__conversation___ZaY81 .aiConsultant-module__innerConversationContainer___gV3aV .aiConsultant-module__message___VYn9V.aiConsultant-module__ada___G5Ryy .aiConsultant-module__copyButton___qQI1k,
.aiConsultant-module__container___Wbcma .react-resizable .aiConsultant-module__innerContainer___-9WZO .aiConsultant-module__column___T3vGH .aiConsultant-module__conversation___ZaY81 .aiConsultant-module__innerConversationContainer___gV3aV .aiConsultant-module__message___VYn9V.aiConsultant-module__ada___G5Ryy .aiConsultant-module__feedbackButton___9xUam {
  font-size: 16px;
  width: fit-content;
}
.aiConsultant-module__container___Wbcma .react-resizable .aiConsultant-module__innerContainer___-9WZO .aiConsultant-module__column___T3vGH .aiConsultant-module__conversation___ZaY81 .aiConsultant-module__innerConversationContainer___gV3aV .aiConsultant-module__message___VYn9V.aiConsultant-module__ada___G5Ryy.aiConsultant-module__assistant___97WMT {
  flex-direction: column;
  gap: 16px;
}
.aiConsultant-module__container___Wbcma .react-resizable .aiConsultant-module__innerContainer___-9WZO .aiConsultant-module__column___T3vGH .aiConsultant-module__conversation___ZaY81 .aiConsultant-module__innerConversationContainer___gV3aV .aiConsultant-module__message___VYn9V.aiConsultant-module__ada___G5Ryy.aiConsultant-module__assistant___97WMT .aiConsultant-module__bottomColumn___9xRdY {
  align-items: center;
  border-top: 1px solid #667085;
  display: flex;
  gap: 16px;
  justify-content: flex-end;
  margin-left: 24px;
  padding-top: 16px;
  width: calc(100% - 24px);
}
.aiConsultant-module__container___Wbcma .react-resizable .aiConsultant-module__innerContainer___-9WZO .aiConsultant-module__column___T3vGH .aiConsultant-module__conversation___ZaY81 .aiConsultant-module__innerConversationContainer___gV3aV .aiConsultant-module__message___VYn9V.aiConsultant-module__ada___G5Ryy.aiConsultant-module__assistant___97WMT .aiConsultant-module__bottomColumn___9xRdY .aiConsultant-module__feedbackButton___9xUam {
  cursor: pointer;
  rotate: 180deg;
}
.aiConsultant-module__container___Wbcma .react-resizable .aiConsultant-module__innerContainer___-9WZO .aiConsultant-module__column___T3vGH .aiConsultant-module__conversation___ZaY81 .aiConsultant-module__innerConversationContainer___gV3aV .aiConsultant-module__message___VYn9V.aiConsultant-module__ada___G5Ryy.aiConsultant-module__assistant___97WMT .aiConsultant-module__bottomColumn___9xRdY .aiConsultant-module__feedbackButton___9xUam.aiConsultant-module__positive___FwpPW {
  rotate: none;
}
.aiConsultant-module__container___Wbcma .react-resizable .aiConsultant-module__innerContainer___-9WZO .aiConsultant-module__column___T3vGH .aiConsultant-module__conversation___ZaY81 .aiConsultant-module__innerConversationContainer___gV3aV .aiConsultant-module__message___VYn9V.aiConsultant-module__ada___G5Ryy.aiConsultant-module__assistant___97WMT .aiConsultant-module__leftColumn___TE5cd {
  width: 100%;
}
.aiConsultant-module__container___Wbcma .react-resizable .aiConsultant-module__innerContainer___-9WZO .aiConsultant-module__column___T3vGH .aiConsultant-module__conversation___ZaY81 .aiConsultant-module__innerConversationContainer___gV3aV .aiConsultant-module__message___VYn9V.aiConsultant-module__ada2___d0HZt.aiConsultant-module__assistant___97WMT {
  gap: 32px;
}
.aiConsultant-module__container___Wbcma .react-resizable .aiConsultant-module__innerContainer___-9WZO .aiConsultant-module__column___T3vGH .aiConsultant-module__conversation___ZaY81 .aiConsultant-module__innerConversationContainer___gV3aV .aiConsultant-module__message___VYn9V.aiConsultant-module__ada2___d0HZt.aiConsultant-module__assistant___97WMT .aiConsultant-module__bottomColumn___9xRdY {
  border-top: none;
  line-height: 16px;
  padding-top: 0;
}
.aiConsultant-module__container___Wbcma .react-resizable .aiConsultant-module__innerContainer___-9WZO .aiConsultant-module__column___T3vGH .aiConsultant-module__conversation___ZaY81 .aiConsultant-module__innerConversationContainer___gV3aV .aiConsultant-module__message___VYn9V.aiConsultant-module__assistant___97WMT {
  background: rgba(0, 0, 0, 0.4);
}
.aiConsultant-module__container___Wbcma .react-resizable .aiConsultant-module__innerContainer___-9WZO .aiConsultant-module__column___T3vGH .aiConsultant-module__conversation___ZaY81 .aiConsultant-module__innerConversationContainer___gV3aV .aiConsultant-module__message___VYn9V.aiConsultant-module__user___yOnv6 {
  background: rgba(255, 255, 255, 0.1);
}
.aiConsultant-module__container___Wbcma .react-resizable .aiConsultant-module__innerContainer___-9WZO .aiConsultant-module__column___T3vGH .aiConsultant-module__conversation___ZaY81 .aiConsultant-module__innerConversationContainer___gV3aV .aiConsultant-module__message___VYn9V .aiConsultant-module__rightColumn___8-RYs {
  width: 40px;
  border-left: 0;
}
.aiConsultant-module__container___Wbcma .react-resizable .aiConsultant-module__innerContainer___-9WZO .aiConsultant-module__column___T3vGH .aiConsultant-module__conversation___ZaY81 .aiConsultant-module__innerConversationContainer___gV3aV .aiConsultant-module__message___VYn9V .aiConsultant-module__leftColumn___TE5cd {
  width: calc(100% - 40px);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}
.aiConsultant-module__container___Wbcma .react-resizable .aiConsultant-module__innerContainer___-9WZO .aiConsultant-module__column___T3vGH .aiConsultant-module__conversation___ZaY81 .aiConsultant-module__innerConversationContainer___gV3aV .aiConsultant-module__message___VYn9V .aiConsultant-module__leftColumn___TE5cd .aiConsultant-module__hyperlink___nwgzK {
  color: white;
  font-weight: bold;
  text-decoration: underline;
}
.aiConsultant-module__container___Wbcma .react-resizable .aiConsultant-module__innerContainer___-9WZO .aiConsultant-module__column___T3vGH .aiConsultant-module__conversation___ZaY81 .aiConsultant-module__innerConversationContainer___gV3aV .aiConsultant-module__message___VYn9V .aiConsultant-module__leftColumn___TE5cd .aiConsultant-module__hyperlink___nwgzK .aiConsultant-module__icon___KZfOf {
  margin-left: 5px;
  font-size: 12px;
}
.aiConsultant-module__container___Wbcma .react-resizable .aiConsultant-module__innerContainer___-9WZO .aiConsultant-module__column___T3vGH .aiConsultant-module__conversation___ZaY81 .aiConsultant-module__innerConversationContainer___gV3aV .aiConsultant-module__message___VYn9V .aiConsultant-module__leftColumn___TE5cd .aiConsultant-module__cta___yQj34 {
  border-radius: 6px;
  display: inline-block;
  padding: 8px 14px;
  background-color: #00B08C;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: #000000;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  cursor: pointer;
}
.aiConsultant-module__container___Wbcma .react-resizable .aiConsultant-module__innerContainer___-9WZO .aiConsultant-module__column___T3vGH .aiConsultant-module__conversation___ZaY81 .aiConsultant-module__innerConversationContainer___gV3aV .aiConsultant-module__message___VYn9V .aiConsultant-module__leftColumn___TE5cd .aiConsultant-module__cta___yQj34.aiConsultant-module__disabled___L-io3 {
  background-color: #979797;
  cursor: default;
}
.aiConsultant-module__container___Wbcma .react-resizable .aiConsultant-module__innerContainer___-9WZO .aiConsultant-module__column___T3vGH .aiConsultant-module__conversation___ZaY81 .aiConsultant-module__innerConversationContainer___gV3aV .aiConsultant-module__message___VYn9V .aiConsultant-module__leftColumn___TE5cd .aiConsultant-module__iconContainer___j59tC {
  width: 16px;
  height: 16px;
  font-size: 16px;
}
.aiConsultant-module__container___Wbcma .react-resizable .aiConsultant-module__innerContainer___-9WZO .aiConsultant-module__column___T3vGH .aiConsultant-module__conversation___ZaY81 .aiConsultant-module__innerConversationContainer___gV3aV .aiConsultant-module__message___VYn9V .aiConsultant-module__leftColumn___TE5cd .aiConsultant-module__iconContainer___j59tC .aiConsultant-module__aiIcon___2c8-4 {
  width: 16px;
  height: 16px;
  vertical-align: top;
  margin-top: 2px;
  filter: brightness(0) invert(1);
}
.aiConsultant-module__container___Wbcma .react-resizable .aiConsultant-module__innerContainer___-9WZO .aiConsultant-module__column___T3vGH .aiConsultant-module__conversation___ZaY81 .aiConsultant-module__innerConversationContainer___gV3aV .aiConsultant-module__message___VYn9V .aiConsultant-module__leftColumn___TE5cd .aiConsultant-module__iconContainer___j59tC.aiConsultant-module__ada___G5Ryy {
  width: 24px;
  height: 24px;
  font-size: 24px;
}
.aiConsultant-module__container___Wbcma .react-resizable .aiConsultant-module__innerContainer___-9WZO .aiConsultant-module__column___T3vGH .aiConsultant-module__conversation___ZaY81 .aiConsultant-module__innerConversationContainer___gV3aV .aiConsultant-module__message___VYn9V .aiConsultant-module__leftColumn___TE5cd .aiConsultant-module__iconContainer___j59tC.aiConsultant-module__ada___G5Ryy .aiConsultant-module__aiIcon___2c8-4 {
  width: 20px;
  height: 20px;
  vertical-align: top;
  margin-top: 2px;
}
.aiConsultant-module__container___Wbcma .react-resizable .aiConsultant-module__innerContainer___-9WZO .aiConsultant-module__column___T3vGH .aiConsultant-module__conversation___ZaY81 .aiConsultant-module__innerConversationContainer___gV3aV .aiConsultant-module__message___VYn9V .aiConsultant-module__leftColumn___TE5cd .aiConsultant-module__iconContainer___j59tC.aiConsultant-module__ada___G5Ryy .text-avatar,
.aiConsultant-module__container___Wbcma .react-resizable .aiConsultant-module__innerContainer___-9WZO .aiConsultant-module__column___T3vGH .aiConsultant-module__conversation___ZaY81 .aiConsultant-module__innerConversationContainer___gV3aV .aiConsultant-module__message___VYn9V .aiConsultant-module__leftColumn___TE5cd .aiConsultant-module__iconContainer___j59tC.aiConsultant-module__ada___G5Ryy .image-circular-avatar {
  width: 24px;
  height: 24px;
  font-size: 14px;
  margin: 0;
}
.aiConsultant-module__container___Wbcma .react-resizable .aiConsultant-module__innerContainer___-9WZO .aiConsultant-module__column___T3vGH .aiConsultant-module__conversation___ZaY81 .aiConsultant-module__innerConversationContainer___gV3aV .aiConsultant-module__message___VYn9V .aiConsultant-module__leftColumn___TE5cd .aiConsultant-module__text___qWX9- {
  color: #ffffff;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 100%;
}
.aiConsultant-module__container___Wbcma .react-resizable .aiConsultant-module__innerContainer___-9WZO .aiConsultant-module__column___T3vGH .aiConsultant-module__conversation___ZaY81 .aiConsultant-module__innerConversationContainer___gV3aV .aiConsultant-module__message___VYn9V .aiConsultant-module__extraQuestions___xwH0O {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 30px;
  align-self: stretch;
}
.aiConsultant-module__container___Wbcma .react-resizable .aiConsultant-module__innerContainer___-9WZO .aiConsultant-module__column___T3vGH .aiConsultant-module__conversation___ZaY81 .aiConsultant-module__innerConversationContainer___gV3aV .aiConsultant-module__message___VYn9V .aiConsultant-module__extraQuestions___xwH0O .aiConsultant-module__text___qWX9- {
  color: #98A2B3;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.aiConsultant-module__container___Wbcma .react-resizable .aiConsultant-module__innerContainer___-9WZO .aiConsultant-module__column___T3vGH .aiConsultant-module__conversation___ZaY81 .aiConsultant-module__innerConversationContainer___gV3aV .aiConsultant-module__message___VYn9V .aiConsultant-module__extraQuestions___xwH0O .aiConsultant-module__questionsContainer___ILGBJ {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}
.aiConsultant-module__container___Wbcma .react-resizable .aiConsultant-module__innerContainer___-9WZO .aiConsultant-module__column___T3vGH .aiConsultant-module__conversation___ZaY81 .aiConsultant-module__innerConversationContainer___gV3aV .aiConsultant-module__message___VYn9V .aiConsultant-module__extraQuestions___xwH0O .aiConsultant-module__questionsContainer___ILGBJ .aiConsultant-module__question___K6Iui {
  display: flex;
  padding: 4px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  border-radius: 20px;
  border: #EAECF0;
  background-color: #ffffff;
  cursor: pointer;
  color: #475467;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.aiConsultant-module__container___Wbcma .react-resizable .aiConsultant-module__innerContainer___-9WZO .aiConsultant-module__column___T3vGH .aiConsultant-module__conversation___ZaY81 .aiConsultant-module__innerConversationContainer___gV3aV .aiConsultant-module__message___VYn9V .aiConsultant-module__extraQuestions___xwH0O .aiConsultant-module__questionsContainer___ILGBJ .aiConsultant-module__question___K6Iui:hover {
  background-color: #736DBA;
  color: #ffffff;
}
.aiConsultant-module__container___Wbcma .react-resizable .aiConsultant-module__innerContainer___-9WZO .aiConsultant-module__column___T3vGH .aiConsultant-module__conversation___ZaY81 .aiConsultant-module__innerConversationContainer___gV3aV .aiConsultant-module__fetchingAiLogoContainer___G-WeO {
  width: 40px;
  height: 40px;
  margin-left: 8px;
}
.aiConsultant-module__container___Wbcma .react-resizable .aiConsultant-module__innerContainer___-9WZO .aiConsultant-module__column___T3vGH .aiConsultant-module__conversation___ZaY81 .aiConsultant-module__innerConversationContainer___gV3aV .aiConsultant-module__fetchingAiLogoContainer___G-WeO .aiConsultant-module__fetchingAiLogo___Er7WL {
  width: 100%;
}
.aiConsultant-module__container___Wbcma .react-resizable .aiConsultant-module__innerContainer___-9WZO .aiConsultant-module__column___T3vGH .aiConsultant-module__conversation___ZaY81 .aiConsultant-module__conversationEnd___aG0GI {
  height: 0;
  width: 100%;
}
.aiConsultant-module__container___Wbcma .react-resizable .aiConsultant-module__innerContainer___-9WZO .aiConsultant-module__column___T3vGH .aiConsultant-module__inputContainer___g0NBM {
  height: 85px;
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 16px;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}
.aiConsultant-module__container___Wbcma .react-resizable .aiConsultant-module__innerContainer___-9WZO .aiConsultant-module__column___T3vGH .aiConsultant-module__inputContainer___g0NBM.aiConsultant-module__ada2___d0HZt {
  height: unset;
}
.aiConsultant-module__container___Wbcma .react-resizable .aiConsultant-module__innerContainer___-9WZO .aiConsultant-module__column___T3vGH .aiConsultant-module__inputContainer___g0NBM .aiConsultant-module__inputInnerContainer___R3ocH {
  max-width: 784px;
  width: 100%;
  display: flex;
  gap: 8px;
  flex-direction: column;
}
.aiConsultant-module__container___Wbcma .react-resizable .aiConsultant-module__innerContainer___-9WZO .aiConsultant-module__column___T3vGH .aiConsultant-module__inputContainer___g0NBM .aiConsultant-module__inputInnerContainer___R3ocH .aiConsultant-module__input___qi5MO.ant-input-affix-wrapper-focused {
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0 0 0 4px rgba(231, 231, 240, 0.25);
}
.aiConsultant-module__container___Wbcma .react-resizable .aiConsultant-module__innerContainer___-9WZO .aiConsultant-module__column___T3vGH .aiConsultant-module__inputContainer___g0NBM .aiConsultant-module__inputInnerContainer___R3ocH .aiConsultant-module__textContainer___4-CDI {
  position: relative;
}
.aiConsultant-module__container___Wbcma .react-resizable .aiConsultant-module__innerContainer___-9WZO .aiConsultant-module__column___T3vGH .aiConsultant-module__inputContainer___g0NBM .aiConsultant-module__inputInnerContainer___R3ocH .aiConsultant-module__textContainer___4-CDI .aiConsultant-module__textArea___w6VUO {
  padding-right: 30px !important;
  scroll-behavior: smooth;
  scrollbar-color: #D0D5DD rgba(249, 250, 251, 0.1);
}
.aiConsultant-module__container___Wbcma .react-resizable .aiConsultant-module__innerContainer___-9WZO .aiConsultant-module__column___T3vGH .aiConsultant-module__inputContainer___g0NBM .aiConsultant-module__inputInnerContainer___R3ocH .aiConsultant-module__textContainer___4-CDI .aiConsultant-module__textArea___w6VUO::-webkit-scrollbar-track {
  background-color: rgba(249, 250, 251, 0.1);
}
.aiConsultant-module__container___Wbcma .react-resizable .aiConsultant-module__innerContainer___-9WZO .aiConsultant-module__column___T3vGH .aiConsultant-module__inputContainer___g0NBM .aiConsultant-module__inputInnerContainer___R3ocH .aiConsultant-module__textContainer___4-CDI .aiConsultant-module__textArea___w6VUO::-webkit-scrollbar-thumb {
  background: #D0D5DD;
}
.aiConsultant-module__container___Wbcma .react-resizable .aiConsultant-module__innerContainer___-9WZO .aiConsultant-module__column___T3vGH .aiConsultant-module__inputContainer___g0NBM .aiConsultant-module__inputInnerContainer___R3ocH .aiConsultant-module__textContainer___4-CDI .aiConsultant-module__textArea___w6VUO:focus {
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0 0 0 4px rgba(231, 231, 240, 0.25);
}
.aiConsultant-module__container___Wbcma .react-resizable .aiConsultant-module__innerContainer___-9WZO .aiConsultant-module__column___T3vGH .aiConsultant-module__inputContainer___g0NBM .aiConsultant-module__inputInnerContainer___R3ocH .aiConsultant-module__textContainer___4-CDI .aiConsultant-module__actionButton___uOYpz {
  bottom: 2px;
  position: absolute;
  right: 12px;
}
.aiConsultant-module__container___Wbcma .react-resizable .aiConsultant-module__innerContainer___-9WZO .aiConsultant-module__column___T3vGH .aiConsultant-module__inputContainer___g0NBM .aiConsultant-module__inputInnerContainer___R3ocH .aiConsultant-module__action___DvJDc {
  cursor: pointer;
}
.aiConsultant-module__container___Wbcma .react-resizable .aiConsultant-module__innerContainer___-9WZO .aiConsultant-module__column___T3vGH .aiConsultant-module__inputContainer___g0NBM .aiConsultant-module__inputInnerContainer___R3ocH .aiConsultant-module__disclaimer___GkWsy {
  color: #D0D5DD;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
}
.aiConsultant-module__container___Wbcma .react-resizable .aiConsultant-module__innerContainer___-9WZO .aiConsultant-module__column___T3vGH .aiConsultant-module__popularTopics___bEjuC {
  width: 100%;
  padding: 16px 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  flex: 1 0 0;
  align-self: stretch;
  overflow: hidden;
  position: relative;
}
.aiConsultant-module__container___Wbcma .react-resizable .aiConsultant-module__innerContainer___-9WZO .aiConsultant-module__column___T3vGH .aiConsultant-module__popularTopics___bEjuC .aiConsultant-module__popularTopicsHeader___lxhFh {
  padding: 0 16px;
  display: flex;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}
.aiConsultant-module__container___Wbcma .react-resizable .aiConsultant-module__innerContainer___-9WZO .aiConsultant-module__column___T3vGH .aiConsultant-module__popularTopics___bEjuC .aiConsultant-module__popularTopicsHeader___lxhFh .aiConsultant-module__title___DsUVP {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
}
.aiConsultant-module__container___Wbcma .react-resizable .aiConsultant-module__innerContainer___-9WZO .aiConsultant-module__column___T3vGH .aiConsultant-module__popularTopics___bEjuC .aiConsultant-module__popularTopicsHeader___lxhFh .aiConsultant-module__title___DsUVP .aiConsultant-module__main___C2zWM {
  color: #5AC7FA;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 2.4px;
  text-transform: uppercase;
}
.aiConsultant-module__container___Wbcma .react-resizable .aiConsultant-module__innerContainer___-9WZO .aiConsultant-module__column___T3vGH .aiConsultant-module__popularTopics___bEjuC .aiConsultant-module__popularTopicsHeader___lxhFh .aiConsultant-module__title___DsUVP .aiConsultant-module__secondary___XQHzC {
  font-family: Source Sans Pro;
  color: #EAECF0;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.aiConsultant-module__container___Wbcma .react-resizable .aiConsultant-module__innerContainer___-9WZO .aiConsultant-module__column___T3vGH .aiConsultant-module__popularTopics___bEjuC .aiConsultant-module__popularTopicsHeader___lxhFh .aiConsultant-module__title___DsUVP .aiConsultant-module__secondary___XQHzC.aiConsultant-module__hidden___i-AKZ {
  display: none;
}
.aiConsultant-module__container___Wbcma .react-resizable .aiConsultant-module__innerContainer___-9WZO .aiConsultant-module__column___T3vGH .aiConsultant-module__popularTopics___bEjuC .aiConsultant-module__popularTopicsHeader___lxhFh .aiConsultant-module__close___7Ecs1 {
  font-size: 16px;
  cursor: pointer;
}
.aiConsultant-module__container___Wbcma .react-resizable .aiConsultant-module__innerContainer___-9WZO .aiConsultant-module__column___T3vGH .aiConsultant-module__popularTopics___bEjuC .aiConsultant-module__links___JKS-G {
  padding: 0 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  overflow: auto;
}
.aiConsultant-module__container___Wbcma .react-resizable .aiConsultant-module__innerContainer___-9WZO .aiConsultant-module__column___T3vGH .aiConsultant-module__popularTopics___bEjuC .aiConsultant-module__links___JKS-G.aiConsultant-module__hidden___i-AKZ {
  height: 0;
  transition: 300ms height;
}
.aiConsultant-module__container___Wbcma .react-resizable .aiConsultant-module__innerContainer___-9WZO .aiConsultant-module__column___T3vGH .aiConsultant-module__popularTopics___bEjuC .aiConsultant-module__links___JKS-G::-webkit-scrollbar-track {
  background-color: rgba(249, 250, 251, 0.1);
}
.aiConsultant-module__container___Wbcma .react-resizable .aiConsultant-module__innerContainer___-9WZO .aiConsultant-module__column___T3vGH .aiConsultant-module__popularTopics___bEjuC .aiConsultant-module__links___JKS-G .aiConsultant-module__link___7EPIQ {
  display: flex;
  padding: 4px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  border-radius: 20px;
  border: 1px solid #EAECF0;
  background: #ffffff;
  color: #475467;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  cursor: pointer;
}
.aiConsultant-module__container___Wbcma .react-resizable .aiConsultant-module__innerContainer___-9WZO .aiConsultant-module__column___T3vGH .aiConsultant-module__popularTopics___bEjuC .aiConsultant-module__links___JKS-G .aiConsultant-module__link___7EPIQ:hover {
  background: rgba(0, 0, 0, 0.4);
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
  color: #ffffff;
  border-color: transparent;
}
.aiConsultant-module__container___Wbcma .react-resizable .aiConsultant-module__innerContainer___-9WZO .aiConsultant-module__column___T3vGH .aiConsultant-module__popularTopics___bEjuC .aiConsultant-module__links___JKS-G .aiConsultant-module__link___7EPIQ.aiConsultant-module__disabled___L-io3 {
  pointer-events: none;
  opacity: 0.4;
}
.aiConsultant-module__container___Wbcma .react-resizable .aiConsultant-module__innerContainer___-9WZO .aiConsultant-module__rightColumn___8-RYs {
  border-left: 1px solid #98A2B3;
  width: 319px;
  min-width: 319px;
  max-width: 319px;
  margin-right: -319px;
  transition: 300ms margin-right;
}
.aiConsultant-module__container___Wbcma .react-resizable .aiConsultant-module__innerContainer___-9WZO .aiConsultant-module__rightColumn___8-RYs.aiConsultant-module__chatBoxMode___hFZ6o {
  width: 100%;
  max-width: 100%;
  margin-right: 0;
  border: none;
  transition: 300ms height;
}
.aiConsultant-module__container___Wbcma .react-resizable .aiConsultant-module__innerContainer___-9WZO .aiConsultant-module__rightColumn___8-RYs.aiConsultant-module__chatBoxMode___hFZ6o.aiConsultant-module__chatBoxRightColumn___6dkzA {
  height: 48px;
}
.aiConsultant-module__container___Wbcma .react-resizable .aiConsultant-module__innerContainer___-9WZO .aiConsultant-module__rightColumn___8-RYs.aiConsultant-module__chatBoxRightColumn___6dkzA {
  height: 100%;
  margin-right: 0;
}
.aiConsultant-module__container___Wbcma .react-resizable .aiConsultant-module__innerContainer___-9WZO .aiConsultant-module__rightColumn___8-RYs.aiConsultant-module__chatBoxRightColumn___6dkzA .aiConsultant-module__popularTopics___bEjuC {
  background-color: #1D2939;
  border-bottom: 1px solid #98A2B3;
  max-height: 48px;
}
.aiConsultant-module__container___Wbcma .react-resizable .aiConsultant-module__innerContainer___-9WZO .aiConsultant-module__rightColumn___8-RYs.aiConsultant-module__chatBoxRightColumn___6dkzA .aiConsultant-module__popularTopics___bEjuC .aiConsultant-module__popularTopicsHeader___lxhFh {
  line-height: 15px;
}
.aiConsultant-module__container___Wbcma .react-resizable .aiConsultant-module__innerContainer___-9WZO .aiConsultant-module__rightColumn___8-RYs.aiConsultant-module__chatBoxRightColumn___6dkzA.aiConsultant-module__shown___sOF0y {
  border-bottom: none;
}
.aiConsultant-module__container___Wbcma .react-resizable .aiConsultant-module__innerContainer___-9WZO .aiConsultant-module__rightColumn___8-RYs.aiConsultant-module__chatBoxRightColumn___6dkzA.aiConsultant-module__shown___sOF0y .aiConsultant-module__popularTopics___bEjuC {
  background-color: unset;
  border-bottom: none;
  max-height: unset;
}
.aiConsultant-module__container___Wbcma .react-resizable .aiConsultant-module__innerContainer___-9WZO .aiConsultant-module__rightColumn___8-RYs.aiConsultant-module__chatBoxModeOpened___GFe8R {
  background-color: transparent;
  border-bottom: 1px solid #98A2B3;
}
.aiConsultant-module__container___Wbcma .react-resizable .aiConsultant-module__innerContainer___-9WZO .aiConsultant-module__rightColumn___8-RYs.aiConsultant-module__chatBoxModeOpened___GFe8R.aiConsultant-module__shown___sOF0y {
  border-bottom: 1px solid #98A2B3;
  height: 220px;
}
.aiConsultant-module__container___Wbcma .react-resizable .aiConsultant-module__innerContainer___-9WZO .aiConsultant-module__rightColumn___8-RYs.aiConsultant-module__chatBoxModeOpened___GFe8R.aiConsultant-module__shown___sOF0y .aiConsultant-module__popularTopics___bEjuC {
  max-height: 220px;
}
.aiConsultant-module__container___Wbcma .react-resizable .aiConsultant-module__innerContainer___-9WZO .aiConsultant-module__rightColumn___8-RYs.aiConsultant-module__shown___sOF0y {
  margin-right: 0;
}
.aiConsultant-module__container___Wbcma .react-resizable .aiConsultant-module__innerContainer___-9WZO .aiConsultant-module__rightColumn___8-RYs.aiConsultant-module__ada___G5Ryy {
  margin-right: -304px;
}
.aiConsultant-module__container___Wbcma .aiConsultant-module__reactResizableHandle___-p8zw {
  position: absolute;
  display: flex;
  width: 40px;
  top: 0;
  right: calc(50% - 20px);
  background-position: bottom right;
  cursor: n-resize;
  height: 24px;
  justify-content: center;
}
.aiConsultant-module__container___Wbcma .aiConsultant-module__reactResizableHandle___-p8zw::before, .aiConsultant-module__container___Wbcma .aiConsultant-module__reactResizableHandle___-p8zw::after {
  content: "";
  width: 29px;
  height: 2px;
  background-color: #D0D5DD;
}
.aiConsultant-module__container___Wbcma .aiConsultant-module__reactResizableHandle___-p8zw::before {
  top: 8px;
  position: absolute;
}
.aiConsultant-module__container___Wbcma .aiConsultant-module__reactResizableHandle___-p8zw::after {
  top: 14px;
  position: absolute;
}

.aiConsultant-module__disclaimerModal___EyuJt .aiConsultant-module__text___qWX9- {
  color: #475467;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.aiConsultant-module__openTrigger___HZbls {
  position: absolute;
  right: 0;
  bottom: 86px;
  display: flex;
  padding: 8px 16px;
  align-items: center;
  gap: 16px;
  border-radius: 12px 0px 0px 0px;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  justify-content: center;
  line-height: normal;
  opacity: 0;
  transition: 100ms opacity;
  cursor: pointer;
}
.aiConsultant-module__openTrigger___HZbls.aiConsultant-module__shown___sOF0y {
  opacity: 1;
  transition: 1000ms opacity;
}
.aiConsultant-module__openTrigger___HZbls .aiConsultant-module__icon___KZfOf {
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.aiConsultant-module__openTrigger___HZbls .aiConsultant-module__title___DsUVP {
  color: #5AC7FA;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 2.4px;
  text-transform: uppercase;
}`;
document.head
    .appendChild(document.createElement("style"))
    .appendChild(document.createTextNode(css));
export {css};
export default {
  "tooltip": "aiConsultant-module__tooltip___Tdj1m",
  "openChatButton": "aiConsultant-module__openChatButton___Ons5b",
  "buttonInnerContainer": "aiConsultant-module__buttonInnerContainer___8OWP3",
  "icon": "aiConsultant-module__icon___KZfOf",
  "opened": "aiConsultant-module__opened___C7OPF",
  "text": "aiConsultant-module__text___qWX9-",
  "innerText": "aiConsultant-module__innerText___6SgV7",
  "betaTag": "aiConsultant-module__betaTag___rHzoW",
  "container": "aiConsultant-module__container___Wbcma",
  "chatBoxContainer": "aiConsultant-module__chatBoxContainer___1tUiy",
  "chatBoxWithToolBoxOpened": "aiConsultant-module__chatBoxWithToolBoxOpened___KMG2w",
  "chatBoxWithToolBoxMinimized": "aiConsultant-module__chatBoxWithToolBoxMinimized___KF2zG",
  "react-resizable": "react-resizable",
  "header": "aiConsultant-module__header___Zomk6",
  "innerHeader": "aiConsultant-module__innerHeader___4wS0z",
  "logo": "aiConsultant-module__logo___YqpY7",
  "title": "aiConsultant-module__title___DsUVP",
  "actions": "aiConsultant-module__actions___QkliU",
  "chatBoxActions": "aiConsultant-module__chatBoxActions___r-Gft",
  "action": "aiConsultant-module__action___DvJDc",
  "ant-btn": "ant-btn",
  "ant-btn-default": "ant-btn-default",
  "chatBoxAction": "aiConsultant-module__chatBoxAction___BwYDn",
  "sx-icon": "sx-icon",
  "sx-window": "sx-window",
  "active": "aiConsultant-module__active___W7y0j",
  "themeToggle": "aiConsultant-module__themeToggle___UvrN-",
  "ant-switch": "ant-switch",
  "ant-switch-handle": "ant-switch-handle",
  "ant-switch-inner": "ant-switch-inner",
  "ant-switch-checked": "ant-switch-checked",
  "innerContainer": "aiConsultant-module__innerContainer___-9WZO",
  "chatBoxInnerContainer": "aiConsultant-module__chatBoxInnerContainer___NfEV8",
  "column": "aiConsultant-module__column___T3vGH",
  "chatBoxColumn": "aiConsultant-module__chatBoxColumn___UwXqB",
  "conversation": "aiConsultant-module__conversation___ZaY81",
  "chatBox": "aiConsultant-module__chatBox___EcUwE",
  "ada2": "aiConsultant-module__ada2___d0HZt",
  "innerConversationContainer": "aiConsultant-module__innerConversationContainer___gV3aV",
  "chatBoxInnerConversationContainer": "aiConsultant-module__chatBoxInnerConversationContainer___BTNq8",
  "loader": "aiConsultant-module__loader___t8NGe",
  "loaderChatBox": "aiConsultant-module__loaderChatBox___aSk3I",
  "message": "aiConsultant-module__message___VYn9V",
  "ada": "aiConsultant-module__ada___G5Ryy",
  "copyButton": "aiConsultant-module__copyButton___qQI1k",
  "feedbackButton": "aiConsultant-module__feedbackButton___9xUam",
  "assistant": "aiConsultant-module__assistant___97WMT",
  "bottomColumn": "aiConsultant-module__bottomColumn___9xRdY",
  "positive": "aiConsultant-module__positive___FwpPW",
  "leftColumn": "aiConsultant-module__leftColumn___TE5cd",
  "user": "aiConsultant-module__user___yOnv6",
  "rightColumn": "aiConsultant-module__rightColumn___8-RYs",
  "hyperlink": "aiConsultant-module__hyperlink___nwgzK",
  "cta": "aiConsultant-module__cta___yQj34",
  "disabled": "aiConsultant-module__disabled___L-io3",
  "iconContainer": "aiConsultant-module__iconContainer___j59tC",
  "aiIcon": "aiConsultant-module__aiIcon___2c8-4",
  "text-avatar": "text-avatar",
  "image-circular-avatar": "image-circular-avatar",
  "extraQuestions": "aiConsultant-module__extraQuestions___xwH0O",
  "questionsContainer": "aiConsultant-module__questionsContainer___ILGBJ",
  "question": "aiConsultant-module__question___K6Iui",
  "fetchingAiLogoContainer": "aiConsultant-module__fetchingAiLogoContainer___G-WeO",
  "fetchingAiLogo": "aiConsultant-module__fetchingAiLogo___Er7WL",
  "conversationEnd": "aiConsultant-module__conversationEnd___aG0GI",
  "inputContainer": "aiConsultant-module__inputContainer___g0NBM",
  "inputInnerContainer": "aiConsultant-module__inputInnerContainer___R3ocH",
  "input": "aiConsultant-module__input___qi5MO",
  "ant-input-affix-wrapper-focused": "ant-input-affix-wrapper-focused",
  "textContainer": "aiConsultant-module__textContainer___4-CDI",
  "textArea": "aiConsultant-module__textArea___w6VUO",
  "actionButton": "aiConsultant-module__actionButton___uOYpz",
  "disclaimer": "aiConsultant-module__disclaimer___GkWsy",
  "popularTopics": "aiConsultant-module__popularTopics___bEjuC",
  "popularTopicsHeader": "aiConsultant-module__popularTopicsHeader___lxhFh",
  "main": "aiConsultant-module__main___C2zWM",
  "secondary": "aiConsultant-module__secondary___XQHzC",
  "hidden": "aiConsultant-module__hidden___i-AKZ",
  "close": "aiConsultant-module__close___7Ecs1",
  "links": "aiConsultant-module__links___JKS-G",
  "link": "aiConsultant-module__link___7EPIQ",
  "chatBoxMode": "aiConsultant-module__chatBoxMode___hFZ6o",
  "chatBoxRightColumn": "aiConsultant-module__chatBoxRightColumn___6dkzA",
  "shown": "aiConsultant-module__shown___sOF0y",
  "chatBoxModeOpened": "aiConsultant-module__chatBoxModeOpened___GFe8R",
  "reactResizableHandle": "aiConsultant-module__reactResizableHandle___-p8zw",
  "disclaimerModal": "aiConsultant-module__disclaimerModal___EyuJt",
  "openTrigger": "aiConsultant-module__openTrigger___HZbls",
  "slide-right": "aiConsultant-module__slide-right___Ida8A"
};