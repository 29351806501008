import { FEATURES } from '../../../common/accessControl.helper';
import { BlockByFeatureType, BlockedByFeature, Features, isFeatureEnabled } from '../../../featureToggles/features';
import { getAccountTypeWhereFeatureIsAvailable, isFeatureAvailableByAccountTier } from '../../../selectors/accessControl';
import * as projectViews from '../../project/common/viewTypes';
import { UpgradeTooltipTextPerTier } from '../accessControl';
import { AccountsSupported } from '../educationalModalBase/helper';

interface IDisabledInformation {
  needUpgrade: boolean;
  disabledText?: React.ReactNode;
}

interface IParams {
  key: string;
  accessType: string;
  isPastProject: boolean;
  isDisabled: boolean;
  disabledTooltip?: React.ReactNode;
}

export const getDisabledInformationPerProjectView = (params: IParams): IDisabledInformation | BlockByFeatureType => {
  const { key, accessType, isPastProject, isDisabled, disabledTooltip } = params;
  if (!isFeatureEnabled(Features.TrialsOfTiers)) {
    return BlockedByFeature;
  }

  let isFeatureAvailableOnAccountTier = false;
  let tier: AccountsSupported | undefined = undefined;

  switch (key) {
    case projectViews.LANGUAGES:
      isFeatureAvailableOnAccountTier = isFeatureAvailableByAccountTier(FEATURES.LANGUAGE_MANAGEMENT, accessType);
      tier = getAccountTypeWhereFeatureIsAvailable(FEATURES.LANGUAGE_MANAGEMENT, accessType);
      break;
    default:
      return {
        needUpgrade: isDisabled,
        disabledText: disabledTooltip,
      };
  }
  if (isDisabled && !isFeatureAvailableOnAccountTier) {
    return {
      needUpgrade: true,
      disabledText: <UpgradeTooltipTextPerTier accessType={tier || ''} isPastProject={false} />,
    };
  }

  return {
    needUpgrade: isPastProject ? !isFeatureAvailableOnAccountTier : isDisabled,
    disabledText: <UpgradeTooltipTextPerTier accessType={tier || ''} isPastProject={isPastProject} />,
  };
};