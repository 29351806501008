const css = `/** do not use this yet */
.index-module__description___pMc-1 {
  -webkit-font-smoothing: antialiased;
  font-family: "Source Sans Pro";
}

.index-module__description___pMc-1 {
  font-size: 16px;
  line-height: normal;
}

.index-module__description___pMc-1 {
  font-weight: 400;
}

/* Desktop - Eyebrow/Small - Semi-Bold */
.index-module__blocked___gqjlB {
  opacity: 0.9;
}

.index-module__disabled-component___ZVEmU {
  opacity: 0.6;
  cursor: not-allowed;
}

.index-module__description___pMc-1 {
  -webkit-font-smoothing: antialiased;
  font-family: "Source Sans Pro";
}

.index-module__description___pMc-1 {
  font-size: 16px;
  line-height: normal;
}

.index-module__description___pMc-1 {
  font-weight: 400;
}

/* Desktop - Eyebrow/Small - Semi-Bold */
/** do not use this yet */
.index-module__appContainer___a6Tyv {
  height: 100vh;
  display: flex;
  flex-direction: inherit;
  overflow: hidden;
}
.index-module__appContainer___a6Tyv .index-module__appContent___lyR7j {
  height: 100%;
  display: flex;
  flex-direction: column;
  width: calc(100vw - 218px);
}

.index-module__description___pMc-1 {
  text-align: center;
  color: #475467;
}

.index-module__modalActions___7PcAa {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}
.index-module__modalActions___7PcAa .index-module__button___xgCdN {
  width: 204px;
}

.index-module__titleContent___b-adm {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.index-module__circle___ZlKya {
  background-color: #CCCBE7;
  width: 58px;
  height: 58px;
  border-radius: 29px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}
.index-module__circle___ZlKya .index-module__innerCircle___voB8N {
  background-color: #EAECF0;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}`;
document.head
    .appendChild(document.createElement("style"))
    .appendChild(document.createTextNode(css));
export {css};
export default {
  "description": "index-module__description___pMc-1",
  "blocked": "index-module__blocked___gqjlB",
  "disabled-component": "index-module__disabled-component___ZVEmU",
  "appContainer": "index-module__appContainer___a6Tyv",
  "appContent": "index-module__appContent___lyR7j",
  "modalActions": "index-module__modalActions___7PcAa",
  "button": "index-module__button___xgCdN",
  "titleContent": "index-module__titleContent___b-adm",
  "circle": "index-module__circle___ZlKya",
  "innerCircle": "index-module__innerCircle___voB8N"
};