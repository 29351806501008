import React, { Component } from 'react';

import { connect } from 'react-redux';

import { getQueryParam } from '../../services/queryParameters';
import { validateImpersonateCode } from '../../actions/security';
import { isValidatingImpersonationCode } from '../../selectors/security/user';

import Loading from './loading';

interface IState {
  code: string | null;
}

interface IProps {
  validateImpersonateCode: typeof validateImpersonateCode;
  isCheckingCode: boolean;
}

class ImpersonateCodeListener extends Component<IProps, IState> {
  public constructor(props: IProps) {
    super(props);

    this.state = { code: getQueryParam('sx_icode') };
  }

  public componentDidMount() {
    if (this.state.code) {
      this.props.validateImpersonateCode(this.state.code);
    }
  }

  public render(): React.ReactNode {
    const { isCheckingCode = false, children } = this.props;

    return (isCheckingCode ? <Loading /> : <>{children}</>);
  }
}

const mapStateToProps = (state) => {
  const isCheckingCode = isValidatingImpersonationCode(state);
  return {
    isCheckingCode,
  };
};

const mapDispatchToProps = { validateImpersonateCode };

const connected = connect(mapStateToProps, mapDispatchToProps)(ImpersonateCodeListener);

export { connected as ImpersonateCodeListener };
