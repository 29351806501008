import { IGlobalState } from '../../interfaces/state';
import { BlockByFeatureType, BlockedByFeature, Features, isFeatureEnabled } from '../../../featureToggles/features';
import { ToolboxState } from '../../../components/analysis/toolbox/common/utils';

export const getToolboxViewState = (state: IGlobalState): ToolboxState | BlockByFeatureType => {
  if (!isFeatureEnabled(Features.ada2)) {
    return BlockedByFeature;
  }
  return state.analysis.toolbox.view;
};
