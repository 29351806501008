import { Features, isFeatureEnabled } from '../../../../featureToggles/features';
import { FEATURES } from '../../../../common/accessControl.helper';
import { EducationModalBase } from '../../../common/educationalModalBase';

import styles from './index.module.scss';

interface IProps {
  onClose: () => void;
  featureName: FEATURES;
}

export const EducationModalForAnalysis: React.FunctionComponent<IProps> = (props: IProps) => {
  if (!isFeatureEnabled(Features.TrialsOfTiers)) {
    return null;
  }

  const { featureName } = props;

  return (
    <EducationModalBase
      onClose={props.onClose}
      featureName={featureName}
      withBackButton={false}
      className={styles.icon}
      showCloseIcon
    />
  );
};
