import React, { Component } from 'react';

import { connect } from 'react-redux';
import NotificationSystem from 'react-notification-system';

import { isMobileOrTablet } from '../../services/platform';
import {
  disposeNotification,
  showNotification,
} from '../../actions/notifications';
import { FeatureToggle, Off, On } from '../../featureToggles';
import { Features } from '../../featureToggles/features';

import { capitalizeFirstLetter } from './utils/string';
import { SxIcon } from './sxLibrary';
import './notifications.scss';
import style, { defaultColors } from './notificationsStyle';

import colors from '../../sxVariablesExported.module.scss';

export class Notifications extends Component {
  constructor(props) {
    super(props);
    this.state = {
      position: isMobileOrTablet() ? 'bc' : 'tc',
    };
  }

  componentDidUpdate() {
    if (this.props.newNotification) {
      let notification = this.props.newNotification;
      const { position } = this.state;
      if (!notification.position) {
        notification = { ...notification, position };
      }
      notification.message = this.getMessageWithIcon(
        notification.message,
        notification.level,
      );
      this.refs.notificationSystem.addNotification(notification);
      this.props.disposeNotification();
    }
  }

  getMessageWithIcon(msg, level) {
    return (
      <div>
        {this.renderIcon(level)}
        <div className="messageContainer">
          <span className="level">{capitalizeFirstLetter(level)}</span> {msg}
        </div>
        <div className="dismissButton">
          <SxIcon name="sx-cross" color={colors.f7DarkInactiveText} noFloat />
        </div>
      </div>
    );
  }

  renderIcon(level) {
    switch (level) {
      case 'success':
        return (
          <div className="iconContainer" style={{ backgroundColor: defaultColors.success.iconShadow }}>
            <div style={{ backgroundColor: defaultColors.success.hex }}>
              <SxIcon name="sx-check" color="white" noFloat fontSize="9px" />
            </div>
          </div>
        );
      case 'warning':
        return (
          <div className="iconContainer" style={{ backgroundColor: defaultColors.warning.iconShadow }}>
            <div style={{ backgroundColor: defaultColors.warning.hex }}>
              <SxIcon name="sx-icon-exclamation-point" color="white" noFloat />
            </div>
          </div>
        );
      case 'info':
        return (
          <div className="iconContainer" style={{ backgroundColor: defaultColors.info.iconShadow }}>
            <div style={{ backgroundColor: defaultColors.info.hex }}>
              <SxIcon name="sx-icon-flag" color="white" noFloat />
            </div>
          </div>
        );
      case 'error':
        return (
          <div className="iconContainer" style={{ backgroundColor: defaultColors.error.iconShadow }}>
            <div style={{ backgroundColor: defaultColors.error.hex }}>
              <SxIcon name="sx-cross" color="white" noFloat />
            </div>
          </div>
        );
      default:
        return null;
    }
  }

  render() {
    return <FeatureToggle feature={Features.uirefresh}>
      <Off>
        <NotificationSystem ref="notificationSystem" style={style} />
      </Off>
      <On>
        <div className={Features.uirefresh}>
          <NotificationSystem ref="notificationSystem" style={style} />
        </div>
      </On>
    </FeatureToggle>;
  }
}

function mapStateToProps(state) {
  const { notifications } = state;
  return { newNotification: notifications.newNotification };
}

export default connect(mapStateToProps, {
  disposeNotification,
  showNotification,
})(Notifications);
